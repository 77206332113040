import React, { createContext, useState, useContext } from "react";

type ContextType = {
    isRetrato: boolean;
    setIsRetrato: (value: boolean) => void;
    indexTabPlayerView: number;
    setIndexTabPlayerView: (value: number) => void;
    indexTabMenuView: number;
    setIndexTabMenuView: (value: number) => void;
    indexTabRight: number;
    setIndexTabRight: (value: number) => void;

};

const ContextMain = createContext<ContextType>({
    isRetrato: false,
    setIsRetrato: (value: boolean) => { },
    indexTabPlayerView: 0,
    setIndexTabPlayerView: (value: number) => { },
    indexTabRight: 0,
    setIndexTabRight: (value: number) => { },
    indexTabMenuView: 0,
    setIndexTabMenuView: (value: number) => { },
});

const ProvidePlayer: React.FC = ({ children }) => {
    const [isRetrato, setIsRetrato] = useState<boolean>(false);

    const [indexTabPlayerView, setIndexTabPlayerView] = useState<number>(0)

    const [indexTabMenuView, setIndexTabMenuView] = useState<number>(0)

    const [indexTabRight, setIndexTabRight] = useState<number>(0)

    return (
        <ContextMain.Provider
            value={{
                isRetrato,
                setIsRetrato,
                indexTabPlayerView,
                setIndexTabPlayerView,
                indexTabMenuView,
                setIndexTabMenuView,
                indexTabRight,
                setIndexTabRight
            }}
        >
            {children}
        </ContextMain.Provider>
    );
};
export default ProvidePlayer;

export function useIsRetrato() {
    const info: ContextType = useContext(ContextMain);
    const { isRetrato, setIsRetrato } = info;
    return { isRetrato, setIsRetrato };
}


export function useIndexTabPlayerView() {
    const info: ContextType = useContext(ContextMain);
    const { indexTabPlayerView, setIndexTabPlayerView } = info;
    return { indexTabPlayerView, setIndexTabPlayerView };
}


export function useIndexTabMenuView() {
    const info: ContextType = useContext(ContextMain);
    const { indexTabMenuView, setIndexTabMenuView } = info;
    return { indexTabMenuView, setIndexTabMenuView };
}



export function useIndexTabRight() {
    const info: ContextType = useContext(ContextMain);
    const { indexTabRight, setIndexTabRight } = info;
    return { indexTabRight, setIndexTabRight };
}