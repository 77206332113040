import React from 'react';
import { useAuth } from '../../../hooks/Auth2';
import { useEvent } from '../../../hooks/EventContext';
import Survey from '../../Survey';
const Enquete: React.FC = () => {
    const { getUser } = useAuth();
    const user = getUser();
    const eventContext = useEvent();
    return (
        <>
            {eventContext.channel?.id && <Survey user={user} channelId={eventContext.channel?.id}></Survey>}
        </>
    )
}
export default Enquete;