import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Files from '../pages/Files';
import Lobby from '../pages/Lobby';
import LobbyGrid from '../pages/LobbyGrid';
import Stand3d from '../pages/Stand3d';
import Profile from '../pages/Profile';
import ProfileGrid from '../pages/ProfileGrid';
import Home from '../pages/Home';
import Default from '../pages/Default';
import LogOut from '../pages/LogOut';
import DashBoard2 from '../pages/Dashboard2';
import Schedule29 from '../pages/Schedules';
import Schedule30 from '../pages/Schedules/index2';
import Schedule01 from '../pages/Schedules/index3';
import Sponsors from '../pages/Sponsors';
import Partners2 from '../pages/Partners2';
import Partners from '../pages/Partners';
import Logistic from '../pages/Logistic';
import Feed from '../pages/Feed';
import Speakers from '../pages/Speakers';
import Schedule from '../pages/Schedule';
import Iframe from '../pages/IframeLp';

import DashboardGrid from '../pages/DashboardGrid';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import PrivacyPolicySeminarioIu from '../pages/PrivacyPolicy/seminario-iu';
import TermsAndConditions from '../pages/TermsAndConditions';
import Contact from '../pages/Contact';
import NotFound from '../pages/NotFound';
import Content from '../pages/Content';
import Products from '../pages/Products';
import Tips from '../pages/Tips';
import Surveys from '../pages/Surveys';
import SatisfactionSearch from '../pages/SatisfactionSearch';
import Ask from '../pages/Ask';
import SignInCallBack from '../pages/SignInCallBack';
import LogoutCallBack from '../pages/LogoutCallBack';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Default} />
    <Route path="/callback" component={SignInCallBack} />
    <Route path="/logout-callback" component={LogoutCallBack} />

    <Route path="/:event" exact component={Iframe} />
    <Route path="/:event/privacy-and-policy" component={PrivacyPolicy} />
    <Route path="/:event/privacy-and-policy-seminario-iu" component={PrivacyPolicySeminarioIu} />
    <Route path="/:event/terms-of-use" component={TermsAndConditions} />
    <Route path="/:event/contact" component={Contact} />
    <Route path="/:event/home" exact component={Home} />
    <Route path="/:event/signin" component={SignIn} />
    <Route path="/:event/callback/:data" component={SignInCallBack} />
    <Route path="/:event/signup" component={SignUp} />
    <Route path="/:event/forgot-password" component={ForgotPassword} />
    <Route path="/:event/reset-password" component={ResetPassword} />
    <Route
      path="/:event/dashboard2/:channel"
      component={DashBoard2}
      isPrivate
    />

    <Route
      path={['/:event/dashboardGrid/:channel', '/:event/dashboardGrid']}
      component={DashboardGrid}
      isPrivate
    />
    <Route
      path="/:event/dashboard/:channel/partners2"
      component={Partners2}
      isPrivate
    />
    <Route path="/:event/dashboard/partners" component={Partners2} isPrivate />
    <Route path="/:event/dashboard/sponsors" component={Sponsors} isPrivate />
    <Route path="/:event/dashboard/feeds" component={Feed} isPrivate />
    <Route path="/:event/dashboard/logistic" component={Logistic} isPrivate />
    <Route path="/:event/dashboard/contents" component={Content} isPrivate />
    <Route path="/:event/dashboard/products" component={Products} isPrivate />
    <Route path="/:event/dashboard/tips" component={Tips} isPrivate />
    <Route path="/:event/dashboard/surveys" component={Surveys} isPrivate />
    <Route
      path="/:event/dashboard/search"
      component={SatisfactionSearch}
      isPrivate
    />

    <Route path="/:event/dashboard/ask" component={Ask} isPrivate />
    <Route path="/:event/dashboard/speakers" component={Speakers} isPrivate />
    <Route path="/:event/dashboard/schedule" component={Schedule} isPrivate />

    <Route path="/:event/dashboard/:channel" component={DashBoard2} isPrivate />
    <Route path="/:event/schedule29" component={Schedule29} isPrivate />
    <Route path="/:event/schedule30" component={Schedule30} isPrivate />
    <Route path="/:event/schedule01" component={Schedule01} isPrivate />
    <Route path="/:event/dashboard" component={DashBoard2} isPrivate />
    <Route path="/:event/dashboard2" component={DashBoard2} isPrivate />
    <Route path="/:event/lobby" component={Lobby} isPrivate />
    <Route path="/:event/lobbyGrid" component={LobbyGrid} isPrivate />
    <Route path="/:event/stand3d" component={Stand3d} isPrivate />
    <Route path="/:event/files" component={Files} isPrivate />
    <Route path="/:event/logout" isPrivate component={LogOut} />
    <Route path="/:event/profile" component={ProfileGrid} isPrivate />
    <Route path="/:event/:channel" component={DashBoard2} isPrivate />
    <Route path="*" component={NotFound} />
  </Switch>
);

export default Routes;
