import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header2 from '../../components/Header2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserFriends,
  faHeart,
  faMoon,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';

import './styles.css';

import SideTabMenu from '../../components/SideTabMenu';
import { useEvent } from '../../hooks/EventContext';
import NewsFeatures from '../../components/NewsFeatures/index';
import FooterTerms from '../../components/FooterTerms';

const LobbyGrid: React.FC = (props) => {
  const { event: eventKey, channel: channelKey } =
    useParams<{ channel: string; event: string }>();
  const { t, i18n } = useTranslation();
  const { event, channel, ...eventContext } = useEvent();

  useEffect(() => {
    console.log('LobbyGrid useEffect', { eventKey, channelKey });

    if (eventKey && channelKey) eventContext.loadInfo(eventKey, channelKey);
  }, [eventKey, channelKey]);

  console.log('LobbyGrid', { event, channel });

  const speaker = [
    {
      cover:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FLayer%20673.png?alt=media&token=1e8c2256-8495-4bb2-84d1-6e9db29a871b',
      name: 'Plenária',
      hour: '12h',
      online: '778',
      like: '356',
      speakers: [
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ffuturehacker%2Fspeakers%2Fgui_rangel.png?alt=media&token=5fa77cc9-7796-44a6-a8bc-debc418a88f1',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Flp%2Fteam%2F4.jpg?alt=media&token=d25620ff-1d28-4adc-be81-3acea8236a34',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Fcustomization%2F5816f39c-ca63-4135-bb74-7362d55dd067?alt=media&token=542dbeb6-8cd8-4973-8de2-192ee245cce4',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Fcustomization%2F5816f39c-ca63-4135-bb74-7362d55dd067?alt=media&token=542dbeb6-8cd8-4973-8de2-192ee245cce4',
        },
      ],
      description:
        'With supporting text below as a natural lead-in to additional content.',
    },
    {
      cover:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FLayer%20673.png?alt=media&token=1e8c2256-8495-4bb2-84d1-6e9db29a871b',
      name: 'Face-Live',
      hour: '09h',
      online: '456',
      like: '32',
      speakers: [
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ffuturehacker%2Fspeakers%2Fgui_rangel.png?alt=media&token=5fa77cc9-7796-44a6-a8bc-debc418a88f1',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Flp%2Fteam%2F4.jpg?alt=media&token=d25620ff-1d28-4adc-be81-3acea8236a34',
        },
      ],
      description:
        'With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.',
    },
    {
      cover:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FLayer%20673.png?alt=media&token=1e8c2256-8495-4bb2-84d1-6e9db29a871b',
      name: 'Plenária 2',
      hour: '09h',
      online: '456',
      like: '32',
      speakers: [
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ffuturehacker%2Fspeakers%2Fgui_rangel.png?alt=media&token=5fa77cc9-7796-44a6-a8bc-debc418a88f1',
        },
      ],
      description:
        'With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.',
    },
    {
      cover:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FLayer%20673.png?alt=media&token=1e8c2256-8495-4bb2-84d1-6e9db29a871b',
      name: 'Face-Live',
      hour: '09h',
      online: '456',
      like: '32',
      speakers: [
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ffuturehacker%2Fspeakers%2Fgui_rangel.png?alt=media&token=5fa77cc9-7796-44a6-a8bc-debc418a88f1',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Flp%2Fteam%2F4.jpg?alt=media&token=d25620ff-1d28-4adc-be81-3acea8236a34',
        },
      ],
      description:
        'With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.',
    },
    {
      cover:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FLayer%20673.png?alt=media&token=1e8c2256-8495-4bb2-84d1-6e9db29a871b',
      name: 'Plenária',
      hour: '12h',
      online: '778',
      like: '356',
      speakers: [
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ffuturehacker%2Fspeakers%2Fgui_rangel.png?alt=media&token=5fa77cc9-7796-44a6-a8bc-debc418a88f1',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Flp%2Fteam%2F4.jpg?alt=media&token=d25620ff-1d28-4adc-be81-3acea8236a34',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Fcustomization%2F5816f39c-ca63-4135-bb74-7362d55dd067?alt=media&token=542dbeb6-8cd8-4973-8de2-192ee245cce4',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Fcustomization%2F5816f39c-ca63-4135-bb74-7362d55dd067?alt=media&token=542dbeb6-8cd8-4973-8de2-192ee245cce4',
        },
      ],
      description:
        'With supporting text below as a natural lead-in to additional content.',
    },
    {
      cover:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FLayer%20673.png?alt=media&token=1e8c2256-8495-4bb2-84d1-6e9db29a871b',
      name: 'Plenária',
      hour: '12h',
      online: '778',
      like: '356',
      speakers: [
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ffuturehacker%2Fspeakers%2Fgui_rangel.png?alt=media&token=5fa77cc9-7796-44a6-a8bc-debc418a88f1',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Flp%2Fteam%2F4.jpg?alt=media&token=d25620ff-1d28-4adc-be81-3acea8236a34',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Fcustomization%2F5816f39c-ca63-4135-bb74-7362d55dd067?alt=media&token=542dbeb6-8cd8-4973-8de2-192ee245cce4',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Fcustomization%2F5816f39c-ca63-4135-bb74-7362d55dd067?alt=media&token=542dbeb6-8cd8-4973-8de2-192ee245cce4',
        },
      ],
      description:
        'With supporting text below as a natural lead-in to additional content.',
    },
    {
      cover:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FLayer%20673.png?alt=media&token=1e8c2256-8495-4bb2-84d1-6e9db29a871b',
      name: 'Plenária',
      hour: '12h',
      online: '778',
      like: '356',
      speakers: [
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ffuturehacker%2Fspeakers%2Fgui_rangel.png?alt=media&token=5fa77cc9-7796-44a6-a8bc-debc418a88f1',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Flp%2Fteam%2F4.jpg?alt=media&token=d25620ff-1d28-4adc-be81-3acea8236a34',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Fcustomization%2F5816f39c-ca63-4135-bb74-7362d55dd067?alt=media&token=542dbeb6-8cd8-4973-8de2-192ee245cce4',
        },
      ],
      description:
        'With supporting text below as a natural lead-in to additional content.',
    },
    {
      cover:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FLayer%20673.png?alt=media&token=1e8c2256-8495-4bb2-84d1-6e9db29a871b',
      name: 'Plenária',
      hour: '12h',
      online: '778',
      like: '356',
      speakers: [
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ffuturehacker%2Fspeakers%2Fgui_rangel.png?alt=media&token=5fa77cc9-7796-44a6-a8bc-debc418a88f1',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Flp%2Fteam%2F4.jpg?alt=media&token=d25620ff-1d28-4adc-be81-3acea8236a34',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Fcustomization%2F5816f39c-ca63-4135-bb74-7362d55dd067?alt=media&token=542dbeb6-8cd8-4973-8de2-192ee245cce4',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Fcustomization%2F5816f39c-ca63-4135-bb74-7362d55dd067?alt=media&token=542dbeb6-8cd8-4973-8de2-192ee245cce4',
        },
      ],
      description:
        'With supporting text below as a natural lead-in to additional content.',
    },
    {
      cover:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FLayer%20673.png?alt=media&token=1e8c2256-8495-4bb2-84d1-6e9db29a871b',
      name: 'Face-Live',
      hour: '09h',
      online: '456',
      like: '32',
      speakers: [
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ffuturehacker%2Fspeakers%2Fgui_rangel.png?alt=media&token=5fa77cc9-7796-44a6-a8bc-debc418a88f1',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Flp%2Fteam%2F4.jpg?alt=media&token=d25620ff-1d28-4adc-be81-3acea8236a34',
        },
      ],
      description:
        'With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.',
    },
    {
      cover:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FLayer%20673.png?alt=media&token=1e8c2256-8495-4bb2-84d1-6e9db29a871b',
      name: 'Plenária 2',
      hour: '09h',
      online: '456',
      like: '32',
      speakers: [
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ffuturehacker%2Fspeakers%2Fgui_rangel.png?alt=media&token=5fa77cc9-7796-44a6-a8bc-debc418a88f1',
        },
      ],
      description:
        'With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.',
    },
    {
      cover:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FLayer%20673.png?alt=media&token=1e8c2256-8495-4bb2-84d1-6e9db29a871b',
      name: 'Face-Live',
      hour: '09h',
      online: '456',
      like: '32',
      speakers: [
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ffuturehacker%2Fspeakers%2Fgui_rangel.png?alt=media&token=5fa77cc9-7796-44a6-a8bc-debc418a88f1',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Flp%2Fteam%2F4.jpg?alt=media&token=d25620ff-1d28-4adc-be81-3acea8236a34',
        },
      ],
      description:
        'With supporting text below as a natural lead-in to additional content.With supporting text below as a natural lead-in to additional content.',
    },
    {
      cover:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FLayer%20673.png?alt=media&token=1e8c2256-8495-4bb2-84d1-6e9db29a871b',
      name: 'Plenária',
      hour: '12h',
      online: '778',
      like: '356',
      speakers: [
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ffuturehacker%2Fspeakers%2Fgui_rangel.png?alt=media&token=5fa77cc9-7796-44a6-a8bc-debc418a88f1',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Flp%2Fteam%2F4.jpg?alt=media&token=d25620ff-1d28-4adc-be81-3acea8236a34',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Fcustomization%2F5816f39c-ca63-4135-bb74-7362d55dd067?alt=media&token=542dbeb6-8cd8-4973-8de2-192ee245cce4',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Fcustomization%2F5816f39c-ca63-4135-bb74-7362d55dd067?alt=media&token=542dbeb6-8cd8-4973-8de2-192ee245cce4',
        },
      ],
      description:
        'With supporting text below as a natural lead-in to additional content.',
    },
    {
      cover:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FLayer%20673.png?alt=media&token=1e8c2256-8495-4bb2-84d1-6e9db29a871b',
      name: 'Plenária',
      hour: '12h',
      online: '778',
      like: '356',
      speakers: [
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ffuturehacker%2Fspeakers%2Fgui_rangel.png?alt=media&token=5fa77cc9-7796-44a6-a8bc-debc418a88f1',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Flp%2Fteam%2F4.jpg?alt=media&token=d25620ff-1d28-4adc-be81-3acea8236a34',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Fcustomization%2F5816f39c-ca63-4135-bb74-7362d55dd067?alt=media&token=542dbeb6-8cd8-4973-8de2-192ee245cce4',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Fcustomization%2F5816f39c-ca63-4135-bb74-7362d55dd067?alt=media&token=542dbeb6-8cd8-4973-8de2-192ee245cce4',
        },
      ],
      description:
        'With supporting text below as a natural lead-in to additional content.',
    },
    {
      cover:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FLayer%20673.png?alt=media&token=1e8c2256-8495-4bb2-84d1-6e9db29a871b',
      name: 'Plenária',
      hour: '12h',
      online: '778',
      like: '356',
      speakers: [
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ffuturehacker%2Fspeakers%2Fgui_rangel.png?alt=media&token=5fa77cc9-7796-44a6-a8bc-debc418a88f1',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Flp%2Fteam%2F4.jpg?alt=media&token=d25620ff-1d28-4adc-be81-3acea8236a34',
        },
        {
          cover:
            'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fnovalp%2Fcustomization%2F5816f39c-ca63-4135-bb74-7362d55dd067?alt=media&token=542dbeb6-8cd8-4973-8de2-192ee245cce4',
        },
      ],
      description:
        'With supporting text below as a natural lead-in to additional content.',
    },
  ];

  return (
    <>
      <Header2 />

      <div className="main-lobby">
        <div className="child1">
          <div className="channel">
            <h2>Sandbox</h2>
            <p>
              Sejam bem-vindos: <span>10/05 - 12h</span>
            </p>
          </div>
        </div>
        <img
          className="img-fluid-banner"
          src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Frstcom-sandbox%2Fcustomization%2F85286c54-bf04-46f5-8108-29fc4a4a14a6?alt=media&token=5eb1056e-9b1f-4014-b5b3-66340a9be617"
          alt=""
        />

        <div className="card-slide">
          <div className="title-card">
            <NewsFeatures />
          </div>
        </div>
        <FooterTerms />
      </div>
    </>
  );
};
export default LobbyGrid;
