import React from 'react';
import {useTheme} from '../../hooks/ThemeContext';
import {Container} from './style';
import './ModalProduct.css';
import { useTranslation } from 'react-i18next';

const ModalContent: React.FC<any> = ({
  id = 'modal',
  onClose = () => { },
  product,
}) => {
  const handleOutsideClick = (e: any) => {
    if (e.target.id === id) onClose();
  };
  const {theme} = useTheme();
  const { i18n } = useTranslation();

  return (
    <div id={id} className="modalProducts" onClick={handleOutsideClick}>
      <Container className="containerProducts" theme={theme}>
        <button className="close" onClick={onClose}></button>
        {product?.cover &&(
            <img
            className="img-fluid imgProductModal"
            src={product?.cover}
            alt=""
          />
        )}
        <div className="product">
          <div className='infosProductContainer'>
            <div className="inforProductDescription">
            <p
              className="p-li"
              dangerouslySetInnerHTML={{
                __html: product?.description[i18n.language],
              }}
              style={{lineBreak: 'anywhere', width: '100%'}}
            />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ModalContent;
