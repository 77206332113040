import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner, Conteiner_Lobby } from './style';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';
import { asEnumerable } from 'linq-es2015';
import { Link } from 'react-router-dom';
import { useEvent } from '../../../hooks/EventContext';
import { useAuth } from '../../../hooks/Auth2';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

interface ChatProps {
  user: any;
  channelId: string
  eventId: string
};



const GalleryVideoTeca: React.FC<ChatProps> = (props: any) => {
  const { t, i18n } = useTranslation();
  const firestore = firebase.firestore();
  const { addToast } = useToast();
  const [channels, setChannels] = useState<any[]>([]);
  const eventContext = useEvent();
  const [activePage, setActivePage] = useState('');
  const { getUser } = useAuth();
  const user = getUser();


  useEffect(() => {
    if (props.eventId && props.channelId) {
      const unsubscribe = firestore.collection('channel').where('eventId', '==', props.eventId).where('active', '==', true).onSnapshot((snapshot) => {
        const docs = snapshot.docs.map(d => d.data());
        const result = asEnumerable(docs).OrderBy((u: any) => u.order).ToArray();
        setChannels(result);

        const channel = asEnumerable(docs).FirstOrDefault((c: any) => c.id == props.channelId);
        if (channel) {
          eventContext.setChannel(channel);
        }
      });
      return () => {
        unsubscribe();
      }
    }

  }, [props.eventId, props.channelId]);

  function HeaderView() {
    let location = useLocation();
    let splt = location.pathname.split('/');

    if (splt[2] == 'lobby' || splt[2] == 'stand3d') {

      //setActivePage(splt[2]);
      return true;
    }

    else
      return false;
  }

  const hasHeaderView = HeaderView();

  return (
    <>
      {hasHeaderView ?
        <Conteiner_Lobby>
          {/* {channels.length > 0 &&
            <div>
              <br></br>
              <p className="texts" style={{ padding: '10px' }}>
                {t('Follow the rooms of our event')}
              </p>
              <hr />
            </div>
          } */}
          <div className="flex-container">

            {!channels.length &&
              <Spinner animation="border" role="status">
                <span className="sr-only">{t('Loading')}</span>
              </Spinner>}


            {channels.map((channel: any, i: number) => (


             
              (!props.params.channel || channel.id != eventContext.channel.id) && 

                channel?.key != 'stand3d' ?
                <React.Fragment key={i}>

                  {
                     (!!channel?.type && channel.type == 'videoteca') &&
                    (!!user?.restrictChannels && !!user?.restrictChannels[channel.key]) &&
                    <Link key={i} className="linkChannels" to=
                      {'/' + props.params.event + '/dashboard/' + channel.key}>
                      <div className="roomBox"  >
                        <div className="room">

                          {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}

                          {!!channel.picture && <div className="bgImage" style={{ backgroundImage: `url(${channel.picture})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>}

                          {
                            !!channel.name_languages &&
                            <span className="roomTitle">{channel.name_languages[i18n.language]}</span>
                          }

                          {
                            !channel.name_languages &&
                            <span className="roomTitle">{channel.name}</span>
                          }


                          {
                            <div className="roomBaseDescription">
                              <div className="roomDescription">
                                {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                                {
                                  !!channel?.description_languages &&
                                  <p className="roomName">{parse(`${channel?.description_languages[i18n.language]}`)}</p>

                                }

                                {
                                  !channel?.description_languages &&
                                  <p className="roomName">
                                    {parse(`${channel?.description}`)}
                                  </p>
                                }

                              </div>
                              <div className="roomDescription">
                                <p className="roomHour"> {channel.hour}</p>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </Link>
                  }


                  {
                     (!!channel?.type && channel.type == 'videoteca') &&
                    !user?.restrictChannels &&
                    <Link key={i} className="linkChannels" to=
                      {'/' + props.params.event + '/dashboard/' + channel.key}>
                      <div className="roomBox"  >
                        <div className="room">

                          {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}

                          {!!channel.picture && <div className="bgImage" style={{ backgroundImage: `url(${channel.picture})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>}

                          {
                            !!channel.name_languages &&
                            <span className="roomTitle">{channel.name_languages[i18n.language]}</span>
                          }

                          {
                            !channel.name_languages &&
                            <span className="roomTitle">{channel.name}</span>
                          }


                          {
                            <div className="roomBaseDescription">
                              <div className="roomDescription">
                                {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                                {
                                  !!channel?.description_languages &&
                                  <p className="roomName">{parse(`${channel?.description_languages[i18n.language]}`)}</p>

                                }

                                {
                                  !channel?.description_languages &&
                                  <p className="roomName">
                                    {parse(`${channel?.description}`)}
                                  </p>
                                }

                              </div>
                              <div className="roomDescription">
                                <p className="roomHour"> {channel.hour}</p>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </Link>

                  }


                </React.Fragment>
                :
                <React.Fragment key={i}>

                  {
                     (!!channel?.type && channel.type == 'videoteca') &&
                    (!!user?.restrictChannels && !!user?.restrictChannels[channel.key]) &&
                    <Link key={i} className="linkChannels" to=
                      {'/' + props.params.event + '/' + channel.key}>
                      <div className="roomBox"  >
                        <div className="room">

                          {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}

                          {!!channel.picture && <div className="bgImage" style={{ backgroundImage: `url(${channel.picture})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>}

                          {
                            !!channel.name_languages &&
                            <span className="roomTitle">{channel.name_languages[i18n.language]}</span>
                          }

                          {
                            !channel.name_languages &&
                            <span className="roomTitle">{channel.name}</span>
                          }


                          {
                            <div className="roomBaseDescription">
                              <div className="roomDescription">
                                {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                                {
                                  !!channel?.description_languages &&
                                  <p className="roomName">{parse(`${channel?.description_languages[i18n.language]}`)}</p>

                                }

                                {
                                  !channel?.description_languages &&
                                  <p className="roomName">
                                    {parse(`${channel?.description}`)}
                                  </p>
                                }

                              </div>
                              <div className="roomDescription">
                                <p className="roomHour"> {channel.hour}</p>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </Link>
                  }

                  {
                     (!!channel?.type && channel.type == 'videoteca') &&
                    !user?.restrictChannels &&
                    <Link key={i} className="linkChannels" to=
                      {'/' + props.params.event + '/' + channel.key}>
                      <div className="roomBox"  >
                        <div className="room">

                          {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}

                          {!!channel.picture && <div className="bgImage" style={{ backgroundImage: `url(${channel.picture})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>}

                          {
                            !!channel.name_languages &&
                            <span className="roomTitle">{channel.name_languages[i18n.language]}</span>
                          }

                          {
                            !channel.name_languages &&
                            <span className="roomTitle">{channel.name}</span>
                          }


                          {
                            <div className="roomBaseDescription">
                              <div className="roomDescription">
                                {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                                {
                                  !!channel?.description_languages &&
                                  <p className="roomName">{parse(`${channel?.description_languages[i18n.language]}`)}</p>

                                }

                                {
                                  !channel?.description_languages &&
                                  <p className="roomName">
                                    {parse(`${channel?.description}`)}
                                  </p>
                                }

                              </div>
                              <div className="roomDescription">
                                <p className="roomHour"> {channel.hour}</p>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </Link>

                  }

                </React.Fragment>

            ))}

          </div>
        </Conteiner_Lobby>
        :
        <Conteiner>

          <div className="flex-container">

            {!channels.length &&
              <Spinner animation="border" role="status">
                <span className="sr-only">{t('Loading')}</span>
              </Spinner>}


            {channels.map((channel: any, i: number) => (
              
              (!props.params.channel || channel.id != eventContext.channel.id) &&



              <React.Fragment key={i}>

                {
                  !!channel?.type && channel.type == 'videoteca' &&
                  (channel?.key != 'stand3d' && !!user?.restrictChannels && !!user?.restrictChannels[channel.key]) &&


                  <Link key={i} className="linkChannels" to=
                    {'/' + props.params.event + '/dashboard/' + channel.key}>
                    <div className="roomBox"  >
                      <div className="room">

                        {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}

                        {!!channel.picture && <div className="bgImage" style={{ backgroundImage: `url(${channel.picture})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>}

                        {
                          !!channel.name_languages &&
                          <span className="roomTitle">{channel.name_languages[i18n.language]}</span>
                        }

                        {
                          !channel.name_languages &&
                          <span className="roomTitle">{channel.name}</span>
                        }


                        {
                          <div className="roomBaseDescription">
                            <div className="roomDescription">
                              {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                              {
                                !!channel?.description_languages &&
                                <p className="roomName">{parse(`${channel?.description_languages[i18n.language]}`)}</p>

                              }

                              {
                                !channel?.description_languages &&
                                <p className="roomName">
                                  {parse(`${channel?.description}`)}
                                </p>
                              }

                            </div>
                            <div className="roomDescription">
                              <p className="roomHour"> {channel.hour}</p>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </Link>

                }

                {
                  !!channel?.type && channel.type == 'videoteca' &&
                  (channel?.key != 'stand3d' && !user?.restrictChannels) &&

                  <Link key={i} className="linkChannels" to=
                    {'/' + props.params.event + '/dashboard/' + channel.key}>
                    <div className="roomBox"  >
                      <div className="room">

                        {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}

                        {!!channel.picture && <div className="bgImage" style={{ backgroundImage: `url(${channel.picture})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>}

                        {
                          !!channel.name_languages &&
                          <span className="roomTitle">{channel.name_languages[i18n.language]}</span>
                        }

                        {
                          !channel.name_languages &&
                          <span className="roomTitle">{channel.name}</span>
                        }


                        {
                          <div className="roomBaseDescription">
                            <div className="roomDescription">
                              {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                              {
                                !!channel?.description_languages &&
                                <p className="roomName">{parse(`${channel?.description_languages[i18n.language]}`)}</p>

                              }

                              {
                                !channel?.description_languages &&
                                <p className="roomName">
                                  {parse(`${channel?.description}`)}
                                </p>
                              }

                            </div>
                            <div className="roomDescription">
                              <p className="roomHour"> {channel.hour}</p>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </Link>

                }



                {
                  !!channel?.type && channel.type == 'videoteca' &&
                  (channel?.key === 'stand3d' && !!user?.restrictChannels && !!user?.restrictChannels[channel.key]) &&
                  <Link key={i} className="linkChannels" to=
                    {'/' + props.params.event + '/' + channel.key}>
                    <div className="roomBox"  >
                      <div className="room">

                        {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}

                        {!!channel.picture && <div className="bgImage" style={{ backgroundImage: `url(${channel.picture})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>}

                        {
                          !!channel.name_languages &&
                          <span className="roomTitle">{channel.name_languages[i18n.language]}</span>
                        }

                        {
                          !channel.name_languages &&
                          <span className="roomTitle">{channel.name}</span>
                        }


                        {
                          <div className="roomBaseDescription">
                            <div className="roomDescription">
                              {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                              {
                                !!channel?.description_languages &&
                                <p className="roomName">{parse(`${channel?.description_languages[i18n.language]}`)}</p>

                              }

                              {
                                !channel?.description_languages &&
                                <p className="roomName">
                                  {parse(`${channel?.description}`)}
                                </p>
                              }

                            </div>
                            <div className="roomDescription">
                              <p className="roomHour"> {channel.hour}</p>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </Link>
                }

                {
                  !!channel?.type && channel.type == 'videoteca' &&
                  (channel?.key === 'stand3d' && !user?.restrictChannels) &&
                    <Link key={i} className="linkChannels" to=
                      {'/' + props.params.event + '/' + channel.key}>
                      <div className="roomBox"  >
                        <div className="room">

                          {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}

                          {!!channel.picture && <div className="bgImage" style={{ backgroundImage: `url(${channel.picture})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>}

                          {
                            !!channel.name_languages &&
                            <span className="roomTitle">{channel.name_languages[i18n.language]}</span>
                          }

                          {
                            !channel.name_languages &&
                            <span className="roomTitle">{channel.name}</span>
                          }


                          {
                            <div className="roomBaseDescription">
                              <div className="roomDescription">
                                {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                                {
                                  !!channel?.description_languages &&
                                  <p className="roomName">{parse(`${channel?.description_languages[i18n.language]}`)}</p>

                                }

                                {
                                  !channel?.description_languages &&
                                  <p className="roomName">
                                    {parse(`${channel?.description}`)}
                                  </p>
                                }

                              </div>
                              <div className="roomDescription">
                                <p className="roomHour"> {channel.hour}</p>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </Link>
                }

              </React.Fragment>

            ))}

          </div>
        </Conteiner>
      }
      <br></br>
      <div style={{ width: '100%' }}>
        <a href="" style={{ padding: 20, fontStyle: 'italic', fontSize: 12 }}>Caso as salas demorem para carregar, clique aqui.</a>

      </div>
      <br></br>
    </>
  );


};

export default GalleryVideoTeca;
