import React from 'react';

import { useAuth } from '../../../hooks/Auth2';
import { useEvent } from '../../../hooks/EventContext';
import QuestionToSpeaker from '../../QuestionToSpeaker';
const Pergunte: React.FC = (props?: any) => {
    const { getUser } = useAuth();
    const user = getUser();
    const eventContext = useEvent();
    return (
        <>
            {eventContext.channel?.id && <QuestionToSpeaker user={user} eventId={eventContext.event?.id} channelId={eventContext.channel?.id} {...props}></QuestionToSpeaker>}
        </>
    )
}
export default Pergunte;
