import React from 'react';
import { useParams } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useDimension';
import DashboardMasterPage from '../../masterpages/DashboardMasterPage2';
import DefaultMasterPage from '../../masterpages/DefaultMasterPage';
import { Container, Content } from './styles';
import { useAuth } from '../../hooks/Auth2';
import FooterTerms from '../../components/FooterTerms';

const PrivacyPolicy: React.FC = (props: any) => {
  const { signOut, getUser } = useAuth();
  const user = getUser();

  React.useEffect(() => {
    try {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } catch (error) {}
  }, []);

  const { event } = useParams<{ event?: string }>();
  return !user || (user && user.eventKey !== event) ? (
    <>
      <ContentPage />
      <FooterTerms />
    </>
  ) : (
    <DashboardMasterPage {...props}>
      <ContentPage />
    </DashboardMasterPage>
  );
};

const ContentPage = () => {
  const { event } = useParams<{ event?: string }>();

  const termos_de_uso = `${window.location.origin}/${event}/terms-of-use`;
  const url_politica = `${window.location.origin}/${event}/privacy-and-policy`;
  const url_evento = `${window.location.origin}/${event}`;

  return (
    <Container>
      <Content>
        <h1 className="privacyPolicy-title">
          POLÍTICA GERAL DE PRIVACIDADE E PROTEÇÃO DE DADOS
        </h1>
        <p>
          Nós da <b>RSTCOM</b> temos um compromisso com a proteção dos dados
          pessoais utilizados em nossas atividades. Esta Política de Privacidade
          e Proteção de Dados Pessoais foi desenvolvida cuidadosamente para que
          você, usuário dos nossos serviços, possa entender nossas políticas de
          uso e práticas sobre seus Dados Pessoais e como o tratamento destes
          dados é realizado.
        </p>
        <p>
          Esta Política abrange nossas atividades de coleta de dados no website
          acessível no endereço: <a href={url_politica}>{url_politica}</a>,
          disponibilizado e mantido pela <b>RSTCOM</b> que se aplica ao titular
          (pessoa natural identificada ou identificável) que interagem com os
          produtos e serviços da <b>RSTCOM</b> como consumidores. Esta Política
          explica as regras sobre a obtenção, uso dos dados coletados, como
          acessar e atualizar, tratamento, armazenamento, proteção dos dados,
          transferência, enriquecimento dos dados coletados dos usuários, além
          do registro de sua satisfação, de acordo com a lei em vigor no Brasil.
        </p>
        <p className="privacyPolicy-text-underline">
          A Política esclarece as condições de coleta, uso, armazenamento,
          tratamento e proteção dos dados na plataforma, em conformidade com a
          Lei Geral de Proteção de dados nº 13.709 de 14 de agosto de 2018 e o
          Decreto nº 869/2018.
        </p>
        <div className="privacyPolicy-text-block">
          <h2>1. Definição</h2>
          <ul className="privacyPolicy-list-type-letter">
            <li>
              <b> Dados Pessoais:</b> são informações relativas a uma pessoa
              identificada ou identificável. Dados pessoais que tenham sido
              descaracterizados, codificados ou anonimizados, mas que possam ser
              utilizados para identificar uma pessoa.
            </li>
            <li>
              <b>Usuário:</b> pessoas que acessam a plataforma 4yourlive pelo
              endereço <a href={url_evento}>{url_evento}</a>
            </li>
            <li>
              <b>Controlador:</b> “Pessoa natural ou jurídica, de direito
              público ou privado, a quem competem as decisões referentes ao
              tratamento de dados pessoais”. Em outras palavras, toda pessoa
              física ou jurídica que recolha informações pessoais é considerada
              um controlador.
            </li>
            <li>
              <b>Operador:</b> é a empresa ou profissional diretamente
              responsável pelo tratamento dos dados, direcionado ou contratado
              pelo controlador. Tanto o operador quanto o controlador devem
              manter registros sobre o tratamento de dados.
            </li>
            <li>
              <b>Dados anonimizados:</b> é descrito como “dado relativo ao
              titular que não possa ser identificado, considerando a utilização
              de meios técnicos razoáveis e disponíveis na ocasião de seu
              tratamento”. Em outras palavras, trata-se de uma informação que
              foi descaracterizada em algum nível para que o seu titular não
              possa mais ser identificado por meios simples, mas que ainda é
              importante para o controlador.
            </li>
            <li>
              <b>Consentimento:</b> manifestação livre, informada e inequívoca
              pela qual o titular concorda com o tratamento de seus dados
              pessoais para uma finalidade determinada.
            </li>
            <li>
              <b>Dado sensível:</b> são informações bem particulares e íntimas
              do titular, como dados relativos à etnia, opinião política,
              convicção religiosa ou sexual. Essas informações são tidas como
              delicadas e, por isso, o controlador só deve solicitá-las para
              finalidades bastante específicas.
            </li>
            <li>
              <b>Encarregado:</b> pessoa indicada pelo controlador e operador
              para atuar como canal de comunicação entre o controlador, os
              titulares dos dados e a Autoridade Nacional de Proteção de Dados.
            </li>
            <li>
              <b>Tratamento:</b> toda operação realizada com dados pessoais,
              como as que se referem a coleta, produção, recepção,
              classificação, utilização, acesso, reprodução, transmissão,
              distribuição, processamento, arquivamento, armazenamento,
              eliminação, avaliação ou controle da informação, modificação,
              comunicação, transferência, difusão ou extração.
            </li>
            <li>
              <b>Cookies:</b> são pequenos arquivos que são armazenados no
              dispositivo de um navegador da internet, quando ele acessa um site
              pela primeira vez. Eles são responsáveis por coletar e armazenar
              informações sobre a navegação dentro desse ambiente, permitindo
              que elas sejam usadas posteriormente.
            </li>
            <li>
              <b>Plataforma:</b> site disponibilizado por meio do link{' '}
              <a href={url_evento} target="_blank">
                {url_evento}
              </a>
              .
            </li>
          </ul>
        </div>
        <div className="privacyPolicy-text-block">
          <h2>2. Fontes de dados pessoais</h2>
          <h3>
            Nós coletamos seus Dados Pessoais através das seguintes fontes:
          </h3>
          <ul>
            <li>
              <b>Fale com o encarregado de proteção de dados (DPO):</b> É uma
              ferramenta para o usuário receber o atendimento de que precisa em
              qualquer assunto relacionado ao tratamento de seus dados pessoais.
            </li>
            <li>
              <b>
                Fale com um especialista / dúvidas / help desk – Via WhatsApp:
              </b>{' '}
              Disponibilizamos em nossa plataforma acesso ao WhatsApp para o
              usuário receber o atendimento de que precisa, tirar dúvidas,
              buscar informações a respeito do evento , cadastro, utilização da
              plataforma, problemas no uso do site.
            </li>
            <li>
              <b>Cookies:</b> São utilizadas para realizar métricas de
              performance da plataforma, identificar problemas no uso, captar o
              comportamento dos Usuários de forma geral e coletar dados de
              impressão de conteúdo.
            </li>
            <li>
              <b>Cadastro:</b> formulário de cadastro disponível na página de
              inscrição do evento ou na área de login da plataforma, onde o
              participante precisa cadastrar seus dados.
            </li>
            <li>
              <b>Timeline:</b> funcionalidade da plataforma onde o usuário pode
              compartilhar mensagens de texto e imagens.
            </li>
          </ul>
        </div>
        <div className="privacyPolicy-text-block">
          <h2>
            3. Quais são seus dados pessoais que coletamos e como estes são
            coletados
          </h2>
          <p>
            Durante sua interação junto ao site{' '}
            <a href={url_evento}>{url_evento}</a> usando uma das fontes de
            coleta previamente mencionadas, podemos coletar vários tipos de
            dados pessoais sobre você, conforme exposto a seguir:
          </p>
          <ul>
            <li>
              <b>Informações de dados diretos</b> Nome completo, CPF, CRM (no
              caso de eventos médicos),CFM(no caso de eventos farmacêuticos),
              RG, Fotos.
            </li>
            <li>
              <b>Informações de Contato:</b> Inclui qualquer tipo de informação
              que possa facilitar nosso contato com você, incluindo seu endereço
              físico de correspondência, números de telefone, endereços de
              correio eletrônico.
            </li>
            <li>
              <b>Informações demográficas e seus interesses:</b> Qualquer
              informação que possa descrever seus dados demográficos, faixa
              etária, gênero, localização geográfica, produtos favoritos
            </li>
            <li>
              <b>Dados Pessoais Sensíveis:</b> A <b>RSTCOM</b>, não lida
              normalmente com Dados Pessoais considerados sensíveis de acordo
              com a legislação vigente, dessa forma não temos a intenção de
              coletar ou processor dados pessoais sensíveis no curso normal de
              suas interações com nossos produtos ou serviços. Quando houver
              necessidade de processar seus dados pessoais sensíveis, por
              qualquer motivo, nós obteremos seu prévio, expresso e formal
              consentimento para qualquer processamento que for voluntário. Se
              processarmos seus dados pessoais sensíveis para outras
              finalidades, nós nos apoiamos nas seguintes bases legais: (i)
              detecção e prevenção de crime; e (ii) cumprimento da lei
              aplicável.
            </li>
          </ul>
        </div>
        <div className="privacyPolicy-text-block">
          <h2>4. Sobre a finalidade e uso de seus dados pessoais</h2>
          <p>
            Os itens a seguir descrevem as finalidades para as quais a{' '}
            <b>RSTCOM</b> coleta seus Dados Pessoais, e os diferentes tipos de
            Dados Pessoais que coletamos para cada finalidade. Note, por favor,
            que nem todos os usos abaixo serão relevantes para todos os
            indivíduos e podem se aplicar apenas a situações específicas.
          </p>
          <ul>
            <li>
              <p>
                <b>Fale com o encarregado de proteção de dados:</b> Com seu
                consentimento, seus Dados Pessoais são utilizados para
                finalidade de prestar serviços ao usuário, incluindo responder a
                suas dúvidas, questionamentos e solicitações no que se refere ao
                tratamento de seus dados pessoais. Usualmente, isso requer
                certas informações pessoais de contato e comprovação da sua
                identidade como titular dos dados em questão.
              </p>
              <ul>
                <li>
                  <b>Motivo para uso dos seus dados pessoais nessa situação:</b>
                  <ul>
                    <li>Cumprir obrigações legais;</li>
                    <li>Fazer contato.</li>
                  </ul>
                </li>
                <li>
                  <b>Nossos interesses legítimos nessa situação:</b>
                  <ul>
                    <li>
                      Melhorar continuamente produtos e serviços do{' '}
                      <b>RSTCOM</b> através do relacionamento com clientes,
                      fornecedores ou qualquer pessoa que queira se comunicar
                      com o <b>RSTCOM</b> através do canal Fale com o
                      encarregado de proteção de dados.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <b>Fale com um especialista / dúvidas/ help desk:</b> Com seu
                consentimento, seus Dados Pessoais são utilizados para
                finalidade de prestar serviços ao usuário, incluindo responder a
                suas dúvidas, questionamentos e sugestões. Usualmente, isso
                requer certas informações pessoais de contato e informações
                sobre o motivo de seu questionamento, dúvida ou sugestão, por
                exemplo, sobre um produto, questão ou reclamação sobre produto,
                questionamento em geral.
              </p>
              <ul>
                <li>
                  <b>Motivo para uso dos seus dados pessoais nessa situação:</b>
                  <ul>
                    <li>Cumprir obrigações legais;</li>
                    <li>Fazer contato.</li>
                  </ul>
                </li>
                <li>
                  <b>Nossos interesses legítimos nessa situação:</b>
                  <ul>
                    <li>
                      Melhorar continuamente produtos e serviços do{' '}
                      <b>RSTCOM</b> através do relacionamento com clientes,
                      fornecedores ou qualquer pessoa que queira se comunicar
                      com o <b>RSTCOM</b> através do canal Fale com um
                      especialista / dúvidas / help desk.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <b>Cadastro:</b> Com seu consentimento, seus Dados Pessoais são
                utilizados para finalidade de fazer a inscrição no evento
                digital e fornecer dados sobre a sua participação no evento
              </p>
              <ul>
                <li>
                  <b>Motivo para uso dos seus dados pessoais nessa situação:</b>
                  <ul>
                    <li>Cumprir obrigações legais;</li>
                    <li>
                      Controlar o acesso à plataforma somente aos participantes
                      cadastrados;
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Nossos interesses legítimos nessa situação:</b>
                  <ul>
                    <li>
                      Melhorar continuamente produtos e serviços do{' '}
                      <b>RSTCOM</b> através da análise dos dados de utilização
                      da plataforma
                    </li>
                    <li>Evitar acessos indevidos a eventos restritos</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <b>Timeline:</b> Na área da timeline da plataforma você pode
                compartilhar imagens e textos, inclusive imagens mostrando seu
                rosto. Ao compartilhar qualquer tipo de imagem você consente na
                ulitização de tais imagens pela RSTcom na relaização do evento
              </p>
              <ul>
                <li>
                  <b>Motivo para uso dos seus dados pessoais nessa situação:</b>
                  <ul>
                    <li>
                      Interesse legítimo da RSTcom no funcionamento da timeline;
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Nossos interesses legítimos nessa situação:</b>
                  <ul>
                    <li>
                      Oferecer a funcionalidade da timeline na plataforma
                      4yourlive como solução de interação entre os participantes
                      aos organizadores do evento
                    </li>
                    <li>
                      O funcionamento da timeline depende do compartilhamento
                      das imagens e textos
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="privacyPolicy-text-block">
          <h2>5. Com quem podemos compartilhar</h2>
          <p>
            Para a execução das atividades da plataforma, sempre que necessário,
            nós poderemos compartilhar os seus dados pessoais com outras
            empresas do nosso grupo, com prestadores de serviço, parceiros, com
            os contratantes e organizadores do evento ou com órgãos reguladores.
            Apresentamos a seguir um resumo destas possibilidades:
          </p>
          <ul>
            <li>
              Com nossos parceiros quando necessário e/ou apropriado à prestação
              de serviços relacionados.
            </li>
            <li>
              Com as empresas organizadoras e realizadores dos eventos digitais
              da qual a <b>RSTCOM</b> foi contratada para realizar utilizando a
              plataforma 4youlive.
            </li>
            <li>
              Com as empresas e indivíduos contratados para a execução de
              determinadas atividades e serviços em nome do <b>RSTCOM</b>
            </li>
            <li>Com empresas do grupo.</li>
            <li>
              Com fornecedores e parceiros para consecução dos serviços
              contratados com a <b>RSTCOM</b> (como tecnologia da informação,
              contabilidade, entre outros).
            </li>
            <li>
              Quando necessário em decorrência de obrigação legal, determinação
              de autoridade competente, ou decisão judicial
            </li>
          </ul>
        </div>
        <div className="privacyPolicy-text-block">
          <h2>
            6. Sobre a divulgação, o armazenamento ou transferência de seus
            dados pessoais
          </h2>
          <p>
            A <b>RSTCOM</b> adota medidas adequadas para garantir que seus Dados
            Pessoais sejam mantidos de forma confidencial e segura. Entretanto,
            que estas proteções não se aplicam a informações que você tenha
            escolhido compartilhar em áreas públicas, como redes sociais de
            terceiros.
          </p>
          <p>
            A base de dados coletada é de nossa propriedade e responsabilidade e
            não será comercializada ou vendida para terceiros, seu uso, acesso
            ou compartilhamento será utilizado dentro dos propósitos das
            atividades da <b>RSTCOM</b> podendo ser fornecido apenas mediante
            processo judicial.
          </p>
          <ul>
            <li>
              <b>Pessoas que podem acessar seus Dados Pessoais:</b> Seus Dados
              Pessoais serão processados por nossos colaboradores ou agentes
              autorizados, desde que estes precisem ter acesso a tais
              informações, dependendo dos propósitos específicos para os quais
              seus Dados Pessoais tenham sido coletados.
            </li>
            <li>
              <b>Medidas tomadas em ambientes operacionais:</b> Armazenamos seus
              Dados Pessoais em ambientes operacionais que usam medidas de
              segurança razoáveis, tanto técnicas quanto administrativas, para
              prevenir qualquer tipo de acesso não autorizado. Seguimos
              protocolos razoáveis para proteger Dados Pessoais.
            </li>
            <li>
              <b>Transferência internacional dos seus dados:</b> Seus dados
              pessoais não serão transferidos para outros países, excetuando-se
              as hipóteses previstas na legislação vigente, sendo observado
              nestas situações todos os requisitos estabelecidos na legislação
              vigente, e adotada as melhores práticas de mercado a fim de
              garantir a proteção e privacidade dos seus dados pessoais.
            </li>
            <li>
              <b>Medidas que a RSTCOM espera que você tome:</b> É importante que
              você também tenha um papel em manter seus Dados Pessoais seguros.
              Quando criar uma conta online, por favor, assegure-se de escolher
              uma senha que seja forte para evitar que partes não autorizadas a
              adivinhem. Recomendamos que você nunca revele ou compartilhe sua
              senha com outras pessoas. Você é o único responsável por manter
              esta senha confidencial.
            </li>
            <p>
              Se Você usar um computador compartilhado ou público, nunca escolha
              a opção de lembrar seu nome de login, endereço de e-mail ou senha,
              e certifique-se que você saiu da sua conta (realizou o log out)
              sempre que deixar o computador.
            </p>
            <p>
              A <b>RSTCOM</b> nunca solicitará ao Usuário que informe sua senha
              fora da Plataforma, por telefone, e-mail ou por qualquer outro
              meio de comunicação. A senha do Usuário deverá ser usada
              exclusivamente no momento do acesso à Conta do Usuário na
              Plataforma e, caso o Usuário suspeite que sua senha tenha sido
              exposta a terceiros, recomendamos a imediata substituição da
              senha.
            </p>
          </ul>
        </div>
        <div className="privacyPolicy-text-block">
          <h2>
            7. Sobre a retenção e término do tratamento de seus dados pessoais
          </h2>
          <p>
            De acordo com a legislação vigente, a <b>RSTCOM</b> utilizará seus
            Dados Pessoais pelo período máximo de 03 meses após a realização do
            evento, seguido do devido descarte ou mediante a solicitação pelo
            usuário.
          </p>
          <p>
            Dados Pessoais usados para fornecer uma experiência personalizada a
            Você serão mantidos exclusivamente pelo tempo permitido, de acordo
            com a legislação vigente.
          </p>
          <p>
            Você pode obter maiores detalhes sobre a retenção dos seus Dados
            Pessoais através dos canais de comunicação detalhados nesta
            política.
          </p>
          <p>
            Quando no término do tratamento de seus Dados Pessoais, estes serão
            eliminados no âmbito e nos limites técnicos das atividades,
            autorizada a conservação nas situações previstas na legislação
            vigente.
          </p>
        </div>
        <div className="privacyPolicy-text-block">
          <h2>8. Sobre dados pessoais de crianças e adolescentes</h2>
          <p>
            A <b>RSTCOM</b> não solicita, coleta, processa, armazena ou
            compartilha, conscientemente dados pessoais de crianças e
            adolescentes menores de idade. Se descobrirmos a ocorrência de
            qualquer tipo de manuseio dos referidos dados, forma
            não-intencional, removeremos os dados pessoais daquela criança ou
            adolescente de nossos registros rapidamente.
          </p>
          <p>
            Entretanto, a <b>RSTCOM</b> pode coletar dados pessoais de crianças
            e adolescentes diretamente dos seus pais ou responsáveis legais, e
            com consentimento explícito, e de acordo com as regras da legislação
            vigente.
          </p>
          <p className="privacyPolicy-text-underline">
            De acordo com a lei 13.709/2018 Art.14 (§ 1º O tratamento de dados
            pessoais de crianças deverá ser realizado com o consentimento
            específico e em destaque dado por pelo menos um dos pais ou pelo
            responsável legal.)
          </p>
        </div>
        <div className="privacyPolicy-text-block">
          <h2>9. Como utilizamos Cookies</h2>
          <p>
            Cookies são arquivos ou informações que podem ser armazenadas em
            seus dispositivos, quando você visita as Páginas do <b>RSTCOM</b>.
            Geralmente, um cookie contém o nome do site que o originou, seu
            tempo de vida e um valor, que é gerado aleatoriamente.
          </p>
          <p>
            A <b>RSTCOM</b> utiliza cookies para facilitar o uso e melhor
            adaptar as Páginas aos seus interesses e necessidades, bem como para
            compilarmos informações sobre a utilização de nossos sites e
            serviços, auxiliando a melhorar suas estruturas e seus conteúdos. Os
            cookies também podem ser utilizados para acelerar suas atividades e
            experiências futuras nas Páginas.
          </p>
          <ul>
            <li>
              <b>Cookies Necessários:</b> Esses cookies nos ajudam a entender
              como os visitantes interagem com as Páginas da <b>RSTCOM</b>,
              fornecendo informações sobre as áreas visitadas, o tempo de visita
              ao site e quaisquer problemas encontrados, como mensagens de erro.
            </li>
            <li>
              <b>Cookies de desempenho:</b> Esses Cookies que nos permitem
              entender como nossos usuários interagem com o site, avaliando
              páginas mais e menos acessadas e outras informações estatísticas.
            </li>
          </ul>
          <p>
            Por fim, lembramos que, caso o Usuário não aceite alguns cookies das
            Páginas da <b>RSTCOM</b>, certos serviços poderão não funcionar de
            maneira ideal.
          </p>
        </div>
        <div className="privacyPolicy-text-block">
          <h2>10. Sobre seus direitos referentes a dados pessoais</h2>
          <p>
            A transparência sobre o tratamento dos seus dados pessoais é
            prioridade para a <b>RSTCOM</b>. Além das informações
            disponibilizadas nesta Política de Privacidade, você pode também
            exercer os direitos previstos na Lei Geral de Proteção de Dados,
            entre eles:
          </p>
          <ul>
            <li>Confirmação da existência de tratamento.</li>
            <li>Acesso aos dados.</li>
            <li>Correção de dados incompletos, inexatos ou desatualizados.</li>
            <li>
              Anonimização, bloqueio ou eliminação de dados desnecessários,
              excessivos ou tratados em desconformidade com a legislação
              vigente.
            </li>
            <li>
              Portabilidade dos dados a outro fornecedor de serviço ou produto,
              mediante requisição expressa, em conformidade com a legislação
              vigente.
            </li>
            <li>
              Eliminação dos dados pessoais tratados com o consentimento do
              titular, excetuando-se as hipóteses previstas na legislação
              vigente.
            </li>
            <li>
              Informação das entidades públicas e privadas com as quais os dados
              são compartilhados.
            </li>
            <li>
              Informação sobre a possibilidade de não fornecer consentimento e
              sobre as consequências da negativa.
            </li>
            <li>
              Revogação do consentimento para o tratamento dos seus Dados
              Pessoais, excetuando-se as hipóteses previstas na legislação
              vigente.
            </li>
          </ul>
          <p>
            Estes direitos podem ser exercidos através dos canais de comunicação
            detalhados nesta política, de forma gratuita sendo necessário à
            validação da sua identidade através do fornecimento de uma cópia de
            seu RG ou meios equivalentes de identificação, em conformidade com a
            legislação vigente.
          </p>
          <p>
            Sempre que um pedido for submetido sem o fornecimento das provas
            necessárias a comprovação da legitimidade do titular dos dados, o
            pedido será automaticamente rejeitado. Ressaltamos que qualquer
            informação de identificação fornecida a <b>RSTCOM</b> somente será
            processada de acordo com, e na medida permitida pelas leis vigentes.
          </p>
          <p>
            Ressaltamos que em determinados casos, não podermos excluir seus
            Dados Pessoais sem também excluir sua conta de usuário.
            Adicionalmente, algumas situações requerem a retenção de seus Dados
            Pessoais depois que você pedir sua exclusão, para satisfazer
            obrigações legais ou contratuais.
          </p>
          <p>
            A <b>RSTCOM</b> faz o máximo possível para poder atender todas as
            questões que você possa ter sobre a forma a qual processamos seus
            Dados Pessoais. Contudo, se você tiver preocupações não resolvidas,
            você tem o direito de reclamar às autoridades de proteção de dados
            competentes.
          </p>
        </div>
        <div className="privacyPolicy-text-block">
          <h2>11. Alterações em nossa Política de Privacidade</h2>
          <p>
            Sempre que a <b>RSTCOM</b> decidir mudar a forma que tratamos seus
            Dados Pessoais, esta Política será atualizada. Nos reservamos o
            direito de fazer alterações às nossas práticas e a esta Política a
            qualquer tempo, desde que mantida a conformidade com a legislação
            vigente.
            <span className="privacyPolicy-text-underline">
              Toda e qualquer alteração visa a se adequar às eventuais
              modificações em nossos Sites, sejam de mudanças para novas
              tecnologias ou sempre que for necessário, bem como a novos
              requisitos legais, regulatórios ou contratuais. Quando isso
              acontecer, informaremos na própria plataforma do site, sempre que
              o usuário acessar.
            </span>
          </p>
        </div>
        <div className="privacyPolicy-text-block">
          <h2>12. Como entrar em contato</h2>
          Sempre que você tiver alguma dúvida sobre esta Política de
          Privacidade, mesmo após sua leitura, ou precisar interagir conosco
          sobre assuntos envolvendo os seus dados pessoais, poderá fazê-lo por
          meio dos nossos canais de atendimento disponíveis:
          <ul>
            <li>
              <p>
                <b>Encarregado pelo Tratamento de Dados Pessoais:</b>
              </p>
              <ul>
                <li>
                  E-mail do encarregado de dados:{' '}
                  <span className="privacyPolicy-text-underline">
                    dpo@rstcom.net
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            A <b>RSTCOM</b> receberá, investigará e responderá, dentro de um
            prazo considerado razoável, qualquer solicitação ou reclamação sobre
            a forma como Nós tratamos seus Dados Pessoais, incluindo reclamações
            sobre desrespeito aos seus direitos sob as leis de privacidade e
            proteção de Dados Pessoais vigentes.
          </p>
          <p>
            Recomendamos que verifiquem a versão atualizada desta Política de
            Privacidade sempre que navegarem em nossas lojas virtuais. Estamos
            sempre à disposição para esclarecer suas dúvidas e colocar você no
            controle dos seus dados pessoais. Contem conosco para mantê-los
            informados.
          </p>
        </div>
        <div className="privacyPolicy-text-block">
          <h2>13. Legislação de foro aplicável:</h2>
          <p>
            A base legal apresentada da política de privacidade, será
            interpretada segundo a legislação brasileira pela lei 13.709/2018,
            na atualização da medida provisória nº 869/18.
          </p>
          <p>
            Este documento é regido e deve ser interpretado de acordo com as
            leis da República Federativa do Brasil. Fica eleito o Foro da
            Comarca de São Paulo, como o competente para dirimir quaisquer
            questões porventura oriundas do presente documento, com expressa
            renúncia a qualquer outro, por mais privilegiado que seja.
          </p>

          <br />
          <p>São Paulo, 07 de abril 2022</p>
          <p>
            <b>RSTCOM Comunicação Estratégica.</b>
          </p>
        </div>
      </Content>
    </Container>
  );
};
export default PrivacyPolicy;
