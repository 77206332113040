import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ContainerPros {
  customization: any;
}

export const Container = styled.div<ContainerPros>`
  padding-top: 50px;
  min-height: 800px;

  .table-responsive {
    background: #002C4E;
    opacity: 0.75;
  }
`;
