import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuth } from '../../hooks/Auth2';
import { useEvent } from '../../hooks/EventContext';
import DashboardMasterPage2 from '../../masterpages/DashboardMasterPage2';
import asEnumerable from 'linq-es2015';
import './styles.css';
import { useWindowDimensions } from '../../hooks/useDimension';
import { IconsList } from '../../components/Icons';
import { Tabs, Tab } from '../../components/Tabs/tabs';
import useParam from '../../components/Tabs/use-param';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import HeaderTitle from '../../components/HeaderTitle';
import * as S from './styles';
import CardSlideChannel from '../../components/NewsFeatures/CardSlideChannel';

const Lobby: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { getUser } = useAuth();
  const { event: eventKey, channel: channelKey } = useParams<{
    channel: string;
    event: string;
  }>();

  const user = getUser();
  const { width } = useWindowDimensions();
  const widthVideoSS =
    width <= 500
      ? width - 50
      : Number(
          width >= 1500
            ? width >= 1500 && width <= 1920
              ? Number(1500)
              : Number(1500 - 150)
            : width - 150,
        );

  const { channels, channel, event } = useEvent();
  const [groups, setGroups] = useState<string[]>();
  const [hasSimpleChannels, setHasSimpleChannels] = useState(false);
  const [selectedTab, setSelectedTab] = useState('channels');

  useEffect(() => {
    let docs = channels;

    let _userRestrictChannels: string[] = [];

    if (user?.restrictChannels && Object.keys(user?.restrictChannels).length) {
      _userRestrictChannels = Object.keys(user?.restrictChannels);

      console.log('restrict', { _userRestrictChannels, docs });

      if (_userRestrictChannels.length) {
        docs = docs.filter(
          (doc) =>
            _userRestrictChannels.findIndex(
              (channelKey) => channelKey?.trim() === doc.key?.trim(),
            ) >= 0,
        );
      }
    }

    const _hasSimpleChannel =
      docs?.filter(
        (d) =>
          d.type_room !== 'eventShowRoom' && !d.customization?.channel_group,
      ).length > 0;

    let channelGroups = docs
      ?.filter((d) => d.customization.channel_group)
      .map<string>((d) => d.customization.channel_group);

    channelGroups = Array.from(new Set<string>(channelGroups));
    channelGroups = channelGroups.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

    console.log('looby channel groups', {
      channelGroups,
      _hasSimpleChannel,
      docs,
    });

    if (
      channelGroups?.length &&
      !_hasSimpleChannel &&
      channelGroups.indexOf(selectedTab) < 0
    )
      setSelectedTab(channelGroups[0]);

    setGroups(channelGroups);
    setHasSimpleChannels(_hasSimpleChannel);
  }, [channels]);

  console.log('Looby', {
    selectedTab,
    eventKey,
    channelKey,
    event,
    channels,
    channel,
    groups,
  });

  useEffect(() => {
    let arr = [
      'group_',
      'channels',
      'cloudword',
      'timeline',
      'gallery',
      'externallink',
    ];

    const $features = [];

    for (let i = 0; i < event?.resources.length; i++) {
      let found = arr.find((el) => el == event?.resources[i].key);

      if (found) $features.push(found);
    }
    if ($features.length) {
      setSelectedTab($features[0]);
    }
  }, []);

  return (
    <DashboardMasterPage2
      background={event?.customization?.backgroundImg}
      {...props}
    >
      <S.Container>
        <HeaderTitle
          theaterMode={false}
          eventRoom="Lobby"
          eventDate={event?.eventdate}
        />
        <S.ImageBanner src={event?.customization?.bannerlobby} />
        <S.ContainerFeatures>
          <Tabs selected={selectedTab} onSelect={setSelectedTab}>
            {asEnumerable(event?.resources || []).Any(
              (r: any) => r.key == 'channels',
            ) &&
              hasSimpleChannels && (
                <Tab
                  id="channels"
                  label={t('Rooms')}
                  icon={IconsList.iconChannels}
                >
                  <CardSlideChannel
                    eventId={event?.id}
                    channelId={channel?.id}
                    {...props}
                  ></CardSlideChannel>
                </Tab>
              )}

            {groups?.map((g) => (
              <Tab
                key={`group_${g}`}
                id={`group_${g}`}
                label={t(g)}
                icon={IconsList.iconChannels}
              >
                <CardSlideChannel
                  eventId={event?.id}
                  channelId={channel?.id}
                  group={g}
                  {...props}
                />
              </Tab>
            ))}
          </Tabs>
        </S.ContainerFeatures>
      </S.Container>
    </DashboardMasterPage2>
  );
};

export default Lobby;
