import React from 'react';

interface IProps {
  id: string,
  selected: boolean;
}
export const TabPanel: React.FC<IProps> = ({ id, selected, children }) => {
  return (
    <div className={`tab-panel ${selected && 'tab-panel--active'}`} data-id={id}>
      {children}
    </div>
  );
}
