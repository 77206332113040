import React, {
  ButtonHTMLAttributes,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner } from './style';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import api from '../../services/api';
import { asEnumerable } from 'linq-es2015';
import { useToast } from '../../hooks/Toast';
import $ from 'jquery';
import { useEvent } from '../../hooks/EventContext';
import useWindowDimensions from '../../hooks/useDimension';
import { useAuth } from '../../hooks/Auth2';
import { useIsRetrato } from '../../context/contextplayer';

import iconArrowRight from '../../assets/images/icons/icon-arrow-right.svg';
import { useTheme } from '../../hooks/ThemeContext';
import { useTranslation } from 'react-i18next';
interface Props {
  user: any;
  channelId: string;
  style?: any;
}

const Links: React.FC<Props> = (props) => {
  const { getUser } = useAuth();
  const { theme } = useTheme();
  const { t } = useTranslation();

  const { links } = useEvent();

  return (
    <Conteiner id="link" theme={theme}>
      {/* <p className="bp-title mb-4">Links</p> */}

      <div
        className="container-links"
        // className="table-responsive"
      >
        <div className="links-header">
          <h3>{t("We reserved important links for you!")}</h3>
        </div>
        <div className="content-links">
          {links.map((item: any, index: number) => (
            <a
              target="_blank"
              key={index}
              href={`//${item.url}`}
              className="link-item"
            >
              {`${index + 1}. `}
              {item.name}
              <img
                src={iconArrowRight}
                alt=""
                width="100%"
                className="links-icon"
              />
            </a>
          ))}
        </div>
      </div>
    </Conteiner>
  );
};

export default Links;
