import React, { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactElement;
  icon: ReactNode;
  title: string;
}

export default function TabScreen(props: Props): ReactElement {
  const { children } = props;
  return children;
}
