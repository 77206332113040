import 'firebase/auth';
import 'firebase/firestore';

import firebase from 'firebase/app';

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useInterval } from 'beautiful-react-hooks';
import moment from 'moment';
import { uuid } from 'uuidv4';

import { Util } from '../utils/util';
import api from '../services/api';
import { AUTH_URL } from '../services';
import config from '../config';

export interface IUser {
  eventKey: string;
  id: string;
  role: string;
  name: string;
  surname: string;
  email: string;
  avatar_url: string;
  password?: string;
  fields: any;
  restrictChannels?: any;
  isModerator: boolean;
  isAttendance: boolean;
  avatar?: string;
  uid?: string;
}

interface SignInCredentials {
  email: string;
  password: string;
  eventKey: string;
  pagestart: string;
  event?: any;
}

interface SignInSSOCredentials {
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email_verified: boolean;
  sub: string;
  email: string;
  password?: string;
  eventKey: string;
  pagestart?: string;
  event?: any;
}

interface AuthContextData {
  getUser(): IUser | null;
  signIn(credentials: SignInCredentials): Promise<IUser>;
  signInSSO(
    credentials: Partial<SignInSSOCredentials>,
    event?: any,
  ): Promise<IUser>;
  signOut(): Promise<void>;
  updateUser(user: IUser): void;
  setUser(user: IUser): void;
  getSessionId(): string;
  token_facelive: string;
} 

const Auth = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  let history = useHistory();
  let location = useLocation();

  const [tabId, setTabId] = useState('');
  const tabIdRef = useRef('');
  const [skipSignOut, setSkipSignOut] = useState<boolean>(false);

  // useEffect(() => {
  //   const unsubscribe = firebase.auth().onAuthStateChanged(async (firebaseUser: firebase.User | null) => {
  //     if (firebaseUser) {
  //       if (!user || user.refreshToken != firebaseUser.refreshToken) {

  //         setUser(firebaseUser);
  //         await updateToken(firebaseUser);
  //         if (location.pathname.indexOf("/dashboard") == -1) {
  //           history.replace("dashboard");
  //         }
  //       }
  //     }
  //   });

  //   return () => {
  //     unsubscribe();
  //   }
  // }, [user]);

  const [user, setUser] = useState<any>(() => {
    const user = localStorage.getItem('@Storage:user');
    if (user) {
      return JSON.parse(user);
    }
    return null;
  });

  useEffect(() => {
    console.log('useerr auth2', user);
  }, [user]);

  const [token, setToken] = useState<string>(() => {
    const token = localStorage.getItem('@Storage:token');

    if (token) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return token;
    }
    return '';
  });
  const [token_facelive, setTokenFacelive] = useState<string>(() => {
    const $token_facelive = localStorage.getItem('@Storage:token_facelive');

    if ($token_facelive) {
      return $token_facelive;
    }
    return '';
  });
  const [customToken, setCustomToken] = useState<string>(() => {
    const $customToken = localStorage.getItem('@Storage:customToken');

    if ($customToken) {
      return $customToken;
    }
    return '';
  });

  useEffect(() => {
    if (customToken) {
      firebase
        .auth()
        .signInWithCustomToken(customToken)
        .then((user) => {})
        .catch((e) => {
          localStorage.removeItem('@Storage:customToken');

          console.log('User Firebase Auth error', e);
        });
    }

    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (firebaseUser: firebase.User | null) => {
        if (firebaseUser) {
          // if (!user || user.refreshToken != firebaseUser.refreshToken) {
          //   // setUser(firebaseUser);
          //   // await updateToken(firebaseUser);
          //   // if (location.pathname.indexOf('/dashboard') == -1) {
          //   //   history.replace('dashboard');
          //   // }
          // }
        }
      });

    return () => {
      unsubscribe();
    };
  }, [customToken]);

  const firestore = firebase.firestore();
  useEffect(() => {
    if (user && user.eventId) {
      firestore
        .collection('logout')
        .doc(user.eventId)
        .get()
        .then((ref) => {
          if (ref.exists) {
            const unsubscribe = firestore
              .collection('logout')
              .doc(user.eventId)
              .onSnapshot((snapshot) => {
                const data = snapshot.data();

                console.log('snapshot signout', { data });

                data?.uids.map((uid: any) => {
                  if (uid === user.id) {
                    // signOut();
                  }
                });
                //setTimeline(items);
              });
            return () => {
              unsubscribe();
            };
          }
        });
    }
  }, [user]);

  useEffect(() => {
    console.log('useEffect register listener logout', user);
    if (user && user.id) {
      const unsubscrible = firestore
        .collection('system_messages')
        .doc(user.id)
        .collection('logout')
        .orderBy('time', 'desc')
        .limit(1)
        .onSnapshot(
          (snapshot) => {
            snapshot.docChanges().forEach((change) => {
              console.log('system_messages', {
                change,
                data: change?.doc?.data(),
              });

              if (change.type === 'added') {
                const date = moment();
                const system_message_date = moment(change?.doc?.data()?.time);
                const diff = date.diff(system_message_date, 'millisecond');

                console.log('system_messages added', {
                  data: change?.doc?.data(),
                  sessionId: tabIdRef.current,
                  diff,
                });

                // if (diff <= 1000) {
                if (
                  (change?.doc?.data()?.sessionId &&
                    change?.doc?.data()?.sessionId !== tabIdRef.current &&
                    diff <= 5000) ||
                  (!change?.doc?.data()?.sessionId && diff <= 1000)
                ) {
                  setToken('');
                  setUser(null);
                  localStorage.removeItem('@Storage:token');
                  localStorage.removeItem('@Storage:user');
                  localStorage.removeItem('@Storage:usertoken');
                }
              }
            });
          },
          (err) => {
            console.log('logout snapshot err', err);
          },
        );

      return () => {
        unsubscrible?.();
      };
    }
  }, [user, tabId]);

  useEffect(() => {
    if (
      (window?.performance?.getEntriesByType('navigation')?.[0] as any)
        ?.type === 'back_forward'
    ) {
      sessionStorage.removeItem('4yourlive:tabid');
      console.log('tab duplicada');
    }

    tabIdRef.current =
      tabIdRef.current || sessionStorage.getItem('4yourlive:tabid') || '';

    if (!tabIdRef.current) {
      tabIdRef.current = uuid();
      sessionStorage.setItem('4yourlive:tabid', tabIdRef.current);
    }

    setTabId(tabIdRef.current);
  }, []);

  function updateToken(refreshToken: string) {
    api.defaults.headers.authorization = `Bearer ${refreshToken}`;
    localStorage.setItem('@Storage:token', refreshToken);
    // setToken(refreshToken);
    return token;
  }

  async function updateUser(user: any) {
    const userConverted: IUser = user?.displayName
      ? {
          avatar_url: user?.photoURL || '',
          name: user?.displayName || '',
          email: user?.email || '',
          id: user?.uid || '',
        }
      : user;

    //const profile = await api.get("/profile");
    localStorage.setItem('@Storage:user', JSON.stringify(userConverted));

    setUser(user);
    return userConverted;
  }

  async function updateTokenFaceLive($token_facelive: any) {
    localStorage.setItem(
      '@Storage:token_facelive',
      JSON.stringify($token_facelive),
    );
    setTokenFacelive($token_facelive);
    return $token_facelive;
  }

  const getUser = (): IUser | null => {
    if (!user) return null;

    return {
      ...user,
      isModerator: user?.role === 'moderator' || user?.role === 'attendance',
      isAttendance: user?.role === 'attendance',
    };
  };

  // const signIn = useCallback(async ({ email, password, eventKey, pagestart }) => {

  //   const result = await api.post("/sessions/login", {
  //     email,
  //     password,
  //     eventKey
  //   });

  //   localStorage.setItem('@Storage:usertoken', result.data.token);

  //   const signedUser = await firebase.auth().signInWithCustomToken(result.data.token);
  //   updateToken(await signedUser?.user?.getIdToken(true) || "");
  //   const user = updateUser(result.data.user);
  //   setSkipSignOut(true);

  //   if(pagestart){
  //     document.location.href = pagestart;
  //   }
  //   else
  //   {
  //     document.location.href = `/${eventKey}/`;
  //   }

  //   return user;
  // }, []);

  const signIn = async ({
    email,
    password,
    eventKey,
    pagestart,
    event,
  }: any) => {
    let result: any = null;
    // try 3x

    let loginUri = '/sessions/login';

    // if (window.location.href.indexOf('rstcom-sandbox') >= 0)
    //   loginUri = AUTH_URL;
    const data = { email, password, eventKey, sessionId: tabIdRef.current };

    try {
      result = await api.post(loginUri, data);
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }

      try {
        await Util.sleep(3);
        result = await api.post('/sessions/login', data);
      } catch (error2) {
        try {
          await Util.sleep(3);
          result = await api.post('/sessions/login', data);
        } catch (error3) {
          throw error3;
        }
      }
    }

    localStorage.setItem('@Storage:usertoken', result.data.token);
    localStorage.setItem('@Storage:customToken', result.data.firebase_token);

    // const signedUser = await firebase
    //   .auth()
    //   .signInWithCustomToken(result.data.token);
    // updateToken((await signedUser?.user?.getIdToken(true)) || '');
    await firebase
      .auth()
      .signInWithCustomToken(result.data.firebase_token)
      .then(() => {
        console.log('firebase auth success');
      })
      .catch((e) => {
        console.log('firebase auth error', e);
      });

    if (event?.settings?.redirect?.enabled) {
      window.location.href = event?.settings?.redirect?.url;
      return result.data.user;
    }

    updateToken(result.data.token);
    setCustomToken(result.data.firebase_token);
    const $user = updateUser(result.data.user);
    // const token_facelive = updateTokenFaceLive(result.data.token_facelive);
    setSkipSignOut(true);

    if (pagestart) {
      // document.location.href = pagestart;
      if (result?.data?.user?.restrictChannels) {
        const _keys = Object.keys(result?.data?.user?.restrictChannels);
        if (_keys.length === 1) {
          history.push(`/${eventKey}/dashboard/${_keys}`);
        } else {
          history.push(pagestart);
        }
      } else {
        if (
          result.data.user.statusRSVP === 2 ||
          result.data.user.statusRSVP === 0
        ) {
          throw new Error();
        } else {
          history.push(pagestart);
        }
      }
    } else {
      // document.location.href = `/${eventKey}/`;
      history.push(`/${eventKey}/`);
    }

    return $user;
  };

  const signInSSO = async (data: any, event: any) => {
    const eventKey = event.key;
    const pagestart = event?.pagestart;

    localStorage.setItem('@Storage:usertoken', data.token);
    localStorage.setItem('@Storage:customToken', data.firebase_token);

    // const signedUser = await firebase
    //   .auth()
    //   .signInWithCustomToken(result.data.token);
    // updateToken((await signedUser?.user?.getIdToken(true)) || '');
    await firebase
      .auth()
      .signInWithCustomToken(data.firebase_token)
      .then(() => {
        console.log('firebase auth success');
      })
      .catch((e) => {
        console.log('firebase auth error', e);
      });

    if (event?.settings?.redirect?.enabled) {
      window.location.href = event?.settings?.redirect?.url;

      return data.user;
    }

    updateToken(data.token);
    setCustomToken(data.firebase_token);
    const $user = updateUser(data.user);
    // const token_facelive = updateTokenFaceLive(result.data.token_facelive);
    setSkipSignOut(true);

    if (pagestart) {
      const _pageStart = `/${eventKey}/${pagestart}`;
      // document.location.href = pagestart;
      if (data?.user?.restrictChannels) {
        const _keys = Object.keys(data?.user?.restrictChannels);
        if (_keys.length === 1) {
          history.push(`/${eventKey}/dashboard/${_keys}`);
        } else {
          history.push(_pageStart);
        }
      } else {
        if (data.user.statusRSVP === 2 || data.user.statusRSVP === 0) {
          throw new Error();
        } else {
          history.push(_pageStart);
        }
      }
    } else {
      // document.location.href = `/${eventKey}/`;
      history.push(`/${eventKey}/`);
    }

    return $user;
  };

  const signOut = useCallback(async () => {
    const $token = localStorage.getItem('@Storage:token');

    if ($token) {
      try {
        api.post('/sessions/logout');
      } catch (error) {
        try {
          api.post('/sessions/logout');
        } catch (e) {
          console.log('logout error', e);
        }
      }

      setToken('');
      setUser(null);
      localStorage.removeItem('@Storage:token');
      localStorage.removeItem('@Storage:user');
      localStorage.removeItem('@Storage:usertoken');
    }
  }, []);

  const getSessionId = () => {
    return tabIdRef.current;
  };

  // const updateUser = useCallback(
  //   (user: User) => {
  //     localStorage.setItem('@Storage:user', JSON.stringify(user));

  //     let currentUser = firebase.auth().currentUser;
  //     if (currentUser) {
  //       let updatedUser = { ...currentUser, ...user };
  //       firebase.auth().updateCurrentUser(updatedUser);
  //     }

  //     setData({
  //       token: data.token,
  //       user
  //     });
  //   },
  //   [setData, data.token],
  // );

  document.onkeydown = fkey;
  document.onkeypress = fkey;
  document.onkeyup = fkey;

  let wasPressed = false;

  function fkey(e: any) {
    e = e || window.event;
    if (wasPressed) return;

    if (e.keyCode == 116) {
      wasPressed = true;
    }
  }

  window.onbeforeunload = function (e: any) {
    const ev = e;
    if (
      window?.document?.activeElement?.localName == 'body' &&
      !wasPressed &&
      !skipSignOut &&
      user?.id
    ) {
      //signOut();
    }
  };

  async function keepAlive(): Promise<void> {
    try {
      const userToken = localStorage.getItem('@Storage:usertoken') as string;
      const sessionChannel = sessionStorage.getItem(
        '@Storage:channelKey',
      ) as string;
      const sessionChannelId = sessionStorage.getItem(
        '@Storage:channelId',
      ) as string;
      const sessionChannelName = sessionStorage.getItem(
        '@Storage:channelName',
      ) as string;
      if (userToken) {
        console.log(`keep-alive ${new Date().toString()}`);
        const result = await api.post('/sessions/keep-alive', {
          channelKey: sessionChannel === 'undefined' ? 'Lobby' : sessionChannel,
          channelId:
            sessionChannelId === 'undefined' ? 'Lobby' : sessionChannelId,
          channelName:
            sessionChannelName === 'undefined' ? 'Lobby' : sessionChannelName,
        });
        const usertoken = result.data;
        if (usertoken) {
          const signedUser = await firebase
            .auth()
            .signInWithCustomToken(usertoken.firebase_token);
          // const token = await signedUser?.user?.getIdToken(true);

          setCustomToken(usertoken.firebase_token);
          if (usertoken.token) {
            updateToken(usertoken.token);
          }
        }
      }
    } catch (error) {
      console.log('Cannot keep connection alive: ', error);
    }
  }

  useInterval(() => {
    // execute only when has a loged user
    if (user) keepAlive();
  }, 1000 * 30);

  // useEffect(() => {
  //   console.log('ok');

  //   // repeat the function each 1000ms

  //   // const unsubscribe = setInterval(keepAlive, 6000 * 3);//60000
  //   // return () => {
  //   //   clearInterval(unsubscribe);
  //   // };
  // }, [user]);

  function useInterval2(callback: any, delay: any) {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        //savedCallback?.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  function Counter() {
    const [count, setCount] = useState(0);

    useInterval2(() => {
      // Your custom logic here
      setCount(count + 1);
    }, 1000);

    console.log(count);
  }
  //Counter();

  return (
    <Auth.Provider
      value={{
        getUser,
        signIn,
        signInSSO,
        signOut,
        updateUser,
        setUser,
        getSessionId,
        token_facelive,
      }}
    >
      {children}
    </Auth.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(Auth);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
