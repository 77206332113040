import React from 'react';
import './style.css';
import Lottie from 'react-lottie';
import animationDataLostInSpace from '../../assets/animations/404-lost-in-space.json';
import animationNumber from '../../assets/animations/numberAnimation.json';
import logo4YourLive from '../../assets/images/logo-4yourlive.svg';
import logo4YourLiveV2 from '../../assets/images/logo-4yourlive-v2.svg';

const NotFound: React.FC = () => {
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationNumber,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationDataLostInSpace,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return <div className='notFound-container'>
    <nav>
      <img src={logo4YourLive} alt="" />
    </nav>
    <div className='notFound-content'>
      <div className='notFound-animation notFound-animation-lostInSpace'>
        <Lottie options={defaultOptions} isPaused={false} isStopped={false} />
      </div>
      <div className='notFound-animation notFound-animation-number404'>
        <Lottie options={defaultOptions1} isPaused={false} isStopped={false} />
      </div>
      <div>
        <h2>Desculpe, a página que você está procurando não foi encontrada por aqui.</h2>
        <h3>O que pode ter acontecido?</h3>
        <p>O conteúdo que você procurava pode ter sido removido ou estar temporariamente indisponível. Verifique se você digitou corretamente o endereço desejado ou, se preferir, acesse o mapa do site ou a home para encontrar o que precisa.</p>
      </div>
    </div>

    <footer>
      <div>
        <img src={logo4YourLiveV2} alt="" />
        Plataforma de transmissão completa de eventos.
      </div>
      <a href="/">Enviar Feedback</a>
    </footer>
  </div>
}
export default NotFound;
