import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner } from './style';
import { asEnumerable } from 'linq-es2015';
import { useEvent } from '../../../hooks/EventContext';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { useSpeaker } from '../../../hooks/SpeakersContext';

interface ChatProps {
  user: any;
  channelId: string;
  eventId: string;
}

const Biography: React.FC<ChatProps> = (props: any) => {
  const { t, i18n } = useTranslation();

  const { speakers } = useSpeaker();

  return (
    <Conteiner>
      <main className="container">
        <div className="mt-4"> {/* row mt-4*/}
          <div className="col">
            <div className="agenda-area">
              {speakers
                ?.filter(
                  (speaker: any) =>
                    speaker.views &&
                    speaker.views.findIndex(
                      (v: any) =>
                        v.key === 'biography' &&
                        v.channelId === props.channelId,
                    ) >= 0,
                )
                .map((speaker: any, i: number) => (
                  <div key={i} className="card-body">
                    <div className="row speakers">
                      <div className="col-12 ">
                        <React.Fragment key={speaker.id}>
                          <div className="row speakers">
                            {!!speaker && !!speaker.picture && (
                              <div className="col-md-2 ">
                                <div className="box-speaker">
                                  <img
                                    alt=""
                                    src={speaker.picture}
                                    className="img img-rounded img-fluid"
                                  />
                                </div>
                              </div>
                            )}
                            <div className="col-md-8">
                              {!!speaker && !!speaker.name && (
                                <p>
                                  <strong>
                                    {speaker.name}
                                    {speaker.crm ? ` - ${speaker.crm}` : ''}
                                  </strong>
                                </p>
                              )}
                              {!!speaker &&
                                !!speaker.description[i18n.language] && (
                                  <div
                                    style={{
                                      textAlign: 'justify',
                                      fontSize: 13,
                                    }}
                                  >
                                    {parse(
                                      `${speaker.description[i18n.language]}`,
                                    )}
                                    {/* {speaker.description[i18n.language]} */}
                                  </div>
                                )}
                            </div>
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </main>
    </Conteiner>
  );
};

export default Biography;
