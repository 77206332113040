import React, { useState, useEffect, CSSProperties } from 'react';
import Tab from './Tab';
import TabScreen from './Tab/TabScreen';
import ChatView from './Chat';
import Enquete from './Enquete';
import Pergunte from './Pergunte';
import LinksCom from './Links';
import Banner from '../../components/Banner';
import ModalSpeakers from '../ModalSpeakers';
import { useTranslation } from 'react-i18next';

import { useIndexTabRight, useIsRetrato } from '../../context/contextplayer';
import { useWindowDimensions } from '../../hooks/useDimension';
import { useAuth } from '../../hooks/Auth2';
import { useEvent } from '../../hooks/EventContext';

import './styles.css';

interface Props {
  width: number;
  height: number | string;
  style?: CSSProperties;
}

interface InfoSpeaker {
  id?: string;
  avatar?: string;
  name?: string;
  pSocialMedia?: string;
  title?: string;
  linkedin?: string;
  instagram?: string;
  twitter?: string;
}

const INITIAL_TAB_INDEX = 0;

const TabMenuRight: React.FC<Props> = (props) => {
  const { indexTabRight } = useIndexTabRight();
  const { width } = useWindowDimensions();
  const PropsData: Props = { ...props, height: props.height };
  const { isRetrato } = useIsRetrato();
  const { t } = useTranslation();
  const eventContext = useEvent();
  const { getUser } = useAuth();
  const user = getUser();

  const stylesitem = (index: number) => {
    const styles: CSSProperties = {
      display: indexTabRight === index ? 'flex' : 'none',
      width: '100%',
      height: '100%',
      background: 'white',
    };

    return styles;
  };

  function showTabItem(key: string) {
    if (
      eventContext.channel?.resources &&
      Array.isArray(eventContext.channel.resources)
    ) {
      return eventContext.channel.resources.some(
        (item: any) => item?.key === key && Boolean(item.tab),
      );
    }
    return false;
  }

  const [isMobile, SetIsMobile] = useState(
    window.matchMedia('(max-width: 992px)').matches,
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      SetIsMobile(window.matchMedia('(max-width: 992px)').matches);
    });
  });

  const [isModalVisible, seIsModalVisible] = useState(null);

  function handleOutsideClickOpen(id:any){
    seIsModalVisible(id);
  }

  function handleOutsideClickOnClose(id:any){
    seIsModalVisible(null);
  }

  const [speaker] = useState<InfoSpeaker[]>([
    {
      id: '1',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'ALEXADRE DORÉ',
      pSocialMedia: 'ale@xandre.dr',
      title: `
        ale@xandre.dr Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '2',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow',
      pSocialMedia: 'Jhow teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '3',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jonathan Gama',
      pSocialMedia: ' Jonathan Gamateste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '4',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Airton Silva',
      pSocialMedia: 'Airton Silva teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '5',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Hudson Gambs',
      pSocialMedia: 'Hudson Gambs teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '6',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Well Pinga',
      pSocialMedia: 'Well Pinga teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '7',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Cross Piscina',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '8',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Luis ok',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '9',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow Gama',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '10',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow Gama',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '11',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow Gama',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '12',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow Gama',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '13',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow Gama',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '14',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow Gama',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '15',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow Gama',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '16',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow Gama',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '17',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow Gama',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '18',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow Gama',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '19',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow Gama',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '20',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow Gama',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '21',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow Gama',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '22',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow Gama',
      pSocialMedia: 'teste jhow',
      title: `
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '23',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'Jhow Gama',
      pSocialMedia: 'Jhow Gama teste jhow',
      title: `
        Jhow Gama Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
    {
      id: '24',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2FCamada%203.png?alt=media&token=6e4f4732-3ac4-4a02-83fb-239e1fbf34b8',
      name: 'João Luis',
      pSocialMedia: 'João Luis teste',
      title: `
        João Luis Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s </br></br>

        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy
        text of the printing and typesetting  industry. Lorem Ipsum has been the industry's  standard
        dummy text ever since the 1500s, sum has been the industry's  standard dummy text the 1500s,
      `,
      linkedin: 'https://www.linkedin.com/login/pt',
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
    },
  ]);

  return (
    <div
      className="TabMenuRightFirefox"
      style={{
        ...props.style,
        width: props.width,
        // background: 'white',
        height: '100%',
        flexDirection: 'column',
        marginTop: user?.isModerator && isMobile ? '100px' : isMobile? '80px' : '',
      }}
    >
      <div className="bannerFeatures">
        <Banner height={80} width={100} padding={0} />
        {/* {isModalVisible === speakers.id ? (
          <ModalSpeakers onClose={handleOutsideClickOnClose} />
        ) : null} */}
      </div>
      <Tab
        width={props.width}
        height={!isMobile ? props.height : 'auto'}
        initialTabIndex={INITIAL_TAB_INDEX}
        margin-bottom={130}
      >
        {(showTabItem('chat') || showTabItem('chat2')) && (
          <TabScreen
            key="chat"
            icon={
              <span className={`fa fa-comments iconsizeitemmaeutab chat`} />
            }
            title={t('Chat')}
          >
            <div className="borderitemsmsms">
              <ChatView {...PropsData} />
            </div>
          </TabScreen>
        )}
        {showTabItem('ask') && (
          <TabScreen
            icon={<span className={`fa fa-user iconsizeitemmaeutab ask`} />}
            title={String(t('Q_A').replace('<br>', ''))
              .replace('<br/>', '')
              .replace('<br />', '')}
          >
            <Pergunte />
          </TabScreen>
        )}
        {showTabItem('survey') && (
          <TabScreen
            icon={<span className={`fa fa-list iconsizeitemmaeutab survey`} />}
            title={t('Survey')}
          >
            <Enquete />
          </TabScreen>
        )}
        {showTabItem('links') && (
          <TabScreen
            icon={<span className={`fa fa-link iconsizeitemmaeutab links`} />}
            title={t('Links')}
          >
            <LinksCom />
          </TabScreen>
        )}
      </Tab>

      {eventContext.channel?.customization?.infoImage ? <Banner height={600} width={320} /> : <div style={{marginTop: '50px'}}></div>}
      <div style={{ padding: isRetrato ? 29 : 0 }} />
    </div>
  );
};
export default TabMenuRight;
