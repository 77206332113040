import styled , { css}from 'styled-components';

interface ButtonProps {
  customization: any;
}

export const Button = styled.button<ButtonProps>`
    width: 100%;
    max-width: 220px;
    background-color: #000;
    color: #f0f0f0;
    font-size: 16px;
    padding: 10px 10px;
    margin-top: 35px;
    font-family: var(--fontFamily);
    border: 1px solid;
    background: #02C90F;
    /* ${(props) => css`
      background: linear-gradient(
        90deg,
        ${props.customization?.backgroundHeaderGradient?.color1 ||
          props.customization?.backgroundHeaderGradientColor1}
          0%,
        ${props.customization?.backgroundHeaderGradient?.color2 ||
          props.customization?.backgroundHeaderGradientColor2}
          90%
      );
    `} */

    &:hover{
      background: #10ad1a;
      /* background-color: transparent; */
      border: 1px solid #000;
      color:#fff
    }
  `;

