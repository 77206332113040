import React, { useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useToast } from '../../hooks/Toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Input2 from '../../components/Input2';
import Button from '../../components/Button';

import { AnimationContainer } from './styles';
import api from '../../services/api';
import DefaultMasterPage from '../../masterpages/DefaultMasterPage';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../hooks/EventContext';

interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
  token: string;
}

const ResetPassword: React.FC = (props: any) => {
  const formRef = useRef<FormHandles>(null);
  const eventContext = useEvent();

  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          // password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ , 'Senha não está no formato correto')
          // .required('Senha é obrigatória'),
          password: Yup.string().required('Senha é obrigatória'),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Senhas diferentes.',
          ),
          token: Yup.string().required('Token é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { password, password_confirmation, token } = data;
        // const token = location.search.replace('?token=', '');

        if (!token) {
          throw new Error();
        }

        await api.post('participant/change-password', {
          password,
          password_confirmation,
          token,
        });

        addToast({
          type: 'success',
          title: t('Password has been changed'),
          description:
          t('Password changed successfully'),
        });

        history.push(`/${props.params.event}/signin`);

      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: t('Error reset your password'),
          description: t('There was an error resetting your password, please try again'),
        });
      }
    },
    [addToast, history, location.search],
  );

  return (
    <DefaultMasterPage {...props} scheme="frm-recovery">
      <AnimationContainer>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1 style={{ color: eventContext.event?.customization?.fontColor || '#fff'}}>{t("Reset Password")}</h1>

          <Input2
            name="password"
            icon={FiLock}
            type="password"
            placeholder="Nova senha"
          />

          <Input2
            name="password_confirmation"
            icon={FiLock}
            type="password"
            placeholder="Confirmação da senha"
          />
          <Input2
            name="token"
            icon={FiLock}
            type="text"
            placeholder="Token"
          />

          {/* <div style={{textAlign:'initial', marginTop: '10px', marginBottom: '10px'}}>
            <span style={{fontSize: '10px', color: eventContext.event?.customization?.fontColor || '#fff'}}>
            {t("Minimum 8 digits")}
            </span>
            <br/>
            <span style={{fontSize: '10px', color: eventContext.event?.customization?.fontColor || '#fff'}}>
            {t("At least one capital letter")}
            </span>
            <span style={{fontSize: '10px', color: eventContext.event?.customization?.fontColor || '#fff'}}>
            {t("At least one lower letter")}
            </span>
            <br/>
            <span style={{fontSize: '10px', color: eventContext.event?.customization?.fontColor || '#fff'}}>
            {t("At least one special character")}
            </span>
            <br/>
            <span style={{fontSize: '10px', color: eventContext.event?.customization?.fontColor || '#fff'}}>
            {t("At least one number")}
            </span>
          </div> */}

          <Button type="submit" background={eventContext?.event?.customization?.buttonBackgroundColor}>{t("Change Password")}</Button>
        </Form>
      </AnimationContainer>
    </DefaultMasterPage>
  );
};

export default ResetPassword;
