import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Conteiner } from './style';
import Spinner from 'react-bootstrap/Spinner';

// type ContainerButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
//   loading?: boolean;
//   spinner?: boolean;
//   loadingText?: any;
//   classe?: string;
// };

interface Props {
  loading?: boolean;
  spinner?: boolean;
  loadingText?: any;
  classe?: string;
  click?: any;
  customization?: any;
}

const ContainerButton: React.FC<Props> = ({
  children,
  loading,
  loadingText,
  spinner,
  click,
  classe = 'button_send_form',
  customization,
  ...rest
}) => {
  return (
    <Conteiner>
      {!loading && !click && (
        <Button className={classe} type="submit" {...rest}>
          {children}
        </Button>
      )}
      {!loading && click && (
        <Button className={classe} type="button" onClick={click}>
          {children}
        </Button>
      )}

      {loading && (
        <Spinner animation="border" role="status" size="sm">
          <span className="sr-only" style={{ color: '#fff' }}>
            {loadingText}
          </span>
        </Spinner>
      )}
    </Conteiner>
  );
};

export default ContainerButton;
