import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useAuth } from '../../hooks/Auth2';
import { useEvent } from '../../hooks/EventContext';
import DefaultMasterPage from '../../masterpages/DefaultMasterPage';

// import { Container } from './styles';

const SignInCallBack: React.FC = (props: any) => {
  const params = useParams<{ data?: string }>();
  const [isReady, setIsReady] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const eventContext = useEvent();
  const { signInSSO } = useAuth();

  // const { signInSSO } = useAuth();
  // const { user, isAuthenticated, isLoading, logout } = useAuth0();
  // // const eventContext = useEvent();

  // console.log('SignInCallBack', { user, isAuthenticated, isLoading });

  // useEffect(() => {
  //   if (isAuthenticated && user) {
  //     // const pagestart = eventContext.event?.pagestart;
  //     signInSSO({
  //       ...user,
  //       eventKey: props.params.event,
  //       pagestart: props?.location?.state?.pagestart,
  //     });
  //   }
  // }, [isAuthenticated, user]);

  // const isReady = !isLoading;

  // console.log('render sigincallback', { props });

  console.log('render', { params });

  if (params.data && eventContext?.event) {
    const data = JSON.parse(atob(params.data));

    console.log('render data', { data });

    signInSSO(data, eventContext.event);
  }

  return (
    <DefaultMasterPage {...props} scheme="frm-signin">
      <div>
        {!isReady && <div>carregando...</div>}
        {isReady && !isAuthenticated && <div>Falha no login</div>}

        {/* {isReady && isAuthenticated && (
          <div>
            Seja Bem Vindo, {user?.name || user?.nickname || user?.email}
            <br />
            <button onClick={() => logout({ localOnly: true })}>Sair</button>
          </div>
        )} */}
      </div>
    </DefaultMasterPage>
  );
};

export default SignInCallBack;
