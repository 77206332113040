import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/firebase-storage';
import { Conteiner } from './style';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';
import { asEnumerable } from 'linq-es2015';
import { useEvent } from '../../../hooks/EventContext';
import { Card, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Spinner from 'react-bootstrap/Spinner';
import Button from '../../Button';
import ContainerButton from '../../ContainerButton';
import avatarImg from '../../../assets/images/avatar.png';
import * as Yup from 'yup';
import { useAuth } from '../../../hooks/Auth2';

interface Props {
  user: any;
  channelId: string;
  eventId: string;
  item: any;
}

const Timeline: React.FC<Props> = (props: any) => {
  const { register, setValue, handleSubmit, errors } = useForm<FormData>();
  const firestore = firebase.firestore();
  const { addToast } = useToast();
  const [lgShow, setLgShow] = useState(false);
  const [sending, isSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const { event, channel } = useEvent();
  const { getUser } = useAuth();
  const user = getUser();

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      setLoading(true);

      const schema = Yup.object().shape({
        userComment: Yup.string().required('O comentário é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: true,
      });

      const entity: any = {
        createdAt: moment.utc().valueOf(),
        comment: data.userComment,
        channelId: channel?.id || 'lobby',
        eventId: event.id,
        user: {
          name: props.user.name,
          email: props.user.email,
        },
        uid: user?.id || user?.uid,
      };

      const ref = firestore.collection('timeline').doc(props.item.id);
      await ref.update({
        comments: firebase.firestore.FieldValue.arrayUnion(entity),
      });

      setValue('userComment', '');
      addToast({
        type: 'success',
        title: 'Post enviado',
        description: 'Sua mensagem foi enviada com sucesso!',
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro no envio',
        description:
          error?.response?.data?.message ||
          error.message ||
          'Ocorreu um erro ao fazer o envio, tente novamente.',
      });
    }
  });

  return (
    <>
      {/* <Button onClick={() => setLgShow(true)}>Comentários ({props.item?.comments?.length})</Button> */}
      <div className="bloco-comentarios" onClick={() => setLgShow(true)}>
        <i className="fa fa-comment text-muted" aria-hidden="true" />
        <span>{props.item?.comments?.length}</span>
      </div>

      <Conteiner>
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {props.item.user.name}
              <br />
              {props.item.text}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={onSubmit}>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Digite seu comentário</Form.Label>
                <Form.Control
                  name="userComment"
                  ref={register}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>

              <ContainerButton
                loading={loading}
                classe="buttonSendMessage"
                loadingText="Enviando..."
              >
                Enviar <i className="fa fa-paper-plane"></i>
              </ContainerButton>
            </Form>

            <hr></hr>

            {(props.item.comments || []).map((comment: any, i: number) => (
              <Card key={i} style={{ width: '100%', border: '0px' }}>
                <Card.Body>
                  <Card.Subtitle className="mb-2">
                    {<img style={{ width: '15px' }} src={avatarImg} />}
                    {comment.user.name} -
                    <em style={{ fontSize: '11px', color: '#ccc' }}>
                      {moment(comment.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                    </em>
                  </Card.Subtitle>
                  <Card.Text>
                    {comment.comment}
                    <br></br>
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          </Modal.Body>
        </Modal>
      </Conteiner>
    </>
  );
};

export default Timeline;
