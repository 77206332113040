import React, { useState } from 'react';
import { useEvent } from '../../hooks/EventContext';
import { Link, useParams } from 'react-router-dom';
import { FooterTermsContainer } from './style';
import { Content, Dot } from './style';
import { useTranslation } from 'react-i18next';
import ModalFeedBack from '../ModalFeedBack';
import { useTheme } from '../../hooks/ThemeContext';

const FooterTerms = () => {
  const eventContext = useEvent();
  const [isModalVisible, seIsModalVisible] = useState(false);
  const { event } = useParams<{ event?: string }>();
  const { t , i18n} = useTranslation();
  const {theme} = useTheme();

  function handleOutsideClickOpen(bool: boolean) {
    seIsModalVisible(bool);
  }

  function handleOutsideClickOnClose(bool: boolean) {
    seIsModalVisible(false);
  }

  return <FooterTermsContainer>
    <Content theme={theme}>
      {
        i18n.language == 'en' ? <a href="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fvolpecapital-convidado%2FTerms%20and%20Conditions%20of%20Use_Volpe%20Capital.pdf?alt=media&token=1371b567-24d4-4943-9cf8-88ce24e77440" target="_blank">{t("Terms")}</a> : <a><Link to={`/${event}/terms-of-use`}>{t("Terms")}</Link></a>
      }

      <Dot theme={theme}/>
      {
        // Condition to change policy for seminario-iu, if not, do it normal
        event === 'seminario-iu' ? <a href="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fsemin%C3%A1rio-iu%2Fcustomization%2FAviso%20de%20Privacidade_%20Seminario%20Internacional%20nov24.pdf?alt=media&token=ed63bbe2-0c6a-44c8-924c-246e44f69c65" target="_blank">{t("Privacy policy")}</a> : <a><Link to={`/${event}/privacy-and-policy`}>{t("Privacy policy")}</Link></a>
      }
      <Dot theme={theme}/>
      <a><Link to={`/${event}/contact`}>{t("Speak with DPO")}</Link></a>
      <Dot theme={theme}/>
      <a
      style={{color: `${theme.colors.text}`}}
      onClick={() => handleOutsideClickOpen(true)}
      >{t("Feedback")}</a>
    </Content>
    {isModalVisible ? (
              <ModalFeedBack
                onClose={handleOutsideClickOnClose}
                key={isModalVisible}
              />
      ): null}
  </FooterTermsContainer>
}
export default FooterTerms;
