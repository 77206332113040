import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ContainerPros {
  customization: any;
}

export const Container = styled.div<ContainerPros>`
a {
  color: #191919;
}

.tab-content{
  background-color:#fff;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #191919;
  border-color: #191919 #dee2e6 #fff;
}
.icon-date{
  font-size:18px;
  ${(props) => css`
    background-color: ${props.customization.dateBgColor};
  `}
}
.icon-hour{
  font-size:18px;
  ${(props) => css`
    background-color: ${props.customization.hourBgColor};
  `}
}
.side-menu {
  position: absolute;
  right: -60px;
}

.roomBox{
    width:220px;
    height:180px;
    padding:5px;
    .room{
      .bgImage{
        width:100%;
        height:110px;
      }
    }
  }

  .roomBaseDescription{
    display: flex;
    justify-content: space-between;
  }
  .roomTitle{
    color:#000;
    font-size:11px;
  }
  .roomDescription:first-child{
    font-size: 11px;
    font-weight: bold;
    width: 82%;
  }
  .roomDescription:last-child{
    font-size:13px;
    font-weight:bold;
    width:18%;
  }

  .roomHour{
    color:#333;
    text-align:right;
  }
  .flex-container {
    display: flex;
    flex-wrap: wrap;
  }

.boxLinks{
  display:flex;
}

.d-flex {
    display: flex !important;
    justify-content: flex-end;
}

  /*Agenda*/
  .agenda-titulo p {
      font-weight: 600;
      padding: 0 10px;
  }
  h1.agenda-title {
      font-size: 18px;
      font-weight: 700;
      color: #000;
  }
  p.agenda-p {
      font-weight: 600;
      margin: 0;
  }
  p.horario-total {
      color:#5c98ff;
  }
  span.agenda-data {
      color: #e82175;
      font-weight: 900;
  }
  span.agenda-horario {
      padding: 23px;
      color: #458aff;
      font-weight: 900;
  }
  .agenda-area .card-body {
      margin: 0;
      padding: 10px 10px;
  }

 @media only screen and (max-width: 767px) {
    .box-speaker img {
        width: 30%;
    }
    .speakers {
        text-align: center;
    }
    .box-speaker {
        text-align: center;
    }
}
@media only screen and (max-width: 557px){
  .icon-live {
    margin-top: 0.5rem;
  }
  .live-title {
    font-size: 20px;
  }
  .infor-header {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .icons-header span {
    font-size:13px;
  }
}
`;
