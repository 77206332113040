import React, { useEffect, useRef, useState } from 'react';
import { FiHome, FiCamera, FiLogOut } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import { useAuth } from '../../hooks/Auth2';
import { useIsVisiblePopup } from '../../context/contextPopup';
import { useEvent } from '../../hooks/EventContext';
import * as S from './styles';
import Languages from '../../components/Languages';

import './css.css';

import Header from '../../components/Header';

import { useWindowDimensions } from '../../hooks/useDimension';
import api from '../../services/api';
import FooterTerms from '../../components/FooterTerms';
import Footer from '../../components/Footer';
import { useTheme } from '../../hooks/ThemeContext';

interface PopUp {
  id: string;
  content: string;
  name: string;
  isActive: boolean;
  url: string;
}

const DashboardMasterPage2: React.FC<any> = (props) => {
  const { event, channel } = useParams<{ channel: string; event: string }>();
  const history = useHistory();
  const [image, setImage] = useState<boolean>(true);
  const { isVisiblePopup, setIsVisiblePopup } = useIsVisiblePopup();
  const { signOut, getUser } = useAuth();
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const widthVideoSS =
    width <= 500
      ? width - 50
      : Number(
          width >= 1500
            ? width >= 1500 && width <= 1920
              ? Number(1500 - 150)
              : Number(1500 - 150)
            : width - 150,
        );
  const user = getUser();
  const eventContext = useEvent();
  const timer = useRef<any>();
  const inputStyle = width > 920 ? 24 : 0;
  // const [timer, setTimer] = useState<any>();
  // const ga4React: any = useGA4React();

  React.useEffect(() => {
    try {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (props?.params?.event)
      eventContext.loadInfo(props.params.event, props.params.channel);
    sessionStorage.setItem('@Storage:channelKey', channel);
  }, [props.params.event, props.params.channel]);

  useEffect(() => {
    if (!user?.id) return () => null;

    (window.globalThis as any)?.gtag('set', { user_id: user.id });
    (window.globalThis as any)?.gtag('event', 'set_user_id', {
      user_id: user.id,
    });

    console.log('analytics useEffect', { user, event, channel });

    if (timer.current) clearInterval(timer.current);

    const navigator_session_id = sessionStorage.getItem('4yourlive:tabid');

    function keepAlive(): void {
      const time = moment().utc().valueOf();
      const data = {
        time,
        event_category: 'report_session',
        event_label: 'Sessão',
        event_key: event,
        channel_key: channel,
        value: time,
        domain: window.location.host,
        page: window.location.pathname,
        search: window.location.search,
        navigator_agent: navigator.userAgent,
        navigator_lang: navigator.language,
        navigator_session_id,
      };

      try {
        const gtag = (window.globalThis as any)?.gtag || undefined;
        console.log('GA keep_alive', { event, channel, user, gtag });

        gtag?.('event', 'keep_alive', {
          ...data,
          event_callback: () => {
            console.log('keep_alive enviado parar o GA', data);
          },
        });
      } catch (e) {
        console.log('error', e);
      }

      // analytics redundancy data
      try {
        api.post('/analytics', { ...data, uid: user?.id });
      } catch (e) {
        console.log('error', e);
      }
    }

    keepAlive(); // send first ping

    timer.current = setInterval(() => {
      if (!event) return;

      keepAlive();
    }, 1000 * 60 * 1);

    // setTimer(interval);

    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, [user?.id, channel, event]);
  const getUrlIframe = (): string => {
    if (
      eventContext?.channel?.popup_settings &&
      eventContext?.channel?.popup_settings?.length !== 0
    ) {
      const dat: PopUp[] = eventContext?.channel?.popup_settings;
      return (
        dat[dat.findIndex((r: PopUp) => r?.isActive === true)]?.content || ''
      );
    }
    return '';
  };

  const getLinkRedirectPopUp = (): any => {
    if (
      eventContext?.channel?.popup_settings &&
      eventContext?.channel?.popup_settings.length !== 0
    ) {
      const dat: PopUp[] = eventContext?.channel?.popup_settings;
      return dat[dat.findIndex((r: PopUp) => r.isActive === true)]?.url || '';
    }
    return '';
  };

  return (
    <>
      <S.ContainerBackgroundImage
        className="event-background-image"
        channelCustomization={eventContext?.channel?.customization}
        customization={eventContext.event?.customization || {}}
        background={props.background}
        theme={theme}
      />

      {eventContext.event && (
        <S.AnimationContainer
          channelCustomization={eventContext?.channel?.customization}
          customization={eventContext.event?.customization || {}}
          background={props.background}
          theme={theme}
        >
          <S.Container customization={eventContext.event?.customization || {}}>
            <Header props={props} />
            <div
              style={{
                minHeight:
                  eventContext?.channel?.type_room == 'eventShowRoom'
                    ? 'auto'
                    : height,
                maxHeight: undefined,
                // backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                // background:
                //   eventContext.event?.customization.backgroundColor || '#f5f8fd',
              }}
              className={
                channel !== 'plenaria-virtual'
                  ? 'bosyvreibroderpai'
                  : 'bosyvreibroderpai2'
              }
            >
              {channel !== 'plenaria-virtual' ? (
                <S.ContentMaxWidth>{props.children}</S.ContentMaxWidth>
              ) : (
                <>{props.children}</>
              )}

              {isVisiblePopup && getUrlIframe().length !== 0 && (
                <div className="modalViewPage">
                  <div className="modalViewPageContent">
                    <div
                      className="rowviewclosedDv"
                      style={{ textAlign: 'center' }}
                    >
                      {image ? (
                        <div>
                          <span
                            onClick={() => setIsVisiblePopup(false)}
                            className="xclose"
                          >
                            X
                          </span>
                          <a href={`//${getLinkRedirectPopUp()}`}>
                            <img
                              src={getUrlIframe()}
                              onError={() => setImage(false)}
                              className="rowviewclosedIframe"
                            />
                          </a>
                        </div>
                      ) : (
                        <div>
                          <span
                            onClick={() => setIsVisiblePopup(false)}
                            className="xclose"
                          >
                            X
                          </span>
                          <iframe
                            style={{ minWidth: '70vw' }}
                            height="100%"
                            width="100%"
                            src={getUrlIframe()}
                            className="rowviewclosedIframe"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </S.Container>

          <S.Container customization={null}>
            <S.ContentMaxWidth>
              <div
                style={{
                  display:
                    eventContext?.channel?.type_room == 'eventShowRoom'
                      ? 'none'
                      : '',
                  marginTop: inputStyle,
                  // background: eventContext.event?.customization.backgroundColor || '#f5f8fd',
                }}
                className="headernavbarsssend"
              >
                <Footer />
              </div>
              <FooterTerms />
            </S.ContentMaxWidth>
          </S.Container>
        </S.AnimationContainer>
      )}
    </>
  );
};

export default DashboardMasterPage2;
