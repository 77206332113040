import React, { useEffect } from 'react';
import { useAuth } from '../../hooks/Auth2';
interface SignInFormData {
  email: string;
  password: string;
}

const LogOut: React.FC = (props: any) => {
  const { signOut } = useAuth();
  useEffect(() => {
    signOut();
  }, []);
  return <></>;
};

export default LogOut;
