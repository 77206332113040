import React, { useState, useEffect, useCallback } from 'react';
import * as S from '../style';
import firebase from 'firebase/app';
import moment from 'moment';
import { BsCalendarWeek } from 'react-icons/bs';
import { IoMdRefresh } from 'react-icons/io';
import 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import ModalContent from '../../ModalContent';
import '../styles.css';
import { useEvent } from '../../../hooks/EventContext';
import VideoView from '../../VideoView';

interface IProps {
  handleModal: () => void;
  theme: any;
  visibleTab: string;
}

const Content: React.FC<IProps> = (props: any) => {
  const eventContext = useEvent();
  const [contentsFiltered, setContentsFiltered] = useState<{ title: string; cover: string ; category:string; description: string; createdAt: number;}[]>([])
  const [contents, setContents] = useState<
  { title: string; cover: string; category:string; description: string; createdAt: number;}[]
>([]);
  const firestore = firebase.firestore();
  const [arrayIds, setArrayIds] = useState<any[]>([]);
  const [isModalVisible, seIsModalVisible] = useState(null);
  const [isComplete,setIsComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t , i18n} = useTranslation();
  const [isBusy, setIsBusy] = useState<boolean>(true)

  function handleOutsideClickOpen(id: any) {
    seIsModalVisible(id);
  }

  function handleOutsideClickOnClose(bool: boolean) {
    seIsModalVisible(null);
  }

  useEffect(() => {
    setIsComplete(false)
    firestore
      .collection('contents')
      .where('eventId', '==', eventContext.event.id)
      .where('category' , '==' , props.visibleTab)
      // .limit(1)
      .onSnapshot((snap) => {
        const content = snap.docs?.map<{
          cover: string;
          title: string;
          description: string;
          category: string;
          urlAudio: string;
          createdAt: number;
        }>((item) => {
            return {
              ...(item.data() as any),
              id: item.id,
            }
        });

        setContents(content);
        setIsBusy(false)
      });
  }, [props.visibleTab]);

    //next button function
    const showNext = async(item: number) => {
      setLoading(true);
     const contentsQuery = firestore.collection('contents')
      .where('eventId', '==', eventContext.event.id)
      // .where('category' , '==' , visibleTab)
      .orderBy('createdAt', 'asc') //order using firestore timestamp
      .startAfter(item || 0) //we pass props item's first created timestamp to do start after you can change as per your wish

     const querySnapshot = await contentsQuery.get();
      const list: any[] | ((prevState: any) => []) = [];
        querySnapshot.docs?.forEach(function(doc) {
          list.push({ id: doc.id, ...doc.data() });
      });
      if(list.length === 0){
        setIsComplete(true)
      }
      setContents((oldArray: any) => [...oldArray, ...list]);

      setLoading(false);
      setIsBusy(false)
    };

  useEffect(() => {
    if (!props.visibleTab && isBusy) return;
    setContentsFiltered(() => {
      return contents.filter((item: { category: string; }) => item.category === props.visibleTab)
    })
  }, [contents, isBusy])

  return (
    <>
    {contentsFiltered.map((content: any, index) => (
      <div>

        <S.Card theme={props.theme}
        delay={index * 0.0125}
        key={`content-item-${content.title}-${index}`}>
          {content.cover ? (
              <S.CardImageContainer>
               <S.CardImage alt="400x220" src={content.cover} />
            </S.CardImageContainer>
          ) : (
            <VideoView
            preferenceAudioId={content.urlAudio}
            style={{ width: '100%', height: '100%' }}
            />
          )}

          <S.CardContent theme={props.theme}>
            <S.CardInfo>
              <S.CardInfoHeader>
                <S.CardTitle theme={props.theme}>{content?.title}</S.CardTitle>
              </S.CardInfoHeader>
              {/* <S.CardInfoText theme={theme} isCommentShown={arrayIds.includes(index)} lineClamp={arrayIds.includes(index)}>  */}
               {/* <S.CardInfoText theme={props.theme} isCommentShown={arrayIds.includes(index)} lineClamp={arrayIds.includes(index)}>
                {content?.description}
              </S.CardInfoText> */}
              <div className="product">
                <div className='infosProductContainer'>
                  <div className="inforProductDescription">
                  <p
                    className="p-li"
                    dangerouslySetInnerHTML={{
                      __html: content?.description[i18n.language] || content?.description,
                    }}
                    style={{lineBreak: 'anywhere', width: '100%'}}
                  />
                  </div>
                </div>
              </div>
            </S.CardInfo>
              {arrayIds.includes(index) && (
                <div style={{marginBottom: '50px'}}></div>
              )}
            <S.CardFooter theme={props.theme}>
                <S.ConteinerSeeMore>
                  <a onClick={ () => handleOutsideClickOpen(content.id)} style={{color: '#1492E6'}}>
                    {t("See More")}
                  </a>
             </S.ConteinerSeeMore>
               <S.CalendarContainer>

                <BsCalendarWeek/>
                <span>
                  {moment(content.createdAt).format('DD/MM/YYYY')}
                </span>

              </S.CalendarContainer>
            </S.CardFooter>
          </S.CardContent>
      </S.Card>
      {isModalVisible === content.id ? (
          <ModalContent
            onClose={handleOutsideClickOnClose}
            key={`modal-content-${index}`}
            content={content}
          />
        ) : null}
      </div>

      ))}
        {/* <div style={{display: 'flex', justifyContent:'center'}}>
          {loading ? (
          <Spinner animation="border" role="status" variant="light">
            <span className="sr-only" style={{ color: '#fff' }}>
              {t("Loading")}
            </span>
          </Spinner>
        ) : (
          <S.ShowMoreButton isComplete={isComplete} onClick={() => {showNext(contentsFiltered[contentsFiltered.length-1].createdAt)}}>
            <IoMdRefresh size={30} color = "#fff"/>
              <span>
                {t("Load More")}
              </span>
          </S.ShowMoreButton>
        )}
        </div> */}
    </>
  )
}

export default Content;
