import styled, {css} from 'styled-components';

interface IFeatureDown {
  theaterMode: boolean;
}

export const Button = styled.button`
  background: none;
  padding:0;
  margin:0;
  border:0;
  width: initial;
  height: initial;
  fill:${props => props.theme.colors.moon};
  color:${props => props.theme.colors.moon};

  display:flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 12px;

  svg{
    width:18px;

  &:hover{
    opacity: .8;
  }
}

`

export const FeatureDown = styled.div<IFeatureDown>`
  fill: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1.5rem;
  border-radius: 0px 0 10px 10px;
  path{
    fill: inherit;
  }
  ${props => props.theaterMode ? css`
  background: 'transparent';
  ` : css`
  background: ${props => props.theme.colors.background};
  `}
`
