import styled, {css} from 'styled-components';

export const Button = styled.button`
  background: none;
  padding:0;
  margin:0;
  border:0;
  width: initial;
  height: initial;
  margin-left:30px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 12px;

  svg{
    width:18px;
    path{
    fill:${props => props.theme.colors.moon};
    }
  &:hover{
    opacity: .8;
  }
}

`
