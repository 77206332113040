import styled, { keyframes, css } from 'styled-components';
import { shade } from 'polished';

interface ContainerPros {
  customization: any;
}

export const Container = styled.div<ContainerPros>`
  #top-header {
    .nav-link {
      ${(props) => css`
        color: ${props.customization?.fontColor};
      `}
      background-color: transparent;
    }
    .nav-link:hover {
      color: #ddd;
    }
  }
  header {
    width: 100%;
    height: 65px;
    background: #000;
    ${(props) => css`
      background: linear-gradient(
        90deg,
        ${props.customization?.backgroundHeaderGradient?.color1 ||
          props.customization?.backgroundHeaderGradientColor1}
          0%,
        ${props.customization?.backgroundHeaderGradient?.color2 ||
          props.customization?.backgroundHeaderGradientColor2}
          90%
      );
    `}
  }
  a.navbar-brand {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img#log-img {
    max-width: 120px;
    width: auto;
    max-height: 20px;
}

  @media (max-width: 880px) {
    img#log-img {
      height: 35px;
    }
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div<ContainerPros>`
  animation: ${appearFromLeft} 1s;
`;
