import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap-default);
  /* background-color: #fff; */
  /* border-radius: 8px; */
  /* overflow: hidden; */
  /* padding: 15px; */
`;

export const ScheduleItem = styled.div`
  display: flex;
  justify-content: stretch;
  min-height: 150px;
  background-color: #fff;
  border-radius: 8px;
  /* padding: 15px; */
  /* border: 1px solid #e6e6e6; */

  .time_conteiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    box-shadow: 0px -13px 24px #0000001c;
    border-radius: 0 10px 10px 0;
    gap: var(--grid-gap-default);

    svg {
      fill: #b9b9b9;
    }
  }

  .hour {
    font-weight: 600;
    font-size: 1.5rem;
    /* margin-top: 15px; */
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem;
    gap: calc(var(--grid-gap-default) / 2);
  }

  .title {
    font-weight: 600;
  }

  .profession {
    font-size: 0.88rem;
    font-weight: 300;
    color: #c1c1c1;
    line-height: 1;
  }
`;
