import React, { useState, useCallback, useEffect, useMemo } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import { useAuth } from '../../hooks/Auth2';
import { useEvent } from '../../hooks/EventContext';
import DashboardMasterPage from '../../masterpages/DashboardMasterPage';
import { Container } from './styles'

const Files: React.FC = (props: any) => {
  const { signOut, getUser } = useAuth();
  const user = getUser();
  const eventContext = useEvent();
  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    firebase.storage().refFromURL('gs://rstcom-dev.appspot.com/Companies/Hypera/Files').listAll().then((docs) => {
      let items:any[] = [];

      for (const item of docs.items) {
        items.push({
          name: item.name
        })
      }

      setFiles(items);

      setTimeout(async () => {
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          const storeElement = docs.items[i];
          const downloadURL = await storeElement.getDownloadURL();
          const metadata = await storeElement.getMetadata();
          item.metadata = metadata;
          item.downloadURL = downloadURL;
        }

        setFiles([...items]);
      });
    });
  }, [props.params.event])

  return (
    <DashboardMasterPage {...props}>

      <Container customization={eventContext.event?.customization || {}}>
        <div className="container" style={{ marginTop: '62px' }}>
          <div className="table-responsive">
            <table cellSpacing={0} className="table" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th><i className="far fa-file"></i></th>
                  <th>Nome</th>
                  <th>Modificada</th>
                  <th>Tamanho</th>
                  <th>Download</th>
                </tr>
              </thead>

              <tbody>
                {files.map((item: any, index: number) => (
                  <tr key={index}>
                    <td><i className="far fa-file-video"></i></td>
                    <td>{item.name}</td>
                    <td>{item?.metadata?.timeCreated || '...'}</td>
                    <td>{!!item.metadata ? (item.metadata.size / 1024 / 1024).toFixed(2) + ' MB' : '...'}</td>
                    <td>
                      <a target="_blank"
                        href={item.downloadURL}><i
                          className="fas fa-file"></i> Download</a>
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
          <div className="clear"></div>
        </div>

      </Container>
    </DashboardMasterPage>
  );
};

export default Files;
