import styled , {keyframes}from 'styled-components';



interface ContainerPros {
  customization: any;
}
const appearAplha = keyframes`
    from,
    50%,
    to {
        opacity: 0.2;
    }

    25%,
    75% {
        opacity: 1;
    }
`;
export const TabMenuRightFirefox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  .borderitemsmsms {
    /* border: solid; */
    height: 80%;
    border-width: 0.15px;
    border-top-width: 0px;
    outline: 0px;
    border-color: #f7f7f7;
  }

  @-moz-document url-prefix() {
  .TabMenuRightFirefox {
    margin-top: 20px;
  }
`;

export const TabsContainer = styled.div`
  background: ${props => props.theme.colors.primary};
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1) !important;

  .highlighted:before {
    content: '';
    animation: ${appearAplha} 2s infinite;
    position: absolute;
    height: 3px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #02C90F;
  }

  svg{
    height: 24px;
  }
  z-index: 1;
`
export const TabsPaneContainer = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.grayColor};
  overflow: hidden;
  position: relative;

  > div{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
`
