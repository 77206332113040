import React, { createContext, useState, useContext } from "react";

type ContextType = {
    isVisiblePopup: boolean;
    setIsVisiblePopup: (value: boolean) => void;
};

const ContextMain = createContext<ContextType>({
    isVisiblePopup: false,
    setIsVisiblePopup: (value: boolean) => { },
});

const ProviderPopup: React.FC = ({ children }) => {

    const [isVisiblePopup, setIsVisiblePopup] = useState<boolean>(false);
    

    return (
        <ContextMain.Provider
            value={{
                isVisiblePopup,
                setIsVisiblePopup
            }}
        >
            {children}
        </ContextMain.Provider>
    );
};
export default ProviderPopup;

export function useIsVisiblePopup() {
    const info: ContextType = useContext(ContextMain);
    const { isVisiblePopup, setIsVisiblePopup } = info;
    return { isVisiblePopup, setIsVisiblePopup };
}

