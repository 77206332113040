import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as S from './style';
import * as Yup from 'yup';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';
import { useForm } from 'react-hook-form';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { asEnumerable } from 'linq-es2015';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ContainerButton from '../../ContainerButton';
import ListGroup from 'react-bootstrap/ListGroup';
import { useAuth } from '../../../hooks/Auth2';
import { useTranslation } from 'react-i18next';

interface ChatProps {
  user: any;
  channelId: string;
  eventId: string;
}

// var ref = rootRef.child("chat");
// ref.on('value').then(function (snapshot) {
//   snapshot.forEach(function (childSnapshot) {
//     var key = childSnapshot.key;
//     var childData = childSnapshot.val();
//   });
// });

interface SurveyQuestion {
  channelId: string;
  eventId: string;
  index: number;
  responseIndex: number;
  question: string;
  active: boolean;
  options: { id: string; text: string; index: number }[];
  user: {
    email: string;
    id: string;
  };
}

const Survey: React.FC<ChatProps> = (props) => {
  const { register, setValue, handleSubmit, errors, getValues, reset } =
    useForm<SurveyQuestion>();
  const { getUser } = useAuth();
  const user = getUser();
  const firestore = firebase.firestore();
  const [surveys, setSurveys] = useState<any[]>([]);
  const [formOptions, setFormOptions] = useState<any[]>([]);
  const [survey, setSurvey] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { addToast } = useToast();

  useEffect(() => {
    if (props.channelId) {
      let unsubscribe: any = null;
      unsubscribe = firestore
        .collection('survey_question')
        .where('channelId', '==', props.channelId)
        .where('status', '==', 1)
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((a) => {
            return { id: a.id, ...a.data() };
          });

          const data = asEnumerable(docs)
            .OrderByDescending((o: any) => o.createdAt)
            .Select((survey: any, i) => {
              let count = 0;
              const total = asEnumerable(survey.options).Sum(
                (d: any) => survey['count-' + count++] || 0,
              );
              for (let index = 0; index < survey.options.length; index++) {
                const responseCount = survey['count-' + index] || 0;
                survey['percent-' + index] = (responseCount / total) * 100;
              }
              return survey;
            })
            .ToArray();

          setSurveys(data);
        });
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
    let unsubscribe2: any = null;
    unsubscribe2 = firestore.collection('survey_question').where('eventId', '==', props.eventId).where('status', '==', 1).onSnapshot((snapshot) => {
      const docs = snapshot.docs.map((a) => {
        return { id: a.id, ...a.data() };
      });

      const data = asEnumerable(docs).OrderByDescending((o: any) => o.createdAt).Select((survey: any, i) => {
        let count = 0;
        const total = asEnumerable(survey.options).Sum((d: any) => survey['count-' + (count++)] || 0);
        for (let index = 0; index < survey.options.length; index++) {
          const responseCount = survey['count-' + index] || 0;
          survey['percent-' + index] = (responseCount / total) * 100;
        }
        return survey;
      }).ToArray();

      setSurveys(data);
    });
    return () => {
      if (unsubscribe2) {
        unsubscribe2();
      }
    };
  }, [props.channelId, props.eventId]);

  async function editSurvey(survey: any) {
    for (const key in survey) {
      if (survey.hasOwnProperty(key)) {
        const data = survey[key];

        if (
          typeof data === 'string' ||
          typeof data === 'number' ||
          typeof data === 'boolean'
        ) {
          setValue(key, data);
        }
      }
    }
    setSurvey(survey);
    setFormOptions(survey.options);
  }

  async function cancel() {
    reset();
    setSurvey(null);
    setFormOptions([]);
  }

  function deleteSurvey(data: any) {
    if (window.confirm(`${t("Are you sure you want to delete this poll?")}`)) {
      // const ref = firestore.collection('survey_question').doc(id);
      // ref.delete();
      const ref = firestore.collection('survey_question').doc(data.id);
      ref.update({
        status: 0,
      });
    }
  }

  function enableDisableSurvey(data: any) {
    const ref = firestore.collection('survey_question').doc(data.id);
    ref.update({
      active: !data.active,
    });
  }

  function showResponses(data: any) {
    const ref = firestore.collection('survey_question').doc(data.id);
    ref.update({
      showResponses: !data.showResponses,
    });
  }

  function addOption() {
    setFormOptions([...formOptions, {}]);
  }

  function removeOption(ind: number) {
    const arrOptions = [...formOptions];
    arrOptions.splice(ind, 1);
    setFormOptions(arrOptions);
  }

  const onSurveySubmit = handleSubmit(async (data: any) => {
    try {
      if (!user) return;

      const { id, email } = user;

      if (!survey?.id) {
        data.user = {
          id,
          email,
        };
        data.createdAt = moment.utc().valueOf();
        data.channelId = props.channelId || props.eventId;
        data.eventId = props.eventId;
        data.status = 1;
      }

      data.options = [];

      let i = 0;
      while (data['opt-' + i]) {
        data.options.push({
          id: i + 1,
          text: data['opt-' + i],
          index: i,
        });
        delete data['opt-' + i];
        i++;
      }
      if (survey?.id) {
        const ref = firestore.collection('survey_question').doc(survey?.id);
        ref.update(data);
      } else {
        await firestore.collection('survey_question').add(data);
      }

      addToast({
        type: 'success',
        title: `${t("Search saved successfully")}`,
        description: `${t("You can already activate your search")}`,
      });

      reset();
      setFormOptions([]);
      setSurvey(null);
    } catch (error) {
      addToast({
        type: 'error',
        title: `${t("Error on register")}`,
        description:
          error?.response?.data?.message ||
          error.message ||
          `${t("There was an error saving the search, please try again.")}`,
      });
    }
  });

  return (
    <S.Container>
      <S.Form onSubmit={onSurveySubmit}>
        <div>
          <label>
            {t("Insert the question")}
            <textarea
              name="question"
              ref={register({ required: true, maxLength: 1000 })}
              cols={30}
              rows={3}
            ></textarea>
          </label>
          {/* <input
            className="form-control"
            name="question"
            type="text"
            ref={register({ required: true, maxLength: 500 })}
            placeholder="Pergunta" /> */}
          <>
            {errors.question && (
              <span className="error">{t("Please report the question.")}</span>
            )}
          </>
        </div>
        <S.CheckboxLabel htmlFor="survey-active-question">
          <input
            id="survey-active-question"
            name="active"
            type="checkbox"
            ref={register}
          />
          {t("Activate poll")}
        </S.CheckboxLabel>
        <div>
          <S.Table className="moderator-survey-table-alternatives">
            <tbody>
              {formOptions?.map((o: any, ind: number) => (
                <tr key={ind}>
                  <th scope="row">{ind + 1}</th>
                  <td>
                    <input
                      defaultValue={o.text}
                      type="text"
                      name={`opt-${ind}`}
                      placeholder="Opção"
                      ref={register({ required: true })}
                    />
                  </td>
                  <td>
                    <ContainerButton
                      loading={loading}
                      classe="buttonSendMessage removeButton"
                      click={() => {
                        removeOption(ind);
                      }}
                    >
                      <i className="fa fa-times" style={{ color: '#fff' }}></i>
                    </ContainerButton>
                    {/* <button onClick={() => { removeOption(ind) }} type="button" className="btn btn-primary">Remover</button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </S.Table>
        </div>
        <S.ContainerButtons>
          <ContainerButton
            loading={loading}
            classe="moderator-survey-button-info"
            click={addOption}
          >
            <i className="fa fa-plus"></i> {t("Answer")}
          </ContainerButton>

          <ContainerButton
            loading={loading}
            classe="moderator-survey-button-save"
          >
            <i className="fa fa-save"></i> {t("Save poll")}
          </ContainerButton>

          <ContainerButton
            loading={loading}
            classe="moderator-survey-button-remove"
            click={cancel}
          >
            <i className="fa fa-times"></i> {t("Remove")}
          </ContainerButton>
        </S.ContainerButtons>
        <div>
          {/* <button onClick={addOption} type="button" className="btn btn-primary">Adicionar Resposta</button> */}
        </div>
        {/* <button type="submit" className="btn btn-primary">Salvar Enquete</button> &nbsp;&nbsp;
        <button onClick={cancel} type="button" className="btn btn-primary">Cancelar</button> */}
      </S.Form>
      <br></br>
      <Accordion defaultActiveKey="0">
        {surveys.length > 0 &&
          surveys.map((s: any, index1: number) => (
            <Card
              key={index1}
              style={{
                borderBottom: '1px solid #fbfbfb',
                marginBottom: '10px',
              }}
            >
              <Accordion.Toggle
                as={Card.Header}
                eventKey={`${index1}`}
                style={{ padding: '10px' }}
              >
                #{surveys.length - index1} {s.question}
                <i
                  className="fas fa-angle-down"
                  style={{ position: 'absolute', right: '10px' }}
                ></i>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey={`${index1}`}>
                <Card.Body>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <b>Status:</b> {s.active ? `${t("Active")}` : `${t("Inactive")}`}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>{t("Answers")}:</b>{' '}
                      {s.showResponses ? `${t("Showing")}` : `${t("Hidden")}`}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title="Atualizar"
                      >
                        <Dropdown.Item onClick={() => editSurvey(s)}>
                          {t("Edit")}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => enableDisableSurvey(s)}>
                          {!s.active ? t("Enable") : t("Disable")} {t("Survey")}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => showResponses(s)}>
                          {!s.showResponses ? t("Show") : t("Hide")} {t("Answers")}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => deleteSurvey(s)}>
                          {t("Remove")}{' '}
                        </Dropdown.Item>
                      </DropdownButton>
                    </ListGroup.Item>
                  </ListGroup>

                  <S.Table key={index1}>
                    <tbody>
                      {s?.options?.map((o: any, index2: number) => (
                        <tr key={index2}>
                          <th scope="row">{o.id}</th>
                          <td>{o.text}</td>
                          <td>{s['count-' + index2] || '0'}</td>
                          <td>{s['percent-' + index2] || 0}%</td>
                        </tr>
                      ))}
                    </tbody>
                  </S.Table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
      </Accordion>
    </S.Container>
  );
};

export default Survey;
