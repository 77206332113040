import styled from 'styled-components';
import { shade } from 'polished';

export const Conteiner = styled.div`

.youAreHere{
  position:absolute;
  top:20px;
}
#features {
    padding: 0!important;
}
.roomBox{
  height: auto!important;
  margin: 10px!important;
  background-color:#fafafa;
  border-radius:10px;
  min-height:230px;
  border-bottom: 2px solid transparent;
  -webkit-transition: border-bottom 0.5s ease-out;
  -moz-transition: border-bottom 0.5s ease-out;
  -o-transition: border-bottom 0.5s ease-out;
    .room{
      .bgImage{
        width:100%;
        height:215px!important;
      }
    }
  }
  .roomBox:hover{
    border-bottom:2px #666 solid;

    -webkit-transition: border-bottom 0.5s ease-in;
       -moz-transition: border-bottom 0.5s ease-in;
         -o-transition: border-bottom 0.5s ease-in;

  }

  .roomBaseDescription{
    display: flex;
    justify-content: space-between;
    padding:5px;
    color: #000!important;
    height: 60px;
    width: 100%;
  }

  .roomTitle{
    color:#000;
    font-size:11px;
    padding:5px;
  }
  .roomDescription:first-child{
    font-size: 11px;
    font-weight: bold;
    width: 82%;
  }
  .roomDescription:last-child{
    font-size:14px;
    font-weight:bold;
    width:18%;
  }


  .roomHour{
    color: #000;
    font-weight:bold;
    text-align:right;
  }
  .flex-container {
    display: flex;
    flex-wrap: wrap;
  }

/*Salas M.Q*/

/*DASH*/
.roomBox {
  width: 310px;
}


@media only screen and (max-width: 1198px) and (min-width: 1003px){
  .roomBox {
    width: 255px!important;

  }
  .roomBox .room .bgImage {
    height: 230px!important;
  }
}
@media only screen and (max-width: 990px) and (min-width: 766px){
  .roomBox {
    width: 190px!important;

  }
}

@media only screen and (max-width: 767px) and (min-width: 556px){
  .roomBox {
    width: 219px!important;

  }
}

@media only screen and (max-width: 557px){
  .roomBox {
    width: 97%!important;

  }
  .flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .linkChannels{
    width: 100% !important;
  }
}

`;


export const Conteiner_Lobby = styled.div`

.youAreHere{
  position:absolute;
  top:20px;
}
.roomBox{
  height: auto!important;
  margin: 20px 0px !important;
  margin-right: 10px!important;
  background-color:#fafafa;
  border-radius:10px;
  min-height:230px;
  border-bottom: 2px solid transparent;
  -webkit-transition: border-bottom 0.5s ease-out;
  -moz-transition: border-bottom 0.5s ease-out;
  -o-transition: border-bottom 0.5s ease-out;
    .room{
      .bgImage{
        width:100%;
        height:215px!important;
      }
    }
  }
  .roomBox:hover{
    border-bottom:2px #666 solid;

    -webkit-transition: border-bottom 0.5s ease-in;
       -moz-transition: border-bottom 0.5s ease-in;
         -o-transition: border-bottom 0.5s ease-in;

  }

  .roomBaseDescription{
    display: flex;
    justify-content: space-between;
    padding:5px;
    color: #000!important;
    text-align: justify;
    margin-top: -25px;
  }

  .roomBaseDescription2{
    display: flex;
    justify-content: space-between;
    padding:5px;
    color: #000!important;
    height: 60px;
    width: 100%;
    font-weight: bold;
  }

  .roomTitle{
    color:#000;
    font-weight: bold;
    font-size:18px;
    padding:5px;
  }

  .speakers-container{
    display: flex;
    flex-direction: row;
    margin-top: -25px;
    margin-left: 110px;
  }

  img.menu-dropdown-user-image {
      width: 44px;
      height: 44px;
      max-height: initial;
      object-fit: cover;
      border-radius: 50%;
      /* margin-right: 12px; */
      border: 2px solid #fff;
  }
  .roomDescription:first-child{
    font-size: 11px;
    font-weight: 500;
    width: 82%;
  }
  .roomDescription:last-child{
    font-size:14px;
    width:100%;
    padding: 5px;
  }

  .flex-container {
    display: flex;
    overflow-x: auto;
    ::-webkit-scrollbar {
      width: 10px;
      height: 7px;
    }
    ::-webkit-scrollbar-track{
      background: rgba(197, 197, 197, 0.94);
      border-radius: 8px;
     }
    ::-webkit-scrollbar-thumb{
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 8px;
    }
    /* flex-wrap: wrap; */
  }

  .access-room{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px -13px 24px #0000001C;
    border-radius: 9px;
    opacity: 1;
  }

  .icons{
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: end;
  }
  .icons span{
    color: #B9B9B9;
    font: normal normal normal 12px/24px Proxima Nova;
    letter-spacing: 1.56px;
  }

  .icon{
    height: 28px;
    color: #B9B9B9;
  }

  .access-room button{
    background: #02C90F 0% 0% no-repeat padding-box;
    border-radius: 6px;
    height: 30px;
    opacity: 1;
    font-size: 12px;
    padding: 0px 20px;
    color: white;
    border: none;
    margin-right: 10px;
    bottom:0;
  }

  .live{
    background: #02C90F 0% 0% no-repeat padding-box;
    border-radius: 9px;
    height: 20px;
    opacity: 1;
    font-size: 10px;
    padding: 2px 20px;
    color: white;
    border: none;
    margin-right: 10px;
    text-align: center;
    align-items: center;
    justify-content:center;
    align-content: center;
  }

/*Salas M.Q*/
/*LOBBY*/
.roomBox{
  width: 317px;
}
@media only screen and (max-width: 1198px) and (min-width: 1003px){
  .roomBox {
    width: 420px;
    height: auto;
    margin: 15px;
    padding: 0;
  }
  .roomBox .room .bgImage {
    height: 230px;
  }
  .flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 990px) and (min-width: 766px){
  .roomBox {
    width: 327px;
    height: auto;
    margin: 5px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) and (min-width: 556px){
  .roomBox {
    width: 245px;
    height: auto;
    margin: 5px;
    padding: 0;
  }
}

@media only screen and (max-width: 557px){
  .roomBox {
    width: 315px;
    height: auto;
    margin: 5px;
    padding: 0;
  }
  .flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
`;
