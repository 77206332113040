import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, NavLink } from 'react-router-dom';
import { useEvent } from '../../hooks/EventContext';
import Languages from '../../components/Languages';
import { MenuDropDownUser } from '../MenuDropdownUser';
import { asEnumerable } from 'linq-es2015';
import Sidebar from '../MenuRightSlider/Sidebar';
import { useAuth } from '../../hooks/Auth2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Icon
} from '../Icons';
import './header.css';

const Header: React.FC<any> = () => {
  const { getUser } = useAuth();
  const user = getUser();
  const eventContext = useEvent();

  // to={`/${eventContext?.eventKey}/dashboard/${eventContext?.channel?.key}/partners`}

  console.log('eventContextEventKey', eventContext);

  return (
    <div className="header">
      <div className="header-content">
        <div className="header_left">
          <Sidebar />
        </div>

        <div className="header_center">
          <div className="header_option">
            <NavLink
              exact
              to={`/${eventContext?.eventKey}/lobby`}
              activeClassName="active"
            >
              <Icon name="iconLobby" />
              Lobby
            </NavLink>
          </div>

          {asEnumerable(eventContext.channel?.resources || []).Any(
            (r: any) => r.key == 'partners',
          ) &&
            eventContext.channel?.id && (
              <div className="header_option">
                <NavLink
                  exact
                  to={`/${eventContext?.eventKey}/dashboard/${eventContext?.channel?.key}/partners`}
                  activeClassName="active"
                >
                  <Icon name="iconPartners" />

                  Parceiros
                </NavLink>
              </div>
            )}

          {asEnumerable(eventContext.event?.resources || []).Any(
            (r: any) => r.key == 'sponsors',
          ) &&
            eventContext.channel?.id && (
              <div className="header_option">
                <NavLink
                  exact
                  to={`/${eventContext?.eventKey}/dashboard/${eventContext?.channel?.key}/sponsors`}
                  activeClassName="active"
                >
                  <Icon name="iconSponsors" />

                  Patrocinadores
                </NavLink>
              </div>
            )}

          {asEnumerable(eventContext.channel?.resources || []).Any(
            (r: any) => r.key == 'network',
          ) &&
            eventContext.channel?.id && (
              <div className="header_option">
                <NavLink
                  exact
                  to={`/${eventContext?.eventKey}/dashboard/${eventContext?.channel?.key}/network`}
                  activeClassName="active"
                >
                  <Icon name="iconNetwork" />

                  Network
                </NavLink>
              </div>
            )}

          {asEnumerable(eventContext.channel?.resources || []).Any(
            (r: any) => r.key == 'schedule',
          ) &&
            eventContext.channel?.id && (
              <div className="header_option">
                <NavLink
                  exact
                  to={`/${eventContext?.eventKey}/dashboard/${eventContext?.channel?.key}/schedule`}
                  activeClassName="active"
                >
                  <Icon name="iconSchedule" />

                  Agenda
                </NavLink>
              </div>
            )}
          {eventContext.channel?.key && (
            <div className="header_option">
              <NavLink
                exact
                to={`/${eventContext?.eventKey}/dashboard/${eventContext?.channel?.key}/speakers`}
                activeClassName="active"
              >
                <Icon name="iconSpeakers" />

                Speakers
              </NavLink>
            </div>
          )}

          {eventContext.channel?.key && (
            <div className="header_option">
              <NavLink
                exact
                to={`/${eventContext?.eventKey}/dashboard/${eventContext?.channel?.key}`}
                activeClassName="active"
              >
                <Icon name="iconChannels" />

                Sala
              </NavLink>
            </div>
          )}
        </div>

        <div className="header_right">
          <div className="header_language">
            <Languages data={eventContext.event?.settings?.languages} />
          </div>
          <div className="header_info">
            {/* <img src={eventContext.event?.customization?.logo} alt="" /> */}

            {/* <NavLink
            to={`/${eventContext?.eventKey}/logout`}
            className="iconLogout"
          >
          </NavLink> */}
            <MenuDropDownUser />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
