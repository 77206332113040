import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner, Conteiner_Lobby } from './style';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';
import { asEnumerable } from 'linq-es2015';
import { Link, useParams } from 'react-router-dom';
import { useEvent } from '../../../hooks/EventContext';
import { useAuth } from '../../../hooks/Auth2';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lock from '../../../assets/images/lock.svg';
import { BsEyeFill } from "react-icons/bs";
import {
  faUsers,
  faHeart
} from '@fortawesome/free-solid-svg-icons';

interface ChatProps {
  user: any;
  channelId: string;
  eventId: string;
  group?: string;
}

const Channels: React.FC<ChatProps> = (props: any) => {
  const { event, channel: channelKey } = useParams<{
    channel: string;
    event: string;
  }>();

  const { group } = props;
  const { t, i18n } = useTranslation();
  const firestore = firebase.firestore();
  const { addToast } = useToast();
  const [channels, setChannels] = useState<any[]>([]);
  const eventContext = useEvent();
  const [activePage, setActivePage] = useState('');
  const { getUser } = useAuth();
  const user = getUser();

  useEffect(() => {
    const docs = eventContext.channels;

    const channel = asEnumerable(docs).FirstOrDefault(
      (c: any) => c.id === props.channelId,
    );

    const result = asEnumerable(docs)
      .Where((c: any) => {
        return (
          (!!channelKey || !(c.showlobby === false)) && // if channelKey not exists and showlobby equals to false hide channel
          ((!group && !c?.customization?.channel_group) ||
            c.customization?.channel_group?.trim() === group)
        );
      })
      .OrderBy((u: any) => u.order)
      .ToArray();
    console.log('test', { result, docs, group, channel });

    setChannels(result);

    if (channel) {
      eventContext.setChannel(channel);
    }
    // if (props.eventId && props.channelId) {
    //   const unsubscribe = firestore
    //     .collection('channel')
    //     .where('eventId', '==', props.eventId)
    //     .where('active', '==', true)
    //     .onSnapshot((snapshot) => {

    //     });
    //   return () => {
    //     unsubscribe();
    //   };

    console.log('user', user);
  }, [eventContext.channels, group]);

  function HeaderView() {
    let location = useLocation();
    let splt = location.pathname.split('/');

    if (splt[2] == 'lobby' || splt[2] == 'stand3d') {
      //setActivePage(splt[2]);
      return true;
    } else return false;
  }

  const hasHeaderView = HeaderView();

  console.log(`render Channels: ${group}`, {
    user,
    channels,
    eventContext,
    hasHeaderView,
  });

  return (
    <>
      {hasHeaderView ? (
        <Conteiner_Lobby>
          {/* {channels.length > 0 &&
            <div>
              <br></br>
              <p className="texts" style={{ padding: '10px' }}>
                {t('Follow the rooms of our event')}
              </p>
              <hr />
            </div>
          } */}
          <div className="flex-container">
            {!channels.length && (
              <Spinner animation="border" role="status">
                <span className="sr-only">{t('Loading')}</span>
              </Spinner>
            )}

            {channels.map((channel: any, i: number) =>
              (!props.params.channel ||
                channel.id != eventContext.channel.id) &&
              channel?.key != 'stand3d' ? (
                <React.Fragment key={i}>
                  {!!channel?.type ||
                    (channel.type != 'videoteca' &&
                      !!user?.restrictChannels &&
                      Object.keys(user?.restrictChannels)?.findIndex(
                        (channelKey) =>
                          channelKey?.trim() === channel?.key?.trim(),
                      ) >= 0 && (
                        <Link
                          key={i}
                          className="linkChannels"
                          data-cy={`card-channel-${channel?.key}`}
                          to={
                            '/' +
                            props.params.event +
                            '/dashboard/' +
                            channel.key
                          }
                        >
                          <div className="roomBox">
                            <div className="room">
                              {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}

                              {!!channel.picture && (
                                <div
                                  className="bgImage"
                                  style={{
                                    backgroundImage: `url(${channel.picture})`,
                                    backgroundSize: '100%',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                  }}
                                ></div>
                              )}

                              {!!channel.name_languages && (
                                <span className="roomTitle">
                                  {channel.name_languages[i18n.language]}
                                </span>
                              )}

                              {!channel.name_languages && (
                                <span className="roomTitle">
                                  {channel.name}
                                </span>
                              )}

                              {
                                <div className="roomBaseDescription">
                                  <div className="roomDescription">
                                    {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                                    {!!channel?.description_languages && (
                                      <p className="roomName">
                                        {parse(
                                          `${
                                            channel?.description_languages[
                                              i18n.language
                                            ]
                                          }`,
                                        )}
                                      </p>
                                    )}

                                    {!channel?.description_languages && (
                                      <p className="roomName">
                                        {parse(`${channel?.description}`)}
                                      </p>
                                    )}
                                  </div>
                                  <div className="roomDescription">
                                    <p className="roomHour"> {channel.hour}</p>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </Link>
                      ))}

                  {!!channel?.type ||
                    (channel.type != 'videoteca' && !user?.restrictChannels && (
                      <Link
                        key={i}
                        className="linkChannels"
                        to={{
                          pathname:
                            '/' +
                            props.params.event +
                            '/dashboard/' +
                            channel.key,
                          key: String(new Date().getTime()),
                        }}
                      >
                        <div className="roomBox">
                          <div className="room">
                            {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}
                            <div style={{display:'flex', flexDirection: 'row', justifyContent:'space-between',
                            marginBottom: -30, paddingRight: 10, paddingLeft: 10, alignItems: 'center'}}>
                                  <img src={lock} alt="lock image"/>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                      <div className="live">NO AR</div>
                                      <BsEyeFill size={25} color="white"/>
                                    </div>
                                </div>
                            {!!channel.picture && (
                              <div
                                className="bgImage"
                                style={{
                                  backgroundImage: `url(${channel.picture})`,
                                  // backgroundSize: '100%',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center',
                                  borderRadius: '10px',
                                }}
                              ></div>
                            )}

                          <div className="speakers-container">

                           <img className="menu-dropdown-user-image"
                            src="https://images.unsplash.com/photo-1594819047050-99defca82545?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTZ8fGZhY2V8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="img" />
                           <img className="menu-dropdown-user-image"
                            src="https://images.unsplash.com/photo-1594819047050-99defca82545?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTZ8fGZhY2V8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="img" />
                           <img className="menu-dropdown-user-image"
                            src="https://images.unsplash.com/photo-1594819047050-99defca82545?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTZ8fGZhY2V8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="img" />
                           <img className="menu-dropdown-user-image"
                            src="https://images.unsplash.com/photo-1594819047050-99defca82545?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTZ8fGZhY2V8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="img" />

                          </div>

                          <div className="roomBaseDescription2">
                            {!!channel.name_languages && (
                              <span className="roomTitle">
                                {channel.name_languages[i18n.language]}
                              </span>
                            )}

                            {!channel.name_languages && (
                              <span className="roomTitle">{channel.name}</span>
                            )}
                              <p className="roomHour"> {channel.hour}{channel.hour && 'h'}</p>
                          </div>
                            {
                              <div className="roomBaseDescription">
                                <div className="roomDescription">
                                  {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                                  {!!channel?.description_languages && (
                                    <p className="roomName">
                                      {parse(
                                        `${
                                          channel?.description_languages[
                                            i18n.language
                                          ]
                                        }`,
                                      )}
                                    </p>
                                  )}

                                  {!channel?.description_languages && (
                                    <p className="roomName">
                                      {parse(`${channel?.description}`)}
                                    </p>
                                  )}
                                </div>
                              </div>
                            }
                            <div className="access-room">
                              <div className="icons">
                              <FontAwesomeIcon
                                icon={faUsers}
                                color={eventContext.event?.customization?.fontColor}
                                className="icon"
                                size="lg"
                              />
                              <span>
                                0
                              </span>
                              <FontAwesomeIcon
                                icon={faHeart}
                                color={eventContext.event?.customization?.fontColor}
                                className="icon"
                                size="lg"
                              />
                              <span>
                                0
                              </span>
                              </div>

                              <button>
                                Acessar
                              </button>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                </React.Fragment>
              ) : (
                <React.Fragment key={i}>
                  {!!user?.restrictChannels &&
                    Object.keys(user?.restrictChannels)?.findIndex(
                      (channelKey) =>
                        channelKey?.trim() === channel?.key?.trim(),
                    ) >= 0 && (
                      <Link
                        key={i}
                        className="linkChannels"
                        to={{
                          pathname:
                            '/' +
                            props.params.event +
                            '/dashboard/' +
                            channel.key,
                          key: String(new Date().getTime()),
                        }}
                      >
                        <div className="roomBox">
                          <div className="room">
                            {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}

                            {!!channel.picture && (
                              <div
                                className="bgImage"
                                style={{
                                  backgroundImage: `url(${channel.picture})`,
                                  backgroundSize: '100%',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center',
                                }}
                              ></div>
                            )}

                            {!!channel.name_languages && (
                              <span className="roomTitle">
                                {channel.name_languages[i18n.language]}
                              </span>
                            )}

                            {!channel.name_languages && (
                              <span className="roomTitle">{channel.name}</span>
                            )}

                            {
                              <div className="roomBaseDescription">
                                <div className="roomDescription">
                                  {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                                  {!!channel?.description_languages && (
                                    <p className="roomName">
                                      {parse(
                                        `${
                                          channel?.description_languages[
                                            i18n.language
                                          ]
                                        }`,
                                      )}
                                    </p>
                                  )}

                                  {!channel?.description_languages && (
                                    <p className="roomName">
                                      {parse(`${channel?.description}`)}
                                    </p>
                                  )}
                                </div>
                                <div className="roomDescription">
                                  <p className="roomHour"> {channel.hour}</p>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </Link>
                    )}

                  {!user?.restrictChannels && (
                    <Link
                      key={i}
                      className="linkChannels"
                      to={{
                        pathname:
                          '/' +
                          props.params.event +
                          '/dashboard/' +
                          channel.key,
                        key: String(new Date().getTime()),
                      }}
                    >
                      <div className="roomBox">
                        <div className="room">
                          {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}

                          {!!channel.picture && (
                            <div
                              className="bgImage"
                              style={{
                                backgroundImage: `url(${channel.picture})`,
                                backgroundSize: '100%',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                              }}
                            ></div>
                          )}

                          {!!channel.name_languages && (
                            <span className="roomTitle">
                              {channel.name_languages[i18n.language]}
                            </span>
                          )}

                          {!channel.name_languages && (
                            <span className="roomTitle">{channel.name}</span>
                          )}

                          {
                            <div className="roomBaseDescription">
                              <div className="roomDescription">
                                {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                                {!!channel?.description_languages && (
                                  <p className="roomName">
                                    {parse(
                                      `${
                                        channel?.description_languages[
                                          i18n.language
                                        ]
                                      }`,
                                    )}
                                  </p>
                                )}

                                {!channel?.description_languages && (
                                  <p className="roomName">
                                    {parse(`${channel?.description}`)}
                                  </p>
                                )}
                              </div>
                              <div className="roomDescription">
                                <p className="roomHour"> {channel.hour}</p>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </Link>
                  )}
                </React.Fragment>
              ),
            )}
          </div>
        </Conteiner_Lobby>
      ) : (
        <Conteiner>
          <div className="flex-container">
            {!channels.length && (
              <Spinner animation="border" role="status">
                <span className="sr-only">{t('Loading')}</span>
              </Spinner>
            )}

            {channels.map(
              (channel: any, i: number) =>
                (!props.params.channel ||
                  channel.id != eventContext.channel.id) && (
                  <React.Fragment key={i}>
                    {channel?.key != 'stand3d' &&
                      !!user?.restrictChannels &&
                      Object.keys(user?.restrictChannels)?.findIndex(
                        (channelKey) =>
                          channelKey?.trim() === channel?.key?.trim(),
                      ) >= 0 && (
                        <Link
                          key={i}
                          className="linkChannels"
                          to={
                            '/' +
                            props.params.event +
                            '/dashboard/' +
                            channel.key
                          }
                        >
                          <div className="roomBox">
                            <div className="room">
                              {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}

                              {!!channel.picture && (
                                <div
                                  className="bgImage"
                                  style={{
                                    backgroundImage: `url(${channel.picture})`,
                                    backgroundSize: '100%',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                  }}
                                ></div>
                              )}

                              {!!channel.name_languages && (
                                <span className="roomTitle">
                                  {channel.name_languages[i18n.language]}
                                </span>
                              )}

                              {!channel.name_languages && (
                                <span className="roomTitle">
                                  {channel.name}
                                </span>
                              )}

                              {
                                <div className="roomBaseDescription">
                                  <div className="roomDescription">
                                    {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                                    {!!channel?.description_languages && (
                                      <p className="roomName">
                                        {parse(
                                          `${
                                            channel?.description_languages[
                                              i18n.language
                                            ]
                                          }`,
                                        )}
                                      </p>
                                    )}

                                    {!channel?.description_languages && (
                                      <p className="roomName">
                                        {parse(`${channel?.description}`)}
                                      </p>
                                    )}
                                  </div>
                                  <div className="roomDescription">
                                    <p className="roomHour"> {channel.hour}</p>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </Link>
                      )}

                    {channel?.key != 'stand3d' && !user?.restrictChannels && (
                      <Link
                        key={i}
                        className="linkChannels"
                        to={
                          '/' + props.params.event + '/dashboard/' + channel.key
                        }
                      >
                        <div className="roomBox">
                          <div className="room">
                            {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}

                            {!!channel.picture && (
                              <div
                                className="bgImage"
                                style={{
                                  backgroundImage: `url(${channel.picture})`,
                                  backgroundSize: '100%',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center',
                                }}
                              ></div>
                            )}

                            {!!channel.name_languages && (
                              <span className="roomTitle">
                                {channel.name_languages[i18n.language]}
                              </span>
                            )}

                            {!channel.name_languages && (
                              <span className="roomTitle">{channel.name}</span>
                            )}

                            {
                              <div className="roomBaseDescription">
                                <div className="roomDescription">
                                  {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                                  {!!channel?.description_languages && (
                                    <p className="roomName">
                                      {parse(
                                        `${
                                          channel?.description_languages[
                                            i18n.language
                                          ]
                                        }`,
                                      )}
                                    </p>
                                  )}

                                  {!channel?.description_languages && (
                                    <p className="roomName">
                                      {parse(`${channel?.description}`)}
                                    </p>
                                  )}
                                </div>
                                <div className="roomDescription">
                                  <p className="roomHour"> {channel.hour}</p>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </Link>
                    )}

                    {channel?.key === 'stand3d' &&
                      !!user?.restrictChannels &&
                      Object.keys(user?.restrictChannels)?.findIndex(
                        (channelKey) =>
                          channelKey?.trim() === channel?.key?.trim(),
                      ) >= 0 && (
                        <Link
                          key={i}
                          className="linkChannels"
                          to={{
                            pathname:
                              '/' +
                              props.params.event +
                              '/dashboard/' +
                              channel.key,
                            key: String(new Date().getTime()),
                          }}
                        >
                          <div className="roomBox">
                            <div className="room">
                              {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}

                              {!!channel.picture && (
                                <div
                                  className="bgImage"
                                  style={{
                                    backgroundImage: `url(${channel.picture})`,
                                    backgroundSize: '100%',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                  }}
                                ></div>
                              )}

                              {!!channel.name_languages && (
                                <span className="roomTitle">
                                  {channel.name_languages[i18n.language]}
                                </span>
                              )}

                              {!channel.name_languages && (
                                <span className="roomTitle">
                                  {channel.name}
                                </span>
                              )}

                              {
                                <div className="roomBaseDescription">
                                  <div className="roomDescription">
                                    {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                                    {!!channel?.description_languages && (
                                      <p className="roomName">
                                        {parse(
                                          `${
                                            channel?.description_languages[
                                              i18n.language
                                            ]
                                          }`,
                                        )}
                                      </p>
                                    )}

                                    {!channel?.description_languages && (
                                      <p className="roomName">
                                        {parse(`${channel?.description}`)}
                                      </p>
                                    )}
                                  </div>
                                  <div className="roomDescription">
                                    <p className="roomHour"> {channel.hour}</p>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </Link>
                      )}

                    {channel?.key === 'stand3d' && !user?.restrictChannels && (
                      <Link
                        key={i}
                        className="linkChannels"
                        to={{
                          pathname:
                            '/' +
                            props.params.event +
                            '/dashboard/' +
                            channel.key,
                          key: String(new Date().getTime()),
                        }}
                      >
                        <div className="roomBox">
                          <div className="room">
                            {/* {channel.id == eventContext.channel.id && <div className="overlay"><p>Você está aqui!</p></div>} */}

                            {!!channel.picture && (
                              <div
                                className="bgImage"
                                style={{
                                  backgroundImage: `url(${channel.picture})`,
                                  backgroundSize: '100%',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center',
                                }}
                              ></div>
                            )}

                            {!!channel.name_languages && (
                              <span className="roomTitle">
                                {channel.name_languages[i18n.language]}
                              </span>
                            )}

                            {!channel.name_languages && (
                              <span className="roomTitle">{channel.name}</span>
                            )}

                            {
                              <div className="roomBaseDescription">
                                <div className="roomDescription">
                                  {/* <p className="roomName" dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel?.description_languages[i18n.language]}}></p> */}
                                  {!!channel?.description_languages && (
                                    <p className="roomName">
                                      {parse(
                                        `${
                                          channel?.description_languages[
                                            i18n.language
                                          ]
                                        }`,
                                      )}
                                    </p>
                                  )}

                                  {!channel?.description_languages && (
                                    <p className="roomName">
                                      {parse(`${channel?.description}`)}
                                    </p>
                                  )}
                                </div>
                                <div className="roomDescription">
                                  <p className="roomHour"> {channel.hour}</p>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </Link>
                    )}
                  </React.Fragment>
                ),
            )}
          </div>
        </Conteiner>
      )}
    </>
  );
};

export default React.memo(Channels);
