import styled, { css, keyframes } from 'styled-components';

export const MainContainer2Column = styled.main`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 350px;
  gap: 1rem;

  .features-position__sticky{
    position: sticky;
    top: 32px;
    width: 100%;
    height: calc(100vh - 64px);
  }

  @media(max-width: 768px) {
    grid-template-columns: 1fr;
    .features {
      position: initial;
      height: calc(100vh - 32px);
      min-height: 450px;
      padding: 1rem 0;
    }
  }

`;
