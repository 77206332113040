import React, { useEffect } from 'react';
import { MainContainer2Column } from '../../components/Ui/MainContainer2Column';

import FeedGrid from '../../components/FeedGrid';
import SideTabMenu from '../../components/SideTabMenu';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../hooks/EventContext';
import { useAuth } from '../../hooks/Auth2';
import DashboardMasterPage2 from '../../masterpages/DashboardMasterPage2';
import ModalTabs from '../../components/NewsFeatures/ModalTabs';

const Feed: React.FC<any> = (props) => {
  const { event: eventKey, channel: channelKey } =
    useParams<{ channel: string; event: string }>();
  const { event, channel, ...eventContext } = useEvent();
  const [modalFeed, setModalFeed] = React.useState(false);
  const [contentModal, setContentModal] = React.useState(
    null as React.ReactNode,
  );
  function handleModal(): void {
    setModalFeed(!modalFeed);
  }
  function setContentModalFeed(content: React.ReactNode): void {
    setContentModal(content);
  }
  useEffect(() => {
    if (eventKey && channelKey) eventContext.loadInfo(eventKey, channelKey);
  }, [eventKey, channelKey]);
  return (
    <DashboardMasterPage2 {...props}>
      <MainContainer2Column>
        <FeedGrid
          handleModal={handleModal}
          setContentModalFeed={setContentModalFeed}
        />
        <div
        // className="features-position__sticky"
        // Descomentar classe quando <SideTabMenu /> for ser usado
        >
          {/* <SideTabMenu /> */}
        </div>
      </MainContainer2Column>
      {modalFeed && (
        <ModalTabs onClose={handleModal}>{contentModal}</ModalTabs>
      )}
    </DashboardMasterPage2>
  );
};
export default Feed;
