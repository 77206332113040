import qs from 'query-string';

export function getQueryParam(name: string, defaultValue: string) {
  const params = qs.parse(window.location.search);
  return (params[name] || defaultValue) as string;
}

export function updateQueryParam(name: string, value: string) {
  const params = qs.parse(window.location.search);
  params[name] = value;
  const paramsString = qs.stringify(params);
  const url = `${window.location.pathname}?${paramsString}`;

  window.history.pushState(null, '', url);
}
