import styled, { css, keyframes } from 'styled-components';

interface IPartnersItem {
  delay: number;
}

const show = keyframes`
to{
  transform: initial;
  opacity: initial;
}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const PartnersContainerItems = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;

  @media (max-width: 768px) {
    .partners-grid {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }
`;

export const PartnersItem = styled.div<IPartnersItem>`
  a {
    display: block;
    background: ${props => props.theme.colors.primary};
    border-radius: 5px;
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex: 1;
    position: relative;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 1rem;
    width: 210px;
    height: 150px;
  }

  ${(props) =>
    css`
      opacity: 0;
      transform: translateY(-8px);

      animation: ${show} 0.2s forwards cubic-bezier(0.33, 0.41, 0.44, 0.9);
      animation-delay: ${props.delay}s;
    `}
`;
