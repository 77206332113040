import styled, { keyframes, css } from 'styled-components';
import { shade } from 'polished';
interface IContainer {
  customization: any;
  channelCustomization: any;
  background?: any;
  theme: any
}
interface AnimationsPros {
  customization: any;
  channelCustomization: any;
  background?: any;
  theme: any
}
interface ContainerPros {
  customization: any;
}

export const Container = styled.div<ContainerPros>`
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
  /* gap:1rem; */
  a.navbar-brand {
    margin: 0 5px;
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const appearFromLeft2 = keyframes`
  from {
    opacity: 0;
    transform: translateX(0px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div<AnimationsPros>`
  /* background-size: 100% 100%; */



  animation: ${appearFromLeft} 1s;

`;

export const AnimationContainer2 = styled.div<AnimationsPros>`
  animation: ${appearFromLeft2} 1s;
`;

export const ContentMaxWidth = styled.div`
  max-width: var(--max-width-small);
  width: 100%;
  margin:0 auto;
  padding: 0 1rem;
  @media (min-width:1920px) {
    max-width: var(--max-width-large);
    padding: 0 8.75rem;
  }
`
export const ContainerBackgroundImage = styled.div<IContainer>`

${(props) => !props.background ? css`
      background: linear-gradient(
        -45deg,
        ${shade(props.theme.title === 'light' ? 0 : .4, props.customization?.backgroundHeaderGradient?.color2 || props.customization?.backgroundHeaderGradientColor2)}
          20%,
        ${shade(props.theme.title === 'light' ? 0 : .4, props.customization?.backgroundHeaderGradient?.color1 || props.customization?.backgroundHeaderGradientColor1)}
          80%
      );
`: css`
  background: url(${props.background}) ;
  /* background-size: contain !important; */
  /* background-repeat: no-repeat !important; */
  filter: ${props.theme.title === 'dark' ? 'brightness(0.5)' : 'initial'}
`
}}

`
