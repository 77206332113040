import React, { useState, useEffect } from 'react';
import { useEvent } from '../../hooks/EventContext';
import { useTheme } from '../../hooks/ThemeContext';
import * as S from './style';
import firebase from 'firebase/app';
import { IoMdRefresh } from 'react-icons/io';
import 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../hooks/Auth2';
import './styles.css';
import HeaderPageDescription from '../HeaderPageDescription';

const firestore = firebase.firestore();
interface IProps {
  handleModal: () => void;
}

const TipGrid: React.FC<IProps> = (props: any) => {
  const eventContext = useEvent();
  const { theme } = useTheme();
  const { i18n } = useTranslation();
  const [tips, setTips] = useState<
    { title: string; cover: string ; description: string; createdAt: number; urlAudio: string}[]
  >([]);
  const [arrayIds, setArrayIds] = useState<any[]>([]);
  const [isComplete,setIsComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMobile, SetIsMobile] = useState(
    window.matchMedia('(max-width: 992px)').matches,
  );
  const { t } = useTranslation();

  useEffect(() => {
    firestore
      .collection('tips')
      .where('eventId', '==', eventContext.event.id)
      .limit(2)
      .onSnapshot((snap) => {
        const tip = snap.docs?.map<{
          title: string;
          description: string;
          cover: string;
          urlAudio: string;
          createdAt: number;
        }>((item) => ({
          ...(item.data() as any),
          id: item.id,
        }));
      if(tip.length === 0){
        setIsComplete(true)
      }
        setTips(tip);
      });
  }, []);

  //next button function
  const showNext = (item: number ) => {
      const fetchNextData = () => {
           setLoading(true);
           firestore.collection('tips')
          .orderBy('createdAt', 'asc') //order using firestore timestamp
          .limit(2)
          .startAfter(item || 0) //we pass props item's first created timestamp to do start after you can change as per your wish
          .onSnapshot(function (querySnapshot) {
            const list: any[] | ((prevState: { title: string; cover: string; description: string; createdAt: number; urlAudio: string; }[]) => { title: string; cover: string; description: string; createdAt: number; urlAudio: string; }[]) = [];
              querySnapshot.docs?.forEach(function(doc) {
                list.push({ id: doc.id, ...doc.data() });
            });
            if(list.length === 0){
              setIsComplete(true)
            }
            setTips(oldArray => [...oldArray, ...list]);
            setLoading(false);
          })
      };
      fetchNextData();
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      SetIsMobile(window.matchMedia('(max-width: 992px)').matches);
    });
  });

return (
  <>
    {isMobile ? (
        <S.Container>
          <S.CluesContainerItems>
            <HeaderPageDescription title={`${t("Tips")}`}>

            </HeaderPageDescription>
           {tips.map((clue: any, index) => (
             <div>

               <S.Card theme={theme}
               delay={index * 0.0125}
               key={`clue-item-${clue.title}-${index}`}>
                 {clue.cover && (
                     <S.CardImageContainer>
                      <S.CardImage alt="400x220" src={clue.cover} />
                   </S.CardImageContainer>
                 )}

                 <S.CardClue theme={theme}>
                   <S.CardInfo>
                     <S.CardInfoHeader>
                       <S.CardTitle theme={theme}>{clue?.title}</S.CardTitle>
                     </S.CardInfoHeader>
                      {/* <S.CardInfoText theme={theme} isCommentShown={arrayIds.includes(index)} lineClamp={arrayIds.includes(index)}
                        dangerouslySetInnerHTML={{
                          __html: clue?.description[i18n.language] || clue?.description,
                        }}>
                     </S.CardInfoText> */}
                    <div className="product">
                      <div className='infosProductContainer'>
                        <div className="inforProductDescription">
                        <p
                          className="p-li"
                          dangerouslySetInnerHTML={{
                            __html: clue?.description[i18n.language] || clue?.description,
                          }}
                          style={{lineBreak: 'anywhere', maxWidth: '95%'}}
                        />
                        </div>
                      </div>
                    </div>
                   </S.CardInfo>
                 </S.CardClue>
             </S.Card>
             </div>

           ))}
           <div style={{display: 'flex', justifyContent:'center'}}>
           {loading ? (
           <Spinner animation="border" role="status" variant="light">
             <span className="sr-only" style={{ color: '#fff' }}>
               {t("Loading")}
             </span>
           </Spinner>
         ) : (
           <S.ShowMoreButton isComplete = {isComplete} onClick={() => showNext(tips[tips.length-1].createdAt)}>
             <IoMdRefresh size={30} color = "#fff"/>
               <span>
                 {t("Load More")}
               </span>
           </S.ShowMoreButton>
         )}
           </div>
         </S.CluesContainerItems>
       </S.Container>
    ): (
      <Redirect to={`/${eventContext?.eventKey}/signin`}/>
    )}
  </>
  );
};
export default TipGrid;
