import styled, { css, keyframes } from 'styled-components';
interface IContainer {
  theaterMode?: boolean;
  theme?: string;
  customization?: any;
}
interface IEventDate {
  dateBgColor: string;
  hourBgColor: string;
}
export const Container = styled.div<IContainer>`
  ${(props) =>
    props.theaterMode &&
    css`
    background-color: none;
  `}
  ${(props) =>
    !props.theaterMode &&
    css`
    /* background-color: ${props => props.theme.colors.headerTitle}; */

    ${(props) => css`
    background: linear-gradient(
        -45deg,
        ${props.customization?.headerBgColor1 || 'rgba(0,0,0, 0.2)'} 20%,
        ${props.customization?.headerBgColor2 || 'rgba(0,0,0, 0.2)'} 80%
      );
`}
  background: ${props => props.theme.title === 'dark' && props.theme.colors.background};
  `}
  border-radius: 10px 10px 0 0;
  padding: 1.25rem 1.75rem;
`;

export const EventTitle = styled.h2`
  font-family: "Proxima Nova";
  font-weight: 400;
  letter-spacing: 0;
  color: #fff;
  line-height: 1;
  font-size: 2rem;
`;

export const EventInfo = styled.p`
  padding: 0;
  margin: 0;
  font-family: "Proxima Nova";
  font-weight: 300;
  font-style: italic;
  color: #fff;
  font-size: 1rem;
`;

export const EventRoom = styled.span`
  margin-right: 8px;
  text-transform: capitalize;
`;

export const EventDate = styled.span<IEventDate>`
    ${(props) =>
    props.dateBgColor &&
    css`
    color: ${props.dateBgColor};
  `}
  span{
    ${(props) =>
    props.hourBgColor &&
    css`
    color: ${props.hourBgColor};
  `}
  }
`;


