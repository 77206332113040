import React, { createContext, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useEvent } from './EventContext';
import usePersistedState from "../utils/userPersistedState";
import dark from "../styles/themes/dark";
import light from "../styles/themes/light";

const ThemeContext = createContext<ThemeData>({} as ThemeData);

export interface ThemeData {
  toggleTheme(): void;
  theme: any;
}

const ThemeProvider: React.FC = ({ children }) => {
  const { event } = useEvent();
  const [eventId, setEventId] = useState<string>();
  const [theme, setTheme] = usePersistedState("theme", light);

  const toggleTheme = () => {
    setTheme(theme.title === "light" ? dark : light);
  };

  return (
    <>
      <ThemeContext.Provider
        value={{
          toggleTheme,
          theme,
        }}
      >
        {children}
      </ThemeContext.Provider>
    </>
  );
};

function useTheme(): ThemeData {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within an ThemeProvider');
  }

  return context;
}

export { ThemeProvider, useTheme };
