import styled from 'styled-components';
import { shade } from 'polished';

export const Conteiner = styled.div`
  width: 100%;
  .bp-title {
    margin-left: 10px;
  }

  p {
    font-size: 13px;
  }

  .container-links {
    padding: 0 1rem;
    background: ${(props) => props.theme.colors.grayColor};
    height: 100%;

    .links-header {
      padding: 0.5rem 1rem;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #c5c4c4;

      h3 {
        font-size: 0.88rem !important;
        padding: 1rem;
        margin: 0;
        font-weight: 400;
        color: ${(props) => props.theme.colors.askText};
      }
    }
  }
  .content-links {
    width: 100%;
    padding: 1rem 0;

    .link-item {
      font-size: 1rem;
      width: 100%;
      padding: 1rem 1rem 1rem 0;
      display: flex;
      justify-content: space-between;
      position: relative;
      opacity: 0.9;

      .links-icon {
        width: 10px;
        transition: all 0.25s;
      }
      &:hover .links-icon {
        transition: all 0.1s;
        transform: translateX(25%);
      }
      &:hover {
        opacity: 1;
      }
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, #0dccff, #4760ff);
        background-size: 200%;
        background-position: 0%;
        transition: all 0.3s linear;
      }
      &:hover::before {
        transition: all 0.1s;
        background-position: 100%;
      }
    }
  }
`;
