import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: #000;
  width: 100%;
  height: auto;
  margin: 0 40px;
  overflow: overlay;
  border-radius: 10px;
  max-height: 700px;
  max-width: fit-content;

  h1,h2,h3,h4,h5,p{
    color: ${props => props.theme.colors.text};;
  }
`
