import React, { useState, useEffect, CSSProperties } from 'react';
import { useAuth } from '../../hooks/Auth2';
import { useEvent } from '../../hooks/EventContext';
import Features from '../Features';
import './styles.css';
interface Props {
  style: CSSProperties;
  restProps?: any;
}

const MenuTabRoom: React.FC<Props> = (props) => {
  const { getUser } = useAuth();
  const user = getUser();
  const eventContext = useEvent();

  const [isMobile, SetIsMobile] = useState(
    window.matchMedia('(max-width: 920px)').matches,
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      SetIsMobile(window.matchMedia('(max-width: 920px)').matches);
    });
  });

  return (
    <div
      style={{
        ...props.style,
        // background: 'red',
        width: isMobile ? props?.style?.width : '100%!important',
        overflow: 'none',
        maxHeight: undefined,
        height: 'auto',
        marginBottom: '10px',
        justifyContent: 'space-between',
      }}
    >
      {!!eventContext.channel?.resources && (
        <Features
          customization={eventContext.event?.customization || {}}
          user={user}
          eventId={eventContext.event?.id}
          channelId={eventContext.channel?.id}
          group={eventContext.channel?.customization?.channel_group}
          {...props?.restProps}
        />
      )}
    </div>
  );
};
export default MenuTabRoom;
