import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/firebase-storage';
import { Conteiner } from './style';
import moment from 'moment';
import { useToast } from '../../../../hooks/Toast';
import { asEnumerable } from 'linq-es2015';
import { useEvent } from '../../../../hooks/EventContext';
import { Card, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Spinner from 'react-bootstrap/Spinner';
import Button from '../../../Button';
import ContainerButton  from '../../../ContainerButton';
import * as Yup from 'yup';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

interface Props {
  user: any;
  channelId: string;
  eventId: string;
  item: any;
};

const Partners: React.FC<Props> = (props: any) => {
  const { t, i18n } = useTranslation();
  const { register, setValue, handleSubmit, errors } = useForm<FormData>();
  const firestore = firebase.firestore();
  const { addToast } = useToast();
  const [lgShow, setLgShow] = useState(false);
  const [sending, isSending] = useState(false);
  const [loading, setLoading] = useState(false);

  // const onSubmit = handleSubmit(async (data: any) => {
  //   try {

  //     setLoading(true);

  //     const schema = Yup.object().shape(
  //       {
  //         userComment: Yup.string().required('O comentário é obrigatório')
  //       }
  //     );

  //     await schema.validate(data, {
  //       abortEarly: true,
  //     });


  //     const entity: any = {
  //       createdAt: moment.utc().valueOf(),
  //       comment: data.userComment,
  //       channelId: props.channelId,
  //       eventId: props.eventId,
  //       user: {
  //         name: props.user.name,
  //         email: props.user.email
  //       },
  //       uid: props.user.id || props.user.uid
  //     };

  //     const ref = firestore.collection('timeline').doc(props.item.id);
  //     await ref.update({
  //       comments: firebase.firestore.FieldValue.arrayUnion(entity)
  //     });

  //     setValue("userComment", '');
  //     addToast({
  //       type: 'success',
  //       title: 'Post enviado',
  //       description: 'Sua mensagem foi enviada com sucesso!',
  //     });

  //     setLoading(false);

  //   } catch (error) {
  //     setLoading(false);

  //     addToast({
  //       type: 'error',
  //       title: 'Erro no envio',
  //       description: error?.response?.data?.message || error.message || 'Ocorreu um erro ao fazer o envio, tente novamente.',
  //     });
  //   }
  // });

  return (
    <>
      {/* <Button onClick={() => setLgShow(true)}>Comentários ({props.item?.comments?.length})</Button> */}
      <div className="flex-container" onClick={() => setLgShow(true)} style={{textAlign:'center'}}>
       
       <img src={props?.item?.picture} style={{width:'65%', position:'relative', margin:'0 auto'}} />
      </div>

<Conteiner>

      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
          <img src={props?.item?.picture} style={{height:'80px'}} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

              <>
              {parse(`${props?.item?.biografia[i18n.language]}`)}
              <br>
              </br>
              <br>
              </br>
              <a href={`//${props?.item?.site}`} target="_blank">
                <strong>{parse(`${props?.item?.site}`)}</strong>
              </a>
              </>

        </Modal.Body>
      </Modal>
      </Conteiner>
      </>
  );
};

export default Partners;
