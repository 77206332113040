import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useEvent } from '../../hooks/EventContext';
import useWindowDimensions from '../../hooks/useDimension';

const IframeLp: React.FC = () => {
  const history = useHistory();
  const { event: eventKey } = useParams<{ event: string }>();
  const { event, loadEvent } = useEvent();
  const ref = useRef<HTMLIFrameElement>(null);
  const url = `https://lps.4yourlive.com/${eventKey}/`;
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    loadEvent(eventKey);
  }, [eventKey]);

  console.log('render iframelp', {
    lp: Number(event?.settings?.access?.lp),
    event,
    eventKey,
  });

  return (
    <iframe
      id="lpiframe"
      className="iframeBox"
      ref={ref}
      title="teste"
      src={url}
      width="100%"
      height={height}
      frameBorder="0"
    />
  );
};

export default IframeLp;
