import styled from 'styled-components';
import { shade } from 'polished';
import { ContainerTabs } from '../ContainerTabs';

export const Conteiner = styled(ContainerTabs)`

@media(max-width:768px){
    width: 330px;

    .questionDiv{
      width: 100px;
    }
  }

.questionDateTime{
  font-size:10px;
  font-style:italic;
  color:#ccc;
}

.questionEmail{
  font-size:14px;
  font-style:italic,
}

.questionButtonActions button{
  border:0;
  border-radius:10%;
  background-color:#FD3944;
}
.questionName{
  /* font-weight:600; */
}

.table > tbody > tr > td {
    vertical-align: middle;
}
.table > tbody > tr > th {
    vertical-align: middle;
}
`;

export const Table = styled.table`
  width:100%;
  max-width:100%;
  overflow:auto;
  border-radius: 3px;
  border-collapse: separate;
  border-spacing: 2px;
  display: flex;
  margin: 14px 0;
  border:1px solid #EBEBEB;
  th, td {
    border: none;
  }
  tbody{
    color:#4e4e4e;
    width: 100%;
  tr{
    &:nth-child(even){
      background: #f1f1f1;
    }
      th {
      background: #ffffff;
      padding: 0 1.25rem;
      height: 35px;
      overflow: hidden;
      font-weight: 500;
      color: #616161;
      vertical-align: middle;
    }

    @media(max-width:768px){
      td {
      padding: 0rem;
    }}

    td {
      width: 100%;
      color: inherit;
      font-weight: inherit;
      padding: 1rem;
      line-height: 1.4;
      flex:1;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: .88rem;
      vertical-align: middle;
    }
  }
  @media(max-width:768px){
    tr td{
      /* white-space: nowrap; */
      max-width: 400px;
    }
  }
  .dropdown{
    position: initial;
    padding: 0 4px;
  }
`;
