import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

import { AuthProvider } from './Auth2';
import { ToastProvider } from './Toast';
import { I18Provider } from './i18';
import { EventProvider } from './EventContext';
import { FirebaseProvider } from './Firebase';
import { ChatProvider } from './ChatContext';
import { PresenceProvider } from './PresenceContext';
import { SpeakersProvider } from './SpeakersContext';
import { ThemeProvider } from './ThemeContext';
import { useHistory } from 'react-router-dom';

const AppProvider: React.FC = ({ children, ...rest }) => {
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    const url = `${appState?.event_key}/callback` || window.location.pathname;

    console.log('onRedirectCallback', { url });

    history.push(url, { ...appState });
  };

  return (
    <Auth0Provider
      domain="dev-2qcr8hdi7ym5lg3p.us.auth0.com"
      clientId="1CllwqBbU3v5XMNHQEKRKrz1RZmvx5Ow"
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <FirebaseProvider>
        <AuthProvider>
          <ToastProvider>
            <EventProvider {...rest}>
              <SpeakersProvider>
                <ThemeProvider>
                  <I18Provider>{children}</I18Provider>
                </ThemeProvider>
              </SpeakersProvider>
            </EventProvider>
          </ToastProvider>
        </AuthProvider>
      </FirebaseProvider>
    </Auth0Provider>
  );
};

export default AppProvider;
