import React from 'react';
import { PresenceProvider } from '../../../hooks/PresenceContext';
import UsersComponent from './Users';
// import { Container } from './styles';

export interface ChatProps {
  user: any;
  channelId: string;
  eventId: string;
}

const Users: React.FC<ChatProps> = (props) => {
  return (
    // <PresenceProvider>
    <UsersComponent {...props} />
    // </PresenceProvider>
  );
};

export default Users;
