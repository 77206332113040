import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as S from './style';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';
import { asEnumerable } from 'linq-es2015';
import api from '../../../services/api';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { PresenceProvider, usePresence } from '../../../hooks/PresenceContext';
import { useAuth } from '../../../hooks/Auth2';
import { ChatProps } from '.';
import Emitter from '../../../utils/Event';
import { useTranslation } from 'react-i18next';

const Users: React.FC<ChatProps> = (props: any) => {
  const { getUser } = useAuth();
  const user = getUser();
  // const { participantsOnline } = usePresence();
  const [participantsOnline, setParticipantsOnline] = useState<any[]>([]);
  const firestore = firebase.firestore();
  // const [sessions, setSessions] = useState<any[]>([]);
  const [filtered, setFiltered] = useState<any[]>([]);
  const { addToast } = useToast();
  const [filter, setFilter] = useState<string>('');
  const { t } = useTranslation();

  async function setModerator(data: any) {
    try {
      await api.post('/participant/set-moderator', { email: data.email });
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Usuário setado com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: error?.response?.data?.message || error.message,
      });
    }
  }

  async function logoutUser(data: any) {
    // remove from session manager
    const sessionRef = firestore
      .collection('session_manager')
      .doc(props.channelId);
    const refSession = await sessionRef.get();
    // const doc = refSession.data();
    // if (doc) {
    //   const array = (doc as any)?.users || [];
    //   const userData = asEnumerable(array).FirstOrDefault(
    //     (e: any) =>
    //       e.email.trim().toLowerCase() === data.email.trim().toLowerCase(),
    //   );

    //   await sessionRef.update({
    //     count: array.length > 0 ? array.length - 1 : 0,
    //     users: firebase.firestore.FieldValue.arrayRemove(userData),
    //   });
    // }
    const sessionId = sessionStorage.getItem('4yourlive:tabid')

    api.get(`/sessions/logout/${data.userId}/${sessionId}`);
    // force logout
    // const ref = firestore.collection('logout').doc(props.eventId);

    // if ((await ref.get()).exists) {
    //   ref.update({
    //     uids: firebase.firestore.FieldValue.arrayUnion(data.userId),
    //   });
    // } else {
    //   firebase
    //     .firestore()
    //     .collection('logout')
    //     .doc(props.eventId)
    //     .set({
    //       uids: [data.userId],
    //     });
    // }
  }

  useEffect(() => {
    Emitter.on('PARTICIPANTS.ONLINE', (value: any[]) => {
      setParticipantsOnline((prev) => {
        if (value !== prev) return value;

        return prev;
      });
    });
  }, []);

  useEffect(() => {
    console.log(
      '**** useEffect usersOnline',
      participantsOnline.map((p) => ({
        lastHour: moment(p.lastJoin).format('HH:mm'),
        ...p,
      })),
    );
    const users = asEnumerable(participantsOnline || [])
      .OrderByDescending((u: any) => u.lastJoin)
      .Where(
        (u: any) =>
          !filter ||
          u.email?.toLowerCase().indexOf(filter?.toLowerCase()) > -1 ||
          u.name?.toLowerCase().indexOf(filter?.toLowerCase()) > -1,
      )
      .Take(100)
      .ToArray();
    // const users = asEnumerable(doc.users || []).OrderByDescending((u: any) => u.loginAt).ToArray();
    setFiltered(users);

    return () => { };
  }, [participantsOnline, filter]);

  return (
    <S.Container>
      {/* <h3>Total ({sessions.length} usuários)</h3> */}
      <S.Form>
        <input
          name="filter"
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          type="text"
          placeholder="Filtro"
        />
      </S.Form>
      <p className="sessions-users-text-italic">
        {t("Last 100 users. (Updated each 3 minutes).")}
      </p>
      {filtered.length ? (
        <S.Table>
          <tbody>
            <tr>
              <th>#</th>
              <th>{t("Name")}</th>
              <th>{t("User")}</th>
              {/* <th>Login</th> */}
              <th>{t("Actions")}</th>
            </tr>
            {filtered.map((s: any, index1: number) => (
              <tr key={`sessions-users-${index1}`}>
                <td>{index1 + 1}</td>
                <td>{s.name || s.email}</td>
                <td>{s.email}</td>
                {/* <td>{moment(s.loginAt).format('YYYY-MM-DD HH:mm:ss')}</td> */}
                <td>
                  <DropdownButton id="dropdown-basic-button" title={t("Actions")}>
                    <Dropdown.Item onClick={() => logoutUser(s)}>
                      {t("Logout")}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setModerator(s)}>
                      {t("Change profile")}
                    </Dropdown.Item>
                  </DropdownButton>
                </td>
                {/* <td>
                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Atualizar
                      </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" onClick={() => markAsAnswered(s)}>Marcar como respondida</a>
                  </div>
                </div>
              </td> */}
              </tr>
            ))}
          </tbody>
        </S.Table>
      ) : (
        <S.NotFoundUsers>
          <p>
            <b>0</b> usuarios encontrados.
          </p>
        </S.NotFoundUsers>
      )}
    </S.Container>
  );
};

export default Users;
