import React, { useState, useEffect, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, Col, Nav, Row } from 'react-bootstrap';

import TabScreen from './Tab/TabScreen';
import ChatView from './Chat';
import Enquete from './Enquete';
import Pergunte from './Pergunte';
import LinksCom from './Links';
import ModalSpeakers from '../ModalSpeakers';

import Banner from '../Banner';
import { useIsRetrato } from '../../context/contextplayer';
import { useEvent } from '../../hooks/EventContext';
import { useTheme } from '../../hooks/ThemeContext';

import './styles.css';
import Chat2 from '../Chat2';
import { useAuth } from '../../hooks/Auth2';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faComments,
  faList,
  faLink,
} from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../Icons'
import { TabsContainer, TabsPaneContainer } from './styles';
import { asEnumerable } from 'linq-es2015';
interface Props {
  style?: CSSProperties;
  NewHeight?: number;
  theaterMode?: any;
}

const TABS_ORDERS = ['chat2', 'ask', 'survey', 'links'];

const SideTabMenu: React.FC<Props> = (props) => {
  const { getUser, setUser } = useAuth();
  const user = getUser();
  const { theme } = useTheme();
  const eventContext = useEvent();
  // const [key, setKey] = useState('');
  const { t } = useTranslation();
  const {  key, setKey } = useEvent();

  useEffect(() => {
    for (let prop in TABS_ORDERS) {
      const key = eventContext?.channel?.resources?.find((resource: any) => resource.key === TABS_ORDERS[prop])
      if (key) {
        setKey(key.key);
        break
      }
    }
  }, [eventContext?.channel?.id])

  return (
    !key ? (
      <></>
    ): (
    <div className="sideTabMenu">
      {eventContext.channel?.customization?.infoImageLink && (
        <div className="bannerFeatures">
          <Banner height={80} padding={0} />
        </div>
      )}

      <Tab.Container
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => {
          if (k) setKey(k);
          window.document
          .getElementsByClassName(`iconsizeitemmaeutab ${k}`)?.[0]
          ?.classList?.remove('highlighted');
        }}
      >
        <div className={props.theaterMode ? "theater-tab-container" : "tab-container"}>
          <TabsContainer theme={theme}>
            <Nav variant="pills" className="">
              {asEnumerable(
                eventContext.channel?.resources || [],
              ).Any((r: any) => r.key == 'chat2') &&
              eventContext.channel?.id && (
                <Nav.Item>
                  <Nav.Link eventKey="chat2">
                      <Icon name="iconChat" />
                      <span style={{ textAlign: 'center', fontSize: '10px' }}>
                        Chat
                      </span>
                  </Nav.Link>
                </Nav.Item>
              )}
              {asEnumerable(
                eventContext.channel?.resources || [],
              ).Any((r: any) => r.key == 'ask') &&
                eventContext.channel?.id && (
                  <Nav.Item>
                    <Nav.Link eventKey="ask">
                       <Icon name="iconQuestions" />
                      <span style={{ textAlign: 'center', fontSize: '10px' }}>
                        {t('Q_A')}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                )}
              {asEnumerable(
                eventContext.channel?.resources || [],
              ).Any((r: any) => r.key == 'survey') &&
                eventContext.channel?.id && (
                  <Nav.Item>
                    <Nav.Link eventKey="survey" className={`iconsizeitemmaeutab survey`}>
                       <Icon name="iconSurvey" />
                      <span style={{ textAlign: 'center', fontSize: '10px' }}>
                        {t('Survey')}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                )}
              {asEnumerable(
                eventContext.channel?.resources || [],
              ).Any((r: any) => r.key == 'links') &&
                eventContext.channel?.id && (
                  <Nav.Item>
                    <Nav.Link eventKey="links" className={`iconsizeitemmaeutab links`}>
                        <Icon name="iconLinks" />
                      <span style={{ textAlign: 'center', fontSize: '10px' }}>
                        {t('Links')}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                )}
            </Nav>
          </TabsContainer>
          <TabsPaneContainer theme={theme}>
            {asEnumerable(
              eventContext.channel?.resources || [],
            ).Any((r: any) => r.key == 'chat2') &&
              eventContext.channel?.id && (
                <Tab.Pane eventKey="chat2" title="Chat">
                  <Chat2 user={user} />
                </Tab.Pane>
              )}
            {asEnumerable(
              eventContext.channel?.resources || [],
            ).Any((r: any) => r.key == 'ask') &&
              eventContext.channel?.id && (
                <Tab.Pane eventKey="ask" title="Pergunte">
                  <Pergunte {...props} />
                </Tab.Pane>
              )}
            {asEnumerable(
              eventContext.channel?.resources || [],
            ).Any((r: any) => r.key == 'survey') &&
              eventContext.channel?.id && (
                <Tab.Pane eventKey="survey" title={t('Survey')}>
                  <Enquete />
                </Tab.Pane>
              )}
            {asEnumerable(
              eventContext.channel?.resources || [],
            ).Any((r: any) => r.key == 'links') &&
              eventContext.channel?.id && (
                <Tab.Pane eventKey="links" title={t('Links')}>
                  <LinksCom />
                </Tab.Pane>
              )}
          </TabsPaneContainer>
        </div>
      </Tab.Container>
    </div>
    )
  );
};
export default SideTabMenu;
