import React, { useState, useEffect } from 'react';
import './styles.css';
import Chat from '../../Chat';
import Chat2 from '../../Chat2';
import { useAuth } from '../../../hooks/Auth2';
import { useEvent } from '../../../hooks/EventContext';
import { useIsRetrato } from '../../../context/contextplayer';
import useWindowDimensions from '../../../hooks/useDimension';
import Users from '../../Moderator/Users';
interface Props {
  width: number;
  height: number | string;
}
interface Msm {
  name: string;
  email: string;
  msm: string;
}

const ChatView: React.FC<Props> = (props) => {
  const { getUser } = useAuth();
  const user = getUser();
  const eventContext = useEvent();

  const { width } = useWindowDimensions();

  const largItem =
    width <= 500
      ? width - 50
      : Number(
        width <= 920
          ? Number(width - 150)
          : Number(width >= 1500 ? 1500 - 500 : width - 500),
      );

  // {eventContext.channel?.id && <Chat style={!activeObj['chat'] ? { display: 'none' } : {}} user={user} channelId={eventContext.channel?.id}></Chat>}
  // {eventContext.channel?.id && <Survey style={!activeObj['survey'] ? { display: 'none' } : {}} user={user} channelId={eventContext.channel?.id}></Survey>}
  // {eventContext.channel?.id && <Links style={!activeObj['links'] ? { display: 'none' } : {}} user={user} channelId={eventContext.channel?.id}></Links>}
  // {eventContext.channel?.id && <QuestionToSpeaker style={!activeObj['ask'] ? { display: 'none' } : {}} user={user} eventId={eventContext.event?.id} channelId={eventContext.channel?.id}></QuestionToSpeaker>}

  const [isMobile, SetIsMobile] = useState(
    window.matchMedia('(max-width: 992px)').matches,
  );

  function showTabItem(key: string) {
    console.log('showTab', { key, resources: eventContext.channel?.resources });
    if (
      eventContext.channel?.resources &&
      Array.isArray(eventContext.channel.resources)
    ) {
      return eventContext.channel.resources.some(
        (item: any) => item?.key === key && Boolean(item.tab),
      );
    }
    return false;
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      SetIsMobile(window.matchMedia('(max-width: 992px)').matches);
    });
  });

  return (
    <div
      style={{
        display: 'flex',
        height: '94%',
        maxHeight: user?.isModerator ? !isMobile? Number(largItem / 1.78) + 40 : '100%' : !isMobile ? Number(largItem / 1.78) - 95 : '100%',
        // maxHeight:Number(largItem / 1.78) - 95,
        flexDirection: 'column',
        alignItems: 'center',
        background: 'white',
      }}
    >
      {/* <div style={{ width: props.width - bord, height: props.height - viewInputBox }} className='boxmsmchatview'>
                    {MensagensData.map((res, index) => {
                        return (
                            <div key={index} className='itemmensagemview'>
                                <img src={'https://cdn.onlinewebfonts.com/svg/img_568657.png'} alt="Image Avatar" style={{ width: 20, height: 20 }} />
                                <view style={{ padding: 10 }} />
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>{res.name}</span>
                                    <span>{res.msm}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div style={{ width: props.width - bord, height: viewInputBox }} className='viewinputsbox'>
                    <input placeholder={t('Mensagem')} style={{ width: props.width, height: '100%' }} className='inputsss' />
                    <span className='sendviewinputchat fas fa-paper-plane' style={{ width: 50, height: '80%' }} />
                </div> */}

      {eventContext.channel?.id && showTabItem('chat') && !showTabItem('chat2') && (
        <Chat user={user} {...props} channelId={eventContext.channel.id} />
      )}
      {eventContext.channel?.id && showTabItem('chat') && showTabItem('chat2') && (
        <Chat user={user} {...props} channelId={eventContext.channel.id} />
      )}
      {eventContext.channel?.id && showTabItem('chat2') && !showTabItem('chat') && (
        <Chat2
          user={user}
          {...props}
          eventId={eventContext.event.id}
          channelId={eventContext.channel.id}
        />
      )}
    </div>
  );
};
export default ChatView;
