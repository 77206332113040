import React, { useState, useEffect } from 'react';
import { useEvent } from '../../hooks/EventContext';
import * as S from './style';
import firebase from 'firebase/app';

import 'firebase/firestore';
import HeaderPageDescription from '../HeaderPageDescription';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../hooks/ThemeContext';

const firestore = firebase.firestore();

const SponsorsGrid: React.FC = (props: any) => {
  const eventContext = useEvent();
  const [sponsors, setSponsors] = useState<
    { id: string; name: string; link: string; cover: string }[]
  >([]);
  const { theme } = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    firestore
      .collection('sponsor')
      .where('eventId', '==', eventContext.event.id)
      .onSnapshot((snap) => {
        const sponsors = snap.docs?.map<{
          id: string;
          name: string;
          link: string;
          cover: string;
        }>((item) => ({
          ...(item.data() as any),
          id: item.id,
        }));
        setSponsors(sponsors);
      });
  }, []);

  return (
    <S.Container>
      <HeaderPageDescription
        title={`${t("Sponsors")}`}
        // description={`Lorem Ipsum is simply dummy text of the printing and typesetting industry.`}
      />
      <S.SponsorsContainerItems>
        {sponsors.map((sponsors, index) => (
          <S.SponsorsItem
            delay={index * 0.0125}
            key={`sponsors-item-${sponsors.name}-${index}`}
            theme={theme}
          >
            <abbr title={sponsors.name}>
              <a href={sponsors?.link} target="_blank">
                <img src={sponsors?.cover} alt={sponsors.name} />
              </a>
            </abbr>
          </S.SponsorsItem>
        ))}
      </S.SponsorsContainerItems>
    </S.Container>
  );
};
export default SponsorsGrid;
