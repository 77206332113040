import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner } from './style';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';
import { asEnumerable } from 'linq-es2015';
import { useEvent } from '../../../hooks/EventContext';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { useSpeaker } from '../../../hooks/SpeakersContext';

interface ChatProps {
  user: any;
  channelId: string;
  eventId: string;
}

const Schedule: React.FC<ChatProps> = (props: any) => {
  const { t, i18n } = useTranslation();
  const firestore = firebase.firestore();
  const { addToast } = useToast();
  const [schedule, setSchedule] = useState<any>({
    programation: [],
    programation_language: [],
  });
  const eventContext = useEvent();
  const [unsubscribes, setUnsubscribes] = useState<any[]>([]);
  const { speakers: $speakers } = useSpeaker();
  const [speakers, setSpeakers] = useState<any>({});

  useEffect(() => {
    const speakerObj: any = {};

    if (!$speakers?.length) return;

    $speakers.map((speaker: any) => {
      speakerObj[speaker.id] = speaker;
      return speaker;
    });
    setSpeakers(speakerObj);
  }, [$speakers]);

  useEffect(() => {
    if (props.channelId) {
      const unsubscribe = firestore
        .collection('schedule')
        .doc(props.channelId)
        .onSnapshot((snapshot) => {
          const schedule: any = snapshot.data() || {};
          schedule.programation_language = asEnumerable(
            schedule.programation_language || [],
          )
            .Where((u: any) => u.status == 'actived' || u.status == 'enabled')
            .ToArray();
          const programation: any = asEnumerable(
            schedule.programation_language,
          ).FirstOrDefault((a: any) => a.status == 'actived');
          setSchedule(schedule);

          eventContext.setProgramation(programation);

          if (programation?.speakers?.length) {
            eventContext.setSpeakers(
              programation?.speakers || eventContext.speakers,
            );
          } else {
            let speakers: any[] = [];
            asEnumerable(schedule.programation_language)
              .Select((item: any) => {
                speakers = asEnumerable(speakers)
                  .Union(asEnumerable(item.speakers || []))
                  .Select((speaker: any) => {
                    return {
                      id: speaker.id,
                      name: speaker.name,
                    } as any;
                  })
                  .Distinct()
                  .ToArray();
              })
              .ToArray();
            eventContext.setSpeakers(
              speakers.length ? speakers : eventContext.speakers,
            );
          }
        });
      return () => {
        unsubscribe();

        // call unsubscribe for all speakers
        unsubscribes?.map((_unsubscribe) => _unsubscribe());
      };
    }
  }, [props.channelId, eventContext.channel?.update]);

  // useEffect(() => {
  //   if (schedule.id && schedule.programation_language.length)
  //     loadSpeakers(schedule.programation_language);
  // }, [schedule.id, loadSpeakers]);

  console.log('render Schedule', { speakers, schedule, i18n });

  return (
    <Conteiner>
      <main className="container">
        <div className="mt-4">
          {' '}
          {/* row mt-4*/}
          <div className="col">
            {' '}
            {/*col */}
            <div className="agenda-area">
              {schedule?.programation_language.map((item: any, i: number) => (
                <div key={i} className="card-body">
                  <div className="row speakers">
                    <div className="col-12 ">
                      {item.start && (
                        <span className="horario-total">
                          <i
                            className="fa fa-clock horario-total"
                            style={{ fontSize: '11px' }}
                          ></i>{' '}
                          {`${moment(item.start).format('H:mm')} - ${moment(
                            item.end,
                          ).format('H:mm')}`}
                        </span>
                      )}
                      <br></br>
                      {!!item.title[i18n.language] && (
                        <p className="agenda-p">{item.title[i18n.language]}</p>
                      )}
                      {!!item.description[i18n.language] && (
                        <i className="agenda-p">
                          {parse(`${item.description[i18n.language]}`)}
                        </i>
                      )}
                    </div>
                  </div>
                  {item.speakers &&
                    item.speakers.length > 0 &&
                    item.speakers.map((speaker: any, i: number) => {
                      console.log('speaker map', { speaker, speakers });
                      const _speaker = speakers[speaker.id] || speaker;

                      return (
                        <React.Fragment key={i}>
                          <div className="row speakers">
                            {!!_speaker?.picture && (
                              <div className="col-md-2 ">
                                <div className="box-speaker">
                                  <img
                                    src={_speaker.picture}
                                    className="img img-rounded img-fluid"
                                  />
                                </div>
                              </div>
                            )}
                            <div className="col-md-8">
                              {!!_speaker?.name && (
                                <p>
                                  <strong>{_speaker.name}</strong>
                                </p>
                              )}
                              {!!_speaker?.description[i18n.language] && (
                                <p>
                                  {parse(
                                    `${_speaker.description[i18n.language]}`,
                                  )}
                                  {/* {_speaker.description[i18n.language]} */}
                                </p>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </Conteiner>
  );
};

export default Schedule;
