import React, { useState, useEffect } from 'react';
import { useEvent } from '../../hooks/EventContext';
import { useTheme } from '../../hooks/ThemeContext';
import * as S from './style';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import './styles.css';
import HeaderPageDescription from '../HeaderPageDescription';
import Content from './Content';

interface IProps {
  handleModal: () => void;
}

const ContentGrid: React.FC<IProps> = (props: any) => {
  const eventContext = useEvent();
  const { theme } = useTheme();
  const firestore = firebase.firestore();
  const [contents, setContents] = useState<
    { title: string; cover: string; category:string; description: string; createdAt: number;}[]
  >([]);
  const [contentsCategory, setContentsCategory] = useState<any[]>([])
  const { t } = useTranslation();
  const [isBusy, setIsBusy] = useState<boolean>(true)
  const [visibleTab, setVisibleTab] = useState("");
  const [isMobile, SetIsMobile] = useState(
    window.matchMedia('(max-width: 992px)').matches,
  );

  useEffect(() => {
    firestore
      .collection('contents')
      .where('eventId', '==', eventContext.event.id)
      .onSnapshot((snap) => {
        const content = snap.docs?.map<{
          title: string;
          description: string;
          category: string;
          cover: string;
          createdAt: number;
        }>((item) => {
            return {
              ...(item.data() as any),
              id: item.id,
            }
        });
      let categoryArray = Array.from(new Set(content.map((value: any) => value.category))).map((value) => {
        return {
          category: value
        }
      })
        setContentsCategory(categoryArray)
        setVisibleTab(categoryArray[0]?.category)
        setIsBusy(false)
      });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      SetIsMobile(window.matchMedia('(max-width: 992px)').matches);
    });
  });

return (
  <>
    {contents && isMobile ? (
        <S.Container theme={theme}>
          <S.ContentsContainerItems>
            <HeaderPageDescription title={`${t("Content")}`}>
              <S.ContainerButtonSetDate>
              {contentsCategory.map((category: any, index: number) => {
                return (
                  <S.ButtonSetDateShedule
                    key={index}
                    type="button"
                    onClick={() => setVisibleTab(category.category)}
                    isActive={visibleTab === category.category}
                    customization={eventContext?.event?.customization || {}}
                    theme={theme}
                  >
                    <span>
                      {category.category}
                    </span>
                  </S.ButtonSetDateShedule>
                )
              })}
              </S.ContainerButtonSetDate>
            </HeaderPageDescription>
            {visibleTab && (
              <Content  handleModal={props.handleModal} theme={theme} visibleTab={visibleTab}/>
            )}
         </S.ContentsContainerItems>
       </S.Container>
    ): (
      <Redirect to={`/${eventContext?.eventKey}/signin`}/>
    )}
  </>
  );
};
export default ContentGrid;
