import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import './ModalFooter.css';
import { useEvent } from '../../hooks/EventContext';
import { useToast } from '../../hooks/Toast';
import api from '../../services/api';
import {Button} from './styles';
const ModalFooter: React.FC<any> = ({
  id = 'modal',
  onClose = () => {},
}) => {
  const { t } = useTranslation();
  const { register, setValue, handleSubmit, errors, reset } = useForm<any>({});
  const [registerField, setRegisterField] = useState<any>({});
  const eventContext = useEvent();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { addToast } = useToast();

  const handleOutsideClick = (e: any) => {
    if (e.target.id === id) onClose();
  };

  const onSubmit = async function (data: any) {
    try {
      setLoadingSubmit(true);

      const register = { ...registerField, ...data };
      register.eventId = eventContext.event.id;

        await api.post('/feedback', {
          ...register,
        });

      setRegisterField({});
      reset();
      setLoadingSubmit(false);
      onClose();
      addToast({
        type: 'success',
        title: `${t("Request made")}`,
        description: `${t("Your information was successfully sent!")}`,
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: `${t("Error on Send")}`,
        description:
          error?.response?.data?.message ||
          error.message ||
          `${t("Error on send, try again")}.`,
      });
    }

    setLoadingSubmit(false);
  };

  return (
    <div id={id} className="modalFooter" onClick={handleOutsideClick}>
      <div className="containerFooter">
        <button className="close" onClick={onClose}></button>
        <div className="contact-forms" style={{background: `linear-gradient(${eventContext.event.customization.backgroundHeaderGradientColor1},
          ${eventContext.event.customization.backgroundHeaderGradientColor2})`}}>
      <div className="row">

        <div className="col-md-8 order-0 order-md-1">
          <h3 className="title-contact mt-5 mt-md-0">{t("Speak with a specialist")}</h3>

          <form id="contact-principal" >
            <div className="form-row">

              <div className="form-group col-sm-12">
                <input name='name' type="text" className="form-control" id="name" placeholder={`${t("Name")} *`} required
                onChange={(e: any) => {
                  setRegisterField({
                    ...registerField,
                    name: e.target.value,
                  });
                }}
                ref={register({ required: true })}
                  />
              </div>

              <div className="form-group col-sm-12">
                <input name='email' type="email" className="form-control" id="email" placeholder="Email *" required
                onChange={(e: any) => {
                  setRegisterField({
                    ...registerField,
                    email: e.target.value,
                  });
                }}
                ref={register({ required: true })}
                  />
              </div>

              <div className="form-group col-sm-12">
                <input name='phone' type="text" className="form-control" id="phone" placeholder={`${t("Telephone")} *`} required
                onChange={(e: any) => {
                  setRegisterField({
                    ...registerField,
                    phone: e.target.value,
                  });
                }}
                ref={register({ required: true })}
                  />
              </div>

              <div className="form-group col-sm-12">
                <textarea name='message' className="form-control" rows={5} id="message" placeholder={`${t("Message")} *`}
                onChange={(e: any) => {
                  setRegisterField({
                    ...registerField,
                    message: e.target.value,
                  });
                }}
                ref={register({ required: true })}
                  ></textarea>
              </div>
            </div>

            <div className="form-agreement">
              <p className="agreement-text">
               {t("BY SENDING THE FORM, I AGREE TO SHARE THE INFORMATION MENTIONED ABOVE")}.
              </p>
             </div>

            <div className="d-flex justify-content-center mb-5 mb-md-0">
              {loadingSubmit ? (
              <Button type="button" customization={eventContext.event.customization} >{t("Sending")}...</Button>
              ) :
              <Button type="button" customization={eventContext.event.customization} onClick={handleSubmit(onSubmit)}>{t("Send")}</Button>
            }
            </div>
          </form>
        </div>
      </div>

    </div>
     </div>
      </div>
  );
};

export default ModalFooter;
