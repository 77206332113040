import styled, { keyframes } from 'styled-components';

interface IButton{
  customization: any;
}

const show = keyframes`
  to{
    transform: initial;
    opacity: initial;
  }
`;

export const Container = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap-default);
`;
export const ContainerCards = styled.div`
  width:100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: center;
  gap: var(--grid-gap-default);

  @media(max-width:764px){
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-default);
  overflow: hidden;
  background: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
  flex-direction: column;

  user-select: none;

  transform: scale(.95);
  opacity: 0;
  animation: ${show} .2s forwards;

  display: grid;
  grid-template-rows: auto 1fr;
`;

export const CardImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const CardImage = styled.img`
  box-sizing: content-box;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 150px;
  border:20px solid #EBEBEB;
  opacity: 0;
  animation: ${show} .2s forwards;
`;


// ---------- Content Card

export const CardInfo = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap:16px;
`;

export const CardContent = styled.div`
  display: flex;
  margin:0 auto;
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  padding: 3rem 2rem 2rem;

  opacity: 0;
  animation: ${show} .2s forwards;
  animation-delay: .1s;

  @media (max-width:768px){
    padding: 3rem 1.75rem 2rem;
  }
`;

export const CardInfoHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap:4px;
  margin-top: 2rem;
`;

export const CardTitle = styled.h2`
  font-family: var(--fontFamily);
  font-weight: 500;
  color: ${props => props.theme.colors.text};
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  text-align: center;
`;
export const CardProfession = styled.h3`
  font-family: var(--fontFamily);
  font-weight: 300;
  color:#CECECE;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  text-align: center;
`;

export const CardButtonAccess = styled.button<IButton>`
  cursor: pointer;
  border:0;
  background: ${props => props.customization.buttonBackgroundColor};
  color: ${props => props.customization.buttonTextColor};
  font-family: var(--fontFamily);
  border-radius:6px;
  font-size: 12px;
  font-weight: 400;
  padding: 10px 28px;
  letter-spacing: 1px;
  transition: all .2s;
  text-decoration: none;
  width: fit-content;
  margin-top: 16px;
  &:hover{
    background: #929292;
  }
`;

// ---------- Footer
export const CardFooter = styled.div`
  width: 100%;
  display: flex;
  border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
  align-items: center;
  justify-content: center;
  gap:4px;
  padding: 8px;
  box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.11);

`;

export const LinkFooter = styled.a`
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:4px;
    color:#B9B9B9;
    transition: all .2s;
    svg{
      width: 100%;
      height: 28px;
      fill:#B9B9B9;
      transition: all .2s;
      filter: grayscale(100%);
    }
    &:hover{
      background: rgba(83, 83, 83, 0.1);
      transform: scale(1.1);
      svg{
        transform: scale(.9);
        fill:#3a3a3a;
        filter: grayscale(0%);
      }
    }
`;
