import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './styles.css';
import { useEvent } from '../../hooks/EventContext';

interface Props {
  style?: CSSProperties;
  preferenceAudioId?: string;
}
interface EntityBodyLANG {
  id: string;
  flag: string;
  label: string;
  language: string;
  default: boolean;
  urlAudio: string;
}

const VideoView: React.FC<Props> = (props) => {
  const eventContext = useEvent();
  const { t, i18n } = useTranslation();
  const [isImage, setIsImage] = useState<boolean>(true);
  useEffect(() => {
    if (!isImage) {
      setIsImage(true);
    }
  }, [props?.preferenceAudioId]);

  function getAudio(): string {
    if (
      eventContext.channel?.voiceSettings &&
      eventContext.channel?.voiceSettings?.length !== 0 &&
      props?.preferenceAudioId
    ) {
      const daLang: EntityBodyLANG[] = eventContext.channel?.voiceSettings;
      return (
        daLang[daLang.findIndex((res) => res.id === props?.preferenceAudioId)]
          ?.urlAudio ||
        daLang[daLang.findIndex((res) => res.default === true)]?.urlAudio ||
        daLang[0]?.urlAudio
      );
    }
    if (
      eventContext.channel?.voiceSettings &&
      eventContext.channel?.voiceSettings?.length !== 0
    ) {
      const daLang: EntityBodyLANG[] = eventContext.channel?.voiceSettings;
      return (
        daLang[daLang.findIndex((res) => res.default === true)]?.urlAudio ||
        daLang[0]?.urlAudio
      );
    }
    if(props?.preferenceAudioId){
      return props?.preferenceAudioId
    }

    // return 'https://www.youtube.com/embed/7i4sT1B1yz8';
    return '';
  }

  // function getAudio() {
  //   // return 'https://www.youtube.com/embed/7i4sT1B1yz8';
  //   return eventContext.channel?.streaming[i18n.language] || '';
  // }

  if (isImage && getAudio().length !== 0)
    return (
      // ...props.style, textAlign: 'center'
      <div style={{ ...props?.style, textAlign: 'center' }}>
        <img
          alt="capa"
          className="imgggggg"
          width={props?.style?.width}
          style={{ width: props?.style?.width }}
          src={getAudio()}
          onError={() => {
            setIsImage(false);
          }}
        />
      </div>
    );

  return (
    <>
      <div style={props?.style} className="iframeBox-container">
        <iframe
          title="video"
          className="iframeBox"
          src={getAudio()}
          allowFullScreen
          allow="camera; microphone; autoplay;"
          scrolling="no"
          frameBorder={0}
          style={props?.style}
        />
      </div>
    </>
  );
};
export default VideoView;
