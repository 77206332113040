import React, {
  CSSProperties,
  Children,
  useState,
  isValidElement,
  ReactNode,
  ReactElement,
  useEffect,
} from 'react';

import { useIndexTabRight } from '../../../context/contextplayer';
import { useEvent } from '../../../hooks/EventContext';

import './styles.css';

interface Props {
  children: ReactNode;
  width: number;
  height: number | string;

  initialTabIndex: number;
}

function renderResource(resource: any) {
  if (typeof resource === 'function') return resource();

  if (isValidElement(resource)) return resource;

  return null;
}

const TabsMenu: React.FC<Props> = (props: Props) => {
  const headerMenuTabs = 89;
  const { children, initialTabIndex } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState(initialTabIndex);
  const eventContext = useEvent();

  const tabs = Children.toArray(children) as ReactElement[];

  const { children: tabContent, indexTab } = tabs[selectedTabIndex]?.props || (
    <></>
  );

  useEffect(() => {
    if (eventContext.channel?.resources) setSelectedTabIndex(0);
  }, [eventContext.channel?.resources]);

  const styleSpanTranslateText: CSSProperties = {
    textAlign: 'center',
    fontSize: 10,
  };

  return (
    <div
      style={{
        height: props.height,
        minHeight: '100%',
        maxHeight:'600px'
      }}
      {...props}
    >
      <div
        style={{ width: props.width, height: headerMenuTabs }}
        className="headertabmmenurigth"
      >
        {tabs.map(({ props }, index) => {
          const { icon, title } = props;

          function onClickTabButton() {
            setSelectedTabIndex(index);
          }

          return (
            <button
              className={`${index === selectedTabIndex ? 'tabSelected' : ''}`}
              onClick={onClickTabButton}
            >
              <div className={`iconsizeitemmaeutab`}>
                {renderResource(icon)}
              </div>
              <span style={styleSpanTranslateText}>{title}</span>
            </button>
          );
        })}
      </div>
      {tabContent}
    </div>
  );
};

export default TabsMenu;
