import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { Form } from '@unform/web';

interface IForm {
  loading: boolean;
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap:var(--grid-gap-default);
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:var(--grid-gap-default);
`;

export const FormProfile = styled(Form) <IForm>`
    margin: 3rem 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    h1 {
      margin-bottom: 24px;
      font-size: 1.5rem;
      text-align: left;
      color: ${props => props.theme.colors.text};
    }
    ${({ loading }) => loading && css`
        opacity: .6;
        user-select: none;
    `}
`;
export const FormContainerInputs = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:12px;

    label{
     color: ${props => props.theme.colors.text};
    }
`;

export const InputBlock = styled.div`
  width:100%;
  margin-top:0px;

  label{
    margin:0;
    line-height: 1;
    margin-bottom: 4px;
  }
  select{
    background: #ffffff;
    border-radius: 5px;
    padding: 4px 16px;
    width: 100%;
    height: 50px;
    border: 2px solid #EBEBEB;
    flex: 1;
    color: #6d6d6d;
    box-shadow: 0 0 0 30px white inset !important;
    outline: none;
    font-weight: 400;
    &::placeholder {
      color: #B9B9B9;
    }
  }

  @media(max-width:768px){
    label{
      font-size: .88rem;
    }
  }
`;

export const InputGroup2Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap:12px;

  >*:not(.profile-form-input-small){
    max-width: calc(100% - 200px - 12px);
  }
  .profile-form-input-small{
    max-width: 200px;
  }

  @media(max-width:768px){
    >*:not(.profile-form-input-small), .profile-form-input-small{
    width:100%;
    max-width:100%;
  }

  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  gap: 12px;
  padding: 1rem 0;

  @media(max-width:768px){
    flex-direction: column;
    & > button{
      padding: 14px 1.75rem;
      width: 100%;
      flex:1
    }
}
`;

export const Avatarinput = styled.div`
  margin-bottom: 24px;
  position: relative;
  width: 200px;
  /* align-self: center; */

  img {
    border: 12px solid #EBEBEB;
    height: 150px;
    width: 150px;
    border-radius: 100px;
    object-fit: cover;
    /* width: 186px;
    height: 186px;
    border-radius: 50%; */
  }

  label {
    position: absolute;
    width: 48px;
    height: 48px;
    background: #EBEBEB;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      height: 20px;
      color: #fff;
    }

    &:hover {
      background: ${shade(0.2, '#02C90F')};
    }

    input {
      display: none;
    }
  }
`;

export const ProfileContent = styled.div`
  display: flex;
  /* justify-content: center;
  align-items: center; */
  background-color: ${props => props.theme.colors.color};
  padding: 2rem 4rem;
  width: 100%;
  border-radius: var(--border-radius-default);

  > a {
    color: #ff9000;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#ff9000')};
    }
  }

  @media(max-width:768px){
    padding: 2rem;
  }
`;

