import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner } from './style';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';
import { asEnumerable } from 'linq-es2015';
import { useEvent } from '../../../hooks/EventContext';
import Iframe from 'react-iframe';

interface ChatProps {
  user: any;
  channelId: string
  eventId: string
};


const Jitsi: React.FC<ChatProps> = (props: any) => {
  const firestore = firebase.firestore();
  const { addToast } = useToast();
  const [schedule, setJitsi] = useState<any>({ programation: [] });
  const eventContext = useEvent();

  useEffect(() => {
   //
  }, [props.channelId]);

  return (
    <Conteiner>
      <main className="container">
        <div className="mt-4"> {/* row mt-4*/}
          <div className="col"> {/*col */}
          <Iframe url={`https://stream.rstcom.live/${props.channelId}`}
        width="100%"
        height="550px"
        id="myId"
        className="myClassname"
        display="block"
        position="relative" 
        allow="camera *;microphone *, fullscreen *"/>
          </div>
        </div>
      </main>
    </Conteiner>
  );
};

export default Jitsi;
