export default {
  title: "light",

  colors: {
    primary: "#fff",
    secondary: "transparent",
    paragraph: "#000",
    grayColor: '#fff',
    color: "#ffffff",
    background: "rgba(0, 0, 0, 0.2)",
    text: "#000",
    inputColor: "#00000040",
    inputPlaceholder: "#000000",
    footer:'#00000040',
    chatContent: '#A1A1A1',
    moon:'#fff',
    askText: "#303030",
    headerTitle: "rgba(0, 0, 0, 0.2)",
    navItem: "#fff"
  },
};
