import React, { useState, useEffect } from 'react';
import { useEvent } from '../../../hooks/EventContext';
import { useTheme } from '../../../hooks/ThemeContext';
import * as S from './style';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../../hooks/Auth2';
import HeaderPageDescription from '../../../components/HeaderPageDescription';
import ContainerButton from '../../../components/ContainerButton';
import * as Yup from 'yup';
import { useToast } from '../../../hooks/Toast';
import { useForm } from 'react-hook-form';
import TabsView from '../../../components/TabsMenu';
import moment from 'moment';

interface IQuestion {
  id?: string;
  channelId?: string;
  eventId?: string;
  speakerId?: string;
  question?: string;
  speaker?: string;
  response?: string;
  userId?: string;
  name?: string;
  email?: string;
  createdAt?: number;
  status?: string;
  user?: any;
}

interface IQuestionData {
  question: string;
  speaker: string;
  speakerId: string;
  status: string;
}

const AskGrid: React.FC<IQuestion> = (props: any) => {
  const eventContext = useEvent();
  const firestore = firebase.firestore();
  const { theme } = useTheme();
  const { getUser } = useAuth();
  const user = getUser();
  const { addToast } = useToast();
  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    errors,
    getValues,
  } = useForm<IQuestionData>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [speakers, setSpeakers] = useState<any[]>([]);
  const [isMobile, SetIsMobile] = useState(
    window.matchMedia('(max-width: 992px)').matches,
  );

  async function sendMessage(
    question: string,
    speaker: string,
    speakerId: any = null,
  ) {
    setLoading(true);
    const data = {
      eventId: eventContext.event.id,
      question,
      speaker,
      speakerId,
      userId: props.user.id,
      email: props.user.email,
      name: props.user.name || props.user.email,
      createdAt: moment.utc().valueOf(),
      status: 'received',
    } as IQuestion;

    const ref = await firestore.collection('question').add(data);

    addToast({
      type: 'success',
      title: t('Data sent successfully'),
      description: t('Thank you for your participation'),
    });

    setLoading(false);
  }

  const handleSendMessage = handleSubmit(async (data: any) => {
    try {
      setLoading(true);

      const schema = Yup.object().shape({
        question: Yup.string().required(t('Please fill in the field')),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const speakersObj: any = {};
      speakers.forEach((v: any) => {
        speakersObj[v.id] = v;
      });

      console.log(data);
      await sendMessage(
        data.question,
        speakersObj[data.speaker].name,
        data?.speaker || '',
      );

      setValue('question', '');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: t('Failed to send response'),
        description:
          error?.response?.data?.message ||
          error.message ||
          t('An error occurred while registering, please try again'),
      });
    }
  })

  useEffect(() => {
    if (eventContext.event.id) {
      const unsubscribe = firebase
        .firestore()
        .collection('speaker')
        .where('eventId', '==', eventContext.event.id)
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((m) => m.data());
          const spe = docs.sort(function (a: any, b: any) {
            return a.order - b.order;
          });
          setSpeakers(spe);
        });

      return () => {
        unsubscribe();
      };
    }

  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      SetIsMobile(window.matchMedia('(max-width: 992px)').matches);
    });
  });

  if (!isMobile) {
    return <Redirect to={`/${eventContext?.eventKey}/signin`} />
  }

  return (
    <>
      {isMobile && (
        <S.ContainerQuestion id="ask" style={props.style} theme={theme} customization={eventContext?.event?.customization || {}}>
          <TabsView user={user} />
          <S.QuestionContainerItems customization={eventContext?.event?.customization || {}}>
            {speakers.length > 0 && (
            <>
              <HeaderPageDescription title={`${t("Ask the speaker")}`} />
              <fieldset className={'question-fieldset form-group message-list'}>
                <form onSubmit={handleSendMessage} className="question-speaker-form">
                  <div className="question-form-container">
                    <div className="question-speaker-form-inputs">
                      <label htmlFor="dropdown-basic-button" className="question-speaker-form-label question-speaker-form-label-select">
                        <select
                          name="speaker"
                          id="dropdown-basic-button"
                          className="question-speaker-form-input"
                          title=""
                          ref={register({ required: true })}
                        >
                          {speakers
                            // ?.filter(
                            //   (speaker: any) =>
                            //     speaker?.views?.findIndex(
                            //       (v: any) =>
                            //         v.key === 'ask' && v.channelId === props.channelId,
                            //     ) >= 0,
                            // )
                            .map((item: any, i: number) => (
                              <option key={i} value={item.id}>
                                {item.name}
                              </option>
                            ))}

                        </select>
                      </label>

                      <label htmlFor="question" className="question-speaker-form-label">
                        <textarea
                          className="question-speaker-form-input"
                          name="question"
                          id="question"
                          ref={register({ required: true, maxLength: 1000 })}
                          cols={30}
                          rows={4}
                        ></textarea>
                      </label>
                    </div>
                  </div>
                  <div className='container-question-btn'>
                    <ContainerButton
                      loading={loading}
                      classe="question-form-btn-send"
                    >
                      <p>
                        {t("Send")}
                      </p>
                    </ContainerButton>
                  </div>
                </form>
              </fieldset>

            </>
            )}
          </S.QuestionContainerItems>
        </S.ContainerQuestion>
      )
        // (
        //   <Redirect to={`/${eventContext?.eventKey}/signin`}/>
        // )}
      }
    </>
  );
};
export default AskGrid;
