import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner } from './style';
import { useToast } from '../../../hooks/Toast';
import { asEnumerable } from 'linq-es2015';
import { useEvent } from '../../../hooks/EventContext';
import Iframe from 'react-iframe';
import Channels from '../Channels';

interface DataProps {
  user: any;
  channelId: string;
  eventId: string;
  channel: any;
}

const Kahoo: React.FC<DataProps> = (props: any) => {
  const firestore = firebase.firestore();
  const { addToast } = useToast();
  const [kahooUrl, setKahooUrl] = useState<any>({});
  const eventContext = useEvent();

  useEffect(() => {
    eventContext.channel?.resources.forEach((element: any) => {
      if (element.key == 'kahoo') {
        setKahooUrl(element.url);
      }
    });
  }, [props.channelId]);

  return (
    <Conteiner>
      <main className="container">
        <div className="mt-4">
          {' '}
          {/* row mt-4*/}
          <div className="col">
            {' '}
            {/*col */}
            <Iframe
              url={kahooUrl}
              width="100%"
              height="550px"
              id="myId"
              className="myClassname"
              display="block"
              position="relative"
              allow="fullscreen *"
            />
          </div>
        </div>
      </main>
    </Conteiner>
  );
};

export default Kahoo;
