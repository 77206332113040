import React, { useEffect } from 'react';
import { MainContainer2Column } from '../../components/Ui/MainContainer2Column';
import './style.css';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SideTabMenu from '../../components/SideTabMenu';
import ScheduleContainer from '../../components/ScheduleContainer/index';

import { useEvent } from '../../hooks/EventContext';
import DashboardMasterPage2 from '../../masterpages/DashboardMasterPage2';

const Partners2: React.FC<any> = (props) => {
  const { event: eventKey, channel: channelKey } =
    useParams<{ channel: string; event: string }>();
  const { t, i18n } = useTranslation();
  const { event, channel, ...eventContext } = useEvent();

  useEffect(() => {
    console.log('DashboardGrid useEffect', { eventKey, channelKey });

    if (eventKey && channelKey) eventContext.loadInfo(eventKey, channelKey);
  }, [eventKey, channelKey]);

  console.log('DashboardGrid', { event, channel });

  return (
    <>
      <DashboardMasterPage2 {...props}>
        <MainContainer2Column>
          <ScheduleContainer />
          <div
          // className="features-position__sticky"
          // Descomentar classe quando <SideTabMenu /> for ser usado
          >
            {/* <SideTabMenu /> */}
          </div>
        </MainContainer2Column>
      </DashboardMasterPage2>
    </>
  );
};
export default Partners2;
