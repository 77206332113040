import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import firebase from 'firebase/app';

import 'firebase/firestore';

const ChatContext = createContext<ChatData>({} as ChatData);

export interface ChatData {
  chat: any;
  isLoading: boolean;
  messages: any[];
  loadChat(
    channelId: string,
    firestore: firebase.firestore.Firestore,
  ): Promise<void>;
}

const ChatProvider: React.FC = ({ children }) => {
  const messagesRef = useRef<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [channelId, setChannelId] = useState<string>();
  const [chat, setChat] = useState<any>(null);
  const [messages, setMessages] = useState<any[]>([]);

  async function loadChat(
    $channelId: string,
    firestore: firebase.firestore.Firestore,
  ): Promise<void> {
    try {
      if (channelId === $channelId) return;

      console.log('***** ChatContext LoadChat *****');

      setChat(null);

      setChannelId($channelId);

      // const result = await api.get(`/chat?channelId=${$channelId}`);
      const $user = firebase.auth()?.currentUser;
      console.log('user signed', {
        user: $user,
        email: $user?.email,
        verified: $user?.emailVerified,
      });

      const docSnap = await firestore.collection('chat2').doc($channelId);
      const channelDoc = await firestore
        .collection('channel')
        .doc($channelId)
        .get();
      const channel = channelDoc.data();

      let $chat: any = null;

      const data = {
        id: $channelId,
        channelId: $channelId,
        eventId: channel?.eventId,
        createdAt: moment.utc().valueOf(),
        count: 0,
        messages: [],
      };

      try {
        let query = await docSnap.get();
        $chat = query?.data();
        $chat.id = query.id;

        if (!$chat) {
          await firestore.collection('chat2').doc($channelId).set(data);

          query = await docSnap.get();
          $chat = query.data();
          $chat.id = query.id;
        }
      } catch (error) {
        if (!$chat) {
          await firestore.collection('chat2').doc($channelId).set(data);
          const query = await docSnap.get();
          $chat = query.data();
          $chat.id = query.id;
        }
      }

      if ($chat) setChat($chat);
      console.log('loadChat', { data: $chat });
    } catch (error) {
      console.log('loadChat error', { error, channelId });
    }
  }

  useEffect(() => {
    if (channelId) {
      const unsubscrible = firebase
        .firestore()
        .collection('chat2')
        .doc(channelId)
        .collection('messages')
        .orderBy('createdAt', 'desc')
        .limit(250)
        .onSnapshot({}, (snapshot) => {
          let _messages: any[] = [];
          const data = snapshot.docChanges().forEach((change) => {
            if (change.type === 'added') {
              _messages.push({ id: change.doc.id, ...change.doc.data() });
            } else if (change.type === 'removed')
              messagesRef.current = messagesRef.current.filter(
                (message) => message.id !== change.doc.id,
              );
          });

          if (messagesRef.current.length === 0)
            _messages.map((message) => messagesRef.current.push(message));
          else _messages.map((message) => messagesRef.current.unshift(message));
          // const _messages = snapshot.da
          // setChat(data);
          console.log('chat changed', {
            _messages,
            changes: snapshot.docChanges(),
          });
          // setMessages((prev) => [...messagesRef.current.slice(0, 250)]);
        });

      return () => {
        unsubscrible();
      };
    }
  }, [channelId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setMessages((prev) => {
        if (
          JSON.stringify(prev) !==
          JSON.stringify(messagesRef.current.slice(0, 250))
        )
          return messagesRef.current.slice(0, 250);

        return prev;
      });
    }, 700);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  return (
    <>
      <ChatContext.Provider
        value={{
          chat,
          messages,
          loadChat,
          isLoading,
        }}
      >
        {children}
      </ChatContext.Provider>
    </>
  );
};

function useChat(): ChatData {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChat must be used within an ChatProvider');
  }

  return context;
}

export { ChatProvider, useChat };
