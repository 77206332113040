import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '../services/api';
import { useHistory } from 'react-router-dom';
import { useToast } from './Toast';
import GlobalStyle from '../styles/global';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { asEnumerable } from 'linq-es2015';
import moment from 'moment';
import { useAuth } from './Auth2';

import { useIsVisiblePopup } from '../context/contextPopup';
const EventContext = createContext<EventData>({} as EventData);

export interface EventData {
  eventKey: string;
  event: any;
  channel: any;
  channels: any[];
  speakers: any[];
  links: any[];
  responses: any[];
  polls: any[];
  chat: any;
  cloudWord: any;
  programation: any;
  setSpeakers(speakers: any[]): void;
  setChannel(channel: any): void;
  setProgramation(programation: any): void;
  loadEvent(eventKey: string): void;
  loadInfo(eventKey: string, channelKey?: string): void;
  key: string;
  setKey: (key: string) => void;
}
interface PopUp {
  id: string;
  content: string;
  name: string;
  isActive: boolean;
}

const EventProvider: React.FC = (props: any) => {
  const history = useHistory();

  const { getUser } = useAuth();
  const user = getUser();
  const [eventKey, setEventKey] = useState('');
  const [channelKey, setChannelKey] = useState('');
  const { isVisiblePopup, setIsVisiblePopup } = useIsVisiblePopup();
  const [event, setEvent] = useState<any>(null);
  const [channel, setChannel] = useState<any>(null);
  const [channels, setChannels] = useState<any[]>([]);
  const [speakers, setSpeakers] = useState<any[]>([]);
  const [programation, setProgramation] = useState<any>();
  const [chat, setChat] = useState<any>(null);
  const [cloudWord, setCloudWord] = useState<any>(null);
  const [links, setLinks] = useState<any[]>([]);
  const [responses, setResponses] = useState<any[]>([]);
  const [polls, setPolls] = useState<any[]>([]);
  const [key, setKey] = useState('');

  const { addToast } = useToast();

  const loadVisiblePopup = (data: PopUp[]) => {
    const urlIframe: string =
      data[data.findIndex((e) => e.isActive === true)]?.content || '';
    if (urlIframe.length !== 0) {
      setIsVisiblePopup(true);
    } else {
      setIsVisiblePopup(false);
    }
  };

  const loadEvent = async (key: string) => {
    if (key != eventKey) {
      setEventKey(key);

      try {
        let result: any = null;

        try {
          api.get('/event/public-info', { params: { key: key } });
        } catch {}

        try {
          const query = await firebase
            .firestore()
            .collection('event')
            .where('key', '==', key)
            .get();
          if (query.docs.length) {
            result = { data: query.docs[0].data() };
          } else {
            result = await api.get('/event/public-info', {
              params: { key: key },
            });
          }
        } catch (error) {
          addToast({
            type: 'success',
            title: 'Aguarde...',
            description:
              'Estamos verificando conexão com o servidor, aguarde...',
          });
          await new Promise((resolve) => {
            setTimeout(resolve, 15000);
          });
        }

        if (!result) {
          try {
            result = await api.get('/event/public-info', {
              params: { key: key },
            });
          } catch (error) {
            result = null;
          }
        }

        if (result?.data) {
          document.title = result.data.name;
          setEvent(result.data);
        } else {
          addToast({
            type: 'error',
            title: 'Link não encontrado',
            description: 'Tente acessar novamente mais tarde',
          });
        }

        result = null;
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Link não encontrado',
          description: 'Tente acessar novamente mais tarde',
        });
      }
    }
  };

  async function loadEventApi(key: string, cKey: string): Promise<void> {
    try {
      const result = await api.get(
        `/event/info?eventKey=${key}&channelKey=${cKey}`,
      );

      if (result?.data && result.data.event) {
        // if (result.data.event && !event) {
        //   setEvent(result.data.event);
        //   document.title = result.data.event.name;
        // }
        // if (result.data.channel && !channel) {
        //   setChannel(result.data.channel);
        //   if (result.data.channel.speakers?.length) {
        //     setSpeakers(result.data.channel.speakers || []);
        //   }
        // }
        // if (result.data.channels && !channels.length) {
        //   setChannels(result.data.channels);
        // }
        // if (result.data.chat) {
        //   setChat(result.data.chat);
        // }
      } else {
        history.push(`/${key}/logout`);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push(`/${key}/logout`);
      }
    }
  }

  const loadInfo = async (key: string, cKey: string = '') => {
    console.log('loadInfo', { key, cKey });
    if (eventKey != key || channelKey != cKey || !channel) {
      setChannel({});
      setEventKey(key);
      setChannelKey(cKey);

      try {
        let result: any = null;

        try {
          firebase
            .firestore()
            .collection('event')
            .where('key', '==', key)
            .get()
            .then((snapshot) => {
              const docs = snapshot.docs.map((doc) => {
                return doc.data();
              });
              if (docs.length) {
                const event = docs[0];
                setEvent(event);
                document.title = event.name;

                // firebase
                //   .firestore()
                //   .collection('channel')
                //   .where('eventId', '==', event.id)
                //   .get()
                //   .then((snapshot) => {
                //     const docs = snapshot.docs.map((doc) => {
                //       return doc.data();
                //     });

                //     setChannels(docs);

                //     const channel =
                //       asEnumerable(docs).FirstOrDefault((a) => a.key == cKey) ||
                //       asEnumerable(docs).FirstOrDefault((a) => a.default) ||
                //       asEnumerable(docs).First();
                //     setChannel(channel);
                //     if (channel?.speakers?.length) {
                //       setSpeakers(channel?.speakers || []);
                //     }
                //   })
                //   .catch((error: any) => {
                //     if (
                //       error?.message
                //         ?.toLowerCase()
                //         .indexOf('insufficient permissions') > -1
                //     ) {
                //       history.push(`/${key}/logout`);
                //     }
                //   });
              }
            });
        } catch (error) {
          history.push(`/${key}/logout`);
        }

        setTimeout(async () => {
          try {
            const result = await api.get(
              `/event/info?eventKey=${key}&channelKey=${cKey}`,
            );

            if (result?.data && result.data.event) {
              if (result.data.event && !event) {
                setEvent(result.data.event);
                document.title = result.data.event.name;
              }

              // if (result.data.channel && !channel) {
              //   setChannel(result.data.channel);
              //   if (result.data.channel.speakers?.length) {
              //     setSpeakers(result.data.channel.speakers || []);
              //   }
              // }

              // if (result.data.channels && !channels.length) {
              //   setChannels(result.data.channels);
              // }

              if (result.data.chat) {
                setChat(result.data.chat);
              }
            } else {
              history.push(`/${key}/logout`);
            }
          } catch (error) {
            if (error?.response?.status == 401) {
              history.push(`/${key}/logout`);
            }
          }
        }, 1000);
      } catch (error) {
        history.push(`/${key}/logout`);
      }
    }
  };

  // useEffect(() => {
  //   if (!!channel?.id) {
  //     const unsubscrible = firebase
  //       .firestore()
  //       .collection('channel')
  //       .doc(channel.id)
  //       .onSnapshot((snapshot) => {
  //         const data = snapshot.data();

  //         if (!!data) {
  //           setChannel({ ...data, update: moment().valueOf() });
  //         }
  //       });

  //     return () => {
  //       unsubscrible();
  //     };
  //   }
  // }, [channel?.id]);
  useEffect(() => {
    if (!!channelKey && event?.id) {
      const unsubscrible = firebase
        .firestore()
        .collection('channel')
        .where('eventId', '==', event.id)
        .where('key', '==', channelKey)
        .onSnapshot(
          (snapshot) => {
            const data = snapshot.docs?.[0]?.data();

            console.log('useEffect channel', {
              channelKey,
              eventKey,
              data,
              docs: snapshot.docs,
            });
            if (data?.popup_settings && data?.popup_settings?.length !== 0) {
              if (
                data?.popup_settings?.findIndex(
                  (e: { isActive: boolean }) => e.isActive === true,
                ) >= 0
              ) {
                loadVisiblePopup(data?.popup_settings);
              } else {
                if (isVisiblePopup) {
                  setIsVisiblePopup(false);
                }
              }
            }

            if (!!data) {
              setChannel({ ...data, update: moment().valueOf() });
              if (data?.speakers?.length) {
                setSpeakers(data?.speakers || []);
              }
            }
          },
          (error) => {
            console.log('error on load channel', error);
          },
        );

      return () => {
        unsubscrible();
      };
    }
  }, [channelKey, event?.id]);

  useEffect(() => {
    if (event?.id) {
      const unsubscrible = firebase
        .firestore()
        .collection('channel')
        .where('eventId', '==', event.id)
        .where('active', '==', true)
        .onSnapshot(
          (snapshot) => {
            const docs = snapshot.docs.map((doc) => {
              return doc.data();
            });

            console.log('channels changed', docs);

            setChannels(docs);

            // if (channel?.id) {
            //   const channelUpdated =
            //     asEnumerable(docs).FirstOrDefault((a) => a.key === channelKey) ||
            //     asEnumerable(docs).FirstOrDefault((a) => a.default) ||
            //     asEnumerable(docs).First();
            //   setChannel(channelUpdated);
            //   if (channelUpdated?.speakers?.length) {
            //     setSpeakers(channelUpdated?.speakers || []);
            //   }
            // }
          },
          (error) => {
            console.log('error on load channels', error);
          },
        );

      return () => {
        unsubscrible();
      };
    }
  }, [event?.id, channel?.id]);

  useEffect(() => {
    if (channel?.id) {
      const unsubscrible = firebase
        .firestore()
        .collection('cloudWord')
        .where('eventId', '==', event.id)
        .where('channelId', '==', channel.id)
        .onSnapshot((snapshot) => {
          const data: any = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setCloudWord(data?.[0]);
        });

      return () => {
        unsubscrible();
      };
    }
  }, [event?.id, channel?.id]);

  useEffect(() => {
    if (channel?.id) {
      const unsubscribe = firebase
        .firestore()
        .collection('links')
        .where('active', '==', true)
        .where('channelId', '==', channel?.id)
        .onSnapshot(
          (snapshot) => {
            const docs = snapshot.docs.map((a) => {
              return { id: a.id, ...a.data() };
            });

            if (snapshot.docs.length) {
              window.document
                .getElementsByClassName('iconsizeitemmaeutab links')?.[0]
                ?.classList?.add('highlighted');
            } else {
              window.document
                .getElementsByClassName('iconsizeitemmaeutab links')?.[0]
                ?.classList?.remove('highlighted');
            }

            setLinks(docs);
          },
          (error) => {
            console.log('error on load links', error);
          },
        );

      return () => {
        unsubscribe();
      };
    }
  }, [channel?.id]);

  useEffect(() => {
    if (!channel?.id || !user?.id) return;

    const unsubscribe = firebase
      .firestore()
      .collection('survey_question')
      .where('active', '==', true)
      .where('status', '==', 1)
      .where('channelId', '==', channel.id)
      .onSnapshot((snapshot) => {
        if (snapshot.docs.length) {
          window.document
            .getElementsByClassName('iconsizeitemmaeutab survey')?.[0]
            ?.classList?.add('highlighted');
        } else {
          window.document
            .getElementsByClassName('iconsizeitemmaeutab survey')?.[0]
            ?.classList?.remove('highlighted');
        }

        const docs = snapshot.docs.map((a) => {
          return { id: a.id, ...a.data() };
        });
        const data = asEnumerable(docs)
          .OrderByDescending((o: any) => o.createdAt)
          .Select((survey: any, i) => {
            let count = 0;
            const total = asEnumerable(survey.options).Sum(
              (d: any) => survey['count-' + count++] || 0,
            );
            for (let index = 0; index < survey.options.length; index++) {
              const responseCount = survey[`count-${index}`] || 0;
              survey[`percent-${index}`] = Math.round(
                (responseCount / total) * 100,
              );
            }

            return survey;
          })
          .ToArray();

        setPolls(data);
      });

    const unsubscribe2 = firebase
      .firestore()
      .collection('survey_response')
      .where('userId', '==', user.id)
      .onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((a) => {
          return { id: a.id, ...a.data() } as any;
        });

        setResponses(docs);
      });

    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, [channel?.id, user?.id]);

  return (
    <>
      <EventContext.Provider
        value={{
          eventKey,
          event,
          channel,
          programation,
          loadEvent,
          loadInfo,
          setSpeakers,
          setProgramation,
          setChannel,
          channels,
          speakers,
          chat,
          cloudWord,
          links,
          responses,
          polls,
          key,
          setKey,
        }}
      >
        {props.children}
      </EventContext.Provider>
      <GlobalStyle customization={event?.customization || {}} />
    </>
  );
};

function useEvent(): EventData {
  const context = useContext(EventContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { EventProvider, useEvent };
