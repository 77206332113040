import styled, { css } from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const ImageBanner = styled.img`
  width: 100%;
  max-height: 620px;
  object-fit: cover;
`

export const ContainerFeatures = styled.div`
  margin-top:1rem
`

