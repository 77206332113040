import React, { useState, useEffect, useCallback } from 'react';
import { useEvent } from '../../hooks/EventContext';
import { useTheme } from '../../hooks/ThemeContext';
import * as S from './style';
import firebase from 'firebase/app';
import avatarImg from '../../assets/images/avatar.png';
import { v4 } from 'uuid';
import moment from 'moment';
import { BsCalendarWeek } from 'react-icons/bs';
import { IoMdRefresh } from 'react-icons/io';
import 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { Icon } from '../Icons';
import { useAuth } from '../../hooks/Auth2';
import './styles.css';
import ModalFeed from '../NewsFeatures/ModalTabs/ModalFeed';
import VideoView from '../VideoView';
import * as Yup from 'yup';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useToast } from '../../hooks/Toast';

const firestore = firebase.firestore();
interface IProps {
  handleModal: () => void;
  setContentModalFeed: (content: React.ReactNode) => void;
}

const FeedGrid: React.FC<IProps> = (props: any) => {
  const eventContext = useEvent();
  const { theme } = useTheme();
  const [feeds, setFeeds] = useState<
    { title: string; cover: string ; description: string; createdAt: number; urlAudio: string}[]
  >([]);
  const { register, setValue, handleSubmit, errors } = useForm<FormData>();
  const { addToast } = useToast();
  const { getUser } = useAuth();
  const user = getUser();
  const [arrayIds, setArrayIds] = useState<any[]>([]);
  const [sending, isSending] = useState(false);
  const [isComplete,setIsComplete] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMobile, SetIsMobile] = useState(
    window.matchMedia('(max-width: 992px)').matches,
  );
  const { t } = useTranslation();

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      if (!user) return;
      isSending(true);
      const schema = Yup.object().shape({
        userText: Yup.string().required(`${t("The message field is obligatory")}`),
        userImg: Yup.mixed().required(`${t("Please select an image")}`),
      });
      await schema.validate(data, {
        abortEarly: true,
      });
      let imgUrl: string = '';
      if (data.userImg.length) {
        const uploaded: any = await firebase
          .storage()
          .ref(`/companies/${eventContext.event.key}/files/${v4()}`)
          .put(data.userImg[0]);
        imgUrl = (await uploaded.ref.getDownloadURL()) || '';
        setIsImageSelected(false);
      }
      else{
        isSending(false);
        addToast({
          type: 'error',
          title: 'Erro no envio',
          description: `${t("Select an image")}`,
        });
        return;
      }

      const entity: any = {
        createdAt: moment.utc().valueOf(),
        description: data.userText,
        eventId: eventContext.event.id,
        user: {
          name: user.name,
          email: user.email,
          avatar: user.avatar || avatarImg,
        },
        uid: user.id || user.uid,
      };

      if (imgUrl) {
        entity.cover = imgUrl;
      }

      await firestore.collection('feeds').add(entity);

      setValue('userText', '');

      addToast({
        type: 'success',
        title: `${t("Post Sent")}`,
        description: `${t("Your message has been sent successfully!")}`,
      });

      isSending(false);
    } catch (error) {
      isSending(false);
      addToast({
        type: 'error',
        title: `${t("Error on Send")}`,
        description:
          error?.response?.data?.message ||
          error.message ||
          `${t("Error on send, try again")}.`,
      });
    }
  });

  function like(feed: any) {
    if (!user) return;
    const ref = firestore.collection('feeds').doc(feed.id);
    feed.likesObj = feed.likesObj || {};
    if (!feed.likesObj[user.id]) {

      feed.likesObj = { ...feed.likesObj, [user.id]: user.name };

      return ref.update({
        likes: firebase.firestore.FieldValue.increment(1),
        likesObj: feed.likesObj,
      });
    } else {
      delete feed.likesObj[user.id];
      return ref.update({
        likes: firebase.firestore.FieldValue.increment(-1),
        likesObj: feed.likesObj,
      });
    }
  }

  // function showComment(id:number) {
  //     setArrayIds(prevArray => [...prevArray, id])
  // }

  // function lessComment(id:number){
  //   const arrayTemp = [...arrayIds];
  //   let index = arrayTemp.indexOf(id,1);
  //   arrayTemp.splice(index,1)
  //   setArrayIds(arrayTemp)
  // }

  function onChange(e: any) {
    const file = e.target.files[0];
    if(!file){
      setIsImageSelected(false);
    }
    else{
      setIsImageSelected(true);
    }
  }

  useEffect(() => {
    firestore
      .collection('feeds')
      .where('eventId', '==', eventContext.event.id)
      .orderBy('createdAt', 'desc')
      // .limit(2)
      .onSnapshot((snap) => {
        const feeds = snap.docs?.map<{
          title: string;
          description: string;
          cover: string;
          urlAudio: string;
          createdAt: number;
        }>((item) => ({
          ...(item.data() as any),
          id: item.id,
        }));
      if(feeds.length === 0){
        setIsComplete(true)
      }
      setFeeds(feeds)
      });
  }, [eventContext.event?.id]);

  //next button function
  const showNext = (item: number ) => {
      const fetchNextData = () => {
           setLoading(true);
           firestore.collection('feeds')
          .orderBy('createdAt', 'asc') //order using firestore timestamp
          .limit(2)
          .startAfter(item || 0) //we pass props item's first created timestamp to do start after you can change as per your wish
          .onSnapshot(function (querySnapshot) {
            const list: any[] | ((prevState: { title: string; cover: string; description: string; createdAt: number; urlAudio: string; }[]) => { title: string; cover: string; description: string; createdAt: number; urlAudio: string; }[]) = [];
              querySnapshot.docs?.forEach(function(doc) {
                list.push({ id: doc.id, ...doc.data() });
            });
            if(list.length === 0){
              setIsComplete(true)
            }
            setFeeds(oldArray => [...oldArray, ...list]);
            setLoading(false);
          })
      };
      fetchNextData();
  };

  function changeModal(feed: any) {
    props.setContentModalFeed(<ModalFeed feed={feed}/>);
    props.handleModal();
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      SetIsMobile(window.matchMedia('(max-width: 992px)').matches);
    });
  });

return (
  <>
    {isMobile ? (
        <S.Container>
         <S.FeedBanner src={eventContext?.event?.customization?.bannerlobby || eventContext?.event?.customization?.bannerlp}/>
          <Form
          className="form-envio w-100 form-row pl-3 pt-4 pr-3"
          onSubmit={onSubmit}
        >
          <Form.Group
            className="col-12 col-lg-5"
            controlId="exampleForm.ControlTextarea1"
          >
          <Form.Group className="">
            <Form.File
              name="userImg"
              ref={register}
              id="custom-file"
              label={isImageSelected ? `${t("Image selected")}` : `${t("Select an image")}`}
              custom
              onChange={onChange}
            />
          </Form.Group>
            <Form.Control
              name="userText"
              ref={register}
              placeholder="Insira uma legenda"
              style={{marginTop: '-15px'}}
            />
          </Form.Group>


          <Form.Group className="col-12">
            {!sending && (
              <Button className="buttonSendMessage" type="submit">
                  <>
                    {`${t("Send")}`}
                    <i
                      style={{ fontSize: '1rem' }}
                      className="fa fa-paper-plane text-white"
                    />
                  </>
              </Button>
            )}
            {sending && (
              <Spinner animation="border" role="status" variant="light" size="sm">
                <span className="sr-only" style={{ color: '#fff' }}>
                  {t("Loading")}
                </span>
              </Spinner>
             )}
          </Form.Group>
        </Form>
          <S.FeedsContainerItems>
           {feeds.map((feeds: any, index) => (
             <div>

               <S.Card theme={theme}
               delay={index * 0.0125}
               key={`feeds-item-${feeds.title}-${index}`}>
                  <div className="d-flex mt-4 align-items-center" style={{gap:'10px'}}>
                    <img
                      src={feeds?.user?.avatar ||avatarImg}
                      alt="avatarImg"
                      className="rounded-circle img-users-bate-papo"
                    />
                    <div className="info-users">
                      <h6 className="my-0">{feeds?.user?.name}</h6>
                      <small className="tempo">
                      </small>
                    </div>
                  </div>
                 {feeds.cover ? (
                     <S.CardImageContainer>
                      <S.CardImage alt="400x220" src={feeds.cover} />
                   </S.CardImageContainer>
                 ) : (
                   <VideoView
                   preferenceAudioId={feeds.urlAudio}
                   style={{ width: '100%', height: '100%' }}
                   />
                 )}

                 <S.CardContent theme={theme}>
                   <S.CardInfo>

                <div className="content-feed-info">
                <S.CalendarContainer>

                <BsCalendarWeek/>
                <span>
                  {moment(feeds.createdAt).format('DD/MM/YYYY')}
                </span>

                </S.CalendarContainer>
                  <button className="feed-countdown-like" onClick={() => changeModal(feeds as any)}>
                    {/* <button className="feed-countdown-like" onClick={() => {}}> */}
                    {/* <Comment {...props} item={feedInfo} /> */}
                    <span className="feed-icon feed-icon-comment"><Icon name="iconComment" /></span>
                    <span>{feeds?.comments?.length || 0}</span>
                  </button>
                  <button className="feed-countdown-comments" onClick={() => like(feeds)}>
                    <span className="feed-icon feed-icon-heart"><Icon name="iconHeart" /></span>
                    <span>{feeds?.likes || 0}</span>
                  </button>
                </div>
                     {/* <S.CardInfoText theme={theme} isCommentShown={arrayIds.includes(index)} lineClamp={arrayIds.includes(index)}> */}
                     <S.CardInfoText theme={theme} isCommentShown={arrayIds.includes(index)} lineClamp={arrayIds.includes(index)}>
                       {feeds?.description}
                     </S.CardInfoText>
                   </S.CardInfo>
                     {arrayIds.includes(index) && (
                       <div style={{marginBottom: '50px'}}></div>
                     )}

                  <S.CardFooter>
                    <p className="date marginCust" onClick={() => changeModal(feeds as any)}>
                      {t("See all")} {feeds?.comments?.length || 0} {t("Comments")}
                      <br />
                    </p>
                  </S.CardFooter >
                 </S.CardContent>
             </S.Card>
               {/* <S.Card theme={theme}
               delay={index * 0.0125}
               key={`feeds-item-${feeds.title}-${index}`}>
                 {feeds.cover ? (
                     <S.CardImageContainer>
                      <S.CardImage alt="400x220" src={feeds.cover} />
                   </S.CardImageContainer>
                 ) : (
                   <VideoFeedView
                   preferenceAudioId={feeds.urlAudio}
                   style={{ width: '100%', height: '100%' }}
                   />
                 )}

                 <S.CardContent theme={theme}>
                   <S.CardInfo>
                     <S.CardInfoHeader>
                       <S.CardTitle theme={theme}>{feeds?.title}</S.CardTitle>
                     </S.CardInfoHeader> */}
                     {/* <S.CardInfoText theme={theme} isCommentShown={arrayIds.includes(index)} lineClamp={arrayIds.includes(index)}> */}
                     {/* <S.CardInfoText theme={theme} isCommentShown={arrayIds.includes(index)} lineClamp={arrayIds.includes(index)}>
                       {feeds?.description}
                     </S.CardInfoText>
                   </S.CardInfo>
                     {arrayIds.includes(index) && (
                       <div style={{marginBottom: '50px'}}></div>
                     )}
                   <S.CardFooter theme={theme}>
                     {!arrayIds.includes(index) ? (
                       <S.ConteinerSeeMore>
                         <a onClick={ () => handleOutsideClickOpen(index)} style={{color: '#1492E6'}}>
                           Veja mais...
                         </a>
                       </S.ConteinerSeeMore>
                     ):(
                       <S.ConteinerSeeMore>
                         <a onClick={ () => lessComment(index)} style={{color: '#1492E6'}}>
                           Veja menos...
                         </a>
                       </S.ConteinerSeeMore>
                     )
                     }
                   </S.CardFooter>
                 </S.CardContent>
             </S.Card> */}
             </div>

           ))}
           {/* <div style={{display: 'flex', justifyContent:'center'}}>
          {loading ? (
            <Spinner animation="border" role="status" variant="light">
              <span className="sr-only" style={{ color: '#fff' }}>
                {t("Loading")}
              </span>
            </Spinner>
          ) : (
            <S.ShowMoreButton isComplete = {isComplete} onClick={() => showNext(feeds[feeds.length-1].createdAt)}>
              <IoMdRefresh size={30} color = "#fff"/>
                <span>
                  {t("Load More")}
                </span>
            </S.ShowMoreButton>
          )}

           </div> */}
         </S.FeedsContainerItems>
       </S.Container>
    ): (
      <Redirect to={`/${eventContext?.eventKey}/signin`}/>
    )}
  </>
  );
};
export default FeedGrid;
