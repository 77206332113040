import styled, { css, keyframes } from 'styled-components';

interface ContainerPros {
    // customization: any;
}
export const Conteiner = styled.div`


  .buttonSendMessage{
        width:auto;
        color: #fff;
        border-radius:50px;
        float: left;
        border:0;
    }

    .defaultButton{
        width:100%;
        color: #fff;
        min-height:45px;
        
        border:0;
        border-radius:50px;
        float: left;
    }
    .defaultButton::focus{
       
        border:0;
    }

    .defaultButton:hover{
       
    }

.infoButton{
    background-color:rgb(76, 78, 161) !important;
}
.removeButton{
    background-color:#FD3944 !important;
}
.saveButton{
    background-color:green !important;
}

.buttonSendMessage::focus{
    color: #fff;
    background-color: #000000;
    border-color: #FFF;
}
.buttonSendMessage:hover{
    color: #fff;
    background-color: #000000;
    border-color: #FFF;
}
`;
