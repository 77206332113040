import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const bgAnimate = keyframes`
    from,
    50%,
    to {

        color: #000;
        background-color: lightblue;
    }

    25%,
    75% {

        color: lightblue;
        background-color: #000;
    }
`;
interface ContainerPros {
  customization: any;
  theme:any;
}

export const Conteiner = styled.div<ContainerPros>`

  .survey-fieldset{
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgb(180, 180, 180);
    }
  }
  .enquete {
    display:flex;
  }


.survey-form{
  display: flex;
  width: calc(100% - 1rem);
  margin:0 auto;
  padding: 1rem 0;
  border-bottom: 1px solid #C5C4C4;

  .survey-form-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    gap:.5rem
  }

  .survey-form-content{
    display:flex;
    flex-direction: column;
    gap:6px
  }

  .survey-form-title{
    text-align: left;
    font-size: 1.25rem;
    font-weight: 500;
    color:#606060;
  }

  .survey-form-results-container{
    display: flex;
    flex-direction: column;

    .survey-form-item-label-result{
      display: flex;
      flex-direction: column;
      margin:0;
      color:#606060;
      font-size: .88rem;
    }
}

  .survey-form-item-label{
    color:#606060;
    font-size: 1rem;
    display: grid;
    grid-template-columns:auto auto;
    align-items: flex-start;
    justify-content: flex-start;
    gap:8px;
    margin:0;
    padding: 4px 1rem;
    position: relative;
    cursor: pointer;

    input{
      margin:0 8px;

      display:flex;
      align-items: center;
      justify-content: center;

      opacity: 0;
      position: absolute;
      top:0;
      left:0;

      &:checked ~ .survey-form-item-checkmark{
        transition: all .5s;
        background: linear-gradient(90deg,#8EE064, #1BE3A4);
        transform: initial;
        filter: hue-rotate(0deg);
        border:none
      }
      &:checked ~ .survey-form-item-checkmark > .survey-icon{
        opacity: 1;
        transition: all .3s;
        transform: rotate(0deg);
      }
    }
    .survey-form-item-checkmark{
      display:flex;
      align-items: center;
      justify-content: center;

      color:#fff;
      font-size: 12px;
      width: 20px;
      height: 20px;
      background: #D4D3D3;
      filter: hue-rotate(100deg);
      border-radius: 3px;
      /* overflow: hidden; */

      .survey-icon{
        opacity: 0;
        transition: all .1s;
        transform: scale(1.5) rotate(-90deg);
      }
    }
    &:hover .survey-form-item-checkmark{
      filter: brightness(90%);
    }
  }

  .survey-form-btn-send{
    width: 100%;
    border-radius: 24px;
    padding: 0 1.5rem;
    margin-top: 24px;
    height: 44px;
    border:0;
    /* background: linear-gradient(90deg, #12C3FF 20%, #4269FF  100%); */
    background: ${props => props.customization.buttonBackgroundColor};
    background-repeat: no-repeat;
    background-size: 150%;
    background-position-x:-0%;
    display:flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in;

    p{
      flex:1;
      text-align: center;
      margin:0;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1rem;
      color: ${props => props.customization.buttonTextColor};
    }
    &:hover{
      transition: all .2s ease-out;
      background-position-x: 100%;
    }
}

}


`;
