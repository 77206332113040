import React, { useEffect } from 'react';
import { IUser } from '../../hooks/Auth2';
import { PresenceProvider, usePresence } from '../../hooks/PresenceContext';

type Props = {
  user?: IUser;
  eventId?: string;
  channelId?: string;
};

const PresenceUtils: React.FC<Props> = ({ user, eventId, channelId }) => {
  const { registerPresence, getTotal, updatePresence, gap } = usePresence();

  useEffect(() => {
    console.log('PresenceUtils useEffect', {
      eventId,
      channelId,
      isModerator: user?.isModerator,
    });
    let intervalTotal: any = null;
    let intervalUpdate: any = null;
    if (eventId && channelId) {
      registerPresence(eventId, channelId);

      if (user?.isModerator) {
        getTotal(eventId, channelId); // force first load
        intervalTotal = setInterval(() => {
          // console.log('getTotal interval');
          getTotal(eventId, channelId);
        }, gap);
      }

      intervalUpdate = setInterval(() => {
        // console.log('updatePresence interval');
        updatePresence(eventId, channelId);
      }, gap * 0.8);
    }

    return () => {
      if (intervalTotal) clearInterval(intervalTotal);
      if (intervalUpdate) clearInterval(intervalUpdate);
      // console.log('Page Dashboard render unmount');
    };
  }, [eventId, channelId]);

  return <div id="presence-utils" className="d-none" />;
};

const Presence: React.FC<Props> = (props) => {
  return (
    <PresenceProvider>
      <PresenceUtils {...props} />
    </PresenceProvider>
  );
};

export default Presence;
