import React, { RefObject } from 'react';

interface IProps {
  id: string;
  selected: boolean;
  onClick: () => void;
  ref?: RefObject<HTMLElement>;
  onMouseDown?: (event: { clientX: number; clientY: number; }) => void;
}
export const TabButton: React.FC<IProps> = ({
  id,
  selected,
  onClick,
  children,
}) => {
  return (
    <button
      className={`tab-button ${selected ? 'tab-button--active' : ''}`}
      onClick={onClick}
      data-id={id}
    >
      {children}
    </button>
  );
};
