import React, { useEffect } from 'react';
import { useAuth } from '../../hooks/Auth2';
import { useEvent } from '../../hooks/EventContext2';

// import { Container } from './styles';

const LogoutCallBack: React.FC = () => {
  const eventContext = useEvent();
  const { signOut } = useAuth();

  useEffect(() => {
    signOut().then(
      (r) =>
        (window.location.href = `${window.location.origin}/${eventContext?.event?.key}/signin`),
    );
  }, []);

  return <div>Logout</div>;
};

export default LogoutCallBack;
