import React, {
  ButtonHTMLAttributes,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as Yup from 'yup';
import moment from 'moment';
import { FiMessageCircle } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import $ from 'jquery';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import Button from '../Button';
// import { Conteiner, MessageBox, MessageList } from './style';
import { Conteiner, MessageBox, MessageList } from './style';
import { ChatProvider, useChat } from '../../hooks/ChatContext';
import { useEvent } from '../../hooks/EventContext';
import { useAuth } from '../../hooks/Auth2';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../hooks/useDimension';

type ChatProps = React.ComponentPropsWithoutRef<'div'> & {
  user: any;
  channelId: string;
  style?: any;
  width?: number;
  height?: number | string;
};

interface IChat {
  id: string;
  channelId: string;
  eventId: string;
  createdAt: number;
  messages: IChatMessage[];
}

interface IChatMessage {
  uid: string;
  userName: string;
  userId: string;
  content: string;
  createdAt: number;
}

// var ref = rootRef.child("chat");
// ref.on('value').then(function (snapshot) {
//   snapshot.forEach(function (childSnapshot) {
//     var key = childSnapshot.key;
//     var childData = childSnapshot.val();
//   });
// });

const schema = Yup.object().shape({
  message: Yup.string()
    .min(1, 'Digite aqui no mínimo 1 caractere')
    .max(200, 'Digite aqui até 200 caracteres'),
});

interface ChatData {
  message: string;
}

const Chat: React.FC<ChatProps> = (props) => {
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    errors,
    getValues,
    reset,
  } = useForm<ChatData>({ validationSchema: schema });
  const { t, i18n } = useTranslation();
  const firestore = firebase.firestore();
  const [lastMessage, setLastMessage] = useState<number>(0);
  const [counter, setCounter] = useState<number>(0);
  const { chat, loadChat } = useChat();
  const [messages, setMessages] = useState<any[]>([]);
  const { event, cloudWord } = useEvent();
  const { getUser } = useAuth();
  const user = getUser();

  const { width } = useWindowDimensions();
  const largItem =
    width <= 500
      ? width - 50
      : Number(
          width <= 920
            ? Number(width - 150)
            : Number(width >= 1500 ? 1500 - 500 : width - 500),
        );
  const [isMobile, SetIsMobile] = useState(
    window.matchMedia('(max-width: 992px)').matches,
  );

  const chatContainer = useRef<HTMLDivElement>(null);
  const viewInputBox: number = 40;

  function scrollBottom(): void {
    chatContainer.current?.scrollTo({
      behavior: 'smooth',
      top: chatContainer.current?.scrollHeight,
    });
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      SetIsMobile(window.matchMedia('(max-width: 992px)').matches);
    });
  }, []);

  useEffect(() => {
    if (!props.channelId) return;

    let unsubscribe: any = null;

    loadChat(props.channelId, firestore).then(() => {
      // scrollBottom();
    });

    return () => {
      console.log('***** chat destroy *******');
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [props.channelId]);

  useEffect(() => {
    const _messages: Array<any> = [];

    if (chat?.messages) {
      chat.messages.forEach((message: any) =>
        _messages.unshift({ ...message }),
      );

      if (JSON.stringify(messages) !== JSON.stringify(_messages))
        setMessages(_messages);
    }
  }, [chat]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter >= 1) {
        setCounter(counter - 1);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  });

  function sendHashTagToCloudWord(message: string): void {
    const words: string[] = message.split(' ');
    const hashtags: string[] = words
      ?.filter((word: string) => word.indexOf('#') === 0)
      ?.map((word) => word.replace(/[#]/g, ' ').trim()); // get all words when starts with #

    const cloudRef = firestore.collection('cloudWord').doc(cloudWord.id);

    hashtags.forEach((hashtag) => {
      const word = hashtag;
      if (cloudWord?.words?.[hashtag])
        cloudRef.update({
          [`words.${hashtag}`]: firebase.firestore.FieldValue.increment(1),
        });
      else
        cloudRef.update({
          [`words.${hashtag}`]: 1,
        });
    });

    // hashtags.map((hashtag: string) => {
    //   api
    //     .post('/admin/cloud-words', {
    //       value: 1,
    //       text: hashtag.replace(/[#]/g, ' ').trim(),
    //       eventKey: '',
    //       channelId: props.channelId,
    //       channelKey: '',
    //       eventId: event.id,
    //       userId: user.id,
    //     })
    //     .then((r) => console.log('Hashtag enviada para nuvem de palavras'))
    //     .catch((e) => console.log('Erro ao enviar a hashtag para nuvem', e));
    // });
  }

  async function sendFirestoreMessage(
    chatId: string,
    content: string,
  ): Promise<void> {
    if (lastMessage > moment.utc().valueOf()) {
      return;
    }
    const counter = !process.env.REACT_APP_CYPRESS ? 30 : 5;
    console.log('sendFirestoreMessage', { env: process.env, counter });

    setCounter(counter);
    setLastMessage(moment.utc().add(counter, 'seconds').valueOf());

    const { id, name, email } = props.user;

    const data = {
      userId: id,
      userName: name || email,
      content,
      createdAt: moment.utc().valueOf(),
    };

    const ref = firestore.collection('chat').doc(chatId);
    setValue('message', '');
    ref.update({
      count: firebase.firestore.FieldValue.increment(1),
      messages: firebase.firestore.FieldValue.arrayUnion(data),
    });

    if (content.indexOf('#') >= 0) sendHashTagToCloudWord(content);
  }

  // async function handleSendMessage() {
  //   if (String(messageText).length < 1 || String(messageText).length > 200) {
  //     return setErrorMSM('The message must be between 1 and 200 characters');
  //   }

  //   if (String(errorMSM).length !== 0) {
  //     setErrorMSM('');
  //   }
  //   sendFirestoreMessage(chat$?.id);

  //   // } catch (err) {
  //   //   if (err instanceof Yup.ValidationError) {
  //   //     const errors = getValidationErrors(err);
  //   //   }
  //   // }
  // }

  async function handleSendMessage(data: any): Promise<void> {
    try {
      // const schema = Yup.object().shape({
      //   message: Yup.string()
      //     .min(1, 'No mínimo 1 digitos')
      //     .max(200, 'No maximo 200 digitos'),
      // });

      // await schema.validate(data, {
      //   abortEarly: false,
      // });

      sendFirestoreMessage(chat?.id, data.message);
      reset();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const $errors = getValidationErrors(err);
        console.log('errors', { $errors });
        // setError($errors);
      }
    }
  }

  return (
    <>
      <div
        style={{
          width: props.width ? Number(props.width) : '100%',
          height: isMobile ? '350px' : '100%',
          maxHeight: user?.isModerator
            ? !isMobile
              ? Number(largItem / 1.78) + 40
              : '350px'
            : !isMobile
            ? Number(largItem / 1.78) + 0
            : '350px',

          display: 'flex',
          flexDirection: 'column-reverse',
        }}
        className="boxmsmchatview"
        ref={chatContainer}
      >
        {messages?.map((res: any, index: number) => {
          return (
            <div key={index} className="itemmensagemview" >
              <img
                src={'https://cdn.onlinewebfonts.com/svg/img_568657.png'}
                alt="Image Avatar"
                style={{ width: 20, height: 20 }}
              />
              <view style={{ padding: 10 }} />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{res.userName}</span>
                <span>{res.content}</span>
              </div>
            </div>
          );
        })}
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <div
          className="qtd-caracteres"
          style={{
            position: 'relative',
            textAlign: 'left',
            padding: '0 10px',
          }}
        >
          {watch('message')?.length || 0}/200
        </div>
        <div style={{ flex: 1, textAlign: 'center', marginLeft: -45 }}>
          {counter > 0 && <span data-cy="chat-countdown">({counter})</span>}
        </div>
      </div>
      <form
        onSubmit={handleSubmit(handleSendMessage)}
        style={{
          width: props.width ? Number(props.width) : '100%',
          height: viewInputBox,
          background: 'white',
        }}
        className="viewinputsbox"
      >
        <input
          ref={register({ required: true })}
          name="message"
          placeholder={t('Message')}
          type="text"
          // maxLength={200}
          data-cy="input-message"
          style={{ width: '100%', height: '100%', background: 'white' }}
          className="inputsss"
        />
        {chat?.id && (
          <button
            style={{
              border: 'none',
              background: 'transparent',
              width: 50,
              outline: 'none',
            }}
            data-cy="submit-message"
            type="submit"
          >
            <span
              className="sendviewinputchat fas fa-paper-plane"
              style={{ height: '80%', outline: 'none' }}
            />
          </button>
        )}
      </form>
      {!!errors.message && <span>{errors.message.message}</span>}
    </>
  );
};

export default Chat;

/* <div style={{ width: props.width - bord, height: props.height - viewInputBox }} className='boxmsmchatview'>
    {MensagensData.map((res, index) => {
        return (
            <div key={index} className='itemmensagemview'>
                <img src={'https://cdn.onlinewebfonts.com/svg/img_568657.png'} alt="Image Avatar" style={{ width: 20, height: 20 }} />
                <view style={{ padding: 10 }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{res.name}</span>
                    <span>{res.msm}</span>
                </div>
            </div>
        )
    })}
</div>
<div style={{ width: props.width - bord, height: viewInputBox }} className='viewinputsbox'>
    <input placeholder={t('Mensagem')} style={{ width: props.width, height: '100%' }} className='inputsss' />
    <span className='sendviewinputchat fas fa-paper-plane' style={{ width: 50, height: '80%' }} />
</div> */

{
  /* <Conteiner id="chat" style={{ width: props.width ? Number(props.width - bord) : '100%', height: props.height ? Number(props.height - viewInputBox) : '100%' }} className='boxmsmchatview'>
<MessageList style={{ width: '100%', height: props.height ? Number(props.height - 40) : '100%' }} ref={chatContainer} id="chatMessage">
  {chat$?.messages?.map((message, i) => (
    <React.Fragment key={i}>
      <div className="d-flex align-items-center mb-1">
        <i className="fas fa-user bp-img" />
        <span className="bp-title-user">
          <b>{message.userName}</b>
        </span>
      </div>
      <p className="ml-5 sec-texts">{message.content}</p>
    </React.Fragment>
  ))}
</MessageList>
<br></br>
<MessageBox style={{ width: '100%' }}>
  <form onSubmit={handleSubmit(handleSendMessage)}>
    <div className="">
      {getValues()?.message?.length || 0}/200
      {counter > 0 && <>({counter})</>}
      <div>
        <input
          id="bp-send"
          type="text"
          placeholder="Mensagem"
          name="message"
          ref={register({
            required: true,
            minLength: 1,
            maxLength: 200,
          })}
        />
        {errors.message && <span>Digite aqui até 200 caracteres</span>}
        <span id="box_icone_send">
          <button id="icone_send" type="submit">
            <i className="fas fa-paper-plane" />
          </button>
        </span>
      </div>
    </div>
  </form>
</MessageBox>
<br></br>
</Conteiner > */
}
