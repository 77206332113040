import React, { useEffect, useState } from 'react';
import { Conteiner } from './style';
import Channels from './Channels';
import GalleryVideoTeca from './GalleryVideoTeca';
import Biography from './Biography';
import Schedule from './Schedule';
import Jitsi from './Jitsi';
import Whiteboard from './Whiteboard';
import Kahoo from './Kahoo';
import ExtenalLink from './ExternalLink';
import Disclaimer from './Disclaimer';
import Partners from './Partners';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { asEnumerable } from 'linq-es2015';
import Timeline from '../Timeline';
import { useEvent } from '../../hooks/EventContext';
import { useTranslation } from 'react-i18next';
import Iframe from 'react-iframe';
import Word3d from '../Word3d';
interface FeaturesProps {
  user: any;
  channelId: string;
  eventId: string;
  customization: any;
}

const Features: React.FC<FeaturesProps> = (props: any) => {
  const eventContext = useEvent();
  const [features, setFeatures] = useState<any>([]);
  const { t, i18n } = useTranslation();
  const [currentTab, setCurrentTab] = useState<string | null>(null);
  const [hasFeatures, setHasFeatures] = useState(false);

  function handleIframeLoad(count = 0) {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    console.log('handleIframeLoad', count);

    if (count < 3)
      setTimeout(() => {
        handleIframeLoad(count);
      }, 100);
  }

  useEffect(() => {
    let arr = [
      'channels',
      'schedule',
      'timeline',
      'jitsi',
      'whiteboard',
      'externalLink',
      'disclaimer',
      'partners',
      'speakers',
      'speakers_img',
      'videoteca',
      'biography',
      'externallink',
      'cloudword',
    ];

    const $features = [];

    for (let i = 0; i < eventContext.channel?.resources.length; i++) {
      let found = arr.find(
        (el) => el == eventContext.channel?.resources[i].key,
      );

      if (found) $features.push(found);
    }

    if ($features.length) {
      setHasFeatures(true)
      setFeatures($features);
      setCurrentTab($features[0]);
    }
  }, [props.channelId, eventContext.channel?.update]);


  const b64EncodeUnicode = (str: any) => {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
      }),
    );
  };

  return (
    <Conteiner id="features" HasFeatures={hasFeatures}>
      {' '}
      {/*Para moderador tem que ter row e col-md-12*/}
      <Tabs
        defaultActiveKey={features[0]}
        onSelect={(v) => setCurrentTab(v)}
        id="features-tab"
      >
        {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'channels',
        ) &&
          eventContext.channel?.id && (
            <Tab
              tabClassName="bordertopwieh0tab"
              eventKey="channels"
              title={t('Rooms')}
            >
              <Channels {...props}></Channels>
            </Tab>
          )}

        {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'schedule',
        ) &&
          eventContext.channel?.id && (
            <Tab
              tabClassName="bordertopwieh0tab"
              eventKey="schedule"
              title={t('Schedule')}
            >
              <Schedule {...props}></Schedule>
            </Tab>
          )}

        {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'jitsi',
        ) &&
          eventContext.channel?.id && (
            <Tab
              tabClassName="bordertopwieh0tab"
              eventKey="jitsi"
              title={t('Event Room')}
            >
              <Jitsi {...props}></Jitsi>
            </Tab>
          )}

        {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'whiteboard',
        ) &&
          eventContext.channel?.id && (
            <Tab
              tabClassName="bordertopwieh0tab"
              eventKey="whiteboard"
              title="Whiteboard"
            >
              <Whiteboard {...props}></Whiteboard>
            </Tab>
          )}

        {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'kahoo',
        ) &&
          eventContext.channel?.id && (
            <Tab
              tabClassName="bordertopwieh0tab"
              eventKey="kahoo"
              title="Kahoo"
            >
              <Kahoo {...props} channel={eventContext.channel}></Kahoo>
            </Tab>
          )}

        {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'externalLink',
        ) &&
          eventContext.channel?.id && (
            <Tab
              tabClassName="bordertopwieh0tab"
              eventKey="externalLink"
              title="Game"
            >
              <ExtenalLink
                {...props}
                channel={eventContext.channel}
              ></ExtenalLink>
            </Tab>
          )}

        {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'disclaimer',
        ) &&
          eventContext.channel?.id && (
            <Tab
              tabClassName="bordertopwieh0tab"
              eventKey="disclaimer"
              title="Disclaimer"
            >
              <Disclaimer
                {...props}
                channel={eventContext.channel}
              ></Disclaimer>
            </Tab>
          )}

        {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'partners',
        ) &&
          eventContext.channel?.id && (
            <Tab
              tabClassName="bordertopwieh0tab"
              eventKey="partners"
              title={t('Partners')}
            >
              <Partners {...props} channel={eventContext.channel}></Partners>
            </Tab>
          )}

        {eventContext.channel?.resources.map(
          (channel: any, i: number) =>
            !!channel.tab == true &&
            channel.key == 'generic_data_merck' && (
              <Tab
                tabClassName="bordertopwieh0tab"
                eventKey={channel.key}
                title={t(`${channel.name}`)}
              >
                <div style={{ width: '100%', padding: 10 }}>
                  <a href={`${channel.data.link}`} target="_blank">
                    <img src={channel.data.picture} style={{ width: '100%' }} />
                  </a>
                </div>
              </Tab>
            ),
        )}

        {eventContext.channel?.resources.map(
          (channel: any, i: number) =>
            !!channel.tab == true &&
            channel.key == 'generic_data_sim' && (
              <Tab
                tabClassName="bordertopwieh0tab"
                eventKey={channel.key}
                title={t(`${channel.name}`)}
              >
                <div style={{ width: '100%', padding: 10 }}>
                  <a href={`${channel.data.link}`} target="_blank">
                    <img src={channel.data.picture} style={{ width: '100%' }} />
                  </a>
                </div>
              </Tab>
            ),
        )}

        {eventContext.channel?.resources.map(
          (channel: any, i: number) =>
            !!channel.tab == true &&
            channel.key == 'generic_data_speakers' && (
              <Tab
                tabClassName="bordertopwieh0tab"
                eventKey={channel.key}
                title={t(`${channel.name}`)}
              >
                <div style={{ width: '100%', padding: 10 }}>
                  {channel.data.link != '' ? (
                    <a href={`${channel.data.link}` || ''} target="_blank">
                      <img
                        src={channel.data.picture}
                        style={{ width: '100%' }}
                      />
                    </a>
                  ) : (
                    <img src={channel.data.picture} style={{ width: '100%' }} />
                  )}
                </div>
              </Tab>
            ),
        )}

        {eventContext.channel?.resources.map(
          (channel: any, i: number) =>
            !!channel.tab == true &&
            channel.key == 'videoteca' && (
              <Tab
                tabClassName="bordertopwieh0tab"
                eventKey={channel.key}
                title={t(`${channel.name}`)}
              >
                <GalleryVideoTeca {...props}></GalleryVideoTeca>
              </Tab>
            ),
        )}

        {eventContext.channel?.resources.map(
          (channel: any, i: number) =>
            !!channel.tab == true &&
            channel.key == 'biography' && (
              <Tab
                tabClassName="bordertopwieh0tab"
                eventKey={channel.key}
                title={t(`${channel.name}`)}
              >
                <Biography {...props}></Biography>
              </Tab>
            ),
        )}

        {eventContext.channel?.resources.map(
          (channel: any, i: number) =>
            !!channel.tab == true &&
            channel.key == 'externallink' && (
              <Tab
                key={`feature_${i}`}
                eventKey={`feature_${i}`}
                title={channel?.name_languages[i18n.language]}
              >
                <div className="mt-4">
                  {' '}
                  {/* row mt-4  props?.user?.name+'|'+props?.user?.email+'|'+props?.user?.fields?.profile*/}
                  <div className="col">
                    <Iframe
                      url={
                        channel?.link_languages[i18n.language] +
                        // '?iframe=true&nofocus=y' +
                        '&data=' +b64EncodeUnicode(props?.user?.name +' '+ (props?.user?.fields?.lastname || ''))+'&user='+
                        b64EncodeUnicode(JSON.stringify(props?.user))
                      }
                      width="100%"
                      height="550px"
                      id="myId"
                      className="myClassname"
                      display="block"
                      position="relative"
                      allow="camera *;microphone *"
                      onLoad={handleIframeLoad}
                    />
                  </div>
                </div>
              </Tab>
            ),
        )}
        {/* channel?.link_languages[i18n.language] +
                        '&data=' +
                        btoa(
                          'nome:' +
                            props?.user?.name +
                            ';email:' +
                            props?.user?.email +
                            ';cidade:' +
                            props?.user?.fields?.city +
                            ';estado:' +
                            props?.user?.fields?.uf,
                        ) */}
        {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'timeline',
        ) && (
          <Tab eventKey="timeline" title="Timeline">
            <Timeline
              user={props.user}
              eventId={eventContext.event?.id}
              channelId={eventContext.channel?.id}
              {...props}
            />
          </Tab>
        )}

        {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'cloudword',
        ) && (
          <Tab eventKey="cloudword" title="Nuvem de palavras">
            <Word3d
              isVisible={currentTab === 'cloudword'}
              user={props.user}
              eventId={eventContext.event?.id}
              channelId={eventContext.channel?.id}
              {...props}
            />
          </Tab>
        )}
      </Tabs>
    </Conteiner>
  );
};

export default Features;
