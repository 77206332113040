import styled, { css, keyframes } from 'styled-components';
interface IContainer {
  theme:string;
  customization?:any;
}
export const Container = styled.div<IContainer>`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.colors.footer};
  ${(props) => css`
    background: linear-gradient(
        -45deg,
        ${props.customization?.headerBgColor1 || 'rgba(0,0,0, 0.2)'} 20%,
        ${props.customization?.headerBgColor2 || 'rgba(0,0,0, 0.2)'} 80%
      );
`}
  background: ${props => props.theme.title === 'dark' && props.theme.colors.footer};
  border-radius: 10px;
  padding: 12px;
  width: 100%;
  max-width: 1600px;

  @media screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: repeat(1, auto);
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    background: ${props => props.theme.colors.footer};
    border-radius: 10px;
    padding: 12px;
    width: 100%;
    max-width: 1600px;
}
`;
