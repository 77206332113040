import styled, {css} from 'styled-components';

interface HasFeatures {
  HasFeatures: boolean;
}

export const Conteiner = styled.div<HasFeatures>`
    width: ${props => props.HasFeatures && '100%' } ;
    
    .tab-content {
    color: black;
    background-color: white;
    height: auto;
    overflow: auto;
    ::-webkit-scrollbar {
      height: 5px;
      width: 5px;

    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgb(180, 180, 180);
    }
  }

  .boxFeatures{
    width:'100% !important';
    overflow-x: hidden !important;
  }
  p {
    font-size: 13px;
  }
  a {
    color: #fff;
  }
  display: block;



  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #191919;
    border-color: #191919 #dee2e6 #fff;
    background: #fff;

  }
  .nav-tabs .nav-link{
    background: #cccccc;
  }
  .nav-item {
    flex: none;
}
  a.dropdown-item {
    display: block;
    padding: 5px;
    cursor: pointer;
  }
#features{
  width:'100% !important';
  overflow-x: hidden !important;
  padding:0px !important;
}


`;
