import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import RichTextEditor from 'react-rte';
import { Container } from './style';

import './index.css';
import Emitter from '../../../utils/Event';

// import { Container } from './styles';

const ChatModeration: React.FC = () => {
  const [editorState, setEditorState] = React.useState(() =>
    RichTextEditor.createEmptyValue(),
  );

  const [message, setMessage] = useState('');

  function sendModerationMessage() {
    var _message = message;
    setMessage('');
    setEditorState(RichTextEditor.createEmptyValue());
    if (message) Emitter.emit('CHAT.SEND_MESSAGE', message);
  }

  return (
    <Container>
      <h5>Mensagem</h5>
      <RichTextEditor
        className="custom-richtext"
        value={editorState}
        onChange={(value) => {
          const _value = value.toString('html');

          setMessage(_value);
          setEditorState(value);
        }}
      />
      <br />
      <Button type="button" onClick={sendModerationMessage}>
        Enviar mensagem
      </Button>
    </Container>
  );
};

export default ChatModeration;
