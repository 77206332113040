import React from 'react';
import { useEvent } from '../../hooks/EventContext';
import { Container, Description, Title } from './styles';
import { useTheme } from '../../hooks/ThemeContext';

interface IProps {
  title?: string;
  description?: string;
}
const HeaderPageDescription: React.FC<IProps> = ({ title, description, children }) => {
  const eventContext = useEvent();
  const { theme } = useTheme();
  const [fontColor, setFontColor] = React.useState(eventContext.event?.customization?.fontColor as string || '#fff')

  React.useEffect(() => {
    setFontColor(eventContext.event?.customization?.fontColor || '#fff')
  }, [eventContext.event?.customization?.fontColor])

  return (
    <Container theme={theme} customization={eventContext.event?.customization}>
      {!title && !description && 'Need title or description'}
      {title && <Title style={{ color: fontColor }}>{title}</Title>}
      {description && <Description style={{ color: fontColor }}>{description}</Description>}
      {children}
    </Container>
  )
}

export default HeaderPageDescription
