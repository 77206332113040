import React from 'react';
import { Icon } from '../../Icons';
import './style.css';

interface IProps {
  onClose: () => void;
}
export const ModalTabs: React.FC<IProps> = ({ onClose, children }) => {
  const handleOutsideClick = (e: any) => {
    if (e.target.id === 'modalTabs') onClose();
  };

  return (
    <div id='modalTabs' className="modalTabs" onClick={handleOutsideClick}>
      <div className="modalTabs-container">
        <button className="modalTabs-close" onClick={onClose}>
          <Icon name="iconClose" />
        </button>
        <div className="modalTabs-content">
          {children}
        </div>
      </div>
    </div>
  )
};

export default ModalTabs;
