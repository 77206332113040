import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background:#fff;
  width: 100%;
  max-width: 1100px;
  margin:2rem auto 0;
  border-radius:5px;
  padding: 4rem 150px;
  a{
    color:#007bff;
    text-decoration:underline !important
  }
  .privacyPolicy-title{
    font-size: 1.5rem;
    font-weight: 700;
  }
  .privacyPolicy-text-underline{
    text-decoration:underline
  }
  .privacyPolicy-list-type-letter{
    list-style: lower-latin;
  }

  h3, p, li{
    font-size: 16px;
    height: auto;
    margin: 4px 0;
    width: 100%;
  }
  h3{
    margin: 8px 0;
  }
  ul{
    width: 100%;
    margin:0 !important;
  }
  .privacyPolicy-text-block{
  padding-left: 8px;
    & > h2{
    font-size: 1.25rem;
    margin: 24px 0 8px 0;
    color:#0570e2;
    font-weight:700;
    /* transform: translateX(-8px); */
  }
    p{
    margin: 8px 0;
    }
    ul{
      display: flex;
      flex-direction: column;
      padding-left: 24px;
      ul{
        list-style: disc;
        ul{
        list-style: circle;
        padding-left: 18px;
      }
      }
      li{

      }
    }
  }

  @media(max-width:764px){
    .privacyPolicy-title{
    font-size: 1.125rem;
  }
    .privacyPolicy-text-block{
      ul{
        padding-left: 18px;
      }
    }
  }
  @media(max-width:480px){
    .privacyPolicy-text-block{
      ul{
        padding-left: 12px;
      }
    }
  }
  @media(max-width:764px){
      padding: 4rem 35px;

      a{
        line-break: anywhere;
      }
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  animation: ${appearFromLeft} 1s;
`;

