import React, { useEffect, useState, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as Yup from 'yup';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import Picker from 'emoji-picker-react';
import { useTranslation } from 'react-i18next';
import { IEmojiData } from 'emoji-picker-react';

import getValidationErrors from '../../utils/getValidationErrors';
// import { Conteiner, MessageBox, MessageList } from './style';
import { useChat } from '../../hooks/ChatContext2';
import { useEvent } from '../../hooks/EventContext';
import { IUser, useAuth } from '../../hooks/Auth2';
import useWindowDimensions from '../../hooks/useDimension';
import Emitter from '../../utils/Event';
import './index.css';
import api from '../../services/api';
import { useTheme } from '../../hooks/ThemeContext';
// Icons
import icon_close from '../../assets/images/icons/icon_close.svg';
import icon_send from '../../assets/images/icons/icon_send.svg';
import icon_addImage from '../../assets/images/icons/icon_add_image.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Icon } from '../Icons';
import AvatarIcon from '../../assets/images/avatarP.png';
import { Form, ChatBoxContent } from './style';

type ChatProps = React.ComponentPropsWithoutRef<'div'> & {
  user: any;
  channelId: string;
  eventId: string;
  style?: any;
  width?: number;
  height?: number | string;
};

interface IChat {
  id: string;
  channelId: string;
  eventId: string;
  createdAt: number;
  messages: IChatMessage[];
}

interface IChatMessage {
  uid: string;
  userName: string;
  userId: string;
  content: string;
  createdAt: number;
}

// var ref = rootRef.child("chat");
// ref.on('value').then(function (snapshot) {
//   snapshot.forEach(function (childSnapshot) {
//     var key = childSnapshot.key;
//     var childData = childSnapshot.val();
//   });
// });

const schema = Yup.object().shape({
  message: Yup.string(),
});

interface ChatData {
  message: string;
}

const Chat: React.FC<ChatProps> = (props) => {
  const { register, watch, setValue, handleSubmit, errors, getValues, reset } =
    useForm<ChatData>({ validationSchema: schema });
  const { t, i18n } = useTranslation();
  const firestore = firebase.firestore();
  const [lastMessage, setLastMessage] = useState<number>(0);
  const [counter, setCounter] = useState<number>(0);
  const [emojiIsVisible, setEmojiIsvisible] = useState(false);
  const { chat, loadChat, messages } = useChat();
  // const [messages, setMessages] = useState<any[]>([]);
  const { event, channel, cloudWord } = useEvent();
  const channelId = channel?.id;
  const { getUser } = useAuth();
  const user: Partial<IUser> = getUser() || {};
  const { theme } = useTheme();
  const eventContext = useEvent();

  const chatContainer = useRef<HTMLDivElement>(null);
  const viewInputBox: number = 40;

  function scrollBottom(): void {
    chatContainer.current?.scrollTo({
      behavior: 'smooth',
      top: chatContainer.current?.scrollHeight,
    });
  }

  useEffect(() => {
    console.log('user2' + user.name);

    if (!channelId) return;

    let unsubscribe: any = null;

    loadChat(channelId, firestore).then(() => {
      // scrollBottom();
    });

    return () => {
      console.log('***** chat destroy *******');
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [channelId]);

  // useEffect(() => {
  //   const _messages: Array<any> = [];

  //   if (chat?.messages) {
  //     chat.messages.forEach((message: any) =>
  //       _messages.unshift({ ...message }),
  //     );

  //     if (JSON.stringify(messages) !== JSON.stringify(_messages))
  //       setMessages(_messages);
  //   }
  // }, [chat]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter >= 1) {
        setCounter(counter - 1);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  });

  function sendHashTagToCloudWord(message: string): void {
    const words: string[] = message.split(' ');
    const hashtags: string[] = words
      ?.filter((word: string) => word.indexOf('#') === 0)
      ?.map((word) => word.replace(/[#]/g, ' ').trim()); // get all words when starts with #

    const cloudRef = firestore.collection('cloudWord').doc(cloudWord.id);

    hashtags.forEach((hashtag) => {
      const word = hashtag;
      if (cloudWord?.words?.[hashtag])
        cloudRef.update({
          [`words.${hashtag}`]: firebase.firestore.FieldValue.increment(1),
        });
      else
        cloudRef.update({
          [`words.${hashtag}`]: 1,
        });
    });

    // hashtags.map((hashtag: string) => {
    //   api
    //     .post('/admin/cloud-words', {
    //       value: 1,
    //       text: hashtag.replace(/[#]/g, ' ').trim(),
    //       eventKey: '',
    //       channelId: channelId,
    //       channelKey: '',
    //       eventId: event.id,
    //       userId: user.id,
    //     })
    //     .then((r) => console.log('Hashtag enviada para nuvem de palavras'))
    //     .catch((e) => console.log('Erro ao enviar a hashtag para nuvem', e));
    // });
  }

  async function handleRemoveMessage(data: any) {
    try {
      if (
        !window.confirm(`${t('Are you sure you want to delete this message?')}`)
      )
        return;

      await firestore
        .collection('chat2')
        .doc(channelId)
        .collection('messages')
        .doc(data.id)
        .delete();
    } catch (error) {}
  }

  async function sendFirestoreMessage(
    channelId: string,
    content: string,
  ): Promise<void> {
    if (lastMessage > moment.utc().valueOf()) {
      return;
    }

    setEmojiIsvisible(false);
    let counter =
      window.location.href.indexOf('homologacao.4yourlive.com') === -1 ? 5 : 0;

    console.log('sendFirestoreMessage', { env: process, counter });

    setCounter(counter);
    setLastMessage(moment.utc().add(counter, 'seconds').valueOf());

    const { id, name, email, isModerator, avatar } = user;

    const data = {
      userId: id,
      userName: name || email,
      content,
      isModerator,
      userAvatar: avatar || null,
      createdAt: moment.utc().valueOf(),
    };

    const chatRef = firestore.collection('chat2').doc(channelId);
    const exists = (await chatRef.get()).exists;
    if (!exists) {
      console.log('setChat', { eventId: event.id, channelId: channelId });
      await chatRef.set({ eventId: event.id, channelId: channelId });
    }
    const messagesRef = chatRef.collection('messages');

    console.log('sendMessage', { data, isModerator, exists });

    await messagesRef
      .add(data)
      .then(() => {
        api.post(`/admin/chat/${event.id}/${channelId}/message`, data);
      })
      .catch((e) => {
        console.log('add message error', { e, data });
      });
    setValue('message', '');
    // ref.update({
    //   count: firebase.firestore.FieldValue.increment(1),
    //   messages: firebase.firestore.FieldValue.arrayUnion(data),
    // });

    if (content.indexOf('#') >= 0) sendHashTagToCloudWord(content);
  }

  // async function handleSendMessage() {
  //   if (String(messageText).length < 1 || String(messageText).length > 200) {
  //     return setErrorMSM('The message must be between 1 and 200 characters');
  //   }

  //   if (String(errorMSM).length !== 0) {
  //     setErrorMSM('');
  //   }
  //   sendFirestoreMessage(chat$?.id);

  //   // } catch (err) {
  //   //   if (err instanceof Yup.ValidationError) {
  //   //     const errors = getValidationErrors(err);
  //   //   }
  //   // }
  // }

  async function handleSendMessage(data: any): Promise<void> {
    const message = data.message as string;
    if (counter > 0) {
      showMessageAlert(
        `${t('Wait')} ${counter} ${t('Seconds')} ${t('To send a new message')}`,
      );
      return;
    }
    if (message.length <= 0 || !message) {
      showMessageAlert(`${t('Type at least 1 character')}`);
      return;
    }
    if (message.length > 200) {
      showMessageAlert(`${t('Enter a maximum of 200 characters')}`);
      return;
    }
    try {
      // const schema = Yup.object().shape({
      //   message: Yup.string()
      //     .min(1, 'No mínimo 1 digitos')
      //     .max(200, 'No maximo 200 digitos'),
      // });

      // await schema.validate(data, {
      //   abortEarly: false,
      // });

      sendFirestoreMessage(channelId, data.message);
      reset();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const $errors = getValidationErrors(err);
        console.log('errors', { $errors });
        // setError($errors);
      }
    }
  }

  function onEmojiClick(event: React.MouseEvent, data: IEmojiData) {
    const message = watch('message');
    setValue('message', `${message}${data.emoji}`);
  }

  useEffect(() => {
    function chatSendMessag(message: string): void {
      handleSendMessage({ message });
    }

    Emitter.on('CHAT.SEND_MESSAGE', chatSendMessag);

    return () => {
      Emitter.off('CHAT.SEND_MESSAGE', chatSendMessag);
    };
  }, []);

  function showMessageAlert(message: string) {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  React.useEffect(() => {
    // messages[0]?.userId === user?.id && scrollBottom();
    scrollBottom();
  }, [messages]);

  console.log('render chat2');
  return (
    <>
      <div className="chat-box-view-container" ref={chatContainer}>
        <div className="chat-box-view">
          {messages
            //.slice(0, 30)
            ?.map((res: any, index: number) => {
              const { isModerator } = res;
              return (
                <div
                  key={res.id}
                  className="chat-box animation_translateX "
                  style={{ animationDelay: `${index * 20}ms` }}
                >
                  <div style={{ padding: 5 }} />

                  <img
                    src={res.userAvatar ? res.userAvatar : AvatarIcon}
                    alt="Image Avatar"
                    style={{
                      width: 43,
                      height: 43,
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                  <ChatBoxContent
                    isModerator={isModerator}
                    theme={theme}
                    className={`animation_transform-show-message chat-box-message-content`}
                  >
                    <span className="chat-box-message-user">
                      <div className="chat-box-message-user-content">
                        {isModerator && (
                          <span className="chat-box-message-moderator">
                            {' '}
                            host{' '}
                          </span>
                        )}

                        <span className="chat-box-message-username">
                          {' '}
                          {res.userName} -{' '}
                        </span>
                        {moment(res.createdAt).format('HH:mm')}
                      </div>
                      {user?.isModerator && (
                        <button
                          onClick={() => handleRemoveMessage(res)}
                          className="btn-delete-message"
                        >
                          <img
                            src={icon_close}
                            alt="delete"
                            width="100%"
                            className="chat-icon"
                          />
                        </button>
                      )}
                    </span>
                    <span
                      className="chat-box-message-content-text"
                      dangerouslySetInnerHTML={{ __html: res.content }}
                    ></span>
                    <span className="chat-box-message-content-date"></span>
                  </ChatBoxContent>
                </div>
              );
            })}
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%' }}></div>
      <Form
        onSubmit={handleSubmit(handleSendMessage)}
        theme={theme}
        customization={eventContext?.event?.customization || {}}
        // className="chat-form-box"
      >
        <div className="input-block-chat">
          {chat?.id && (
            <button
              data-cy="emoji-btn"
              type="button"
              onClick={() => setEmojiIsvisible((prev) => !prev)}
              className="chat-btn-emoji"
              style={{ fill: '#999999' }}
            >
              <Icon name="iconEmoji" />
            </button>
          )}
          <input
            ref={register({ required: true })}
            name="message"
            placeholder={
              counter > 0
                ? `${t('Wait')} ${counter} ${t('Seconds')}...`
                : t('Message')
            }
            disabled={counter > 0}
            type="text"
            // maxLength={200}
            data-cy="input-message"
            className="chat-message-input"
          />
          {/* {chat?.id &&
            <button className="chat-btn-send-image">
              <img src={icon_addImage} alt="adicionar imagem" width="100%" className="chat-icon" />
            </button>
          } */}
          {chat?.id && (
            <button
              data-cy="submit-message"
              type="submit"
              className="chat-btn-send-message"
            >
              <Icon name="iconSend" />
            </button>
          )}
          <div className="chat-character-counter">
            {watch('message')?.length || 0}/200
          </div>
        </div>
      </Form>
      {!!errors.message && <span>{errors.message.message}</span>}
      {emojiIsVisible && (
        <div>
          <Picker
            onEmojiClick={onEmojiClick}
            pickerStyle={{ width: '100%' }}
            groupNames={{
              smileys_people: 'Sorrisos e Pessoas',
              animals_nature: 'Animais e Natureza',
              food_drink: 'Comidas e Bebidas',
              travel_places: 'Viagem e Lugares',
              ctivities: 'Atividades',
              objects: 'Objetos',
              symbols: 'Símbolos',
              flags: 'Bandeiras',
              recently_used: 'Usado recentemente',
            }}
            // groupNames={{
            //   smileys_people: 'yellow faces',
            //   animals_nature: 'cute dogs and also trees',
            //   food_drink: 'milkshakes and more',
            //   travel_places: 'I love trains',
            //   activities: 'lets play a game',
            //   objects: 'stuff',
            //   symbols: 'more stuff',
            //   flags: 'fun with flags',
            //   recently_used: 'did I really use those?!',
            // }}
          />
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Chat;
