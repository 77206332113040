import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Container } from './style';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';
import { asEnumerable } from 'linq-es2015';
import api from '../../../services/api';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import Button from '../../Button';
import { useChat } from '../../../hooks/ChatContext';
import { useEvent } from '../../../hooks/EventContext';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import './index.css';
import { useAuth } from '../../../hooks/Auth2';

interface ChatProps {
  user: any;
  channelId: string;
  eventId: string;
}

const Attendance: React.FC<ChatProps> = (props: any) => {
  const { getUser } = useAuth();
  const user = getUser();
  const { chat } = useChat();
  const { event, channel } = useEvent();

  // const { channel } = props;
  const { register, errors, setValue, handleSubmit, reset } = useForm({
    defaultValues: { ...channel?.streaming },
  });
  const [sessions, setSessions] = useState<any[]>([]);
  const [filtered, setFiltered] = useState<any[]>([]);
  const { i18n } = useTranslation();
  const [changeVideoVisible, setChangeVideoVisible] = useState<boolean>(false);
  const [cleanChatIsBusy, setCleanChatIsBusy] = useState(false);
  const [cleanTimelineIsBusy, setCleanTimelineIsBusy] = useState(false);
  const [logoutIsBusy, setLogoutIsBusy] = useState(false);
  const [changeUrlIsBusy, setChangeUrlIsBusy] = useState(false);
  const [cleanCloudWordsIsBusy, setCleanCloudWordsIsBusy] = useState<any>();
  const [streaming, setStreaming] = useState<any>(
    channel?.streaming?.[i18n.language] || '',
  );

  useEffect(() => {
    console.log('lang change', i18n.language);
    setStreaming(channel?.streaming?.[i18n.language] || '');
  }, [i18n.language]);

  useEffect(() => {
    if (!channel?.id) return;

    setStreaming(channel?.streaming?.[i18n.language] || '');
  }, [channel?.id]);

  async function cleanChat(): Promise<void> {
    if (window.confirm('Você tem certeza que deseja limpar o chat?')) {
      try {
        setCleanChatIsBusy(true);

        const response = await api.post(`/admin/chat/clean/${channel.id}`);

        if (response.status === 201) {
          alert('Chat limpo com sucesso');
        }
      } catch (error) {
        alert(
          error?.response?.data?.message || 'Não foi possível limpar o chat',
        );
      } finally {
        setCleanChatIsBusy(false);
      }
    }
  }
  async function cleanTimeline(): Promise<void> {
    if (window.confirm('Você tem certeza que deseja limpar a timeline?')) {
      try {
        const messagesRef = firebase.firestore().collection('timeline');
        const snap = await messagesRef
          .where('channelId', '==', channel.id)
          .where('eventId', '==', event.id)
          .get();
        const timeline = snap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (timeline?.length) {
          setCleanTimelineIsBusy(true);

          const response = await api.post(
            `/admin/timeline/clean/${event.id}/${channel.id}`,
            timeline,
          );

          if (response.status === 201) {
            alert('Timeline limpa com sucesso');
          }
        }
      } catch (error) {
      } finally {
        setCleanTimelineIsBusy(false);
      }
    }
  }

  async function cleanCloudWords(): Promise<void> {
    if (
      window.confirm('Você tem certeza que deseja limpar a nuvem de palavras?')
    ) {
      try {
        setCleanCloudWordsIsBusy(true);

        const response = await api.delete(
          `/admin/cloud-words/${event.id}/${channel.id}`,
        );

        if (response.status === 201) {
          alert('Nuvem de palavras limpa com sucesso');
        }
      } catch (error) {
      } finally {
        setCleanCloudWordsIsBusy(false);
      }
    }
  }

  async function logoutAllUsers(): Promise<void> {
    if (
      window.confirm(
        'Você tem certeza que deseja deslogar todos usuario do evento?',
      )
    ) {
      try {
        const sessionId = sessionStorage.getItem('4yourlive:tabid')
        const participantRef = firebase.firestore().collection('participant');
        const snap = await participantRef
          .where('eventId', '==', event.id)
          .get();
        console.log(
          'logoutAllUsers',
          snap.docs.map((d) => ({ ...d.data(), id: d.id })),
        );
        snap.docs.map(async (doc) => {
          if (doc.id !== user?.id) {
            await api.get(`/sessions/logout/${doc.id}/${sessionId}`);
          }
        });

        // await api.post(`/sessions/logout-all/${event.id}`);

        alert('usuarios deslogados com suceso');
      } catch (error) {
        let erro = error?.response?.data?.message || error?.message;
        if (erro) erro = `\nerro: ${erro}`;

        alert(`Não foi possível deslogar os usuarios. ${erro}`);
      }
    }
  }
  function changeUrlVideo() {
    setChangeVideoVisible(true);
  }

  async function onStreamingSubmit() {
    console.log('onStreamingSubmit', streaming);

    const _data = { ...channel };

    if (!_data['streaming']) _data['streaming'] = {};
    _data['streaming'][i18n.language] = streaming;

    console.log('onStreamingSubmit post', _data);
    const result = await api.post('/admin/channel', _data);
    alert('Url alterada com sucesso!');
  }

  console.log('attendance render', {
    event,
    channel,
    languages: i18n.languages,
    streaming,
  });

  return (
    <Container>
      {!changeVideoVisible && (
        <div className="row">
          <div className="col-12 col-md-6 mt-3">
            <Button
              className="btn btn-primary w-100"
              onClick={cleanChat}
              loading={cleanChatIsBusy}
              spinner
            >
              Limpar chat
            </Button>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <Button
              className="btn btn-primary w-100"
              onClick={cleanTimeline}
              loading={cleanTimelineIsBusy}
              spinner
            >
              Limpar timeline
            </Button>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <Button
              className="btn btn-primary w-100"
              loading={logoutIsBusy}
              onClick={logoutAllUsers}
            >
              Deslogar usuarios
            </Button>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <Button
              className="btn btn-primary w-100"
              loading={changeUrlIsBusy}
              onClick={changeUrlVideo}
            >
              Alterar url do video
            </Button>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <Button
              className="btn btn-primary w-100"
              loading={cleanCloudWordsIsBusy}
              onClick={cleanCloudWords}
            >
              Limpar nuvem de palavras
            </Button>
          </div>
        </div>
      )}
      {changeVideoVisible && (
        <div className="w-100">
          <h6 className="mt-3">Editar url do video</h6>

          <input
            name="streaming"
            onChange={(e) => {
              const link = e.target.value;
              setStreaming(link);
            }}
            value={streaming}
            className="form-control"
          />
          <Button
            className="btn btn-primary mt-2 w-100"
            type="button"
            onClick={onStreamingSubmit}
          >
            Alterar URL
          </Button>
          <button
            className="btn btn-muted w-100"
            onClick={() => setChangeVideoVisible(false)}
          >
            Cancelar
          </button>
        </div>
      )}
    </Container>
  );
};

export default Attendance;
