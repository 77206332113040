import React, { useCallback, useRef, useContext, useState } from 'react';
import * as allIcons from 'react-icons/fi';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';
import Button from '../../components/Button';
import { Container, Content, AnimationContainer } from './styles';
import DefaultMasterPage from '../../masterpages/DefaultMasterPage';
import { useEvent } from '../../hooks/EventContext';
import { asEnumerable } from 'linq-es2015';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/Auth2';
import ContainerButton from '../../components/ContainerButton';
import { useTranslation } from 'react-i18next';
const icons: any = allIcons;

interface SignUpFormData {
  eventKey: string;
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();

  const { register, setValue, handleSubmit, errors } = useForm<FormData>();
  const errorsAny: any = errors;
  const { addToast } = useToast();
  const history = useHistory();
  const eventContext = useEvent();
  const [passwordMessage, setPasswordMessage] = useState<any>();
  const { getUser } = useAuth();
  const user = getUser();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState<any>({});

  // if (user && user.id) {
  //   history.replace(`/${props.params.event}/dashboard`);
  // }

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      setLoading(true);
      const schema = Yup.object().shape({
        email: Yup.string()
          .required(t('E-mail is required'))
          .email(t('Type a valid email')),
          password: Yup.string().min(6, t('Password must be at least 6 characters')),
      });


      await schema.validate(data, {
        abortEarly: true,
      });

      data.eventKey = props.params.event;
      const participant = await api.post('/participant', data);

      history.push(`/${eventContext.eventKey}/signin`);

      addToast({
        type: 'success',
        title: t('Recovery Password has been sent'),
        description: t('We sent an email to confirm password recovery, check your inbox'),
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: t('Authentication error'),
        description: error?.response?.data?.message || error.message || t('An error occurred while signing in, please try again'),
      });
    }
  });

  const emailValidation = (email: string) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };

  const passwordValidation = (password: string) => {
    let validation = <></>;

    // Validate lowercase letters
    var lowerCaseLetters = /[a-z]/g;
    if (!password.match(lowerCaseLetters)) {
    validation = <React.Fragment> {validation} <span className="error">{t('Password must have one character lowercase')}</span><br /></React.Fragment>
    }

    // Validate capital letters
    // var upperCaseLetters = /[A-Z]/g;
    // if (!password.match(upperCaseLetters)) {
    //   validation = <React.Fragment> {validation} <span className="error">A senha deve ter uma letra maiúscula</span><br /></React.Fragment>
    // }

    // Validate numbers
    var numbers = /[0-9]/g;
    if (!password.match(numbers)) {
    validation = <React.Fragment> {validation} <span className="error">{t('The password must have at least one number')}</span><br /></React.Fragment>
    }

    // Validate length
    if (password.length < 6) {
    validation = <React.Fragment> {validation} <span className="error">{t('Password must be at least 6 characters')}</span><br /></React.Fragment>
    }

    setPasswordMessage(validation);

    return validation !== <></>
  };

  function onChangeSelect(field: any, obj: any) {
    if (field.key) {
      const newFields = { ...fields };
      const registerFields = asEnumerable(eventContext?.event?.registerFields || []).OrderBy((f: any) => f.order).ToArray() as any[];

      for (let i = 0; i < registerFields.length; i++) {
        const item = registerFields[i];
        if (item.dependency) {
          const dependency = item.dependency.split(',');

          if (field.key == dependency[0] && obj.currentTarget.value == dependency[1]) {
            newFields[i] = true;
          }
          else {
            newFields[i] = false;
          }
        }
      }

      setFields(newFields);
    }
  }

  return (
    <DefaultMasterPage {...props} scheme="frm-signup">
      <AnimationContainer>
        <form onSubmit={onSubmit} style={{width:'100%', minWidth:'330px'}}>
          <h3>Cadastre-se</h3>
          {asEnumerable(eventContext?.event?.registerFields || []).OrderBy((f: any) => f.order).Select((field: any, i: any) => {
            let formResult: JSX.Element = <></>;
            formResult = field.type == 'text' && (!field.dependency || fields[i]) ? (<input className="form-control form-text" type="text" key={i} name={`dyn-${field.name.replace(/ /gi, '').toLowerCase()}`} placeholder={field.name} ref={register({ required: field.required })} />) : formResult;
            formResult = field.type == 'number' && (!field.dependency || fields[i]) ? (<input className="form-control form-text" type="number" key={i} name={`dyn-${field.name.replace(/ /gi, '').toLowerCase()}`} placeholder={field.name} ref={register({ required: field.required })} />) : formResult;
            formResult = field.type == 'select' && (!field.dependency || fields[i]) ? (
              <select className="form-control form-text" onChange={(event) => onChangeSelect(field, event)} key={i} name={`dyn-${field.name.replace(/ /gi, '').toLowerCase()}`} ref={register({ required: field.required })} placeholder={field.name}>
                <option value="">{field.name}</option>
                {
                  asEnumerable(field?.dataList || []).OrderBy((f: any) => f.order).Select((value: any, i) => (
                    <option key={i} value={value}>{value}</option>
                  ))
                }
              </select>
            ) : formResult;
              return <React.Fragment key={i}>{formResult} <>{errorsAny[`dyn-${field.name.replace(/ /gi, '').toLowerCase()}`] && <span className="error">{t('Please fill in the field')}</span>}</></React.Fragment>;
          }).ToArray()}

          <input className="form-control form-text" name="email" type="text" ref={register({ required: true, validate: emailValidation })} placeholder={t('Email')} />
        <>{errorsAny[`email`] && <span className="error">{t('E-mail is required')}</span>}</>

          <input className="form-control form-text" name="password" type="password" ref={register({ required: true, validate: passwordValidation })} placeholder={t('Password')} />
        <>{errorsAny[`password`] && <span className="error">{t('Password is required')}</span>}</>
          <br />
          <>{passwordMessage}</>

          <div style={{ textAlign: 'center' }}>
            {asEnumerable(eventContext?.event?.terms || []).OrderBy((f: any) => f.order).Select((term: any, i) => (
              <div key={i} style={{fontSize: '9px', marginBottom: '0' }}>
                {term.description}
                <div>
                  <input name={`term-${i}`} type="checkbox" ref={register({ required: true })} /> {t('Accept')}
                </div>
              </div>
            )).ToArray()}
          </div>

          {/* <Button type="submit">Cadastrar</Button> */}
          <ContainerButton loading={loading} classe={'button_login'} >
            {t('Register')}
          </ContainerButton>
          <Link className="link" to={`/${props.params.event}/signin`}>
          {t('Back to Sign In')}
          </Link>
        </form>
      </AnimationContainer>
    </DefaultMasterPage>
  );
};

export default SignUp;