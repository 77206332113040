import React from 'react'
import { useParams } from 'react-router-dom';
import DashboardMasterPage2 from '../../masterpages/DashboardMasterPage2';
import { ContactContainer, Content } from './style'
import { useAuth } from '../../hooks/Auth2';
import FooterTerms from '../../components/FooterTerms'
import { string } from 'yup';
import { useToast } from '../../hooks/Toast';
import { useTheme } from '../../hooks/ThemeContext';
import { useTranslation } from 'react-i18next';

const INITAL_VALUES = {
  name: '',
  email: '',
  surname: '',
  cpf: '',
  request: '',
  message: ''
}
const emailSubjects = [
  'Receive confirmation of the existence of data processing operations',
  'Access the data collected on me',
  'Correct data',
  'Anonymize or block unnecessary data',
  'Delete data',
  'Make a request for the portability of data to another company',
  'Information about how the controller shares my personal data',
  'Information about the ability to deny consent',
  'Revoke consent',
  'Object to data processing operations'
]
function Contact(props: any) {
  const { signOut, getUser } = useAuth();

  const user = getUser();

  const { event } = useParams<{ event?: string }>();
  return !user || (user && user.eventKey !== event) ? (
    <>
      <ContentPage />
      <FooterTerms />
    </>
  ) : (
    <DashboardMasterPage2  {...props}>
      <ContentPage />
    </DashboardMasterPage2>
  );
};

function ContentPage() {
  const [formData, setFormData] = React.useState(INITAL_VALUES)
  const [IsSubmitting, setIsSubmitting] = React.useState(false);
  const { addToast } = useToast();
  const {theme} = useTheme();
  const { t } = useTranslation();

  React.useEffect(() => {
    try {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } catch (error) { }
  }, []);


  function handleForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (IsSubmitting) return;

    setIsSubmitting(true);

    try {
      // Envio do email
      console.log(formData)
      const obj2 = {
        subject: 'Formulário de contato DPO - 4YourLive',
        to: 'dpo@rstcom.net',
        from: formData.email,
        fromname: formData.name,
        urlmail: 'https://4yourlive.com.br/email/contact.html',
        name: formData.name,
        surname: formData.surname,
        email: formData.email,
        cpf: formData.cpf,
        request: formData.request,
        message: formData.message
      } as any;

      let formBody = [] as any;

      for (const property in obj2) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(obj2[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
      }
      formBody = formBody.join('&');

      fetch('https://rstcom.live/4yourlive-mailgun/index.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: formBody,
      }).then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
        },
        (error) => {
          console.log('FAILED...', error);
        }
      );

      addToast({
        type: 'success',
        title: '',
        description: `${t("Data sent successfully")}`,
      });

    } catch (error) {
      addToast({
        type: 'error',
        title: '',
        // @ts-ignore
        description: error?.response?.data?.message || `${t("Error on send, try again")}`,
      });

    } finally {
      setIsSubmitting(false);
      setFormData(INITAL_VALUES)
    }
  }
  React.useEffect(() => {
    console.log(formData)
  }, [formData])
  function handleChange(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.MouseEvent<HTMLInputElement, MouseEvent>) {
    const inputName = e.currentTarget.name;
    const inputValue = e.currentTarget.value;

    const values = {
      ...formData,
      [inputName]: inputValue
    }

    return setFormData(values);
  }

  return (
    <ContactContainer theme={theme}>
      <header className='contact-page-header'>

        <h1>{t("Speak with our data protector")}</h1>
        <p>{t("This form refers to questions and inquiries regarding how RST.com uses your personal information.")}</p>
        <p>{t("If you would like to request a proposal, submit your resume, or have another inquiry, we'd like to hear from you using the form provided in the CONTACT US tab.")}</p>

      </header>
      <Content>
        <form onSubmit={(e) => handleForm(e)} className='contact-page-form'>
          <div className="contact-input-block-container">
            <div className="contact-input-block">
              <label className="text-black">{t("FirstName")} *</label>
              <input name="name" type="text" placeholder={t("FirstName")} onChange={handleChange} required value={formData.name} />
            </div>
            <div className="contact-input-block">
              <label className="text-black">{t("Lastname")} *</label>
              <input name="surname" type="text" placeholder={t("Lastname")} onChange={handleChange} required value={formData.surname} />
            </div>
            <div className="contact-input-block">
              <label className="text-black">{t("Email")} *</label>
              <input name="email" type="email" placeholder={t("Email")} onChange={handleChange} required value={formData.email} />
            </div>
            <div className="contact-input-block">
              <label className="text-black">CPF (Tax ID)*</label>
              <input name="cpf" type="number" onChange={(e) => handleChange(e)} required value={formData.cpf} maxLength={11} />
            </div>
          </div>

          <div className="contact-page-radio-container">
            <label className="text-black">{t("Select your request")} *</label>
            {emailSubjects.map((emailSubject, index) => {
              return (
                <>
                  <label className="contact-page-radio-input-block" key={emailSubject + index}>
                    <span className="text-black">{t(`${emailSubject}`)}</span>
                    <input type="radio" name="request" value={emailSubject} onChange={handleChange} />
                    <span className="checkmark"></span>
                  </label>
                </>
              )
            })}
          </div>

          <textarea className="description" name="message" id="text" placeholder={t("Please detail your request")} required value={formData.message} onChange={handleChange}></textarea>
          <div className="text-black mt-2 mb-4 d-flex flex-column">
            <small>
              <p>
                {t("We care about your privacy and the protection of your personal data.")}
              </p>
              <p>{t("Data collected in the form above are intended to identify you in order to solve any issues related to your personal data, such as questions and inquiries, according to Brazil's General Data Protection Law (the LGPD, or Lei Geral de Proteção de Dados) No. 13,709 of August 14, 2018.")}</p>
              <p>
               {t("We do not share your personal data collected here with any other operator and/or controller, and will retain your personal data for the period of 2 years.")}
              </p>
            </small>
          </div>

          <button type="submit" className="contact-page-button-submit" disabled={IsSubmitting}>
            {t("Submit")}
          </button>
        </form>
      </Content>

    </ContactContainer>

  )
}

export default Contact
