import React, { useState, useEffect, useRef } from 'react';
import { useEvent } from '../../hooks/EventContext';
import { useTheme } from '../../hooks/ThemeContext';
import * as S from './style';
import firebase from 'firebase/app';
import moment from 'moment';
import { FaPlaneDeparture, FaPlaneArrival, FaPlane } from 'react-icons/fa';
import { GrMapLocation } from 'react-icons/gr';
import 'firebase/firestore';
import HeaderPageDescription from '../HeaderPageDescription';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/Auth2';

const firestore = firebase.firestore();
interface ILogistic {
  name: string;
  crm: string;
  email: string;
  sponsorshipType: string;
  status: string;
  goingOrigin?: string;
  goingDestiny: string;
  goingDate: string;
  goingCompany: string;
  goingDeparture: string;
  goingArrival: string;
  goingLoc: string;
  goingEtkt: string;
  returnOrigin?: string;
  returnDestiny: string;
  returnDate: string;
  returnCompany: string;
  returnDeparture: string;
  returnArrival: string;
  returnLoc: string;
  returnEtkt: string;
  accomodationCheckin: string;
  accomodationCheckout: string;
  transfer: string;
  hotelAccomodationName: string;
  hotelAccomodationAddress: string;
  cityAccomodationUf: string;
  cepAccomodation: string;
  phoneAccomodation: string;
  hotelEventName: string;
  hotelEventAddress: string;
  cityEventUf: string;
  cepEvent: string;
  phoneEvent: string;
  goingFlightNumber: number;
  goingSeatNumber: number;
  returnFlightNumber: number;
  returnSeatNumber: number;
  goingConnectionDate?: string;
  goingConnectionCompany?: string;
  goingConnectionFlightNumber?: string;
  goingConnectionSeatNumber?: string;
  goingConnectionOrigin?: string;
  goingConnectionDestiny?: string;
  goingConnectionDeparture?: string;
  goingConnectionArrival?: string;
  goingConnectionLoc?: string;
  goingConnectionEtkt?: string;
  returnConnectionDate?: string;
  returnConnectionCompany?: string;
  returnConnectionFlightNumber?: string;
  returnConnectionSeatNumber?: string;
  returnConnectionOrigin?: string;
  returnConnectionDestiny?: string;
  returnConnectionDeparture?: string;
  returnConnectionArrival?: string;
  returnConnectionLoc?: string;
  returnConnectionEtkt?: string;
}

const LogisticGrid: React.FC = (props: any) => {
  const { theme } = useTheme();
  const eventContext = useEvent();
  const { t } = useTranslation();
  const { getUser } = useAuth();
  const user = getUser();
  const [isMobile, SetIsMobile] = useState(
    window.matchMedia('(max-width: 992px)').matches,
  );
  const [logistics, setLogistics] = useState<ILogistic[]>([]);

  useEffect(() => {
    firestore
      .collection('logistic')
      .where('eventId', '==', eventContext.event.id)
      .onSnapshot((snap => {
        const logistic = snap.docs?.map((item) => ({
          ...(item.data() as any),
          id: item.id,
        }));
        setLogistics(logistic)
      }))
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => {
      SetIsMobile(window.matchMedia('(max-width: 992px)').matches);
    });
  });

  return (
    <>
      {isMobile && (
        <S.Container>
          <HeaderPageDescription
            title={`${t("Logistic")}`}
          />
          {logistics.map(logistic => (
            <>
              {logistic?.email === user?.email && (
                <S.FeedsContainerItems>
                  <div>
                    <S.Card theme={theme}
                      delay={0.0125}
                      key={`logistics-item-title`}>
                      <S.CardContent theme={theme}>
                        <S.CardInfo>
                          <S.CardInfoHeader>
                            <p style={{ color: `${eventContext?.event?.customization.buttonBackgroundColor}`, fontWeight: 'bold' }}>
                              {t("Flight Info")}
                            </p>
                            <S.CardTitle theme={theme}>{t("GOING")}</S.CardTitle>
                            <S.CardInfoText theme={theme}>
                              {logistic?.goingDate}
                            </S.CardInfoText>
                          </S.CardInfoHeader>
                          {/* <S.CardInfoText theme={theme} isCommentShown={arrayIds.includes(index)} lineClamp={arrayIds.includes(index)}> */}
                          <S.CardImages>
                            <div style={{ backgroundColor: `${eventContext?.event?.customization.buttonBackgroundColor}`, width: '71px', height: '71px', borderRadius: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                              <FaPlaneDeparture size={30} color="white" />
                              <span style={{ color: '#fff', fontSize: '12px' }}>
                                {t("Departure")}
                              </span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                              <FaPlane style={{ marginBottom: '-15px' }} />
                              <img src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ftakedagastroconectadacomomundo7%2Fcustomization%2FGrupo%2010112.svg?alt=media&token=1093a3dd-e8ee-43fc-8323-113ca7133f00" alt="" />
                            </div>
                            <div style={{ backgroundColor: `${eventContext?.event?.customization.buttonBackgroundColor}`, width: '71px', height: '71px', borderRadius: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                              <FaPlaneArrival size={30} color="white" />
                              <span style={{ color: '#fff', fontSize: '12px' }}>
                                {t("Arrival")}
                              </span>
                            </div>
                          </S.CardImages>
                          <S.CardTime theme={theme}>
                            <b style={{ fontSize: '13px' }}>{t("At")} {logistic?.goingDeparture}</b>
                            <div>
                              {/* <b>
                                Tempo de {t("Flight")}
                              </b>
                              <span>
                                0h00min
                              </span> */}
                            </div>
                            <b style={{ fontSize: '13px' }}>{t("At")} {logistic?.goingArrival}</b>
                          </S.CardTime>
                          <S.CardPlace>
                            <div>
                              <S.CardTitle theme={theme}>{logistic?.goingOrigin}</S.CardTitle>
                              {/* <span>Uberlãndia</span> */}
                            </div>
                            <div>
                              <S.CardTitle theme={theme}>{logistic?.goingDestiny}</S.CardTitle>
                              {/* <span>Guarulhos</span> */}
                            </div>
                          </S.CardPlace>
                        </S.CardInfo>
                        <div style={{ color: '#fff', width: '100%' }}></div>
                        <S.CardFooter theme={theme}>
                          {logistic?.goingDate && (
                            <div>
                              <b>{t("Date")}</b>
                              <span>{logistic?.goingDate}</span>
                            </div>
                          )}
                          {logistic?.goingFlightNumber && (
                            <div>
                              <b>{t("Flight")}</b>
                              <span>{logistic?.goingFlightNumber}</span>
                            </div>
                          )}
                          {logistic?.goingCompany && (
                            <div>
                              <b>{t("Flight Company")}</b>
                              <span>{logistic?.goingCompany}</span>
                            </div>
                          )}
                          {logistic?.goingSeatNumber && (
                            <div>
                              <b>{t("Seat Number")}</b>
                              <span>{logistic?.goingSeatNumber}</span>
                            </div>
                          )}
                        </S.CardFooter>
                        <S.CardFooter2 theme={theme}>
                          {logistic?.goingLoc && (
                            <div>
                              <b>{t("Locator")}</b>
                              <span>{logistic?.goingLoc}</span>
                            </div>
                          )}
                          {logistic.goingLoc && (
                            <div>
                              <b>{t("Electronic Ticket")}</b>
                              <span>{logistic?.goingEtkt}</span>
                            </div>
                          )}
                          <div>
                            {/* <b>Portão</b> */}
                            {/* <span>75c</span> */}
                          </div>
                        </S.CardFooter2>
                      </S.CardContent>
                    </S.Card>
                  </div>
                  {logistic?.goingConnectionDate && (
                    <div>
                      <S.Card theme={theme}
                        delay={0.0125}
                        key={`logistics-item-title`}>
                        <S.CardContent theme={theme}>
                          <S.CardInfo>
                            <S.CardInfoHeader>
                              <p style={{ color: `${eventContext?.event?.customization.buttonBackgroundColor}`, fontWeight: 'bold' }}>
                                {t("Flight Info")}
                              </p>
                              <S.CardTitle theme={theme}>{t("Connection")}</S.CardTitle>
                              <S.CardInfoText theme={theme}>
                                {logistic?.goingConnectionDate}
                              </S.CardInfoText>
                            </S.CardInfoHeader>
                            {/* <S.CardInfoText theme={theme} isCommentShown={arrayIds.includes(index)} lineClamp={arrayIds.includes(index)}> */}
                            <S.CardImages>
                              <div style={{ backgroundColor: `${eventContext?.event?.customization.buttonBackgroundColor}`, width: '71px', height: '71px', borderRadius: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <FaPlaneDeparture size={30} color="white" />
                                <span style={{ color: '#fff', fontSize: '12px' }}>
                                  {t("Departure")}
                                </span>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                                <FaPlane style={{ marginBottom: '-15px' }} />
                                <img src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ftakedagastroconectadacomomundo7%2Fcustomization%2FGrupo%2010112.svg?alt=media&token=1093a3dd-e8ee-43fc-8323-113ca7133f00" alt="" />
                              </div>
                              <div style={{ backgroundColor: `${eventContext?.event?.customization.buttonBackgroundColor}`, width: '71px', height: '71px', borderRadius: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <FaPlaneArrival size={30} color="white" />
                                <span style={{ color: '#fff', fontSize: '12px' }}>
                                  {t("Arrival")}
                                </span>
                              </div>
                            </S.CardImages>
                            <S.CardTime theme={theme}>
                              <b style={{ fontSize: '13px' }}>{t("At")} {logistic?.goingConnectionDeparture}</b>
                              <div>
                                {/* <b>
                              Tempo de {t("Flight")}
                            </b>
                            <span>
                              0h00min
                            </span> */}
                              </div>
                              <b style={{ fontSize: '13px' }}>{t("At")} {logistic?.goingConnectionArrival}</b>
                            </S.CardTime>
                            <S.CardPlace>
                              <div>
                                <S.CardTitle theme={theme}>{logistic?.goingConnectionOrigin}</S.CardTitle>
                                {/* <span>Uberlãndia</span> */}
                              </div>
                              <div>
                                <S.CardTitle theme={theme}>{logistic?.goingConnectionDestiny}</S.CardTitle>
                                {/* <span>Guarulhos</span> */}
                              </div>
                            </S.CardPlace>
                          </S.CardInfo>
                          <S.CardFooter theme={theme}>
                            <div>
                              <b>{t("Date")}</b>
                              <span>{logistic?.goingConnectionDate}</span>
                            </div>
                            <div>
                              <b>{t("Flight")}</b>
                              <span>{logistic?.goingConnectionFlightNumber}</span>
                            </div>
                            <div>
                              <b>{t("Flight Company")}</b>
                              <span>{logistic?.goingConnectionCompany}</span>
                            </div>
                            <div>
                              <b>{t("Seat Number")}</b>
                              <span>{logistic?.goingConnectionSeatNumber}</span>
                              {/* <b>Classe</b> */}
                              {/* <span>Business</span> */}
                            </div>
                          </S.CardFooter>
                          <S.CardFooter2 theme={theme}>
                          {logistic?.goingConnectionLoc && (
                            <div>
                              <b>{t("Locator")}</b>
                              <span>{logistic?.goingConnectionLoc}</span>
                            </div>
                          )}
                          {logistic.goingConnectionEtkt && (
                            <div>
                              <b>{t("Electronic Ticket")}</b>
                              <span>{logistic?.goingConnectionEtkt}</span>
                            </div>
                          )}
                            <div>
                              {/* <b>Portão</b> */}
                              {/* <span>75c</span> */}
                            </div>
                          </S.CardFooter2>
                        </S.CardContent>
                      </S.Card>
                    </div>
                  )}
                  <div>
                    <S.Card theme={theme}
                      delay={0.0125}
                      key={`logistics-item-title`}>
                      <S.CardContent theme={theme}>
                        <S.CardInfo>
                          <S.CardInfoHeader>
                            <p style={{ color: `${eventContext?.event?.customization.buttonBackgroundColor}`, fontWeight: 'bold' }}>
                              {t("Flight Info")}
                            </p>
                            <S.CardTitle theme={theme}>{t("RETURN")}</S.CardTitle>
                            <S.CardInfoText theme={theme}>
                              {logistic?.returnDate}
                            </S.CardInfoText>
                          </S.CardInfoHeader>
                          {/* <S.CardInfoText theme={theme} isCommentShown={arrayIds.includes(index)} lineClamp={arrayIds.includes(index)}> */}
                          <S.CardImages>
                            <div style={{ backgroundColor: `${eventContext?.event?.customization.buttonBackgroundColor}`, width: '71px', height: '71px', borderRadius: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                              <FaPlaneDeparture size={30} color="white" />
                              <span style={{ color: '#fff', fontSize: '12px' }}>
                                {t("Departure")}
                              </span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                              <FaPlane style={{ marginBottom: '-15px' }} />
                              <img src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ftakedagastroconectadacomomundo7%2Fcustomization%2FGrupo%2010112.svg?alt=media&token=1093a3dd-e8ee-43fc-8323-113ca7133f00" alt="" />
                            </div>
                            <div style={{ backgroundColor: `${eventContext?.event?.customization.buttonBackgroundColor}`, width: '71px', height: '71px', borderRadius: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                              <FaPlaneArrival size={30} color="white" />
                              <span style={{ color: '#fff', fontSize: '12px' }}>
                                {t("Arrival")}
                              </span>
                            </div>
                          </S.CardImages>
                          <S.CardTime theme={theme}>
                            <b style={{ fontSize: '13px' }}>{t("At")} {logistic?.returnDeparture}</b>
                            <div>
                              {/* <b>
                                Tempo de {t("Flight")}
                              </b>
                              <span>
                                0h00min
                              </span> */}
                            </div>
                            <b style={{ fontSize: '13px' }}>{t("At")} {logistic?.returnArrival}</b>
                          </S.CardTime>
                          <S.CardPlace>
                            <div>
                              <S.CardTitle theme={theme}>{logistic?.returnOrigin}</S.CardTitle>
                              {/* <span>Uberlãndia</span> */}
                            </div>
                            <div>
                              <S.CardTitle theme={theme}>{logistic?.returnDestiny}</S.CardTitle>
                              {/* <span>Guarulhos</span> */}
                            </div>
                          </S.CardPlace>
                        </S.CardInfo>
                        <S.CardFooter theme={theme}>
                          {logistic?.returnDate && (
                            <div>
                              <b>{t("Date")}</b>
                              <span>{logistic?.returnDate}</span>
                            </div>
                          )}
                          {logistic?.returnFlightNumber && (
                            <div>
                              <b>{t("Flight")}</b>
                              <span>{logistic?.returnFlightNumber}</span>
                            </div>
                          )}
                          {logistic?.returnCompany && (
                            <div>
                              <b>{t("Flight Company")}</b>
                              <span>{logistic?.returnCompany}</span>
                            </div>
                          )}
                          {logistic?.returnSeatNumber && (
                            <div>
                              <b>{t("Seat Number")}</b>
                              <span>{logistic?.returnSeatNumber}</span>
                            </div>
                          )}
                        </S.CardFooter>
                        <S.CardFooter2 theme={theme}>
                          {logistic?.returnLoc && (
                              <div>
                                <b>{t("Locator")}</b>
                                <span>{logistic?.returnLoc}</span>
                              </div>
                            )}
                            {logistic.returnEtkt && (
                              <div>
                                <b>{t("Electronic Ticket")}</b>
                                <span>{logistic?.returnEtkt}</span>
                              </div>
                            )}
                          <div>
                            {/* <b>Portão</b> */}
                            {/* <span>75c</span> */}
                          </div>
                        </S.CardFooter2>
                      </S.CardContent>
                    </S.Card>
                  </div>
                  {logistic?.returnConnectionDate && (
                    <div>
                      <S.Card theme={theme}
                        delay={0.0125}
                        key={`logistics-item-title`}>
                        <S.CardContent theme={theme}>
                          <S.CardInfo>
                            <S.CardInfoHeader>
                              <p style={{ color: `${eventContext?.event?.customization.buttonBackgroundColor}`, fontWeight: 'bold' }}>
                                {t("Flight Info")}
                              </p>
                              <S.CardTitle theme={theme}>{t("Connection")}</S.CardTitle>
                              <S.CardInfoText theme={theme}>
                                {logistic?.returnConnectionDate}
                              </S.CardInfoText>
                            </S.CardInfoHeader>
                            {/* <S.CardInfoText theme={theme} isCommentShown={arrayIds.includes(index)} lineClamp={arrayIds.includes(index)}> */}
                            <S.CardImages>
                              <div style={{ backgroundColor: `${eventContext?.event?.customization.buttonBackgroundColor}`, width: '71px', height: '71px', borderRadius: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <FaPlaneDeparture size={30} color="white" />
                                <span style={{ color: '#fff', fontSize: '12px' }}>
                                  {t("Departure")}
                                </span>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                                <FaPlane style={{ marginBottom: '-15px' }} />
                                <img src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ftakedagastroconectadacomomundo7%2Fcustomization%2FGrupo%2010112.svg?alt=media&token=1093a3dd-e8ee-43fc-8323-113ca7133f00" alt="" />
                              </div>
                              <div style={{ backgroundColor: `${eventContext?.event?.customization.buttonBackgroundColor}`, width: '71px', height: '71px', borderRadius: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <FaPlaneArrival size={30} color="white" />
                                <span style={{ color: '#fff', fontSize: '12px' }}>
                                  {t("Arrival")}
                                </span>
                              </div>
                            </S.CardImages>
                            <S.CardTime theme={theme}>
                              <b style={{ fontSize: '13px' }}>{t("At")} {logistic?.returnConnectionDeparture}</b>
                              <div>
                                {/* <b>
                              Tempo de {t("Flight")}
                            </b>
                            <span>
                              0h00min
                            </span> */}
                              </div>
                              <b style={{ fontSize: '13px' }}>{t("At")} {logistic?.returnConnectionArrival}</b>
                            </S.CardTime>
                            <S.CardPlace>
                              <div>
                                <S.CardTitle theme={theme}>{logistic?.returnConnectionOrigin}</S.CardTitle>
                                {/* <span>Uberlãndia</span> */}
                              </div>
                              <div>
                                <S.CardTitle theme={theme}>{logistic?.returnConnectionDestiny}</S.CardTitle>
                                {/* <span>Guarulhos</span> */}
                              </div>
                            </S.CardPlace>
                          </S.CardInfo>
                          <S.CardFooter theme={theme}>
                            {logistic?.returnConnectionDate && (
                              <div>
                                <b>{t("Date")}</b>
                                <span>{logistic?.returnConnectionDate}</span>
                              </div>
                            )}
                            {logistic?.returnConnectionFlightNumber && (
                              <div>
                                <b>{t("Flight")}</b>
                                <span>{logistic?.returnConnectionFlightNumber}</span>
                              </div>
                            )}
                            {logistic?.returnConnectionCompany && (
                              <div>
                                <b>{t("Flight Company")}</b>
                                <span>{logistic?.returnConnectionCompany}</span>
                              </div>
                            )}
                            {logistic?.returnConnectionSeatNumber && (
                              <div>
                                <b>{t("Seat Number")}</b>
                                <span>{logistic?.returnConnectionSeatNumber}</span>
                              {/* <b>Classe</b> */}
                              {/* <span>Business</span> */}
                              </div>
                            )}
                          </S.CardFooter>
                          <S.CardFooter2 theme={theme}>
                            {logistic?.returnConnectionLoc && (
                                <div>
                                  <b>{t("Locator")}</b>
                                  <span>{logistic?.returnConnectionLoc}</span>
                                </div>
                              )}
                              {logistic.returnConnectionEtkt && (
                                <div>
                                  <b>{t("Electronic Ticket")}</b>
                                  <span>{logistic?.returnConnectionEtkt}</span>
                                </div>
                              )}
                            <div>
                              {/* <b>Portão</b> */}
                              {/* <span>75c</span> */}
                            </div>
                          </S.CardFooter2>
                        </S.CardContent>
                      </S.Card>
                    </div>
                  )}
                  <div>
                    <S.Card theme={theme}
                      delay={0.0125}
                      key={`logistics-item-title`}>
                      <S.CardContent theme={theme}>
                        <S.CardInfo>
                          <S.CardImageContainer>
                            <S.CardImage alt="400x220" src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ftakedagastroconectadacomomundo7%2Fcustomization%2FRet%C3%A2ngulo%203424.png?alt=media&token=614fccce-378f-47f3-9e67-64bdada01109" />
                          </S.CardImageContainer>
                          <S.CardInfoHeader>
                            <p style={{ color: `${eventContext?.event?.customization.buttonBackgroundColor}`, fontWeight: 'bold' }}>
                              {t("Hotel Information")}
                            </p>
                            <S.CardInfoText style={{ fontWeight: 'bold' }} theme={theme}>{logistic.hotelAccomodationName}</S.CardInfoText>
                            {"\n"}
                            <S.CardInfoText2 theme={theme}>
                              {logistic?.hotelAccomodationAddress}, {logistic?.cityAccomodationUf} - CEP {logistic?.cepAccomodation}
                            </S.CardInfoText2>
                            <S.CardInfoText2 theme={theme}>
                              ${t("Phone")}: {logistic?.phoneAccomodation}
                            </S.CardInfoText2>
                          </S.CardInfoHeader>
                          {/* https://www.google.com/maps/dir/?api=1&destination=Av. Royal Palm Plaza, 277 */}
                          <S.MapButton customization={eventContext?.event?.customization.buttonBackgroundColor} onClick={() => { window.open(`https://www.google.com/maps/dir/?api=1&destination=${logistic.hotelEventAddress}`, '_blank') }}>
                            <span>{t("How to get there")}</span>
                            <GrMapLocation className='icon' size={15} />
                          </S.MapButton>
                          <div>
                            <p><b>Check-in:</b> {t("Starting at")} {logistic?.accomodationCheckin}</p>
                            <p><b>Check-out:</b> {t("Starting at")} {logistic?.accomodationCheckout}</p>
                            <b>{t("Transfer Service")}</b> <br></br>
                            <i>{logistic?.transfer}</i>
                          </div>
                        </S.CardInfo>
                      </S.CardContent>
                    </S.Card>
                  </div>
                  <div>
                    <S.Card theme={theme}
                      delay={0.0125}
                      key={`logistics-item-title`}>
                      <S.CardContent theme={theme}>
                        <S.CardInfo>
                          <S.CardImageContainer>
                            <S.CardImage alt="400x220" src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ftakedagastroconectadacomomundo7%2Fcustomization%2FRet%C3%A2ngulo%203422.png?alt=media&token=4b491717-3581-4210-bde8-928095b30073" />
                          </S.CardImageContainer>
                          <S.CardInfoHeader>
                            <p style={{ color: `${eventContext?.event?.customization.buttonBackgroundColor}`, fontWeight: 'bold' }}>
                              {t("Local event info")}
                            </p>
                            <S.CardInfoText style={{ fontWeight: 'bold' }} theme={theme}>{logistic?.hotelEventName}</S.CardInfoText>
                            {"\n"}
                            <S.CardInfoText2 theme={theme}>
                              {logistic?.hotelEventAddress}, {logistic?.cityEventUf} - CEP {logistic?.cepEvent}
                            </S.CardInfoText2>
                            <S.CardInfoText2 theme={theme}>
                              {t("Phone")}: {logistic?.phoneEvent}
                            </S.CardInfoText2>
                          </S.CardInfoHeader>
                          <S.MapButton customization={eventContext?.event?.customization.buttonBackgroundColor} onClick={() => { window.open(`https://www.google.com/maps/dir/?api=1&destination=${logistic.hotelEventAddress}`, '_blank') }}>
                            <span>{t("How to get there")}</span>
                            <GrMapLocation className='icon' size={15} />
                          </S.MapButton>
                        </S.CardInfo>
                      </S.CardContent>
                    </S.Card>
                  </div>
                </S.FeedsContainerItems>
              )}
            </>
          ))}
        </S.Container>
      )}
    </>
  );
};
export default LogisticGrid;
