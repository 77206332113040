import styled from 'styled-components';
import { shade } from 'polished';
import { ContainerTabs } from '../ContainerTabs';

export const Container = styled(ContainerTabs)`
  .sessions-users-text-italic{
    font-size: 12px;
    font-style: italic;
    margin-bottom:24px;
    color:#424242;
  }
  .accordion{
    margin-bottom: 2rem;
  }
`;

export const Form = styled.form`
  background: none;
  border:0;
  margin-bottom:4px;
  input{
    background: #ffffff;
    border-radius: 5px;
    padding: 0 16px;
    width: 100%;
    height: 50px;
    border: 2px solid #EBEBEB;
    flex: 1;
    color: #6d6d6d;
    box-shadow: 0 0 0 30px white inset !important;
    outline: none;
    font-weight: 400;
    &::placeholder{
      color: #B9B9B9;
    }
  }
`;
export const NotFoundUsers = styled.div`
padding: 1rem;
 p{
  font-size: 14px;
    margin-bottom:24px;
 }
`

export const Table = styled.table`
  width:100%;
  overflow:auto;
  border:1px solid #EBEBEB;
  border-radius: 3px;
  border-collapse: separate;
  border-spacing: 2px;
  display: flex;
  th, td {
    border: none;
  }
}
  tbody{
    color:#4e4e4e;
    width: 100%;
  tr{
    &:nth-child(odd){
      background: #f1f1f1;
    }
    td:first-child{
        width: fit-content;
        padding: 0 1.25rem;
    }
    td:last-child{
    width: 100%;
    max-width: initial;
    }
    td {
    color: inherit;
    font-weight: inherit;
    vertical-align: middle;
    padding: 4px 2rem 4px 1rem;
    width: fit-content;
    flex:1;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: .88rem;
}
  }
  tr:first-child {
    th:first-child{
      padding: 0 2rem 0 1rem;
    }
    th {
      background: #f7f7f7;
      /* border:2px solid #fff; */
      padding: 0 2rem 0 1rem;
      height: 50px;
      overflow: hidden;
      font-weight: 500;
      color: #616161;
    }
  }
  * {
    white-space: nowrap;
  }
  .dropdown{
    position: initial;
    padding: 0 4px;
    & > button{
      width: 100%;
      max-width: 200px;
    }
  }
`;
