import React, { useEffect } from 'react';
import { MainContainer2Column } from '../../components/Ui/MainContainer2Column';

import SurveyGrid from '../../components/SurveyGrid';
import SideTabMenu from '../../components/SideTabMenu';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../hooks/EventContext';
import { useAuth } from '../../hooks/Auth2';
import DashboardMasterPage2 from '../../masterpages/DashboardMasterPage2';

const Surveys: React.FC<any> = (props) => {
  const { event: eventKey, channel: channelKey } =
    useParams<{ channel: string; event: string }>();
  const { event, channel, ...eventContext } = useEvent();
  const [modalContent, setModalContent] = React.useState(false);
  const { getUser } = useAuth();
  const user = getUser();

  function handleModal(): void {
    setModalContent(!modalContent);
  }
  useEffect(() => {
    if (eventKey && channelKey) eventContext.loadInfo(eventKey, channelKey);
  }, [eventKey, channelKey]);
  return (
    <DashboardMasterPage2 {...props}>
      <MainContainer2Column>
        <SurveyGrid
          user={user}
          handleModal={handleModal}
        />
        <div
        // className="features-position__sticky"
        // Descomentar classe quando <SideTabMenu /> for ser usado
        >
          {/* <SideTabMenu /> */}
        </div>
      </MainContainer2Column>
    </DashboardMasterPage2>
  );
};
export default Surveys;
