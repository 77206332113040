import styled, { css } from 'styled-components';

interface ICustomization {
  fontColor?: string;
}
interface IProps {
  customization?: any
}
export const Container = styled.div<IProps>`
  /* background: ${props => props.theme.colors.background}; */

  ${(props) => css`
  background: linear-gradient(
        -45deg,
        ${props.customization?.headerBgColor1 || 'rgba(0,0,0, 0.2)'} 20%,
        ${props.customization?.headerBgColor2 || 'rgba(0,0,0, 0.2)'} 80%
      );
`}
  background: ${props => props.theme.title === 'dark' && props.theme.colors.background};

  border-radius: var(--border-radius-default);
  padding: 1.25rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap:4px;
`;

export const Title = styled.h2<ICustomization>`${({ fontColor }) => css`
  color: ${fontColor ? fontColor : '#fff'};
  font-size: 1.75rem;
  font-family: var(--fontFamily);
  margin: 0;
  padding: 0;
  font-weight: 400;
`}`

export const Description = styled.p<ICustomization>`${({ fontColor }) => css`
  color: ${fontColor ? fontColor : '#fff'};
  font-size: 1rem;
  font-family: var(--fontFamily);
  margin: 0;
  padding: 0;
  font-weight: 400;
`}`
