import { animation } from 'polished';
import styled, { css, keyframes } from 'styled-components';

interface IButtonSetDateShedule {
  isActive: boolean;
  customization:any;
}
interface ICardSchedule {
  delay: number;
}

const show = keyframes`
to{
  transform: initial;
  opacity: initial;
}
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap:var(--grid-gap-default);
`;

export const ContainerButtonSetDate = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  z-index: 1;
`;

export const ButtonSetDateShedule = styled.button<IButtonSetDateShedule>`
  border: 0;
  border-radius: 6px;
  background: ${props => props.customization.buttonBackgroundColor};
  /* opacity: .9; */
  min-width: 100px;
  padding: 1rem 2rem;
  width: 100%;
  max-width: fit-content;
  transition: all 0.2s;
  box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.15);
  z-index: 2;

  font-size: 0.88rem;
  text-transform: uppercase;
  line-height: 1;
  color: #b9b9b9;
  transition: all 0.2s;
  white-space: nowrap;

  &:hover {
    opacity: 1;
    box-shadow: 0 0px 20px 4px rgba(0, 0, 0, 0.15);
    z-index: 0;
    color: #8d8d8d;
  }
  ${(props) =>
    props.isActive &&
    css`
      background: ${props.customization.buttonBackgroundColor};
      opacity: 1;

      color: #fff;
      &:hover {
        color: #fff;
      }
    `}

  @media (max-width: 768px) {
    flex: 1;
    max-width: initial;
  }
`;

export const ContainerSchedule = styled.div`
    line-break: auto;
`;

export const CardScheduleGroup = styled.div`
  /* display: flex;
  flex-direction: column;
  gap: 16px; */
`;

export const CardSchedule = styled.div<ICardSchedule>`
  background: ${props => props.theme.colors.primary};
  border-radius: 10px;
  height: 100%;
  min-height: 150px;
  /* padding: 2rem; */
  display: grid;
  grid-template-columns: auto minmax(110px, auto) 1fr;
  grid-template-areas: 'speakerDate imageSpeaker contentSpeaker';
  align-items: center;
  justify-content: center;
  gap: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;

  @media not all and (min-resolution:.001dpcm) {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0px;
  align-items: initial;
}

  ${({ delay }) =>
    css`
      opacity: 0;
      transform: translateX(-8px);

      animation: ${show} 0.2s forwards cubic-bezier(0.33, 0.41, 0.44, 0.9);
      animation-delay: ${delay}s;
    `}

  @media (max-width: 1080px) {
    grid-template-columns: minmax(40px, auto) 1fr;
    grid-template-areas: 'speakerDate imageSpeaker' 'speakerDate contentSpeaker';
    justify-items: flex-start;
  }
  @media (max-width: 768px) {
    grid-template-columns: auto 1fr;
    grid-template-areas: 'speakerDate speakerDate' 'imageSpeaker contentSpeaker';
    justify-items: flex-start;
    gap: 0;
  }

  @media (max-width: 512px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'speakerDate' 'imageSpeaker' 'contentSpeaker';
  }
`;

export const CardDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2rem;
  box-shadow: 0px -13px 24px #0000001c;
  grid-area: speakerDate;
  border-radius: 0 10px 10px 0;
  position: relative;

  svg {
    transform: translateY(calc(-100% - 18px));
    height: 26px;
    fill: #B9B9B9;
    border-radius: 100px;
    position: absolute;
  }
  span {
    color: ${props => props.theme.colors.text};
    font-weight: 500;
    font-size: 1.5rem;
  }
  @media (max-width: 1080px) {
    padding: 2rem;
  }
  @media (max-width: 768px) {
    padding: 2rem;
    border-radius: 0 0 10px 10px;
    box-shadow: 12px 0px 24px #0000001c;
    svg{
      transform: initial;
      position: initial;
      margin-bottom: 4px;
    }
    span {
      font-size: 1.25rem;
    }
  }
  @media (max-width: 512px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 8px;
  }
`;

export const CardSpeakerImage = styled.div`

  padding: 1rem;
  grid-area: imageSpeaker;

  img {
    border: 12px solid #EBEBEB;
    height: 110px;
    width: 110px;
    border-radius: 100px;
    object-fit: cover;

  }

  @media (max-width: 1080px){
    padding: 2rem 1.25rem 0px;
  }
  @media (max-width: 768px) {
    padding: 1rem 1.25rem 1.5rem;
  }
`;

export const CardContent = styled.div`
  padding: 2rem 1rem 2rem 0;
  grid-area: contentSpeaker;
  display: flex;
  flex-direction: column;
  gap:8px;

  @media (max-width: 1080px) {
    padding: 0 2rem 2rem;
  }
  @media (max-width: 768px) {
    padding: 2rem;
  }
  @media (max-width: 512px) {
    padding: 0 2rem 2rem;
    line-break: auto;
  }
`;

export const CardHeader = styled.div`
`;

export const CardTitle = styled.div`
  font-size: 1.125rem;
  color: ${props => props.theme.colors.text};
  font-weight: 500;
  line-height: 1;
`;

export const CardProfission = styled.div`
  margin-top:2px;
  font-size: 0.88rem;
  font-weight: 300;
  color: #c1c1c1;
  line-height: 1;
`;

export const CardSubtitle = styled.div``;

export const CardText = styled.div`
  h1,h2,h3,h4,h5,h6{
    font-weight: 500;
    margin-bottom: 4px;
    color: ${props => props.theme.colors.askText};
  }
  h1 {
    font-size: 1.75rem;
  }
  h2, h3 {
    font-size: 1.5rem;
  }
  h4,
  h5,
  h6 {
    font-size: 1rem;
  }
  p {
    font-size: 0.88rem;
    color: ${props => props.theme.colors.askText};
    margin-bottom: 4px;
    &:last-child {
      margin: 0;
    }
  }
`;
