import styled, { css, keyframes } from 'styled-components';

interface ICluesItem {
  delay: number;
}
interface IButton {
  isComplete: boolean;
}

interface descriptionItem {
  isCommentShown?: boolean;
  lineClamp?: boolean;
}

interface IButtonSetDateShedule {
  isActive: boolean;
  customization:any;
}

interface ContainerPros {
  customization: any;
  theme:any;
}
interface IAsk {
  customization:any;
}

export const ContainerQuestion = styled.div<ContainerPros>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .question-fieldset{
    width: 100%;
    min-height:412px;
    max-width: 400px;
    min-width: 400px;
    padding: 15px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    user-select: none;

    transform: scale(.95);
    /* opacity: 0; */
    background: ${props => props.theme.colors.grayColor};

  @media(max-width:764px){
    min-width: 340px;
  }
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgb(180, 180, 180);
    }
  }
  .enquete {
    display:flex;
  }

.question-form-item-container{
  margin-bottom: 20px
}

.question-form{
  display: flex;
  width: calc(100% - 1rem);
  margin:0 auto;
  padding: 1rem 0;

  .question-form-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    gap:.5rem
  }

  .question-form-content{
    display:flex;
    flex-direction: column;
    gap:6px
  }

  .question-form-title{
    text-align: left;
    font-size: 1.25rem;
    font-weight: 500;
    color: ${props => props.theme.colors.text};
  }

  .question-form-results-container{
    display: flex;
    flex-direction: column;

    .question-form-item-label-result{
      display: flex;
      flex-direction: column;
      margin:0;
      color:#606060;
      font-size: .88rem;
    }
}

  .question-form-item-label{
    color:#606060;
    font-size: 1rem;
    display: grid;
    grid-template-columns:auto auto;
    align-items: flex-start;
    justify-content: flex-start;
    gap:8px;
    margin:0;
    position: relative;
    cursor: pointer;

    input{
      margin:0 8px;

      display:flex;
      align-items: center;
      justify-content: center;

      opacity: 0;
      position: absolute;
      top:0;
      left:0;

      p{
        color: ${props => props.theme.colors.text};
      }

      &:checked ~ .question-form-item-checkmark{
        transition: all .5s;
        background: ${props => props.customization.buttonBackgroundColor};
        transform: initial;
        filter: hue-rotate(0deg);
        border:none
      }
      &:checked ~ .question-form-item-checkmark > .survey-icon{
        opacity: 1;
        transition: all .3s;
        transform: rotate(0deg);
        transform: scale(1.5);
      }
    }
    .question-form-item-checkmark{
      display:flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #D0D0D0;
      color:#fff;
      font-size: 12px;
      width: 40px;
      height: 40px;
      background: #fff;
      filter: hue-rotate(100deg);
      border-radius: 10px;
      /* overflow: hidden; */

      .survey-icon{
        opacity: 0;
        transition: all .1s;
        transform: scale(1.5) rotate(-90deg);
      }
    }
    &:hover .question-form-item-checkmark{
      filter: brightness(90%);
    }
     p{
       color: ${props => props.theme.colors.text} !important ;
     }
  }

  .question-form-btn-send{
    width: 100%;
    border-radius: 24px;
    padding: 0 1.5rem;
    margin-top: 24px;
    height: 44px;
    border:0;
    /* background: linear-gradient(90deg, #12C3FF 20%, #4269FF  100%); */
    background: ${props => props.customization.buttonBackgroundColor};
    background-repeat: no-repeat;
    background-size: 150%;
    background-position-x:-0%;
    display:flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in;

    p{
      flex:1;
      text-align: center;
      margin:0;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1rem;
      color: ${props => props.customization.buttonTextColor};
    }
    &:hover{
      transition: all .2s ease-out;
      background-position-x: 100%;
    }
}

}

.question-speaker-form{
  display: flex;
  flex-direction: column;
  .question-speaker-form-inputs{
    display: flex;
    flex-direction: column;
    gap:16px;
    .question-speaker-form-label {
      width: 100%;
      min-height: 45px;
      border:1px solid #808080;
      text-align: center;
      border-radius:10px;

      display:flex;
      align-items: center;
      justify-content: center;

      *{
        width: 100%;
        height: inherit;
        background: transparent;
        border:0;
        outline: none;
        color:#808080;
        padding: .5rem;
      }
      select{
        position: relative;
      }
      textarea{
        padding: 1rem;
        min-height: 220px;
        max-height: 200px;
      }

      &.question-speaker-form-label-select{
      border-radius:25px;
      padding: 0 1.5rem;
        &::before{
          content: '';
          position: absolute;
          width: 24px;
          height: 24px;
        }
      }

    }
  }

  .question-speaker-btn-send{
    width: 100%;
    border-radius: 24px;
    padding: 0 1.5rem;
    margin-top: 24px;
    height: 44px;
    border:0;
    /* background: linear-gradient(90deg, #12C3FF 20%, #4269FF  100%); */
    background: ${props => props.customization.buttonBackgroundColor};
    background-repeat: no-repeat;
    background-size: 150%;
    background-position-x:-0%;
    display:flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in;
      p{
        flex:1;
        text-align: center;
        margin:0;
        text-transform: uppercase;
        font-weight: 600;
        font-size: .88rem;
        color: ${props => props.customization.buttonTextColor};
      }
      &:hover{
        transition: all .2s ease-out;
        background-position-x: 100%;
      }
  }
}
`;

const show = keyframes`
to{
  transform: initial;
  opacity: initial;
}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const QuestionContainerItems = styled.div<IAsk>`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;

  .container-question-btn{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    /* margin-bottom: 60px; */
  }
  .container-question-btn p{
    margin: auto;
  }

  .question-form-btn-send{
    color: ${props => props.customization.buttonTextColor || '#FFF'};
    background-color: ${props => props.customization.buttonBackgroundColor || '#000'};;
    width: 281px;
    height: 50px;
    display: flex;
    border-color: transparent !important;
}

  @media (max-width: 768px) {
    .clues-grid {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }
`;

export const Card = styled.div<ContainerPros>`
  width: 100%;
  min-height:412px;
  max-width: 400px;
  min-width: 400px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  user-select: none;

  transform: scale(.95);
  opacity: 0;
  animation: ${show} .2s forwards;
  background: ${props => props.theme.colors.grayColor};

  @media(max-width:764px){
    min-width: 340px;
  }
`;

export const CardImageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 220px;
  display: flex;
  position: relative;
`;
export const CardImage = styled.img`
  width: 100%;
  height: 220px;
  max-height: 220px;
  object-fit: cover;
  border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
  margin-top: 16px;
  opacity: 0;
  animation: ${show} .2s forwards;

  @media(max-width:764px){
    height: initial;
  }
`;
export const ClueBanner = styled.img`
  width: 107%;
  /* width: 100%; */
  height: 220px;
  max-height: 220px;
  object-fit: cover;
  /* border-radius: 0 0 var(--border-radius-default) var(--border-radius-default); */
  margin-top: -9px !important;
  margin: 0 -12px;
  opacity: 0;
  animation: ${show} .2s forwards;

  @media(max-width:764px){
    height: initial;
  }
`;

export const CardImageIcons = styled.div`
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  padding: .75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .card-channel-icon-live{
    font-family: var(--fontFamily);
    font-weight: 400;
    font-size: 10px;
    background: #02C90F;
    text-transform: uppercase;
    color:#fff;
    border-radius: 20px;
    line-height: 1;
    padding: 4px 14px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-right: 10px;
  }
  svg path{
    height: 16px;
    fill:#fff;
    color:#fff;
  }
`;



// ---------- Content Card

export const CardClue = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem 1.75rem;
  flex-direction: column;
  gap:20px;
  position: relative;

  opacity: 0;
  animation: ${show} .2s forwards;
  animation-delay: .1s;
`;

export const CardSpeakers = styled.div`
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap:12px;
  transform: translateY(-50%);
  padding: 0 1.25rem;

  img{
    width: 100%;
    height: 100%;
    max-width: 54px;
    max-height: 54px;
    object-position: contain;
    border-radius: 30px;
    filter: drop-shadow(0px 0px 5px rgb(0 0 0 / 10%));
  }
  /* margin-bottom: 50%; */
`;

export const CardInfoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
`;
export const CardTitle = styled.h2`
  font-family: var(--fontFamily);
  font-weight: 500;
  color:${props => props.theme.colors.text};
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
`;

export const CardInfoText = styled.p<descriptionItem>`
    font-family: var(--fontFamily);
    font-weight: 400;
    color:${props => props.theme.colors.paragraph};
    font-size: 14px;
    flex:1;
    height: 100%;
    line-height: 1.25rem;
    max-height: 5rem;
    overflow:  ${props => props.isCommentShown ? 'inherit' : 'hidden'};
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.lineClamp ? 0 : 4};
    -webkit-box-orient: vertical;
    margin-bottom: 0;
`;
// ---------- Footer
export const CardFooter = styled.div`
  display: flex;
  border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
  align-items: center;
  justify-content: space-between;
  padding: 20px 26px 12px 26px;
  /* box-shadow: 0px -13px 24px #0000001C; */
  /* margin-top: 1.5rem; */
  background: ${props => props.theme.colors.primary};
`;

export const CalendarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 27% ;
  /* width: 38% ; */
  color:#B9B9B9;
  font-family: var(--fontFamily);
  font-size: 12px;
  font-weight: 400;
  /* padding: 8px 22px; */
  padding: 8px 0px;
  letter-spacing: 1px;
  transition: all .2s;
`;

export const ConteinerSeeMore = styled.div`
  display: flex;
  align-items: center;
  gap:2px;
  button.card-channels-item-icon{
    padding: 4px 4px 4px 8px;
    height: calc(18px + 8px); // Height + padding
    border:0;
    background: none;
    display: flex;
    align-items: baseline;
    gap:4px;
    color:#B9B9B9;
    font-size: 12px;
    cursor: pointer;
    &:hover{
      color:rgba(2, 2, 2, 0.8);
    }
  }
  svg, svg path{
    width: 18px;
    height: 18px;
    fill:#B9B9B9;
    transition: all .2s;
  }
    .icon-heart:hover path{
      fill:rgba(230, 42, 89, 0.8);

    }
    .icon-people:hover path{
      fill:rgba(2, 2, 2, 0.8);
    }
`;
export const ShowMoreButton = styled.div<IButton>`
  display: ${props => props.isComplete ? 'none': 'flex'};
  background: transparent;
  border: 1px solid #fff;
  width: 171px;
  height: 41px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  span{
    color: #fff;
    margin-left: 6px;
  }
`
export const ContainerButtonSetDate = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  z-index: 1;
`;

export const ButtonSetDateShedule = styled.button<IButtonSetDateShedule>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 6px;
  background: ${props => props.customization.buttonBackgroundColor};
  /* opacity: .9; */
  min-width: 100px;
  padding: 1rem 2rem;
  width: 100%;
  max-width: fit-content;
  transition: all 0.2s;
  box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.15);
  z-index: 2;

  font-size: 0.88rem;
  text-transform: uppercase;
  line-height: 1;
  color: #b9b9b9;
  transition: all 0.2s;
  white-space: nowrap;

  &:hover {
    opacity: 1;
    box-shadow: 0 0px 20px 4px rgba(0, 0, 0, 0.15);
    z-index: 0;
    color: #8d8d8d;
  }
  ${(props) =>
    props.isActive &&
    css`
      background: ${props.customization.buttonBackgroundColor};
      opacity: 1;

      color: #fff;
      &:hover {
        color: #fff;
      }
    `}

  @media (max-width: 768px) {
    flex: 1;
    max-width: initial;
  }
`;
