import React, { useEffect } from 'react';
import { MainContainer2Column } from '../../components/Ui/MainContainer2Column';

import TipGrid from '../../components/TipGrid';
import SideTabMenu from '../../components/SideTabMenu';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../hooks/EventContext';
import { useAuth } from '../../hooks/Auth2';
import DashboardMasterPage2 from '../../masterpages/DashboardMasterPage2';
import ModalTabs from '../../components/NewsFeatures/ModalTabs';
import  * as S from './style';
import HeaderPageDescription from '../../components/HeaderPageDescription';

const SatisfactionSearch: React.FC<any> = (props) => {
  const { event: eventKey, channel: channelKey } =
    useParams<{ channel: string; event: string }>();
  const { event, channel, ...eventContext } = useEvent();
  const { t } = useTranslation();

  useEffect(() => {
    if (eventKey && channelKey) eventContext.loadInfo(eventKey, channelKey);
  }, [eventKey, channelKey]);
  return (
    <DashboardMasterPage2 {...props}>
      <MainContainer2Column>
      <S.Container>
          <S.SearchContainerItems>
            <HeaderPageDescription title={`${t("Satisfaction Searchs")}`}/>
          </S.SearchContainerItems>
          <iframe src={event?.settings?.helpdesk_languages?.search || ""}
        className="jmvplayer" allow="autoplay; fullscreen" frameBorder={0} width="100%" height="100%"/>
      </S.Container>

      </MainContainer2Column>
    </DashboardMasterPage2>
  );
};
export default SatisfactionSearch;
