import React, { useEffect } from 'react';
import firebase from 'firebase';
import { Schedule } from '../../../types/Schedule';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Container, ScheduleItem } from './styles';
import { IconsList } from '../../Icons';
import { useSpeaker } from '../../../hooks/SpeakersContext';

// import { Container } from './styles';

type ScheduleProps = {
  event_id: string;
  channel_id: string;
  speakers: any;
};

const firestore = firebase.firestore();

const ScheduleView: React.FC<ScheduleProps> = ({ channel_id }) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [schedule, setSchedule] = React.useState<Partial<Schedule>>({});
  const { speakers } = useSpeaker();
  const programation = React.useMemo(() => {
    const program = schedule.programation_language?.filter(
      (p) => p.status === 'enabled',
    );

    return program?.sort((a, b) => Number(a.order) - Number(b.order));
  }, [schedule]);
  const speakersObj = React.useMemo(() => {
    const obj: any = {};

    speakers.forEach((speaker: any) => {
      obj[speaker.id] = speaker;
    });

    return obj;
  }, [speakers]);

  useEffect(() => {
    let unsubscribe: any = null;

    if (channel_id) {
      console.log('channel_id', channel_id);

      unsubscribe = firebase
        .firestore()
        .collection('schedule')
        .doc(channel_id)
        .onSnapshot((snapshot) => {
          const doc: Partial<Schedule> = snapshot.data() || {};
          setSchedule(doc);
        });
    }

    return () => {
      unsubscribe?.();
    };
  }, [channel_id]);

  console.log('schedule render', { schedule, speakers, speakersObj });

  return (
    <Container>
      {programation?.map((item, index) => {
        return (
          <ScheduleItem
            key={`schedule_${schedule.channelId}_${item.id || index}`}
          >
            <div className="time_conteiner">
              {IconsList.clock}
              <div className="hour">{`${moment(item.start).format(
                'HH:mm',
              )} - ${moment(item.end).format('HH:mm')}`}</div>
            </div>
            <div className="content">
              {item.speakers?.map((speaker) => {
                const _speaker = speakersObj?.[speaker.id] || speaker;

                if (!_speaker) return null;

                return (
                  <div className="content-item">
                    <div className="title">{_speaker.name}</div>
                    <div className="profession">
                      {_speaker.profession?.[lang]}
                    </div>
                  </div>
                );
              })}
              <div className="content-item">
                <div className="title">{item.title[lang]}</div>
                <div>{item.description[lang]}</div>
              </div>
            </div>
          </ScheduleItem>
        );
      })}
    </Container>
  );
};

export default ScheduleView;
