import styled from 'styled-components';

interface ContainerPros {
  customization: any;
  theme:any;
}

export const Conteiner = styled.div<ContainerPros>`
.card-timeline {
    background:  white;
}

.titulo-sessao-cards {
    border-bottom: 1px solid lightgray;
}
.negrito-700 {
    font-weight: 700;
}

.list-group-item {
    border: none;
}
.img-users-bate-papo {
    width: 30px;
    height: 30px;
    align-self: start;
}
.info-users {
    display: inline-block;
    padding-left: 15px;
}
.fs-14 {
    font-size: 13px;
}
.bloco-curtidas {
    display: flex;
    justify-content: center;
    align-items: end ;
    cursor:pointer;

}
.bloco-comentarios {
    display: inline-block;
    margin-left: 20px;
    color:#666;
    cursor:pointer;
}
.info-users .tempo{
    font-size:10px;
    font-style:italic;
    color:#666;
}
.card-text{
    color:#666;
}

ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}
.timeline-input{
    float: left;
}
/*Foto input*/
#pic{
    display: none;
      }

.inputperson{
        cursor: pointer;
     }
     #blah{
 max-width:100px;
 height:100px;
 margin-top:20px;
}

.buttonSendMessage{
    color: ${props => props.customization.buttonTextColor};
    /* background-color: #02C90F; */
    background: ${props => props.customization.buttonBackgroundColor};
    border-color: #FFF;

    border-radius:50px;
}
.buttonSendMessage:focus{
    color: ${props => props.customization.buttonTextColor};
    background: ${props => props.customization.buttonBackgroundColor};
    border-color: #FFF;
}
.buttonSendMessage:hover{
    color: ${props => props.customization.buttonTextColor};
    background: ${props => props.customization.buttonBackgroundColor};
    border-color: #FFF;
    opacity: 0.8;
}

.heart {
  width: 100px;
  height: 100px;
  background: url("https://cssanimation.rocks/images/posts/steps/heart.png") no-repeat;
  background-position: 0 0;
  cursor: pointer;
  transition: background-position 1s steps(28);
  transition-duration: 0s;

  &.is-active {
    transition-duration: 1s;
    background-position: -2800px 0;
  }
}

// BASIC
body {
  background: linear-gradient(135deg, #121721 0%, #000000 100%) fixed;
  color: #FFF;
  font: 300 16px/1.5 "Open Sans", sans-serif;
}

.stage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-imagem img.card-img-top {
    display: fixed;
    object-fit: contain;
    max-width: 280px;
    max-height: 220px;
    height: 220px;
    overflow: hidden;
    border-radius: 10px;
}
.card-imagem {
    text-align: center;
}
.card-text {

    overflow: auto;
    max-height: 4.8em;
    text-align: center;
}

.card-text::-webkit-scrollbar {
  width: 5px;
}
.card-text::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgb(180, 180, 180);
}

.card-body {
    padding: 1rem 0;
    height: 145px;
}

.text-timeline {
  height: 70px;

}

@media only screen and (max-width: 557px){
    button.buttonSendMessage.btn.btn-primary {
        width: 100%;
        margin: 15px 0;
    }
    .titulo-sessao-cards {
        border-bottom: none;
    }
    form.col-md-3.timeline-input {
        border-bottom: 1px solid lightgray;
    }
}
`;
