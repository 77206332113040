import React, { useEffect, useState } from 'react';
import './styles.css';
import { useEvent } from '../../hooks/EventContext';
import Image from 'react-bootstrap/Image';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { FaSearchPlus } from 'react-icons/fa';
import { useTheme } from '../../hooks/ThemeContext';
interface Props {
  width?: number;
  height: number;
  padding?: number;
}
const Banner: React.FC<Props> = (props) => {
  const eventContext = useEvent();
  const [show, setShow] = useState(false);
  const { theme } = useTheme();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="banerescolumns">
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          maxHeight: '80px',
          cursor: eventContext.channel?.customization?.infoImageLink
            ? 'pointer'
            : 'unset',
          borderRadius: '10px',
        }}
      >
        {eventContext.channel?.customization?.infoImage && (
          <Image
            onClick={handleShow}
            //Precisa ser 333x80px
            src={eventContext.channel?.customization?.infoImage}
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '80px',
              cursor: eventContext.channel?.customization?.infoImageLink
                ? 'pointer'
                : 'unset',
              borderRadius: '10px',
            }}
            className="bannerview"
          ></Image>
        )}

        <FaSearchPlus
          color="white"
          size={20}
          style={{ marginLeft: '-30px', marginTop: '10px' }}
        />
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="example-modal-sizes-title-lg"
        centered
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: theme.title === 'dark' ? '#000' : '#fff' }}
        >
          <Modal.Title
            style={{ color: theme.title === 'dark' ? '#fff' : '#000' }}
          >
            Banner
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ backgroundColor: theme.title === 'dark' ? '#000' : '#fff' }}
        >
          <Image
            onClick={() =>
              window.open(
                eventContext.channel?.customization?.infoImageLink,
                '_blank',
              )
            }
            //Precisa ser 333x80px
            src={eventContext.channel?.customization?.infoImage2}
            style={{
              width: '100%',
              height: '100%',
              cursor: eventContext.channel?.customization?.infoImageLink
                ? 'pointer'
                : 'unset',
            }}
            className="bannerview"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Banner;
