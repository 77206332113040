import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ProviderPopup from './context/contextPopup'
import AppProvider from './hooks';
import Routes from './routes/index';
import ProvidePlayer from './context/contextplayer';
import api from './services/api';

const history = createBrowserHistory();

const App: React.FC = () => {
  history.listen((e) => {
    console.log('Change route', {
      e,
      gtag: (window.globalThis as any)?.gtag,
    });
    (window.globalThis as any)?.gtag('event', 'page_view', {
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_title: window.document.title,
    });
  });

  function customDomainSuccess({ data }: any): void {
    try {
      const custom_domain = data?.custom_domain;
      const event_key = data.key;
      const port = false ? ':3002' : '';

      if (
        (!window.location.pathname ||
          window.location.pathname.indexOf(event_key) === -1) &&
        !!custom_domain
      ) {
        console.log('customDomainSuccess redirect', {
          mqt: custom_domain,
          mqtkey: event_key,
        });

        window.location.href = `https://${custom_domain}${port}/${event_key}`; //redirect domain to eventkey
      }
    } catch (error) {
      console.log(`customDomainSuccess error: ${error.message}`, error);
    }
  }

  function customDomainError(e: any, count = 1): void {
    if (e.response?.data?.message !== 'Domain not found' && count < 4)
      api
        .get(`/event/public-info?cdomain=${window.location.hostname}`)
        .then(customDomainSuccess)
        .catch((err: any) => {
          customDomainError(err, count + 1);
        });
    else
      console.log(`Não foi possivel obter os dados do evento: ${e.message}`, e);
  }

  useEffect(() => {
    (window.globalThis as any)?.gtag('event', 'page_view', {
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_title: window.document.title,
    });

    api
      .get(`/event/public-info?cdomain=${window.location.hostname}`)
      .then(customDomainSuccess)
      .catch(customDomainError);
  }, []);

  return (
    <Router history={history}>
      <ProviderPopup>
        <AppProvider>
          <ProvidePlayer>
            <Routes />
          </ProvidePlayer>
        </AppProvider>
      </ProviderPopup>
    </Router>
  );
};

export default App;
