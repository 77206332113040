import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/firebase-storage';
import { v4 } from 'uuid';
import { Conteiner } from './style';
import moment from 'moment';
import { useToast } from '../../hooks/Toast';
import { asEnumerable } from 'linq-es2015';
import { useEvent } from '../../hooks/EventContext';
import { Card, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Comment from './Comment';
import Spinner from 'react-bootstrap/Spinner';
import avatarImg from '../../assets/images/avatar.png';
import { useAuth } from '../../hooks/Auth2';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
interface Props {
  user: any;
  channelId: string;
  eventId: string;
}

const Timeline: React.FC<Props> = (props: any) => {
  const { getUser } = useAuth();
  const user = getUser();
  const { register, setValue, handleSubmit, errors } = useForm<FormData>();
  const firestore = firebase.firestore();
  const { addToast } = useToast();
  const [timeline, setTimeline] = useState<any[]>([]);
  const [sending, isSending] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const { t } = useTranslation();
  const eventContext = useEvent();

  useEffect(() => {
    try {
      if (eventContext.event?.id) {
        const unsubscribe = firestore
          .collection('timeline')
          .where('eventId', '==', eventContext.event.id)
          .where('channelId', '==', eventContext.channel?.id || 'lobby')
          // .orderBy('createdAt', 'desc')
          .onSnapshot((snapshot) => {
            console.log('timeline snapshot', snapshot.docs.length);
            const items = snapshot.docs.map((a) => {
              return { ...a.data(), id: a.id };
            });
            setTimeline(
              items.sort((x: any, y: any) => {
                return y.createdAt - x.createdAt;
              }),
            );
          });
        return () => {
          unsubscribe();
        };
      }
    } catch (error) {
      console.log('timeline snapshot error', error);
    }
  }, [eventContext.event?.id, eventContext.channel?.id]);

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      if (!user) return;
      isSending(true);
      const schema = Yup.object().shape({
        userText: Yup.string().required('O campo de mensagem é obrigatório'),
        userImg: Yup.mixed().required('Por favor selecione uma imagem.'),
      });
      await schema.validate(data, {
        abortEarly: true,
      });
      let imgUrl: string = '';
      if (data.userImg.length) {
        const uploaded: any = await firebase
          .storage()
          .ref(`/companies/${props.params.event}/files/${v4()}`)
          .put(data.userImg[0]);
        imgUrl = (await uploaded.ref.getDownloadURL()) || '';
        setIsImageSelected(false);
      }
      else{
        isSending(false);
        addToast({
          type: 'error',
          title: 'Erro no envio',
          description: 'Por favor selecione uma imagem.',
        });
        return;
      }

      const entity: any = {
        createdAt: moment.utc().valueOf(),
        text: data.userText,
        channelId: eventContext.channel?.id || 'lobby',
        eventId: eventContext.event.id,
        user: {
          name: user.name,
          email: user.email,
          avatar: user.avatar || avatarImg,
        },
        uid: user.id || user.uid,
      };

      if (imgUrl) {
        entity.imgUrl = imgUrl;
      }

      await firestore.collection('timeline').add(entity);

      setValue('userText', '');

      addToast({
        type: 'success',
        title: `${t("Post Sent")}`,
        description: `${t("Your message has been sent successfully!")}`,
      });

      isSending(false);
    } catch (error) {
      isSending(false);
      alert(error.message);
      addToast({
        type: 'error',
        title: `${t("Error on Send")}`,
        description:
          error?.response?.data?.message ||
          error.message ||
          `${t("Error on send, try again")}.`,
      });
    }
  });

  function like(timeline: any) {
    if (!user) return;
    const ref = firestore.collection('timeline').doc(timeline.id);
    timeline.likesObj = timeline.likesObj || {};

    if (!timeline.likesObj[user.id]) {
      timeline.likesObj = { ...timeline.likesObj, [user.id]: true };
      return ref.update({
        likes: firebase.firestore.FieldValue.increment(1),
        likesObj: timeline.likesObj,
      });
    } else {
      delete timeline.likesObj[user.id];
      return ref.update({
        likes: firebase.firestore.FieldValue.increment(-1),
        likesObj: timeline.likesObj,
      });
    }
  }

  function textSubstring(str: string) {
    if (str.length > 150) return str.substring(0, 155) + '...';

    return str;
  }

  console.log('timeline render', { timeline, props });

  function onChange(e: any) {
    const file = e.target.files[0];
    if(!file){
      setIsImageSelected(false);
    }
    else{
      setIsImageSelected(true);
    }
  }

  return (
    <Conteiner>
      {/* <div className=" w-100" style={{ padding: '20px 0 0 0' }}> */}
      <Form
        className="form-envio w-100 form-row pl-3 pt-4 pr-3"
        onSubmit={onSubmit}
      >
        <Form.Group
          className="col-12 col-lg-5"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Control
            name="userText"
            ref={register}
            placeholder="Envie uma mensagem."
          />
        </Form.Group>

        <Form.Group className="col-12 col-lg-4">
          <Form.File
            name="userImg"
            ref={register}
            id="custom-file"
            label={isImageSelected ? "Imagem selecionada" : "Selecione uma imagem"}
            custom
            onChange={onChange}
          />
        </Form.Group>

        <Form.Group className="col-12">
          {!sending && (
            <Button className="buttonSendMessage" type="submit">
              {!sending && (
                <>
                  {`${t("Send")}`}
                  <i
                    style={{ fontSize: '1rem' }}
                    className="fa fa-paper-plane text-white"
                  />
                </>
              )}
              {sending && (
                <Spinner animation="border" role="status" size="sm">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}
            </Button>
          )}
        </Form.Group>
      </Form>
      <hr className="mt-0" />
      {/* </div> */}
      <div className="add-scroll card-timeline">
        {/* <div className="titulo-sessao-cards">
          <br />
          <p className="negrito-700 pt-4 ml-4"></p>
        </div> */}
        <div className="container">
          <div className="row">
            {/*User 1*/}
            {timeline.map((item: any, i: number) => (
              <div className="col-sm-12 col-md-6 col-lg-4" key={i}>
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between lh-condensed pl-0">
                    <div className="d-flex">
                      {/* <img
                        src={avatarImg}
                        alt=""
                        className="rounded-circle img-users-bate-papo"
                      /> */}
                      <div className="info-users">
                        <h6 className="my-0">{item.user.name}</h6>
                        <small className="tempo">
                          {moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                        </small>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="card border-0">
                  {item.imgUrl && (
                    <div className="card-imagem">
                      <img
                        className="card-img-top w-100"
                        src={item.imgUrl}
                        alt=""
                      />
                    </div>
                  )}

                  <div className="card-body  titulo-sessao-cards">
                    <div className="text-timeline">
                      <p className="fs-14 ml-0 card-text">
                        {textSubstring(item.text)}
                      </p>
                    </div>

                    <div className="bloco-curtidas" onClick={() => like(item)}>
                      <i
                        className="fa fa-heart text-danger"
                        aria-hidden="true"
                      />
                      <span className="text-muted">{item.likes || 0}</span>

                      <Comment {...props} item={item} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Conteiner>
  );
};

export default Timeline;
