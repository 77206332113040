import axios from 'axios';

const debug = 'http://localhost:3120/api';
const emulator = 'http://localhost:5001/rstcom-sandbox/us-central1/api';
const sandbox = 'https://us-central1-rstcom-sandbox.cloudfunctions.net/api';
const production = 'https://us-central1-rstcom20.cloudfunctions.net/api';
let api_url = production;

if (
  document.location.href.indexOf('homologacao.4yourlive.com') >= 0 ||
  document.location.href.indexOf('homologacao.rstcom.stream') >= 0 ||
  document.location.href.indexOf('homologacaov3-rstcom') >= 0
)
  api_url = sandbox;
// if (document.location.href.indexOf('127.0.0.1') >= 0) url = debug;
else if (document.location.href.indexOf(':6001') > -1) {
  api_url = sandbox;
} else if (document.location.href.indexOf(':6002') > -1) {
  api_url = emulator;
} else if (document.location.href.indexOf('localhost') > -1) {
  // api_url = debug;
  api_url = sandbox;
} else if (document.location.href.indexOf('127.0.0.1') > -1) {
  api_url = production;
}

console.log(api_url);
const api = axios.create({
  baseURL: api_url,
});

export { api_url };

export default api;
