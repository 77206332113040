import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../hooks/EventContext';
import { Icon } from '../Icons';

import './style.css';

interface EntityBodyLANG {
  id: string;
  flag: string;
  label: string;
  language: string;
  default: boolean;
  urlAudio: string;
}

export const MenuDropdownAudio = (props: any) => {
  const [showDropdownAudio, setShowDropdownAudio] = React.useState(false);
  const { t } = useTranslation();
  const eventContext = useEvent();
  const [onOpen, setOnOpen] = useState<boolean>(false);
  const audioLangs: EntityBodyLANG[] = eventContext.channel?.voiceSettings;

  const getAudioLAbel = () => {
    if (eventContext.channel?.voiceSettings && props?.preferenceAudio) {
      const daLang: EntityBodyLANG[] = eventContext.channel?.voiceSettings;
      return (
        daLang[daLang.findIndex((res) => res.id === props?.preferenceAudio)]
          ?.label ||
        daLang[daLang.findIndex((res) => res.default === true)]?.label ||
        daLang[0]?.label
      );
    }
    if (eventContext.channel?.voiceSettings) {
      const daLang: EntityBodyLANG[] = eventContext.channel?.voiceSettings;
      return (
        daLang[daLang.findIndex((res) => res.default === true)]?.label ||
        daLang[0]?.label
      );
    }
    // return 'https://www.youtube.com/embed/7i4sT1B1yz8';
    return 'Default';
  };

  function changeDropdownAudio() {
    setShowDropdownAudio(!showDropdownAudio);
    setOnOpen(!onOpen)
  }

  return (
    <>
      {eventContext.channel?.voiceSettings && eventContext.channel?.voiceSettings?.length !== 0 &&
        <div className="audio-dropdown-container">
          {eventContext.channel?.voiceSettings && eventContext.channel?.voiceSettings?.length !== 0 && (
            <>
              <button onClick={changeDropdownAudio} className={`audio-dropdown-button ${showDropdownAudio && 'active'}`}>
                {t('Voice')}
                {/* : {getAudioLAbel()} */}
                <Icon name='iconArrowDown' />
              </button>

              {onOpen && <div className="audio-dropdown-content"
                style={{ margin: 2, borderRadius: 10 }}>
                {audioLangs.map((res: EntityBodyLANG, index: number) => {
                  return (
                    <a
                      key={index}
                      onClick={() => {
                        props.setPreferenceAudio(res.id)
                        setOnOpen(false)
                      }}
                      className="w3-bar-item w3-button heihViewLangC"

                    >
                      {res.label}
                    </a>
                  );
                })}
              </div>}
            </>
          )}
        </div>}
      {/* {showDropdownAudio && <div className="container-audio-close-dropdown" onClick={changeDropdownAudio}></div>} */}
      {/* <div className="audio-dropdown-container" onClick={changeDropdownAudio}>
        <button className={`audio-dropdown-button ${showDropdownAudio && 'active'}`} type="button">
          Áudio
          {iconArrowDown}
        </button>
        {showDropdownAudio && <div className="audio-dropdown-content">audio</div>}
      </div> */}
    </>
  )
}
