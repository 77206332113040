import styled, { css, keyframes } from 'styled-components';

export const DivTabsVideo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    background: ${props => props.theme.colors.headerTitle};
    overflow: hidden;
`
