import React, { ButtonHTMLAttributes, useState } from 'react';

import { ButtonForm, Spinner } from './style';
import { backgrounds } from 'polished';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  spinner?: boolean;
  loadingText?: any;
  background?: string;
  color?: string
};

const Button: React.FC<ButtonProps> = ({ children, loading, loadingText, spinner = true, background, color, ...rest }) => {

  const [sending, isSending] = useState(false);

  return (
    <ButtonForm type="button" className='component_button_send_form' background={background} color={color} loading={loading} disabled={loading} {...rest}>
      {(loading && !spinner) && loadingText}
      {(loading && spinner) && <> <Spinner /> {loadingText && <p className='button-send-loadingText'>{loadingText}</p>}</>}

      {!loading && children}
    </ButtonForm>

  );
};

export default Button;
