import styled from 'styled-components';

export const ContainerTabs = styled.div`
  width: 100%;
  @media(min-width:768px){
    padding: 14px 14px 2rem;
  }
  height: 365px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgb(180, 180, 180);
  }
`
