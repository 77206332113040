import React, { useRef, useEffect , useState} from 'react';
import { useEvent } from '../../hooks/EventContext';
import './style.css';
import ModalFeedBack from '../ModalFeedBack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComments,
  faHeart,
  faBookmark,
} from '@fortawesome/free-solid-svg-icons';
import {useTheme} from '../../hooks/ThemeContext';
import {Container} from './style';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = (props: any) => {
  const eventContext = useEvent();
  const [isModalVisible, seIsModalVisible] = useState(false);
  const {theme} = useTheme();
  const { t } = useTranslation();

  function handleOutsideClickOpen(bool: boolean) {
    seIsModalVisible(bool);
  }

  function handleOutsideClickOnClose(bool: boolean) {
    seIsModalVisible(false);
  }

  return (
    <Container theme={theme} customization={eventContext.event?.customization || {}}>
      <div className="footer-logo">
        <img
          className="img-fluid"
          src={eventContext.event?.customization?.logo}
          alt=""
        />
        <p>{eventContext.event?.name}</p>
      </div>
      {/* <div className="footer-feedback">
        <button className="button-feedback"
        onClick={() => handleOutsideClickOpen(true)}
        >{t("Feedback")}</button>
      </div> */}
      {isModalVisible ? (
              <ModalFeedBack
                onClose={handleOutsideClickOnClose}
                key={isModalVisible}
              />
      ): null}
    </Container>
  );
};
export default Footer;
