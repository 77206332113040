import styled, { css, keyframes } from 'styled-components';

interface Container {
  customization: any;
}

const appearAplha = keyframes`
    from,
    50%,
    to {
        opacity: 0.2;
    }

    25%,
    75% {
        opacity: 1;
    }
`;

export const Container = styled.div<Container>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  /* background: url(https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Frstcom%2F5a90a537-c405-45ff-84c9-7f63972d744b.jpg?alt=media&token=ea2a202e-01d9-46b3-9dc3-696d07d267ba) no-repeat; */
  background-size: 100% 100%!important;
  /* background-size: contain !important;
  background-repeat: no-repeat !important; */

  .bodyviewcontainer {
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: space-around;
    padding: 5px;
  }

  .headerviewbody {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  @-moz-document url-prefix() {
    .headerviewbody {
      height: 45px;
    }
  }

  .titulodoeventotext {
    color: #191919;
    font-size: 24px;
    font-weight: 800;
  }


  .header2miniplenario {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding-top: 15px;
  }

  .bolinhavermelha {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 50px;
    height: 40px; */
    border: 1px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    color: white;

    font-weight: bolder;
    padding: 10px;
    font-size: 16px;
  }

  .bolinhaazul {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 55px; */
    padding: 10px;
    /* height: 40px; */
    border: 1px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    color: white;
    font-weight: bolder;
    font-size: 16px;
  }

  .borderonlineview {
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: #707070; */
    font-size: 12px;
    border: solid 1px;
    border-color: #707070;
    width: 140px;
    height: 25px;
    border-radius: 2px;
    background-color: #e5e5e5;
    color: #191919;
  }

  .modovideo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #191919;
    font-size: 12px;
    background-color: #e5e5e5;
    width: 140px;
    height: 25px;
    border-radius: 2px;
  }

  .borderplenariasubtitle {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: auto;
    height: 31px;
    border: 1px solid #d0d4dd;
    border-radius: 4px;
    padding: 10px 12px;
    font-size: 14px !important;
    color: #9ba1ac !important;
  }

  .viewitemend {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .bodyviewcontainerss {
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .bodyviewcontainerss2 {
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .divrowplayerchat {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .containerretratomenuandbanner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between !important;
  }
  .containerretratomenuandbanner2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .headertabmmenurigth > button {
    position: relative;
  }

  .headertabmmenurigth .highlighted:before {
    content: '';
    animation: ${appearAplha} 2s infinite;
    position: absolute;
    height: 3px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(245, 40, 81, 1);
  }

  iframe{
    width:100% !important;
    border:0;
  }

  @media only screen  and (max-width: 767px){
  .titulodoeventotext {
    font-size: 14px;
  }
  .bolinhaazul {

    font-size: 14px;
  }
  .bolinhavermelha {

    font-size: 14px;
  }
  .header2miniplenario {

    padding-top: 15px;
  }
}

  @media only screen and  (max-width: 360px){
  .titulodoeventotext {
    font-size: 13px;
  }
  .bolinhaazul {

    font-size: 12px;
  }
  .bolinhavermelha {

    font-size: 12px;
  }

}
`;
