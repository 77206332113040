import styled from 'styled-components';
import { shade } from 'polished';

export const Conteiner = styled.div`

.commentTimeCreated{
  font-size:11px;
  color:#ccc;
  font-weight:300;
}

.buttonSendMessage{
    color: #fff;
    background-color: #000000;
    border-color: #FFF;

    border-radius:50px;
}
.buttonSendMessage::focus{
    color: #fff;
    background-color: #000000;
    border-color: #FFF;
}
.buttonSendMessage:hover{
    color: #fff;
    background-color: #000000;
    border-color: #FFF;
}

`;
