import styled from 'styled-components';

interface IContainer{
  theme: any;
}

export const FooterTermsContainer = styled.div`
  width: 100%;
  padding: 4px;
  background: transparent;
`
export const Content = styled.div<IContainer>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap:2px;
  a{
    font-size: .77rem;
    color: ${props => props.theme.colors.text};
    padding: 8px;
  }
  @media(max-width:480px){
    flex-direction: row;
  }
`
export const Dot = styled.span<IContainer>`
  width: 4px;
  height: 4px;
  color: ${props => props.theme.colors.text};
  border-radius: 8px;
`
