import React, { useEffect, useRef, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  useHistory,
  useParams,
} from 'react-router-dom';
import moment from 'moment';

import { useAuth } from '../../hooks/Auth2';
import { useIsVisiblePopup } from '../../context/contextPopup';
import { useEvent } from '../../hooks/EventContext';
import Languages from '../../components/Languages';
import { asEnumerable } from 'linq-es2015';

import { useWindowDimensions } from '../../hooks/useDimension';
import api from '../../services/api';

import Sidebar from '../MenuRightSlider/Sidebar';
import { MenuDropDownUser } from '../MenuDropdownUser';

import { Icon } from '../Icons'
// import './header.css';
import * as S from './styles';
import { useTheme } from '../../hooks/ThemeContext';
import { useTranslation } from 'react-i18next';
interface PopUp {
  id?: string;
  content?: string;
  name?: string;
  isActive?: boolean;
  url?: string;
}

const Header: React.FC<any> = ({ props }) => {
  const { event, channel } = useParams<{ channel: string; event: string }>();
  const { getUser } = useAuth();
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const widthVideoSS =
    width <= 500
      ? width - 50
      : Number(
        width >= 1500
          ? width >= 1500 && width <= 1920
            ? Number(1500)
            : Number(1500 - 150)
          : width - 150,
      );
  const user = getUser();
  const eventContext = useEvent();
  const timer = useRef<any>();
  const { t, i18n } = useTranslation();
  const [restrictChannel, setRestrictChannel] = useState("");

  useEffect(() => {
    if (props?.params?.event)
      eventContext.loadInfo(props.params.event, props.params.channel);
  }, [props.params.event, props.params.channel]);

  useEffect(() => {
    if (!user?.id) return () => null;

    (window.globalThis as any)?.gtag('set', { user_id: user.id });
    (window.globalThis as any)?.gtag('event', 'set_user_id', {
      user_id: user.id,
    });

    if (timer.current) clearInterval(timer.current);

    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, [user, channel, event]);

  useEffect(() => {

    let _userRestrictChannels: string[] = [];

    if (user?.restrictChannels) {
      _userRestrictChannels = Object.keys(user?.restrictChannels);
      setRestrictChannel(_userRestrictChannels[0]);
    }

  }, [eventContext.channels]);
  // const [isLandscape, SetIsLandscape] = useState(
  //   window.matchMedia('(max-width: 992px) and (orientation: landscape)').matches,
  // );

  // useEffect(() => {
  //   window.addEventListener('resize', () => {
  //     SetIsLandscape(window.matchMedia('(max-width: 992px) and (orientation: landscape)').matches);
  //   });
  // });

  const [isMobile, SetIsMobile] = useState(
    window.matchMedia('(max-width: 992px)').matches,
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      SetIsMobile(window.matchMedia('(max-width: 992px)').matches);
    });
    sessionStorage.setItem('@Storage:channelId', eventContext?.channel?.id);
    sessionStorage.setItem('@Storage:channelName', eventContext?.channel?.name);
  });

  console.log('eventContext', eventContext);

  return (
    <S.ContainerHeader theme={theme} customization={eventContext?.event?.customization}>
      <S.ContentHeader>
        <S.HeaderColumnLeft>
          <Sidebar />
        </S.HeaderColumnLeft>

        <S.HeaderColumnCenter>
          <S.NavContainer>
            {eventContext?.event?.pagestart !== "plenaria-virtual" && (
              <S.NavItem>
                <NavLink
                  exact
                  to={`/${props.params.event}/${eventContext?.event?.pagestart === 'lobby' ? `dashboard/${channel ? channel : (restrictChannel || eventContext.channels[0]?.key)}`
                    :  restrictChannel ? `dashboard/${restrictChannel}` : `${eventContext?.event?.pagestart}`}`}
                  // to={`/${props.params.event}/${eventContext?.event?.pagestart === 'lobby' ? `dashboard/${eventContext.channels[0]?.key}`
                  //   : `${eventContext?.event?.pagestart}`}`}
                  activeClassName="active"
                >
                  <Icon name='iconChannels' />
                  {t("Room")}
                </NavLink>
              </S.NavItem>
            )}
            {/* {eventContext.channel?.key && (
              <S.NavItem>
                <NavLink
                  exact
                  to={`/${props.params.event}/dashboard/${eventContext?.channel?.key}`}
                  activeClassName="active"
                >
                  <Icon name='iconChannels' />
                  {t("Room")}
                </NavLink>
              </S.NavItem>
            )} */}
            {!!eventContext?.channels &&
              eventContext?.channels.length > 1 && !restrictChannel && (
                <S.NavItem>
                  <NavLink
                    exact
                    to={eventContext?.event?.pagestart !== "plenaria-virtual" ? `/${props.params.event}/lobby` : `/${props.params.event}/plenaria-virtual`}
                    activeClassName="active"
                  >
                    <Icon name='iconLobby' />
                    {eventContext?.event?.pagestart !== "plenaria-virtual" ? (
                      <>
                        Lobby
                      </>
                    ) : (
                      <>
                        Lobby 3D
                      </>
                    )}
                  </NavLink>
                </S.NavItem>
              )}

            {asEnumerable(eventContext.event?.resources || []).Any(
              (r: any) => r.key == 'schedule',
            ) &&
              eventContext.event?.id && (
                <S.NavItem>
                  <NavLink
                    exact
                    to={`/${props.params.event}/dashboard/schedule`}
                    activeClassName="active"
                  >
                    <Icon name='iconSchedule' />
                    {t("Schedule")}
                  </NavLink>
                </S.NavItem>
              )}

            {asEnumerable(eventContext.event?.resources || []).Any(
              (r: any) => r.key == 'speakers',
            ) &&
              eventContext.event?.id && (
                <S.NavItem>
                  <NavLink
                    exact
                    to={`/${props.params.event}/dashboard/speakers`}
                    activeClassName="active"
                  >
                    <Icon name='iconSpeakers' />
                    {t("Speakers")}
                  </NavLink>
                </S.NavItem>
              )}

            {asEnumerable(eventContext.event?.resources || []).Any(
              (r: any) => r.key == 'partners',
            ) &&
              eventContext.event?.id && (
                <S.NavItem>
                  <NavLink
                    exact
                    to={`/${props.params.event}/dashboard/partners`}
                    activeClassName="active"
                  >
                    <Icon name='iconPartners' />
                    {t("Partners")}
                  </NavLink>
                </S.NavItem>
              )}

            {asEnumerable(eventContext.event?.resources || []).Any(
              (r: any) => r.key == 'sponsors',
            ) &&
              eventContext.event?.id && (
                <S.NavItem>
                  <NavLink
                    exact
                    to={`/${props.params.event}/dashboard/sponsors`}
                    activeClassName="active"
                  >
                    <Icon name='iconSponsors' />
                    {t("Sponsorship")}
                  </NavLink>
                </S.NavItem>
              )}

            {/* {asEnumerable(eventContext.channel?.resources || []).Any(
            (r: any) => r.key == 'network',
          ) &&
            eventContext.channel?.id && ( */}
            {/* <S.NavItem>
              <NavLink
                exact
                to={`/${props.params.event}/dashboard/${eventContext?.channel?.key}/network`}
                activeClassName="active"
              >
                <Icon name='iconNetwork' />
                Network
              </NavLink>
            </S.NavItem> */}
            {/* )} */}
          </S.NavContainer>
        </S.HeaderColumnCenter>

        <S.HeaderColumnRight>
          <S.HeaderLanguage>
            <Languages data={eventContext.event?.settings?.languages} />
          </S.HeaderLanguage>
          <MenuDropDownUser />
        </S.HeaderColumnRight>
      </S.ContentHeader>
    </S.ContainerHeader>
  );
};

export default Header;
