import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuth } from '../../hooks/Auth2';
import { useEvent } from '../../hooks/EventContext';
import DashboardMasterPage from '../../masterpages/DashboardMasterPage';
import asEnumerable from 'linq-es2015';
import { Container } from './styles';
import Timeline from '../../components/Timeline';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Channels from '../../components/Features/Channels';
import { useTranslation } from 'react-i18next';

const Stand3d: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { signOut, getUser } = useAuth();
  const user = getUser();

   const eventContext = useEvent();
  // const [activeObj, setActiveObj] = useState<any>({ chat: true });

  // function enableComponent(componentName: string) {
  //   const obj = { ...activeObj };
  //   for (const key in obj) {
  //     if (obj.hasOwnProperty(key)) {
  //       obj[key] = false;
  //     }
  //   }
  //   obj[componentName] = true;
  //   setActiveObj(obj);
  // }

  return (
    <DashboardMasterPage {...props}>

      <Container customization={eventContext.event?.customization || {}}>
        <div>
          <main className="container">
            <div className="row h-100 d-flex flex-row flex-column flex-md-row">
              <div id="player-expanded-container" className="d-none container-fluid">
                <div className="row d-block">
                  <div id="player-expanded-background" className="bg-dark" />
                  <div id="player-expanded" className="keep-16-9-ratio">
                    {/* {user.role == 'moderator' ? <Moderator user={user} channelId={eventContext.channel?.id} eventId={eventContext.event?.id}></Moderator> : <div style={{ height: '420px' }}>
                      <iframe style={{ width: '100%', height: '100%' }} src={eventContext.channel?.videoUrl || "https://www.youtube.com/embed/M7lc1UVf-VE"} frameBorder={0} allowFullScreen />
                    </div>} */}
                  </div>
                </div>
              </div>
              <div className="col-md-12 pt-md-5">
                {/* <div className="d-md-block">
                  <div className="icon-live d-flex justify-content-center align-items-center">LIVE</div>
                </div> */}
                <div className="row mt-3 d-md-flex infor-header">
                  <div className="col-8">
                    <h1 className="live-title">{eventContext.event?.name}</h1>
                  </div>
                  <div className="col-4  d-flex">
                  {
                    eventContext.event?.eventdate != '' && 
                    <div className="icons-header icon-date d-flex justify-content-center align-items-center mr-2">
                      <span className="text-right">{eventContext.event?.eventdate}</span>
                    </div>
                  } 
                    {
                    eventContext.event?.eventhour != '' && 
                    <div className="icons-header icon-hour d-flex justify-content-center align-items-center">
                      <span>{eventContext.event?.eventhour}</span>
                    </div>
                    } 
                  </div>
                </div>
                <div className="row mt-1 d-none d-md-flex">
                  <div className="container">
                    <div className="mini-box d-flex justify-content-center align-items-center">
                  <span className="texts">{t('Welcome')}</span>
                    </div>
                  </div>
                </div>
                <div className="row mt-md-4 d-block">
                  <div id="player-container" className="px-md-3">
                    <div className="keep-16-9-ratio">
                     <iframe src="https://4yourlive.com/mmeventos-zoetisexperience/maplinks/index.html" 
                     style={{overflow:'none', border:0}} width="100%" height="650"></iframe>
                    </div>
                  </div>
                </div>
                <br></br>
                <Tabs defaultActiveKey="channels" id="features-tab">

                  {asEnumerable(eventContext.event?.resources || [])
                    .Any((r: any) => r.key == 'channels') &&
                    <Tab eventKey="channels" title={t('Rooms')} >
                      <Channels eventId={eventContext.event?.id} channelId={eventContext.channel?.id} {...props}></Channels>
                    </Tab>
                  }
                  {asEnumerable(eventContext.event?.resources || [])
                    .Any((r: any) => r.key == 'timeline') &&
                    <Tab eventKey="timeline" title="Timeline">
                      <Timeline user={user} eventId={eventContext.event?.id} channelId={eventContext.channel?.id} {...props}></Timeline>

                    </Tab>
                  }
                </Tabs>
                <br></br>
              </div>

            </div>
          </main>

        </div>

      </Container>
    </DashboardMasterPage>
  );
};

export default Stand3d;
