import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

export type EmitterTypes = {
  'PARTICIPANTS.ONLINE': 'PARTICIPANTS.ONLINE';
  'PARTICIPANTS.QTD_ONLINE': 'PARTICIPANTS.QTD_ONLINE';
  'CHAT.SEND_MESSAGE': 'CHAT.SEND_MESSAGE';
};

const Emitter = {
  on: (event: keyof EmitterTypes, fn: any) => eventEmitter.on(event, fn),
  once: (event: keyof EmitterTypes, fn: any) => eventEmitter.once(event, fn),
  off: (event: keyof EmitterTypes, fn: any) => eventEmitter.off(event, fn),
  emit: (event: keyof EmitterTypes, payload: any) =>
    eventEmitter.emit(event, payload),
};

Object.freeze(Emitter);

export default Emitter;
