import styled, { css, keyframes } from 'styled-components';

interface IFeedsItem {
  delay: number;
}
interface IButton {
  isComplete: boolean;
}

interface descriptionItem {
  isCommentShown?: boolean;
  lineClamp?: boolean;
}

const show = keyframes`
to{
  transform: initial;
  opacity: initial;
}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FeedsContainerItems = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;

  @media (max-width: 768px) {
    .feeds-grid {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }
`;

export const FeedsItem = styled.div<IFeedsItem>`
  a {
    display: block;
    background: ${props => props.theme.colors.primary};
    border-radius: 5px;
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex: 1;
    position: relative;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 1rem;
    width: 210px;
    height: 150px;
  }

  ${(props) =>
    css`
      opacity: 0;
      transform: translateY(-8px);

      animation: ${show} 0.2s forwards cubic-bezier(0.33, 0.41, 0.44, 0.9);
      animation-delay: ${props.delay}s;
    `}
`;


// export const Container = styled.div`
//   width:100%;
//   display: flex;
//   overflow: auto;
//   padding: 0 0rem 2rem;
//   gap:var(--grid-gap-default);

//   &::-webkit-scrollbar {
//     width: 5px;
//     height: 5px;
//   }

//   &::-webkit-scrollbar-track {
//     background-color: rgba(255, 255, 255, 0.2);
//     border-radius: 20px;
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: #fff;
//     border-radius: 20px;
//   }
//   @media(max-width:764px){
//     padding: 0 1rem 2rem 0;
//   }
// `;

export const Card = styled.div<IFeedsItem>`
  width: 100%;
  min-height:442px;
  max-width: 400px;
  min-width: 400px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  user-select: none;

  transform: scale(.95);
  opacity: 0;
  animation: ${show} .2s forwards;
  background: ${props => props.theme.colors.grayColor};

  @media(max-width:764px){
    min-width: 340px;
  }
  @media not all and (min-resolution:.001dpcm) {
  display: flex;
  flex-direction: column;

  div:first-child{
    img {
      margin-right: 10px;
    }
  }
}
`;

export const CardImageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 320px;
  display: block;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  /* display: flex;
  position: relative; */
`;
export const CardImage = styled.img`
  width: 100%;
  height: 320px;
  max-height: 320px;
  object-fit: fill;
  border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
  margin-top: 16px;
  opacity: 0;
  animation: ${show} .2s forwards;

  @media(max-width:764px){
    height: initial;
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    margin: auto;
  }
`;
export const FeedBanner = styled.img`
  width: 107%;
  /* width: 100%; */
  height: 220px;
  max-height: 220px;
  object-fit: cover;
  /* border-radius: 0 0 var(--border-radius-default) var(--border-radius-default); */
  margin-top: -9px !important;
  margin: 0 -14px;
  opacity: 0;
  animation: ${show} .2s forwards;

  @media(max-width:764px){
    height: initial;
  }
`;

export const CardImageIcons = styled.div`
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  padding: .75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .card-channel-icon-live{
    font-family: var(--fontFamily);
    font-weight: 400;
    font-size: 10px;
    background: #02C90F;
    text-transform: uppercase;
    color:#fff;
    border-radius: 20px;
    line-height: 1;
    padding: 4px 14px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-right: 10px;
  }
  svg path{
    height: 16px;
    fill:#fff;
    color:#fff;
  }
`;



// ---------- Content Card

export const CardContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem 1.75rem;
  flex-direction: column;
  /* gap:20px; */
  position: relative;

  opacity: 0;
  animation: ${show} .2s forwards;
  animation-delay: .1s;
`;

export const CardSpeakers = styled.div`
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap:12px;
  transform: translateY(-50%);
  padding: 0 1.25rem;

  img{
    width: 100%;
    height: 100%;
    max-width: 54px;
    max-height: 54px;
    object-position: contain;
    border-radius: 30px;
    filter: drop-shadow(0px 0px 5px rgb(0 0 0 / 10%));
  }
  /* margin-bottom: 50%; */
`;

export const CardInfoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
`;
export const CardTitle = styled.h2`
  font-family: var(--fontFamily);
  font-weight: 500;
  color:${props => props.theme.colors.text};
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
`;

export const CardInfoText = styled.p<descriptionItem>`
    font-family: var(--fontFamily);
    font-weight: 400;
    color:${props => props.theme.colors.paragraph};
    font-size: 14px;
    flex:1;
    height: 100%;
    line-height: 1.25rem;
    max-height: 5rem;
    overflow:  ${props => props.isCommentShown ? 'inherit' : 'hidden'};
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.lineClamp ? 0 : 2};
    line-break: anywhere;
    -webkit-box-orient: vertical;
    margin-bottom: 20px;
`;
// ---------- Footer
export const CardFooter = styled.div`
  display: flex;
  /* position: absolute; */
  /* bottom: 0; */
  border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px 12px 26px;
  /* box-shadow: 0px -13px 24px #0000001C; */
  /* margin-top: 1.5rem; */
`;

export const CalendarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 27% ;
  /* width: 38% ; */
  color:#B9B9B9;
  font-family: var(--fontFamily);
  font-size: 12px;
  font-weight: 400;
  /* padding: 8px 22px; */
  padding: 8px 0px;
  letter-spacing: 1px;
  transition: all .2s;
`;

export const ConteinerSeeMore = styled.div`
  display: flex;
  align-items: center;
  gap:2px;
  button.card-channels-item-icon{
    padding: 4px 4px 4px 8px;
    height: calc(18px + 8px); // Height + padding
    border:0;
    background: none;
    display: flex;
    align-items: baseline;
    gap:4px;
    color:#B9B9B9;
    font-size: 12px;
    cursor: pointer;
    &:hover{
      color:rgba(2, 2, 2, 0.8);
    }
  }
  svg, svg path{
    width: 18px;
    height: 18px;
    fill:#B9B9B9;
    transition: all .2s;
  }
    .icon-heart:hover path{
      fill:rgba(230, 42, 89, 0.8);

    }
    .icon-people:hover path{
      fill:rgba(2, 2, 2, 0.8);
    }
`;
export const ShowMoreButton = styled.div<IButton>`
  display: ${props => props.isComplete ? 'none': 'flex'};
  background: transparent;
  border: 1px solid #fff;
  width: 171px;
  height: 41px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  span{
    color: #fff;
    margin-left: 6px;
  }
`
