import React, { useEffect, useState } from 'react';
import { useEvent } from '../../hooks/EventContext';

import { useAuth } from '../../hooks/Auth2';
import api from '../../services/api';
import { Util } from '../../utils/util';

interface Props {
  user: any;
}

const FaceLive: React.FC<Props> = (props) => {
  const { getUser } = useAuth();
  const [token_facelive, setTokenFacelive] = useState('');
  const user = getUser();
  const eventContext = useEvent();
  
  function scriptLoaded(): void {
    //roomName: 'vpaas-magic-cookie-b989f94b93544e19a4347891f3630f36/SampleAppEmotionalBarrelsHuntMagnificently',
    //roomName:'vpaas-magic-cookie-b989f94b93544e19a4347891f3630f36/room_'+eventContext?.channel?.id,
    const api = new (window.globalThis as any).JitsiMeetExternalAPI('8x8.vc', {
      roomName:'vpaas-magic-cookie-b989f94b93544e19a4347891f3630f36/room_'+eventContext?.channel?.id,
      parentNode: document.querySelector('#jaas-container'),
      userInfo: {
        email: props?.user?.email,
        displayName: props?.user?.name,
      },
      interfaceConfigOverwrite: {
        DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
        TOOLBAR_BUTTONS: 
        [
          'microphone',
          'camera',
          'closedcaptions',
          'desktop',
          'embedmeeting',
          'fullscreen',
          'fodeviceselection',
          'hangup',
          'profile',
          'chat',
          'recording',
          'etherpad',
          'settings',
          'raisehand',
          'videoquality',
          'filmstrip',
          'feedback',
          'stats',
          'shortcuts',
          'tileview',
          'select-background',
          'mute-everyone',
          'mute-video-everyone',
          'security',
        ],
      },
      configOverwrite: {
        startWithAudioMuted: true,
      },
      prejoinPageEnabled: false,
      jwt: token_facelive,
    });

    api.addEventListener('participantRoleChanged', function (event: any) {
      if (event.role === 'moderator') {
        api.executeCommand('toggleLobby', true);
      }
    });

    api.executeCommand('subject', '');
    api.executeCommand('toggleChat');
    api.executeCommand('email', props?.user?.email);
    api.executeCommand('displayName', props?.user?.name);
    api.executeCommand('avatarUrl', '');
  }

  useEffect(() => {
    // const script = document.createElement('script');
    // script.src = 'https://8x8.vc/external_api.js';
    // script.async = true;
    // script.onload = () => scriptLoaded();

    function getToken() {
      api
        .get('/facelive/token')
        .then((r) => {
          setTokenFacelive(r.data.token);
        })
        .catch(async (c) => {
          await Util.sleep(3);
          getToken();
        });
    }

    if (!token_facelive) getToken();
  }, []);

  useEffect(() => {
    if (!!token_facelive) scriptLoaded();
  }, [token_facelive]);

  console.log('facelive', token_facelive);

  return <div id="jaas-container" style={{ width: '100%', height: '100%' }} />;
};

export default FaceLive;
