import styled from 'styled-components';
import { shade } from 'polished';

export const Conteiner = styled.div`
  .myClassname{
    border:0px;
  }
  .container > *{
    padding:0;
  }
  .ReactFlagsSelect-module_selectBtn__19wW7:after {
    border-top: 5px solid #ffffff;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 0;
  }

  .ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
      border-top: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #ffffff;
  }
  button#rfs-btn {
    color: #fff;
    padding: 0 5px;
    border: 0;
  }
  button#rfs-btn:focus {
    outline: none;
}
`;
