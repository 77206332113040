import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';

// import { Container } from './styles';

type ConfirmationModalProps = {
  visible: boolean;
  title: string;
  message: string;
  onConfirm?(): void;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  visible,
  title,
  message,
  onConfirm,
}) => {
  return (
    <Modal show={visible} centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <h5>{message}</h5>
      </ModalBody>
      <ModalFooter>
        <Button variant="primary" onClick={onConfirm}>
          Sim
        </Button>
        <Button variant="danger">Não</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
