import React, { useEffect, useState } from 'react';
import ReactWordcloud from 'react-wordcloud';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { useEvent } from '../../hooks/EventContext';

// const wordss = [
//   {
//     text: 'told',
//     value: 64,
//   },
//   {
//     text: 'Jhow Gama',
//     value: 150,
//   },
//   {
//     text: 'mistake',
//     value: 11,
//   },
//   {
//     text: 'thought',
//     value: 16,
//   },
//   {
//     text: 'bad',
//     value: 17,
//   },
//   {
//     text: 'correct',
//     value: 500,
//   },
//   {
//     text: 'day',
//     value: 54,
//   },
//   {
//     text: 'prescription',
//     value: 12,
//   },
//   {
//     text: 'time',
//     value: 77,
//   },
//   {
//     text: 'thing',
//     value: 45,
//   },
//   {
//     text: 'left',
//     value: 500,
//   },
//   {
//     text: 'pay',
//     value: 13,
//   },
//   {
//     text: 'people',
//     value: 500,
//   },
//   {
//     text: 'month',
//     value: 22,
//   },
//   {
//     text: 'again',
//     value: 35,
//   },
//   {
//     text: 'review',
//     value: 24,
//   },
//   {
//     text: 'call',
//     value: 38,
//   },
//   {
//     text: 'doctor',
//     value: 70,
//   },
//   {
//     text: 'asked',
//     value: 500,
//   },
//   {
//     text: 'finally',
//     value: 14,
//   },
//   {
//     text: 'insurance',
//     value: 29,
//   },
//   {
//     text: 'week',
//     value: 41,
//   },
//   {
//     text: 'called',
//     value: 49,
//   },
//   {
//     text: 'problem',
//     value: 20,
//   },
//   {
//     text: 'going',
//     value: 59,
//   },
//   {
//     text: 'help',
//     value: 49,
//   },
//   {
//     text: 'felt',
//     value: 45,
//   },
//   {
//     text: 'discomfort',
//     value: 11,
//   },
//   {
//     text: 'lower',
//     value: 22,
//   },
//   {
//     text: 'severe',
//     value: 12,
//   },
//   {
//     text: 'free',
//     value: 38,
//   },
//   {
//     text: 'better',
//     value: 54,
//   },
//   {
//     text: 'muscle',
//     value: 14,
//   },
//   {
//     text: 'neck',
//     value: 41,
//   },
//   {
//     text: 'root',
//     value: 24,
//   },
//   {
//     text: 'adjustment',
//     value: 16,
//   },
//   {
//     text: 'therapy',
//     value: 29,
//   },
//   {
//     text: 'injury',
//     value: 20,
//   },
//   {
//     text: 'excruciating',
//     value: 10,
//   },
//   {
//     text: 'chronic',
//     value: 13,
//   },
//   {
//     text: 'chiropractor',
//     value: 35,
//   },
//   {
//     text: 'treatment',
//     value: 59,
//   },
//   {
//     text: 'tooth',
//     value: 32,
//   },
//   {
//     text: 'chiropractic',
//     value: 17,
//   },
//   {
//     text: 'dr',
//     value: 77,
//   },
//   {
//     text: 'relief',
//     value: 19,
//   },
//   {
//     text: 'shoulder',
//     value: 26,
//   },
//   {
//     text: 'nurse',
//     value: 17,
//   },
//   {
//     text: 'room',
//     value: 22,
//   },
//   {
//     text: 'hour',
//     value: 35,
//   },
//   {
//     text: 'wait',
//     value: 38,
//   },
//   {
//     text: 'hospital',
//     value: 11,
//   },
//   {
//     text: 'eye',
//     value: 13,
//   },
//   {
//     text: 'test',
//     value: 10,
//   },
//   {
//     text: 'appointment',
//     value: 49,
//   },
//   {
//     text: 'medical',
//     value: 19,
//   },
//   {
//     text: 'question',
//     value: 20,
//   },
//   {
//     text: 'office',
//     value: 64,
//   },
//   {
//     text: 'care',
//     value: 54,
//   },
//   {
//     text: 'minute',
//     value: 29,
//   },
//   {
//     text: 'waiting',
//     value: 16,
//   },
//   {
//     text: 'patient',
//     value: 59,
//   },
//   {
//     text: 'health',
//     value: 49,
//   },
//   {
//     text: 'alternative',
//     value: 24,
//   },
//   {
//     text: 'holistic',
//     value: 19,
//   },
//   {
//     text: 'traditional',
//     value: 20,
//   },
//   {
//     text: 'symptom',
//     value: 29,
//   },
//   {
//     text: 'internal',
//     value: 17,
//   },
//   {
//     text: 'prescribed',
//     value: 26,
//   },
//   {
//     text: 'acupuncturist',
//     value: 16,
//   },
//   {
//     text: 'pain',
//     value: 64,
//   },
//   {
//     text: 'integrative',
//     value: 10,
//   },
//   {
//     text: 'herb',
//     value: 13,
//   },
//   {
//     text: 'sport',
//     value: 22,
//   },
//   {
//     text: 'physician',
//     value: 41,
//   },
//   {
//     text: 'herbal',
//     value: 11,
//   },
//   {
//     text: 'eastern',
//     value: 12,
//   },
//   {
//     text: 'chinese',
//     value: 32,
//   },
//   {
//     text: 'acupuncture',
//     value: 45,
//   },
//   {
//     text: 'prescribe',
//     value: 14,
//   },
//   {
//     text: 'medication',
//     value: 38,
//   },
//   {
//     text: 'western',
//     value: 35,
//   },
//   {
//     text: 'sure',
//     value: 38,
//   },
//   {
//     text: 'work',
//     value: 64,
//   },
//   {
//     text: 'smile',
//     value: 17,
//   },
//   {
//     text: 'teeth',
//     value: 26,
//   },
//   {
//     text: 'pair',
//     value: 11,
//   },
//   {
//     text: 'wanted',
//     value: 20,
//   },
//   {
//     text: 'frame',
//     value: 13,
//   },
//   {
//     text: 'lasik',
//     value: 10,
//   },
//   {
//     text: 'amazing',
//     value: 41,
//   },
//   {
//     text: 'fit',
//     value: 14,
//   },
//   {
//     text: 'happy',
//     value: 22,
//   },
//   {
//     text: 'feel',
//     value: 49,
//   },
//   {
//     text: 'glasse',
//     value: 19,
//   },
//   {
//     text: 'vision',
//     value: 12,
//   },
//   {
//     text: 'pressure',
//     value: 16,
//   },
//   {
//     text: 'find',
//     value: 29,
//   },
//   {
//     text: 'experience',
//     value: 59,
//   },
//   {
//     text: 'year',
//     value: 70,
//   },
//   {
//     text: 'massage',
//     value: 35,
//   },
//   {
//     text: 'best',
//     value: 54,
//   },
//   {
//     text: 'mouth',
//     value: 20,
//   },
//   {
//     text: 'staff',
//     value: 64,
//   },
//   {
//     text: 'gum',
//     value: 10,
//   },
//   {
//     text: 'chair',
//     value: 12,
//   },
//   {
//     text: 'ray',
//     value: 22,
//   },
//   {
//     text: 'dentistry',
//     value: 11,
//   },
//   {
//     text: 'canal',
//     value: 13,
//   },
//   {
//     text: 'procedure',
//     value: 32,
//   },
//   {
//     text: 'filling',
//     value: 26,
//   },
//   {
//     text: 'gentle',
//     value: 19,
//   },
//   {
//     text: 'cavity',
//     value: 17,
//   },
//   {
//     text: 'crown',
//     value: 14,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 150,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 150,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 150,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 150,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 150,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 150,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 150,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 150,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 150,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 150,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 59,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 150,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 29,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 150,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 17,
//   },
//   {
//     text: 'chargeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
//     value: 150,
//   },
//   {
//     text: 'pocket',
//     value: 12,
//   },
//   {
//     text: 'dollar',
//     value: 11,
//   },
//   {
//     text: 'business',
//     value: 32,
//   },
//   {
//     text: 'refund',
//     value: 10,
//   },
// ];

const options: any = {
  colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
  enableTooltip: true,
  deterministic: false,
  fontFamily: 'impact',
  fontSizes: [20, 40, 60],
  fontStyle: 'normal',
  fontWeight: 'normal',
  padding: 4,
  rotations: 3,
  rotationAngles: [0],
  scale: 'sqrt',
  spiral: 'archimedean',
  transitionDuration: 1000,
};

interface IWord3d {
  channelId: string;
  eventId: string;
}

const Word3d: React.FC<IWord3d> = ({ channelId, eventId }) => {
  const { cloudWord } = useEvent();
  const [words, setWords] = useState<any[]>([]);

  useEffect(() => {
    if (channelId && eventId) {
      const $words: any[] = [];
      const keys = cloudWord?.words ? Object.keys(cloudWord?.words) : [];

      keys.forEach((key) => {
        const word = {
          text: key,
          value: cloudWord?.words[key],
        };
        $words.push(word);
      });

      setWords($words);
    }
  }, [channelId, eventId, cloudWord]);

  console.log('render words', cloudWord?.words);

  return (
    <section id="section-about">
      {/* {!!cloudWord?.title && <h4 className="mt-3">{cloudWord?.title}</h4>} */}
        <div className="row align-items-center">
          <div style={{ height: '554px', width: '100%' }}>
            <ReactWordcloud
              callbacks={{
                onWordClick: console.log,
                onWordMouseOut: console.log,
                onWordMouseOver: console.log,
              }}
              options={options}
              words={words}
            />
          </div>
      </div>
    </section>
  );
};

export default React.memo(Word3d);
