import styled, {css} from 'styled-components';

interface ChatBoxContent {
  isModerator: boolean;
}
interface ContainerPros {
  customization: any;
  theme:any;
}
export const Conteiner = styled.div`
  .bp-title {
    margin-left: 10px;
  }

  p {
    font-size: 13px;
  }

  .itemmensagemview img {
    width: 100%;
  }
`;

export const MessageList = styled.div`
  height: 460px;
  padding: 0 0.5rem;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  color: #000000;

  bp-title-user {
    font-size: 12px;
    font-weight: inherit;
  }
`;

export const MessageBox = styled.div`
  background-color: #fff;
  color: #000000;
  padding: 0.5rem;
  form {
    height: 100%;
  }

  p {
    color: gray;
    font-weight: bold;
    font-size: 12px;
  }

  input {
    border: none;

    border-bottom: 1px solid lightblue;
  }

  button {
    border: none;
    background: transparent;
  }

  p:last-child {
    text-align: right !important;
  }

  i {
    font-size: 20px;
    color: lightblue;
  }
`;

export const Form = styled.form<ContainerPros>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    z-index: 9;
    background-color: ${props => props.theme.colors.primary};
    box-shadow: 0px -1px 12px rgba(0, 0, 0, 0.1);
    padding: 24px 16px;
    border: 0;
    border-radius: 10px 10px 0 0 !important;

    .chat-btn-send-message {
      height: 40px;
      width: 60px;
      background: ${props => props.customization.buttonBackgroundColor};
      color: #fff;
      font-size: 14px;
      border-radius: 5px;
      border: none;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 0.1s;
}
`;

export const ChatBoxContent = styled.div<ChatBoxContent>`
  ${props => props.isModerator
    ? css`
    background: ${props.theme.colors.chatContent};
    color: #FFFFFF;
    width: 275px;
  ` : css`
    background: #EBEBEB;
    color: #303030;
    width: 275px;
  `}

`
