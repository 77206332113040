import React, { useEffect, useState, CSSProperties } from 'react';
import './styles.css';
import firebase from 'firebase/app';
import { useAuth } from '../../hooks/Auth2';
import { useEvent } from '../../hooks/EventContext';
import { useWindowDimensions } from '../../hooks/useDimension';
import {
  useIsRetrato,
  useIndexTabPlayerView,
} from '../../context/contextplayer';
import Survey from '../Moderator/Survey';
import Questions from '../Moderator/Questions';
import Attendance from '../Moderator/Attendance';
import Links from '../Moderator/Links';
import Users from '../Moderator/Users';
// import { useEvent } from '../../hooks/EventContext';
import { usePresence } from '../../hooks/PresenceContext';
import Facelive from '../../components/FaceLive';
import ChatModeration from '../Moderator/ChatModeration';
import { useTheme } from '../../hooks/ThemeContext';
import { DivTabsVideo } from './style';

interface Props {
  user: any;
}

const TabsView: React.FC<Props> = (props) => {
  const { isRetrato } = useIsRetrato();
  const { width, height } = useWindowDimensions();
  const { theme } = useTheme();
  const { getUser } = useAuth();
  const user = getUser();
  const { indexTabPlayerView, setIndexTabPlayerView } = useIndexTabPlayerView();

  const eventContext = useEvent();

  const onActiveStyleSlector = (index: number) => {
    if (index === indexTabPlayerView) {
      return 'itemtabbarvideo itemactivetabvideoview';
    }
    return 'itemtabbarvideo';
  };
  const onChangeTabSelect = (index: number) => {
    setIndexTabPlayerView(index);
    ///muda a tab selecionada
  };
  const tabEvent = (key: string) => {
    if (eventContext?.event?.resources) {
      return (
        eventContext.event.resources.findIndex(
          (e: { key: string }) => e.key === key,
        ) >= 0
      );
    }
    return false;
  };

  const largItem =
    width <= 500
      ? width - 50
      : Number(
        width <= 920
          ? Number(width - 150)
          : Number(width >= 1500 ? 1500 - 500 : width - 500),
      );


  const getStyleTabItem = (index: number) => {
    const style: CSSProperties = {
      display: indexTabPlayerView === index ? 'flex' : 'none',
      width: '100%',
      height: '100%',
      overflow: 'scroll',
      // background: '#f7f7f7',
      justifyContent: 'center',
      padding: 30,
    };
    return style;
  };

  return (
    <>
      <div
        style={{
          width: isRetrato ? '100%' : largItem,
          flexDirection: 'column',
          alignItems: 'center',
          // background:
          //   eventContext.event?.customization.backgroundColor || '#f5f8fd',
          display: 'flex',
        }}
      >
        {user?.isModerator && (
          <DivTabsVideo theme={theme}>

            <span
              onClick={() => onChangeTabSelect(0)}
              className={`${onActiveStyleSlector(0)} fas fa-users`}
            />

            {tabEvent('survey') && (
              <span
                onClick={() => onChangeTabSelect(1)}
                className={`${onActiveStyleSlector(1)} fas fa-edit`}
              />
            )}

            {tabEvent('ask') && (
              <span
                onClick={() => onChangeTabSelect(2)}
                className={`${onActiveStyleSlector(2)} fas fa-question-circle`}
              />
            )}

            {tabEvent('links') && (
              <span
                onClick={() => onChangeTabSelect(3)}
                className={`${onActiveStyleSlector(3)} fas fa-link`}
              />
            )}

            {user?.isAttendance && (
              <span
                onClick={() => onChangeTabSelect(5)}
                className={`${onActiveStyleSlector(5)} fas fa-user-shield`}
              />
            )}
          </DivTabsVideo>
        )}


        <div
          style={{
            // width: Number(isRetrato ? width : largItem),
            // height: !props?.isMobile
            //   ? Number(largItem) / 1.78
            //   : Number(largItem) / 1.1,
            //  background: '#000',
          }}
          className="videoview"
        >
          {user?.isModerator && (
            <>
              <div className="bg-white overflow-auto" style={getStyleTabItem(1)}>
                <Survey
                  channelId={eventContext.channel?.id}
                  eventId={eventContext.event?.id}
                  user
                />
              </div>
              <div className="bg-white overflow-auto" style={getStyleTabItem(2)}>
                <Questions
                  channelId={eventContext.channel?.id}
                  eventId={eventContext.event?.id}
                  user
                />
              </div>

              <div className="bg-white overflow-auto" style={getStyleTabItem(3)}>
                <Links
                  channelId={eventContext.channel?.id}
                  eventId={eventContext.event?.id}
                  user
                />
              </div>
              <div className="bg-white overflow-auto" style={getStyleTabItem(0)}>
                <Users
                  channelId={eventContext.channel?.id}
                  eventId={eventContext.event?.id}
                  user
                />
              </div>
            </>
          )}
          {user?.isAttendance && (
            <div className="bg-white overflow-auto" style={getStyleTabItem(5)}>
              <Attendance {...(props as any)} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TabsView;
