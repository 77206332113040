import { shade } from 'polished';
import styled, { css, keyframes } from 'styled-components';

interface ButtonProps {
  background?: string;
  color?: string;
  loading?: boolean;
};

const spinAnimation = keyframes`
  to{
    transform: rotate(360deg);
  }
`
export const ButtonForm = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap:8px;
  border-radius: var(--border-radius-default);
  padding: 8px 2rem;
  font-size: 15px;
  font-weight: 100;
  border:0;
  white-space: nowrap;
  transition: all .2s;
  letter-spacing: .05em;
  user-select: none;
  & > *{
    user-select: initial;
  }
  .button-send-loadingText{
    font-size: 1rem;
    line-height:1;
    padding:0;
    margin:0;
  }
  ${({ background, color, loading }) => css`
    background: ${background || '#02C90F'};
    color: ${color || '#fff'};

    &:hover{
      background-color: ${shade(0.2, `${background || '#000'}`)};
    }
    `}
    &:disabled{
      opacity: 0.6;
      cursor: not-allowed;
    }

    @media(max-width:768px){
      font-size: 14px;
      padding: 8px 1.75rem;
  }
`;

export const Spinner = styled.span`
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-top:2px solid transparent;
  border-radius: 16px;

  animation: ${spinAnimation} .4s infinite linear;
`
