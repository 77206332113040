import React, { useEffect, useState } from 'react';
import 'firebase/firestore';
import { useTranslation } from 'react-i18next';

import { PresenceContext } from '../../../hooks/PresenceContext';
import Emitter from '../../../utils/Event';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';

interface Props {
  channelId?: string;
  totalOnline?: number;
}

const UsersCountComponent: React.FC<Props> = ({}: any) => {
  const [totalOnline, setTotalOnline] = useState(0);
  const { t } = useTranslation();

  const handleOnTotalOnlineChange = (value: number) => {
    setTotalOnline((prev) => {
      if (prev !== value) return value;
      return prev;
    });
  };

  useEffect(() => {
    Emitter.on('PARTICIPANTS.QTD_ONLINE', handleOnTotalOnlineChange);

    return () => {
      Emitter.off('PARTICIPANTS.QTD_ONLINE', handleOnTotalOnlineChange);
    };
  }, []);

  return (
    <span>{totalOnline}</span>
  );
};

const UsersCount: React.FC<Props> = (props) => (
  <PresenceContext.Consumer>
    {(value) => (
      <UsersCountComponent {...props} totalOnline={value.totalOnline} />
    )}
  </PresenceContext.Consumer>
);

export default UsersCountComponent;
