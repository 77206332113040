import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner, Table } from './style';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useTranslation } from 'react-i18next';

interface ChatProps {
  user: any;
  channelId: string;
  eventId: string;
}

const Questions: React.FC<ChatProps> = (props: any) => {
  const firestore = firebase.firestore();
  const [questions, setQuestions] = useState<any[]>([]);
  const { addToast } = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.channelId) {
      let unsubscribe: any = null;
      unsubscribe = firestore
        .collection('question')
        .orderBy('createdAt', 'desc')
        .where('channelId', '==', props.channelId)
        .where('status', 'in', ['approved', 'received', 'answered'])
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((a) => {
            return { id: a.id, ...a.data() };
          });
          setQuestions(docs);
        });
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
    if (!props.channelId && props.eventId) {
      let unsubscribe: any = null;
      unsubscribe = firestore
        .collection('question')
        .orderBy('createdAt', 'desc')
        .where('eventId', '==', props.eventId)
        .where('status', 'in', ['approved', 'received', 'answered'])
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((a) => {
            return { id: a.id, ...a.data() };
          });
          setQuestions(docs);
        });
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [props.channelId, props.eventId]);

  function markAsAnswered(data: any, status: string) {
    if (status == 'deleted') {
      var r = window.confirm(
        'Tem certeza que você deseja remover esta Pergunta?',
      );
      if (!r) {
        return false;
      }
    }

    const ref = firestore.collection('question').doc(data.id);
    ref.update({
      status,
    });
  }

  function translateStatus(status: string) {
    switch (status) {
      case 'approved':
        return t("Approved");

      case 'answered':
        return t("Answered");

      case 'deleted':
        return t("Deleted");

      case 'received':
        return t("Received");

      default:
        return t("Received");
    }
  }

  console.log('Questions', questions);

  return (
    <Conteiner>
      <Tabs defaultActiveKey="received" id="moderator-controller">
        <Tab eventKey="received" title="Recebidas">
          <Table>
            <tbody>
              {questions.length > 0 ? (
                questions.map((s: any, index1: number) => (
                  <>
                    {s.status !== 'deleted' && s.status === 'received' && (
                      <tr key={index1}>
                        <td>
                          <DropdownButton
                            id="dropdown-basic-button"
                            title="Ações"
                          >
                            <Dropdown.Item
                              onClick={() => markAsAnswered(s, 'approved')}
                            >
                              {t("Approved")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => markAsAnswered(s, 'answered')}
                            >
                              {t("Answered")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => markAsAnswered(s, 'deleted')}
                            >
                              {t("Deleted")}
                            </Dropdown.Item>
                          </DropdownButton>
                        </td>
                        <td style={{ fontWeight: 'bold' }} scope="row">{questions.length - index1}</td>
                        <td style={{width: !props.channelId ? '100px' : "0px"}}>
                          <span className="questionDateTime">
                            {moment(s.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                          </span>
                          <br />
                          <p className="questionName">
                            {t("From")}:{' '}
                            {s.speaker && (
                              <>
                                {s.speaker}
                                <br />
                              </>
                            )}
                            {t("To")}:
                            <em className="questionEmail">
                              {' '}
                              {s.name}  {props.channelId && "| " + s.email}
                            </em>
                          </p>
                          <hr />
                        <div className="questionDiv">
                            {s.question}
                        </div>
                        </td>
                        {props.channelId && (
                          <>
                            <td>{translateStatus(s.status)}</td>
                          </>
                        )}
                      </tr>
                    )}
                  </>
                ))
              ) : (
                <p>
                  {t("No questions available")}
                </p>
              )}
            </tbody>
          </Table>
        </Tab>

        <Tab eventKey="approved" title="Aprovadas">
          <Table>
            <tbody>
              {questions.length > 0 ? (
                questions.map((s: any, index1: number) => (
                  <>
                    {s.status !== 'deleted' && s.status === 'approved' && (
                      <tr key={index1}>
                        <td>
                          <DropdownButton
                            id="dropdown-basic-button"
                            title="Ações"
                          >
                            <Dropdown.Item
                              onClick={() => markAsAnswered(s, 'received')}
                            >
                              {t("Approved")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => markAsAnswered(s, 'answered')}
                            >
                              {t("Answered")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => markAsAnswered(s, 'deleted')}
                            >
                              {t("Removed")}
                            </Dropdown.Item>
                          </DropdownButton>
                        </td>
                        <td style={{ fontWeight: 'bold' }} scope="row">{questions.length - index1}</td>
                        <td style={{width: !props.channelId ? '100px' : "0px"}}>
                          <span className="questionDateTime">
                            {moment(s.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                          </span>
                          <br />
                          <p className="questionName">
                            {t("From")}:{' '}
                            {s.speaker && (
                              <>
                                {s.speaker}
                                <br />
                              </>
                            )}
                            {t("To")}:
                            <em className="questionEmail">
                              {' '}
                              {s.name}  {props.channelId && "| " + s.email}
                            </em>
                          </p>
                          <hr />

                          <div className="questionDiv">
                            {s.question}
                          </div>
                        </td>
                        {props.channelId && (
                          <>
                            <td>{translateStatus(s.status)}</td>
                          </>
                        )}
                      </tr>
                    )}
                  </>
                ))
              ) : (
                <p>
                  {t("No questions available")}
                </p>
              )}
            </tbody>
          </Table>
        </Tab>

        <Tab eventKey="answered" title="Respondidas">
          <Table>
            <tbody>
              {!!questions && questions.length > 0 ? (
                questions.map((s: any, index1: number) => (
                  <>
                    {s.status !== 'deleted' && s.status === 'answered' && (
                      <tr key={index1}>
                        <td>
                          <DropdownButton
                            id="dropdown-basic-button"
                            title="Ações"
                          >
                            <Dropdown.Item
                              onClick={() => markAsAnswered(s, 'approved')}
                            >
                              {t("Approved")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => markAsAnswered(s, 'received')}
                            >
                              {t("Received")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => markAsAnswered(s, 'deleted')}
                            >
                              {t("Removed")}
                            </Dropdown.Item>
                          </DropdownButton>
                        </td>
                        <td style={{ fontWeight: 'bold' }} scope="row">{questions.length - index1}</td>
                        <td style={{width: !props.channelId ? '100px' : "0px"}}>
                          <span className="questionDateTime">
                            {moment(s.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                          </span>
                          <br />
                          <p className="questionName">
                            {t("From")}:{' '}
                            {s.speaker && (
                              <>
                                {s.speaker}
                                <br />
                              </>
                            )}
                            {t("To")}:
                            <em className="questionEmail">
                              {' '}
                              {s.name}  {props.channelId && "| " + s.email}
                            </em>
                          </p>
                          <hr />

                          <div className='questionDiv'>
                            {s.question}
                          </div>
                        </td>
                        {props.channelId && (
                          <>
                            <td>{translateStatus(s.status)}</td>
                          </>
                        )}
                      </tr>
                    )}
                  </>
                ))
              ) : (
                <p>
                  {t("No questions available")}
                </p>
              )}
            </tbody>
          </Table>
        </Tab>
      </Tabs>
    </Conteiner>
  );
};

export default Questions;
