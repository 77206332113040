export default {
  title: "dark",

  colors: {
    primary: "#1C1E20",
    secondary: "#131010bd",
    paragraph: "#B9B9B9",
    grayColor: '#333333',
    color: "#000000",
    background: "#1C1E20",
    text: "#FFFFFF",
    inputColor: "#000000",
    inputPlaceholder: "#666360",
    footer:'#1C1E20',
    chatContent: '#1C1E20',
    moon:'#02C90F',
    askText: "#808080",
    headerTitle: "#1C1E20",
    navItem: "#02C90F"
  },
};
