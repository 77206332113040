import React, { useState, useEffect } from 'react';
import { useEvent } from '../../hooks/EventContext';
import { useTheme } from '../../hooks/ThemeContext';
import * as S from './style';
import firebase from 'firebase/app';
import { IoMdRefresh } from 'react-icons/io';
import 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../hooks/Auth2';
import './styles.css';
import ModalProduct from '../ModalProduct';
import HeaderPageDescription from '../HeaderPageDescription';

const firestore = firebase.firestore();
interface IProps {
  handleModal: () => void;
}

const ProductGrid: React.FC<IProps> = (props: any) => {
  const eventContext = useEvent();
  const { theme } = useTheme();
  const [products, setProducts] = useState<
    { title: string; cover: string ; description: string; createdAt: number;}[]
  >([]);
  const { getUser } = useAuth();
  const [arrayIds, setArrayIds] = useState<any[]>([]);
  const [isComplete,setIsComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [isModalVisible, seIsModalVisible] = useState(false);
  const [isMobile, SetIsMobile] = useState(
    window.matchMedia('(max-width: 992px)').matches,
  );

  function handleOutsideClickOpen(bool: boolean) {
    seIsModalVisible(bool);
  }

  function handleOutsideClickOnClose(bool: boolean) {
    seIsModalVisible(false);
  }
  // function showComment(id:number) {
  //     setArrayIds(prevArray => [...prevArray, id])
  // }

  // function lessComment(id:number){
  //   const arrayTemp = [...arrayIds];
  //   let index = arrayTemp.indexOf(id,1);
  //   arrayTemp.splice(index,1)
  //   setArrayIds(arrayTemp)
  // }

  useEffect(() => {
    firestore
      .collection('products')
      .where('eventId', '==', eventContext.event.id)
      // .limit(2)
      .onSnapshot((snap) => {
        const products = snap.docs?.map<{
          title: string;
          description: string;
          cover: string;
          urlAudio: string;
          createdAt: number;
        }>((item) => ({
          ...(item.data() as any),
          id: item.id,
        }));
      if(products.length === 0){
        setIsComplete(true)
      }
        setProducts(products);
      });
  }, []);

  //next button function
  const showNext = (item: number ) => {
      const fetchNextData = () => {
           setLoading(true);
           firestore.collection('products')
          .orderBy('createdAt', 'asc') //order using firestore timestamp
          .limit(2)
          .startAfter(item || 0) //we pass props item's first created timestamp to do start after you can change as per your wish
          .onSnapshot(function (querySnapshot) {
            const list: any[] | ((prevState: { title: string; cover: string; description: string; createdAt: number;  }[]) => { title: string; cover: string; description: string; createdAt: number;  }[]) = [];
              querySnapshot.docs?.forEach(function(doc) {
                list.push({ id: doc.id, ...doc.data() });
            });
            if(list.length === 0){
              setIsComplete(true)
            }
            setProducts(oldArray => [...oldArray, ...list]);
            setLoading(false);
          })
      };
      fetchNextData();
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      SetIsMobile(window.matchMedia('(max-width: 992px)').matches);
    });
  });

return (
  <>
    {isMobile ? (
        <S.Container>
          <S.ProductsContainerItems>
            <HeaderPageDescription title={`${t("Products")}`}/>
           {products.map((product: any, index) => (
             <div>

               <S.Card theme={theme}
               delay={index * 0.0125}
               key={`product-item-${product.title}-${index}`}>
                 {product.cover && (
                     <S.CardImageContainer>
                      <S.CardImage alt="400x220" src={product.cover} />
                   </S.CardImageContainer>
                 )}

                 <S.CardProduct theme={theme}>
                     {arrayIds.includes(index) && (
                       <div style={{marginBottom: '50px'}}></div>
                     )}
                   <S.CardFooter theme={theme}>
                       <S.ConteinerSeeMore>
                         <a onClick={ () => handleOutsideClickOpen(product.id)} style={{color: '#1492E6'}}>
                           {t("See More")}
                         </a>
                    </S.ConteinerSeeMore>
                   </S.CardFooter>
                 </S.CardProduct>
             </S.Card>
             {isModalVisible === product.id ? (
                 <ModalProduct
                   onClose={handleOutsideClickOnClose}
                   key={`modal-product-${index}`}
                   product={product}
                 />
               ) : null}
             </div>

           ))}
           {/* <div style={{display: 'flex', justifyContent:'center'}}>
           {loading ? (
           <Spinner animation="border" role="status" variant="light">
             <span className="sr-only" style={{ color: '#fff' }}>
              {t("Loading")}
             </span>
           </Spinner>
         ) : (
           <S.ShowMoreButton isComplete = {isComplete} onClick={() => showNext(products[products.length-1].createdAt)}>
             <IoMdRefresh size={30} color = "#fff"/>
               <span>
                 {t("Load More")}
               </span>
           </S.ShowMoreButton>
         )}
           </div> */}
         </S.ProductsContainerItems>
       </S.Container>
    ): (
      <Redirect to={`/${eventContext?.eventKey}/signin`}/>
    )}
  </>
  );
};
export default ProductGrid;
