import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner } from './style';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';
import { asEnumerable } from 'linq-es2015';
import { useEvent } from '../../../hooks/EventContext';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

interface ChatProps {
  user: any;
  channelId: string;
  eventId: string;
}

const Disclaimer: React.FC<ChatProps> = (props: any) => {
  const { t, i18n } = useTranslation();
  const eventContext = useEvent();
  const [dataUrl, setDataUrl] = useState<any>('');

  useEffect(() => {
    eventContext.channel?.resources.forEach((element: any) => {
      if (element.key == 'disclaimer') {
        setDataUrl(element.data);
      }
    });
  }, [props.channelId]);

  return (
    <Conteiner>
      <main className="container">
        <div className="mt-4">
          {' '}
          {/* row mt-4*/}
          <div className="col">
            {' '}
            {/*col */}
            <div className="agenda-area">
              {parse(`${dataUrl[i18n.language]}`)}
            </div>
          </div>
        </div>
      </main>
    </Conteiner>
  );
};

export default Disclaimer;
