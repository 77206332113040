
import styled from 'styled-components'

export const ContactContainer = styled.div`
  width:100%;
  font-family:inherit;

.contact-page-header{
  margin-top:32px;
  background:none;
  padding:2rem 1rem;
  height:initial;
  h1{
    text-align: center;
    font-size: 1.5rem;
    line-height: 1;
    box-sizing: border-box;
    color: ${props => props.theme.colors.text};
}

p{
    font-size: 1rem;
    line-height:1.4;
    padding:0;
    margin:0;
    text-align: center!important;
    color: ${props => props.theme.colors.text};
}
}

.contact-page-form{
  background:#fff;
  max-width:750px;
  margin: 0 auto;
  padding:2rem;
  border-radius:5px;

  .contact-input-block-container {
      display: grid;
      width: 100%;
      grid-gap: 16px;
      grid-template-columns:1fr 1fr;
      margin-bottom: 44px;

      label{
          font-size: 16px
      }

      input {
          background: white;
          border-radius: 3px;
          border: 1px solid #9b9b9b;
          width: 100%;
          padding: 8px 16px;
          display: flex;
          align-items: center;
          color: #111;
          font-size: 16px;
          &::placeholder{
            color:#9b9b9b
          }
      }
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }
      input[type=number] {
          -moz-appearance:textfield;
      }
    }
    .contact-page-button-submit{
      padding:1rem 2rem;
      width:100%;
      text-align:center;
      border:0;
      cursor:pointer;
      text-transform:uppercase;
      background:#111;
      color:#fff;
      font-family:inherit;
      border-radius:3px;
      &:hover{
        opacity:.9
      }
    }
  }



.contact-page-radio-container{
    width:100%;
    display:flex;
    flex-direction:column;
    gap:8px;
    /* padding: 0 120px; */

    .contact-page-radio-input-block {
      display: flex;
      align-items: center;
      padding-left: 28px;
      position: relative;
      cursor: pointer;
      width: 100%;
      /* -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; */

      input {
        display: none;
      }
      .checkmark {
        position: absolute;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #e0e0e0;
        border-radius: 50%;
        border: 2px solid #9b9794;
      }
      .checkmark:hover  {
      opacity:.8
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  .contact-page-radio-input-block input:checked ~ .checkmark {
    background-color: #1f1f1f;
  }
/*
    .contact-page-radio-input-block


    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    } */
}



.description {
    height: 200px;
    margin-top: 50px;
    background: #fff;
    border-radius: 2px;
    border: 1px solid #9b9b9b;
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    color: #111;
}

textarea {
    overflow: auto;
    resize: vertical;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

@media (max-width: 764px) {

.contact-page-form{
  max-width:750px;
  margin: 0 auto;
  padding: 2rem 1rem;
  .contact-input-block-container{
    grid-template-columns:1fr
  }

}

}
`
export const Content = styled.div`
  max-width:1000px;
  margin: 0 auto;
  padding:1rem;
  position:relative;
  .contact-page-message{
    bac
  }
`






