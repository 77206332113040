import React from 'react';
import { NavLink } from 'react-router-dom';
import { useEvent } from '../../hooks/EventContext';
import { useAuth } from '../../hooks/Auth2';
import AvatarIcon from '../../assets/images/avatarP.png';
import { Button } from './style';

import { Icon } from '../Icons';
import './style.css';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../hooks/ThemeContext';

export const MenuDropDownUser = () => {
  const { getUser } = useAuth();
  const eventContext = useEvent();
  const user = getUser();
  const { t } = useTranslation();
  const {theme,toggleTheme} = useTheme();

  const [showMenu, setShowMenu] = React.useState(false);

  function changeMenu() {
    setShowMenu(!showMenu);
  }
  return (
    <>
      {showMenu && <div className="container-close-dropdown" onClick={changeMenu}></div>}
      <div className={`menu-dropdown-container ${showMenu && 'active'}`}>
        <div className="menu-dropdown-username" onClick={changeMenu}>
          <img src={user?.avatar ? user?.avatar : AvatarIcon} width="24" alt="" className="menu-dropdown-user-image" />
          <div>
            {user?.name || user?.email}
          </div>
          <Icon name="iconArrowDown" />
        </div>
        <Button theme={theme} onClick={() => toggleTheme()}><Icon name='iconDarkMode' /></Button>

        {showMenu && <nav className={`menu-dropdown-nav`}>
          <NavLink to={`/${eventContext?.eventKey}/profile`}>
            <Icon name="iconUser" />
            {t("My Profile")}
          </NavLink>
          <NavLink to={`/${eventContext?.eventKey}/logout`}>
            <Icon name="iconLogout" />
            {t("Logout")}
          </NavLink>
        </nav>}
      </div>
    </>
  )
}
