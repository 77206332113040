import React, { useEffect, useState } from 'react';
import 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/app';

interface Props {
  channelId?: string;
  eventId?: string;
  channelName?: string;
}

const UsersCountComponent: React.FC<Props> = ({eventId,channelId,channelName}: any) => {
  const [totalOnline, setTotalOnline] = useState(0);
  const { t } = useTranslation();
  const gap = 1000 * 60 * 3; // seconds gap to check online users

  useEffect(() => {
    const time = new Date().getTime() - gap * 2.5;
    firebase
      .firestore()
      .collection('presence')
      .where('eventId', '==', eventId)
      .where('channelId', '==', channelId)
      .where('ping', '>=', time)
      .get()
      .then((snap) => {
        const $presence = snap.docs.map((v) => ({ id: v.id, ...v.data() }));

        const $presences = $presence?.filter((p: any) => {
          const diff = new Date().getTime() - p.ping;
          const validation = diff <= gap;


          return validation;
        });
        setTotalOnline($presences.length);

        console.log('Get presences', { presences: $presences.length, channelId: channelId, channelName: channelName });
      })
      .catch((e) => {
        console.log('Get presence error', e);
      });
  }, [eventId,channelId,channelName]);

  return (
    <>
      <span>{totalOnline}</span>
    </>
  );
};

export default UsersCountComponent;
