import React from 'react';
import { useTranslation } from 'react-i18next';
import {useTheme} from '../../hooks/ThemeContext';
import VideoView from '../VideoView';
import { BsCalendarWeek } from 'react-icons/bs';
import moment from 'moment';
import {Container} from './style';
import './ModalContent.css';

const ModalContent: React.FC<any> = ({
  id = 'modal',
  onClose = () => { },
  content,
}) => {
  const { i18n } = useTranslation();
  const handleOutsideClick = (e: any) => {
    if (e.target.id === id) onClose();
  };
  const {theme} = useTheme();
  // let preferenceAudios: string = localStorage.getItem('@preferenceAudio') || '';
  // const setPreferenceAudio = (audio: string) => {
  //   localStorage.setItem('@preferenceAudio', audio);
  //   setPreferenceAudios(audio);
  // };
  // const [preferenceAudio, setPreferenceAudios] =
  // useState<string>(preferenceAudios);

  // useEffect(() => {
  //   const pre: string = localStorage.getItem('@preferenceAudio') || '';
  //   if (channel?.voiceSettings) {
  //     const daLang: EntityBodyLANG[] = channel?.voiceSettings;
  //     if (daLang.findIndex(e => e.id === pre) >= 0) {

  //     } else {
  //       setPreferenceAudios('')
  //     }
  //   }
  // }, []);

  return (
    <div id={id} className="modalContents" onClick={handleOutsideClick}>
      <Container className="containerContents" theme={theme}>
        <button className="close" onClick={onClose}></button>
        {content?.cover ?(
            <img
            className="img-fluid imgContentModal"
            src={content?.cover}
            alt=""
          />
          ):
          <VideoView
          preferenceAudioId={content.urlAudio}
          style={{ width: '100%', height: '100%' }}
          />
        }
        <div className="content">

          <div className='infosContentContainer'>
           <div className="inforContent">
             <div className='infoCalendar'>
               <BsCalendarWeek/>
               <p>{moment(content.createdAt).format('DD/MM/YYYY')}</p>
             </div>

            <h5>{content?.title}</h5>
           </div>
            <div className="inforContentDescription">
            <p
              className="p-li"
              dangerouslySetInnerHTML={{
                __html: content?.description,
              }}
              style={{lineBreak: 'anywhere', maxWidth: '95%'}}
            />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ModalContent;
