import styled, { css, keyframes } from 'styled-components';

interface INavItem {
  isActive?: boolean;
}
interface IContainer {
  theme: string;
  customization: any;
}
const show = keyframes`
  to{
    transform: initial;
    opacity: initial;
  }
`
export const ContainerHeader = styled.div<IContainer>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => css`
      background: linear-gradient(
        -45deg,
        ${props.customization?.headerBgColor1 || 'rgba(0,0,0, 0.2)'} 20%,
        ${props.customization?.headerBgColor2 || 'rgba(0,0,0, 0.2)'} 80%
      );
`}
  background: ${props => props.theme.title === 'dark' && props.theme.colors.background};

  /* background: ${props => props.theme.colors.background}; */
  a.active{
    &::after{
      background:${props => props.theme.colors.navItem};
      animation: ${show} 0.1s linear forwards;
    }
    fill: ${props => props.theme.colors.navItem};
    color:${props => props.theme.colors.navItem};
    svg, svg path{
      fill: ${props => props.theme.colors.navItem};
    }
  }
`;

export const ContentHeader = styled.div`
  max-width: 1200px;
  width: 100%;
  padding: 0 1rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap:2rem;

  @media(min-width:1920px){
    max-width: 1745px;
    padding: 0 8.75rem;
  }
`;

export const HeaderColumnLeft = styled.div``;

export const HeaderColumnCenter = styled.div`
  flex:1;
  width: 100%;

  @media only screen and (max-width: 992px){
    display: none;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
`;

export const NavItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.5rem;
  flex:1;
  max-width: 140px;
  cursor: pointer;
  height: 100%;
  position: relative;
  cursor: pointer;
  a{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    color: #fff;
    font-size: 10px;
    font-weight: light;
    letter-spacing: 1px;
    text-transform: uppercase;
    svg, svg path{
      height: 28px;
      fill: #fff;
    }
    &::after{
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background: transparent;

      transform: scaleX(0.9);
      opacity: 0.6;
    }

  }

  &:hover{
    a::after{
      background: #fff;
      animation: ${show} 0.1s linear forwards;
    }
  }
`;

export const HeaderColumnRight = styled.div`
  display: flex;
  align-items: center;
  gap:16px;
`;

export const HeaderLanguage = styled.div``;

