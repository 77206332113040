import React from 'react';
import { useTranslation } from 'react-i18next';
import {useTheme} from '../../hooks/ThemeContext';
import {Container} from './style';
import './ModalSpeakers.css';

const ModalSpeakers: React.FC<any> = ({
  id = 'modal',
  onClose = () => { },
  speaker,
}) => {
  const { i18n } = useTranslation();
  const handleOutsideClick = (e: any) => {
    if (e.target.id === id) onClose();
  };
  const {theme} = useTheme();

  return (
    <div id={id} className="modalSpeakers" onClick={handleOutsideClick}>
      <Container className="containerSpeakers" theme={theme}>
        <button className="close" onClick={onClose}></button>

        <div className="content">
          {speaker?.picture && (
            <img
            className="img-fluid imgSpeakerModal"
            src={speaker?.picture}
            alt=""
          />
          )}
          <div className='infosSpeakerContainer'>
           <div className="inforSpeaker">

            <h2>{speaker?.name}</h2>
            <p>{speaker?.profession?.[i18n.language] || '' }</p>
           </div>
            <div className="inforSpeakerDescription">
            <p
              className="p-li"
              dangerouslySetInnerHTML={{
                __html: speaker?.description?.[i18n.language],
              }}
              style={{lineBreak: 'auto', maxWidth: '100%'}}
            />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ModalSpeakers;
