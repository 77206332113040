import React from 'react';
import DefaultMasterPage from '../../masterpages/DefaultMasterPage';
import { Link, useHistory } from 'react-router-dom';
import { AnimationContainer } from './styles';
import { useEvent } from '../../hooks/EventContext';
import { useAuth } from '../../hooks/Auth2';

const Home: React.FC = (props: any) => {


  const eventContext = useEvent();
  const auth = useAuth();
  const user = auth.getUser();
  const history = useHistory();
  
  if (user?.id && eventContext.event) {
    history.replace(`/${props.params.event}/${eventContext.event?.pagestart || 'dashboard'}`);
  }
  else if (!user?.id && !eventContext.event) {
    history.replace(`/${props.params.event}/${eventContext.event?.pagestart || 'signin'}`);
  }

  return (
    <DefaultMasterPage {...props} scheme="frm-home pt-5">
      <AnimationContainer>
      {eventContext.event?.newAccount && 
        <div className="content text-center">
          <h2>Acesse sua conta</h2>
          <p>Faça login ou crie uma conta para acessar</p>
          <Link to={`/${eventContext.eventKey}/signin`} className="action-button">
            Fazer Login 
          </Link>
          <span className="ou"> ou </span>
          <Link to={`/${eventContext.eventKey}/signup`} className="action-button">
            Criar uma conta
          </Link>
        </div>
        }

      {!eventContext.event?.newAccount && 
        <div className="content text-center">
          <h2>Acesse sua conta</h2>
          <p>Faça login</p>
          <Link to={`/${eventContext.eventKey}/signin`} className="action-button">
            Fazer Login 
          </Link>
         
        </div>
        } 
      </AnimationContainer>
    </DefaultMasterPage>
  );
};

export default Home;
