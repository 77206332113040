import React, {
  ButtonHTMLAttributes,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner } from './style';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import api from '../../services/api';
import { asEnumerable } from 'linq-es2015';
import { useToast } from '../../hooks/Toast';
import $ from 'jquery';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ContainerButton from '../../components/ContainerButton';
import { useEvent } from '../../hooks/EventContext';
import useWindowDimensions from '../../hooks/useDimension';
import { useAuth } from '../../hooks/Auth2';

interface SurveyProps {
  user: any;
  channelId: string;
  style?: any;
}

interface ISurveyQuestion {
  id: string;
  channelId: string;
  eventId: string;
  index: number;
  responseIndex: number;
  question: string;
  active: boolean;
  options: { id: string; text: string; index: number }[];
  user: {
    email: string;
    id: string;
  };
}

// var ref = rootRef.child("survey");
// ref.on('value').then(function (snapshot) {
//   snapshot.forEach(function (childSnapshot) {
//     var key = childSnapshot.key;
//     var childData = childSnapshot.val();
//   });
// });

interface IResponseData {
  index: number;
}

let responsesObj: any = {};

const Survey: React.FC<SurveyProps> = (props) => {
  const { getUser } = useAuth();
  const user = getUser();

  const { addToast } = useToast();
  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    errors,
    getValues,
  } = useForm<IResponseData>();
  const firestore = firebase.firestore();
  const [counter, setCounter] = useState<number>(0);
  const { responses, polls } = useEvent();
  const [newResponsesObj, setNewResponsesObj] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [savedSurveys, setSavedSurveys] = useState<{ [key: string]: boolean }>({});
  const eventContext = useEvent();

  function verifyResponses(responses: any[]): void {
    for (let item of responses) {
      try {
        setValue('option-' + item.surveyId, item.index.toString());
      } catch (error) { }

      if (responsesObj[item.surveyId] === undefined) {
        responsesObj[item.surveyId] = item.index;
        setNewResponsesObj(responsesObj);
      }
    }
  }

  useEffect(() => {
    verifyResponses(responses);
  }, [responses]);

  async function sendFunctionMessage(surveyId: string, index: string) {
    const data = {
      channelId: props.channelId,
      surveyId: surveyId,
      index: index,
    };

    const ref = await api.post('/survey/response', data);

    addToast({
      type: 'success',
      title: 'Resposta enviada com sucesso',
      description: 'Obrigado por sua participação',
    });

    setSavedSurveys((prev) => ({ ...prev, [surveyId]: true }));
  }

  async function handleSendMessage(surveyId: string) {
    try {
      setLoading(true);
      const data = {
        index: newResponsesObj[surveyId],
        surveyId,
      };

      const schema = Yup.object().shape({
        [`index`]: Yup.string().required(
          'É obrigatório a escolha de uma resposta!',
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await sendFunctionMessage(data.surveyId, data.index);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Falha ao enviar resposta',
        description:
          error?.response?.data?.message ||
          error.message ||
          'Ocorreu um erro ao fazer cadastro, tente novamente.',
      });
    }
  }

  function optionChange(surveyId: string, index: number) {
    let response = { ...newResponsesObj };
    response[surveyId] = index;
    setNewResponsesObj(response);
  }

  const { width } = useWindowDimensions();
  const largItem =
    width <= 500
      ? width - 50
      : Number(
        width <= 920
          ? Number(width - 150)
          : Number(width >= 1500 ? 1500 - 500 : width - 500),
      );

  const [isMobile, SetIsMobile] = useState(
    window.matchMedia('(max-width: 992px)').matches,
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      SetIsMobile(window.matchMedia('(max-width: 992px)').matches);
    });
  });

  const handleOptionChange = (surveyId: string, index: number) => {
    setNewResponsesObj((prev: any) => ({
      ...prev,
      [surveyId]: index,
    }));
  };

  return (
    <Conteiner id="survey" style={props.style} customization={eventContext?.event?.customization || {}}>
      {/* <p className="bp-title mb-4">Enquete</p> */}

      <fieldset className={'survey-fieldset form-group message-list ' + (!isMobile ? 'surveydesktop' : ' surveymobile')}>
        {polls.map((survey: any, index) => (
          <form key={index} className={`survey-form`}>
            <div
              className="survey-form-container"
            >
              <div className="survey-form-header">
                <h2
                  className="survey-form-title">
                  {polls.length - index + '. ' + survey.question}
                </h2>
              </div>
              <div className="survey-form-content">
                {!survey.showResponses ? (
                  survey.options?.map((option: any, i: number) => (
                    <div className="survey-form-item-container" key={i}>
                      <label className="survey-form-item-label" htmlFor={`option-${i}-${index}`}>
                        <input
                          checked={
                            newResponsesObj[survey.id]?.toString() ==
                            i.toString() ||
                            responsesObj[survey.id]?.toString() ==
                            i.toString()
                          }
                          // onChange={(event: any) =>
                          //   optionChange(survey.id, i)
                          // }
                          onChange={() => handleOptionChange(survey.id, i)}
                          id={`option-${i}-${index}`}
                          type="radio"
                          radioGroup={`${survey.id}`}
                          name={`option-${survey.id}`}
                          value={i}
                          // disabled={true} 
                          disabled={!!savedSurveys[survey.id]}
                          // disabled={savedSurveys[survey.id]} 
                        />
                        <span className="survey-form-item-checkmark" >
                          <span className="fas fa-check survey-icon"></span>
                        </span>
                        {option.text}

                      </label>
                    </div>
                  ))
                ) : (
                  <>
                    {survey.options?.map((option: any, i: number) => (
                      <div
                        className="survey-form-results-container"
                        key={i}
                      >
                        <label className="survey-form-item-label-result">
                          {option.text}
                        </label>

                        {survey['percent-' + i] <= 30 && (
                          <ProgressBar
                            animated
                            variant="warning"
                            label={`${survey['percent-' + i]}%`}
                            now={survey['percent-' + i]}
                          />
                        )}

                        {survey['percent-' + i] > 30 &&
                          survey['percent-' + i] <= 70 && (
                            <ProgressBar
                              animated
                              variant="info"
                              label={`${survey['percent-' + i]}%`}
                              now={survey['percent-' + i]}
                            />
                          )}

                        {survey['percent-' + i] > 71 && (
                          <ProgressBar
                            animated
                            variant="success"
                            label={`${survey['percent-' + i]}%`}
                            now={survey['percent-' + i]}
                          />
                        )}

                        {/*<div className="progress">

                                         <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{ width: `${survey['percent-' + i]}%` }}
                                          aria-valuenow={survey['percent-' + i]}
                                          aria-valuemin={0}
                                          aria-valuemax={100}>{survey['percent-' + i]}% </div>

                                      </div>*/}
                      </div>
                    ))}
                  </>
                )}
                {responsesObj[survey.id] === undefined &&
                  !survey.showResponses && (
                    <div
                    >
                      <ContainerButton
                        loading={loading}
                        classe="survey-form-btn-send"
                        click={() => handleSendMessage(survey.id)}
                      >
                        <p>
                          Salvar

                        </p>
                        <i className="fas fa-paper-plane"></i>{' '}
                      </ContainerButton>
                      {/* <button
                            type="button"
                            onClick={() => handleSendMessage(survey.id)}
                            className="buttonSendSurvey">Enviar resposta <i className="fa fa-paper-plane" style={{ color: '#fff' }}></i> </button>
                          */}
                    </div>
                  )}
              </div>
            </div>
          </form>
        ))}
      </fieldset>
      {/* <fieldset className="form-group message-list" style={{ padding: '10px', height: 'auto', minHeight: '545px', maxHeight: '545px', overflow: 'hidden', overflowY: 'auto' }}>
        {
          polls.map((survey: any, index) => (
            <form key={index}>
              <span className="col-sm-12" style={{ padding: '10px' }}>{(polls.length - index) + ') ' + survey.question}</span>
              <div className="row" style={{ padding: '10px' }}>
                <div className="col-sm-12">
                  {
                    !survey.showResponses ?
                      survey.options?.map((option: any, i: number) => (
                        <div key={i} className="form-check">

                          <input checked={newResponsesObj[survey.id]?.toString() == i.toString() || responsesObj[survey.id]?.toString() == i.toString()} onChange={(event: any) => optionChange(survey.id, i)} id={`option-${i}-${index}`} className="form-check-input" type="radio" radioGroup={`${survey.id}`} name={`option-${survey.id}`} value={i} />
                          <label htmlFor={`option-${i}-${index}`} className="form-check-label">
                            &nbsp; {option.text}
                          </label>
                        </div>
                      )) : <>
                        {
                          survey.options?.map((option: any, i: number) => (
                            <React.Fragment key={i}>
                              <label className="form-check-label">
                                &nbsp; {option.text}
                              </label>
                              <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: `${survey['percent-' + i]}%` }} aria-valuenow={survey['percent-' + i]} aria-valuemin={0} aria-valuemax={100}>{survey['percent-' + i]}%</div>
                              </div>
                            </React.Fragment>
                          ))
                        }
                      </>
                  }
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-12">
                  {(responsesObj[survey.id] === undefined) && !survey.showResponses && <button type="button" onClick={() => handleSendMessage(survey.id)} className="btn btn-primary">Enviar resposta</button>}
                </div>
              </div>
            </form>
          ))
        }
      </fieldset> */}
    </Conteiner>
  );
};

export default Survey;
