import React from 'react';
import OpenMasterPage from '../../masterpages/OpenMasterPage';
import { AnimationContainer } from './styles';


const Default: React.FC = (props: any) => {

  return (
    <OpenMasterPage noContent={true}>
      <AnimationContainer>
      </AnimationContainer>
    </OpenMasterPage>
  );
};

export default Default;
