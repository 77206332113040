import styled, { keyframes } from 'styled-components';

interface PropsIcon {
  isActive: boolean;
}
const show = keyframes`
  to{
    transform: initial;
    opacity: initial;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* overflow: auto; */
  padding: 0 0rem 2rem;
  gap: var(--grid-gap-default);

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 20px;
  }

  @media (max-width: 764px) {
    padding: 0;
    /* padding: 0 1rem 2rem 0; */
  }
`;

export const Card = styled.div`
  /* width: 100%; */
  min-height: 420px;
  width: calc(33% - var(--grid-gap-default) / 2);
  max-width: 100%;
  /* min-width: 400px; */
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  user-select: none;

  transform: scale(0.95);
  opacity: 0;
  animation: ${show} 0.2s forwards;
  background: ${(props) => props.theme.colors.grayColor};

  @media (max-width: 830px) {
    width: calc(50% - var(--grid-gap-default) / 2);
  }

  @media (max-width: 764px) {
    width: 100%;
    /* min-width: 340px; */
  }
`;

export const CardImageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 220px;
  display: flex;
  position: relative;
`;
export const CardImage = styled.img`
  width: 100%;
  height: 220px;
  max-height: 220px;
  object-fit: cover;
  border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);

  opacity: 0;
  animation: ${show} 0.2s forwards;

  @media (max-width: 764px) {
    height: initial;
  }
`;

export const CardImageIcons = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .card-channel-icon-live {
    font-family: var(--fontFamily);
    font-weight: 400;
    font-size: 10px;
    background: #02c90f;
    text-transform: uppercase;
    color: #fff;
    border-radius: 20px;
    line-height: 1;
    padding: 4px 14px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-right: 10px;
  }
  svg path {
    height: 16px;
    fill: #fff;
    color: #fff;
  }
`;

// ---------- Content Card

export const CardContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem 1.75rem;
  flex-direction: column;
  gap: 8px;
  position: relative;

  opacity: 0;
  animation: ${show} 0.2s forwards;
  animation-delay: 0.1s;
`;

export const CardSpeakers = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  transform: translateY(-50%);
  padding: 0 1.25rem;

  img {
    width: 100%;
    height: 100%;
    max-width: 54px;
    max-height: 54px;
    object-position: contain;
    border-radius: 30px;
    filter: drop-shadow(0px 0px 5px rgb(0 0 0 / 10%));
  }
  /* margin-bottom: 50%; */
`;

export const CardInfoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
`;
export const CardTitle = styled.h2`
  font-family: var(--fontFamily);
  font-weight: 500;
  color: ${(props) => props.theme.colors.text};
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
`;

export const CardInfoText = styled.p`
  font-family: var(--fontFamily);
  font-weight: 400;
  color: ${(props) => props.theme.colors.paragraph};
  font-size: 14px;
  flex: 1;
  height: 100%;
  line-height: 1.25rem;
  max-height: 5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
`;
// ---------- Footer
export const CardFooter = styled.div`
  display: flex;
  border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 12px 16px;
  box-shadow: 0px -13px 24px #0000001c;
  margin-top: 1.5rem;
  background: ${(props) => props.theme.colors.primary};
`;

export const CardButtonAccess = styled.a`
  cursor: pointer;
  background: #02c90f;
  color: #fff;
  font-family: var(--fontFamily);
  border-radius: 6px;
  font-size: 12px;
  font-weight: 400;
  padding: 8px 24px;
  letter-spacing: 1px;
  transition: all 0.2s;
  &:hover {
    background: #10ad1a;
    color: #fff;
  }
`;

export const ContainerIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  button.card-channels-item-icon {
    padding: 4px 4px 4px 8px;
    height: calc(18px + 8px); // Height + padding
    border: 0;
    background: none;
    display: flex;
    align-items: baseline;
    gap: 4px;
    color: #b9b9b9;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: rgba(2, 2, 2, 0.8);
    }
  }
  svg,
  svg path {
    width: 18px;
    height: 18px;
    fill: #b9b9b9;
    transition: all 0.2s;
  }
  .icon-heart:hover path {
    fill: rgba(230, 42, 89, 0.8);
  }
  .icon-people:hover path {
    fill: rgba(2, 2, 2, 0.8);
  }
`;
