import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner } from './style';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';
import { asEnumerable } from 'linq-es2015';
import { useEvent } from '../../../hooks/EventContext';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import DetailsPartner from './DetailsPartner';

interface ChatProps {
  user: any;
  channelId: string
  eventId: string
};

const Partners: React.FC<ChatProps> = (props: any) => {
  const { t, i18n } = useTranslation();
  const eventContext = useEvent();
  const [dataUrl, setDataUrl] = useState<any>('');

  useEffect(() => {

    eventContext.channel?.resources.forEach((element: any) => {

      if (element.key == 'partners') {
        setDataUrl(element.data)
        console.log(element.data)
      }
    });

  }, [props.channelId])

  return (
    <Conteiner>


      <div className="flex-container">

        {dataUrl &&
          dataUrl.map((item: any, i: number) =>
            (
              <React.Fragment key={i}>
                {

                  <div className="roomBox"  >
                    <div className="room" style={{textAlign:'center'}}>
                      <DetailsPartner {...props} item={item} />
                    </div>
                  </div>

                }

              </React.Fragment>


            )
          )}

      </div>
    </Conteiner>
  );
};

export default Partners;
