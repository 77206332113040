import firebase from 'firebase';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Icon } from '../../../Icons';
import * as Yup from 'yup';
import moment from 'moment';
import { useToast } from '../../../../hooks/Toast';
import avatarImg from '../../../../assets/images/avatar.png';

import './style.css';
import { useEvent } from '../../../../hooks/EventContext';
import { useAuth } from '../../../../hooks/Auth2';
interface IProps {
  item: IComments[];
}
interface IComments {
  user: {
    name?: string;
    avatar?: string;
  }
  createdAt?: string;
  comment?: string;
}
export const ModalTimeline: React.FC<IProps> = (props: any) => {
  const { register, setValue, handleSubmit, errors } = useForm<FormData>();
  const firestore = firebase.firestore();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const { event, channel } = useEvent();
  const { getUser } = useAuth();
  const user = getUser();

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      setLoading(true);

      const schema = Yup.object().shape({
        userComment: Yup.string().required('O comentário é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: true,
      });

      const entity: any = {
        createdAt: moment.utc().valueOf(),
        comment: data.userComment,
        channelId: channel?.id || 'lobby',
        eventId: event.id,
        user: {
          name: user?.name,
          email: user?.email,
        },
        uid: user?.id || user?.uid,
      };
      const ref = firestore.collection('timeline').doc(props.item.id);
      await ref.update({
        comments: firebase.firestore.FieldValue.arrayUnion(entity),
      });

      setValue('userComment', '');
      addToast({
        type: 'success',
        title: 'Post enviado',
        description: 'Sua mensagem foi enviada com sucesso!',
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro no envio',
        description:
          error?.response?.data?.message ||
          error.message ||
          'Ocorreu um erro ao fazer o envio, tente novamente.',
      });
    }
  });

  return (
    <div className="modalTimeline-container">
      <header className="modalTimeline-header">
        <img
          src={user?.avatar || avatarImg}
          alt="avatar"
        />
        <h2 className="modalTimeline-name">{user?.name}</h2>
      </header>
      <form className="modalTimeline-form" onSubmit={onSubmit}>
        <label htmlFor="comment">
          Digite seu comentario
          <textarea id="comment" name="userComment" ref={register}></textarea>
        </label>
        <button className="modalTimeline-form-buttonSend">Enviar <Icon name='iconSend'/></button>
        </form>
      {(props?.item?.comments || [])?.map((comment: IComments, i: number) => (
        <div key={i} className="modalTimeline-comment-container">
          <div className="modalTimeline-avatar">
          <img
            src={comment?.user?.avatar || avatarImg}
            alt="avatar"
          />
          <h2 className="modalTimeline-name">{comment?.user?.name}
            <span className="modalTimeline-hour"> {moment(comment?.createdAt).format('DD-MM-YYYY HH:mm:ss')} </span>
          </h2>
        </div>
          <div className="modalTimeline-message">

            <p>
              {comment?.comment}
            </p>
          </div></div>
      ))}
    </div>
  )
};

export default ModalTimeline;
