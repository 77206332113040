import styled from 'styled-components';
import { shade } from 'polished';

export const Conteiner = styled.div`

.youAreHere{
  position:absolute;
  top:20px;
}
#features {
    padding: 0!important;
}
.roomBox{  
  height: auto!important;
  margin: 10px!important;
  background-color:#fafafa;
  border-radius:10px;
  min-height:230px;
  border-bottom: 2px solid transparent;
  -webkit-transition: border-bottom 0.5s ease-out;
  -moz-transition: border-bottom 0.5s ease-out;
  -o-transition: border-bottom 0.5s ease-out;
    .room{
      .bgImage{
        width:100%;
        height:215px!important;
      }
    }
  }
  .roomBox:hover{
    border-bottom:2px #666 solid;

    -webkit-transition: border-bottom 0.5s ease-in;
       -moz-transition: border-bottom 0.5s ease-in;
         -o-transition: border-bottom 0.5s ease-in;

  }

  .roomBaseDescription{
    display: flex;
    justify-content: space-between;
    padding:5px;
  }
  .roomTitle{
    color:#000;
    font-size:11px;
    padding:5px;
  }
  .roomDescription:first-child{
    font-size: 11px;
    font-weight: bold;
    width: 82%;
  }
  .roomDescription:last-child{
    font-size:14px;
    font-weight:bold; 
    width:18%;
  }

  .roomHour{
    color:#333;
    text-align:right; 
  }
  .flex-container {
    display: flex;
    flex-wrap: wrap;
  }

/*Salas M.Q*/

/*DASH*/
.roomBox {
  width: 315px;
}
@media only screen and (max-width: 1198px) and (min-width: 1003px){
  .roomBox {
    width: 265px!important;
    height: auto!important;
    margin: 0px 5px!important;
    padding: 0!important;
  }
  .roomBox .room .bgImage {
    height: 230px!important;
  }
}
@media only screen and (max-width: 990px) and (min-width: 766px){
  .roomBox {
    width: 190px!important;
    height: auto!important;
    margin: 2px!important;
    padding: 0!important;
  }
}

@media only screen and (max-width: 767px) and (min-width: 556px){
  .roomBox {
    width: 219px!important;
    height: auto!important;
    margin: 3px!important;
    padding: 0!important;
  }
}

@media only screen and (max-width: 557px){
  .roomBox {
    width: 97%!important;
    height: auto!important;
    margin: 5px!important;
    padding: 0!important;
  }
  .flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .linkChannels{
    width: 100% !important;
  }
}

`;


export const Conteiner_Lobby = styled.div`

.youAreHere{
  position:absolute;
  top:20px;
}
.roomBox{  
  height: auto!important;
  margin: 10px!important;
  background-color:#fafafa;
  border-radius:10px;
  min-height:230px;
  border-bottom: 2px solid transparent;
  -webkit-transition: border-bottom 0.5s ease-out;
  -moz-transition: border-bottom 0.5s ease-out;
  -o-transition: border-bottom 0.5s ease-out;
    .room{
      .bgImage{
        width:100%;
        height:215px!important;
      }
    }
  }
  .roomBox:hover{
    border-bottom:2px #666 solid;

    -webkit-transition: border-bottom 0.5s ease-in;
       -moz-transition: border-bottom 0.5s ease-in;
         -o-transition: border-bottom 0.5s ease-in;

  }

  .roomBaseDescription{
    display: flex;
    justify-content: space-between;
    padding:5px;
  }
  .roomTitle{
    color:#000;
    font-size:11px;
    padding:5px;
  }
  .roomDescription:first-child{
    font-size: 11px;
    font-weight: bold;
    width: 82%;
  }
  .roomDescription:last-child{
    font-size:14px;
    font-weight:bold; 
    width:18%;
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;
  }

/*Salas M.Q*/
/*LOBBY*/
.roomBox{
  width: 350px!important;
}
@media only screen and (max-width: 1198px) and (min-width: 1003px){
  .roomBox {
    width: 400px!important;
    height: auto!important;
    margin: 15px!important;
    padding: 0!important;
  }
  .roomBox .room .bgImage {
    height: 230px!important;
  }
  .flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 990px) and (min-width: 766px){
  .roomBox {
    width: 335px!important;
    height: auto!important;
    margin: 5px!important;
    padding: 0!important;
  }
}

@media only screen and (max-width: 767px) and (min-width: 556px){
  .roomBox {
    width: 245px!important;
    height: auto!important;
    margin: 5px!important;
    padding: 0!important;
  }
}

@media only screen and (max-width: 557px){
  .roomBox {
    width: 315px!important!important;
    height: auto!important;
    margin: 5px!important;
    padding: 0!important;
  }
  .flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
`;