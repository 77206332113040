import React from 'react';
import { ChatProvider } from '../../hooks/ChatContext2';

import Chat from './chat';

const ChatView: React.FC<any> = (props) => {
  return (
    <ChatProvider>
      <Chat {...props} />
    </ChatProvider>
  );
};

export default ChatView;
