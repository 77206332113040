import { useState, useEffect } from 'react';
import { getQueryParam, updateQueryParam } from './utils';

function useParam(paramName: string, initialValue: string) {
  const [param, setParam] = useState(() =>
    getQueryParam(paramName, initialValue)
  );

  const updateParam = (paramValue: string) => {
    setParam(paramValue);
    updateQueryParam(paramName, paramValue);
  };

  useEffect(() => {
    const handlePopState = () => {
      const paramValue = getQueryParam(paramName, initialValue);
      setParam(paramValue);
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [paramName, initialValue]);

  const data: [string, (paramValue: string) => void] = [param, updateParam]
  return data;
}

export default useParam;
