import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiLogIn, FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useToast } from '../../hooks/Toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Input2 from '../../components/Input2';
import Button from '../../components/Button';

import { AnimationContainer } from './styles';
import api from '../../services/api';
import DefaultMasterPage from '../../masterpages/DefaultMasterPage';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../hooks/EventContext';
import { useHistory, useLocation } from 'react-router-dom';

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPassword: React.FC = (props: any) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const eventContext = useEvent();
  const { addToast } = useToast();
  const { t, i18n } = useTranslation();
  let history = useHistory();

  /**Language */
      let lang = '';
      if(!!eventContext.event?.settings?.languages){
        eventContext.event?.settings?.languages.forEach(function(item:any, index:number){
          console.log(item, index)

          if(item.default == true){
            lang = item.language;
          }
        });
      }


      i18n.language = localStorage.getItem('defaultLanguage') || lang;
  /**Language */



  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required(t('E-mail is required'))
            .email(t('Type a valid email')),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/participant/forgot-password', {
          email: data.email,
          eventKey: props.params.event,
          lang
        });

        addToast({
          type: 'success',
          title: t('Recovery Password has been sent'),
          description:
          t('We sent an email to confirm password recovery, check your inbox'),
        });

        history.push(`/${props.params.event}/reset-password`);

      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: t('Password recovery error'),
          description:t('An error occurred while trying to recover the password, please try again'),
        });
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <DefaultMasterPage {...props}  scheme="frm-recovery">
      <AnimationContainer>
        <Form ref={formRef} onSubmit={handleSubmit}>
         <h4>{t('Recovery Password')}</h4>

          <Input2 name="email" icon={FiMail} placeholder={t('Email')} />

          <div style={{marginTop: '10px'}}/>

          <Button loading={loading} loadingText={t('Loading')} type="submit" background={eventContext?.event?.customization?.buttonBackgroundColor}>
            {t('Send')}
          </Button>
          <Link className="link" to="signin">
            {t('Back to Sign In')}
          </Link>
        </Form>


      </AnimationContainer>
    </DefaultMasterPage>
  );
};

export default ForgotPassword;
