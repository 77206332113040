import React, { useState, useEffect } from 'react';
import { useEvent } from '../../hooks/EventContext';
import { useTheme } from '../../hooks/ThemeContext';
import * as S from './style';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../hooks/Auth2';
import HeaderPageDescription from '../HeaderPageDescription';
import ContainerButton from '../ContainerButton';
import * as Yup from 'yup';
import { useToast } from '../../hooks/Toast';
import { useForm } from 'react-hook-form';
import TabsView from '../TabsMenu';
import api from '../../services/api';

interface IProps {
  handleModal: () => void;
  user: any;
}
interface IResponseData {
  index: number;
}
let responsesObj: any = {};

const SurveyGrid: React.FC<IProps> = (props: any) => {
  const eventContext = useEvent();
  const firestore = firebase.firestore();
  const { theme } = useTheme();
  const { getUser } = useAuth();
  const user = getUser();
  const { addToast } = useToast();
  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    errors,
    getValues,
  } = useForm<IResponseData>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [newResponsesObj, setNewResponsesObj] = useState<any>({});
  const [eventPolls, setEventPolls] = useState<any[]>([]);
  const [responses, setResponses] = useState<any[]>([]);
  const [isMobile, SetIsMobile] = useState(
    window.matchMedia('(max-width: 992px)').matches,
  );

  function verifyResponses(responses: any[]): void {
    for (let item of responses) {
      try {
        setValue('option-' + item.surveyId, item.index.toString());
      } catch (error) { }

      if (responsesObj[item.surveyId] === undefined) {
        responsesObj[item.surveyId] = item.index;
        setNewResponsesObj(responsesObj);
      }
    }
  }

  useEffect(() => {
    verifyResponses(responses);
  }, [responses]);

  async function sendFunctionMessage(surveyId: string, index: string) {
    const data = {
      surveyId: surveyId,
      channelId: eventContext.event.id,
      index: index,
    };

    const schema = Yup.object().shape({
      [`index`]: Yup.string().required(
        'É obrigatório a escolha de uma resposta!',
      ),
    });

    await schema.validate(data, {
      abortEarly: false,
    });

    await api.post('/survey/response', data);
  }

  const handleSendMessage = handleSubmit(async (data: any) => {
    try {
      setLoading(true);

      for(var surveyId in data) {
        var value = newResponsesObj[surveyId];
        await sendFunctionMessage(surveyId, value);
    }

    addToast({
      type: 'success',
      title: 'Resposta enviada com sucesso',
      description: 'Obrigado por sua participação',
    });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Falha ao enviar resposta',
        description:
          error?.response?.data?.message ||
          error.message ||
          'Ocorreu um erro ao fazer cadastro, tente novamente.',
      });
    }
  });

  function optionChange(surveyId: string, index: number) {
    let response = { ...newResponsesObj };
    response[surveyId] = index;
    setNewResponsesObj(response);
  }

  useEffect(() => {
    firestore
      .collection('survey_question')
      .where('eventId', '==', eventContext.event.id)
      .where('active', '==', true)
      .where('status', '==', 1)
      .onSnapshot((snap) => {
        const surveys = snap.docs?.map<{
          active: boolean;
          createdAt: number;
          eventId: string;
          options: any[];
          question: string;
          showResponses: boolean;
          user: any[];
        }>((item) => ({
          ...(item.data() as any),
          id: item.id,
        }));
        setEventPolls(surveys);
      });

      firestore
      .collection('survey_response')
      .where('userId', '==', user?.id)
      .onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((a) => {
          return { id: a.id, ...a.data() } as any;
        });

        setResponses(docs);
      });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      SetIsMobile(window.matchMedia('(max-width: 992px)').matches);
    });
  });

  if(!isMobile){
     return  <Redirect to={`/${eventContext?.eventKey}/signin`}/>
  }

return (
  <>
    {isMobile && (
      <S.ContainerSurvey id="survey" style={props.style} theme={theme} customization={eventContext?.event?.customization || {}}>
        <TabsView user={user}/>
        <S.SurveysContainerItems customization={eventContext?.event?.customization || {}}>
          {eventPolls.length > 0 && (
        <>
        <HeaderPageDescription title={`${t("Survey")}`}/>
        <fieldset className={'survey-fieldset form-group message-list'}>
          <form  onSubmit={handleSendMessage}>
          {eventPolls.map((survey: any, index: any) => (
            <form key={index} className={`survey-form`}>
              <div
                className="survey-form-container"
              >
                <div className="survey-form-header">
                  <h2
                    className="survey-form-title">
                    {index + 1 + ') ' + survey.question}
                  </h2>
                </div>
                <div className="survey-form-content">
                {survey.options?.map((option: any, i: number) => (
                  <div className="survey-form-item-container" key={i}>
                    <label className="survey-form-item-label" htmlFor={`option-${i}-${index}`}>
                      <input
                        checked={
                          newResponsesObj[survey.id]?.toString() ==
                          i.toString() ||
                          responsesObj[survey.id]?.toString() ==
                          i.toString()
                        }
                        onChange={(event: any) =>
                          optionChange(survey.id, i)
                        }
                        id={`option-${i}-${index}`}
                        type="radio"
                        radioGroup={`${survey.id}`}
                        name={`${survey.id}`}
                        ref={register({ required: true })}
                        value={i}
                      />
                      <span className="survey-form-item-checkmark" >
                        <span className="fas fa-check survey-icon"></span>
                      </span>
                      <p>
                        {option.text}
                      </p>
                    </label>
                  </div>
                ))}
                </div>
              </div>
              </form>
          ))}
              <div className='container-survey-btn'>
                <ContainerButton
                  loading={loading}
                  classe="survey-form-btn-send"
                >
                  <p>
                    {t("Send")}
                  </p>
                </ContainerButton>
              </div>
            </form>
          </fieldset>
          </>
        )}
        </S.SurveysContainerItems>
       </S.ContainerSurvey>
    )
    // (
    //   <Redirect to={`/${eventContext?.eventKey}/signin`}/>
    // )}
}
  </>
  );
};
export default SurveyGrid;
