import React, { useRef, useEffect, useState } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import './styles.css';
import { uuid } from 'uuidv4';
import moment from 'moment';
import Comment from './Comment';
import { Icon } from '../../Icons';
import ModalTimeline from '../ModalTabs/ModalTimeline';
import { useEvent } from '../../../hooks/EventContext';
import firebase from 'firebase';
import { Card, Form, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useAuth } from '../../../hooks/Auth2';
import * as Yup from 'yup';
import { useToast } from '../../../hooks/Toast';
import { useForm } from 'react-hook-form';
import avatarImg from '../../../assets/images/avatar.png';
import { Conteiner } from './style';
import { useTranslation } from 'react-i18next';

interface IProps {
  handleModal: () => void;
  setContentModalTimeline: (content: React.ReactNode) => void;
}
const Gallery: React.FC<IProps> = (props: any) => {
  const ref = useRef(null);
  const [timeline, setTimeline] = useState<any[]>([]);
  const firestore = firebase.firestore();
  const { onMouseDown } = useDraggableScroll(ref, {
    direction: 'horizontal',
  });
  const { register, setValue, handleSubmit, errors } = useForm<FormData>();
  const { addToast } = useToast();
  const eventContext = useEvent();
  const { getUser } = useAuth();
  const user = getUser();
  const [sending, isSending] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    try {
      if (eventContext.event?.id) {
        const unsubscribe = firestore
          .collection('timeline')
          .where('eventId', '==', eventContext.event.id)
          // .where('channelId', '==', eventContext.channel?.id || 'lobby')
          // .orderBy('createdAt', 'desc')
          .onSnapshot((snapshot) => {
            const items = snapshot.docs.map((a) => {
              return { ...a.data(), id: a.id };
            });

            console.log('timeline snapshot', { items });

            setTimeline(
              items.sort((x: any, y: any) => {
                return y.createdAt - x.createdAt;
              }),
            );
          });
        return () => {
          unsubscribe();
        };
      }
    } catch (error) {
      console.log('timeline snapshot error', error);
    }
  }, [eventContext.event?.id]);

  function changeModal(timelineInfo: any) {
    props.setContentModalTimeline(<ModalTimeline item={timelineInfo} />);
    props.handleModal();
  }

  function onChange(e: any) {
    const file = e.target.files[0];
    if (!file) {
      setIsImageSelected(false);
    } else {
      setIsImageSelected(true);
    }
  }

  function like(timeline: any) {
    if (!user) return;
    const ref = firestore.collection('timeline').doc(timeline.id);
    timeline.likesObj = timeline.likesObj || {};
    if (!timeline.likesObj[user.id]) {
      timeline.likesObj = { ...timeline.likesObj, [user.id]: true };
      return ref.update({
        likes: firebase.firestore.FieldValue.increment(1),
        likesObj: timeline.likesObj,
      });
    } else {
      delete timeline.likesObj[user.id];
      return ref.update({
        likes: firebase.firestore.FieldValue.increment(-1),
        likesObj: timeline.likesObj,
      });
    }
  }

  return (
    <Conteiner>
      <div className="cards-timeline">
        {timeline.map((timelineInfo: any) => (
          <div className="card text-lefm">
            <div className="header-timeline">
              <div className="nav-timeline">
                <img className="avatar" src={timelineInfo?.user?.avatar} />
                <h6 className="">{timelineInfo?.user.name}</h6>
              </div>
              <p className="date marginCust">
                {moment(timelineInfo.createdAt).format('DD-MM-YYYY HH:mm:ss')}
              </p>
            </div>
            <div className="img-timeline">
              <img className="img-fluid" src={timelineInfo.imgUrl} />
            </div>
            <div className="content-timeline">
              <div className="timeline-actions">
                <div className="content-timeline-info">
                  <button
                    className="timeline-countdown-like"
                    onClick={() => changeModal(timelineInfo as any)}
                  >
                    {/* <button className="timeline-countdown-like" onClick={() => {}}> */}
                    {/* <Comment {...props} item={timelineInfo} /> */}
                    <span className="timeline-icon timeline-icon-comment">
                      <Icon name="iconComment" />
                    </span>
                    <span>{timelineInfo?.comments?.length || 0}</span>
                  </button>
                  <button
                    className="timeline-countdown-comments"
                    onClick={() => like(timelineInfo)}
                  >
                    <span className="timeline-icon timeline-icon-heart">
                      <Icon name="iconHeart" />
                    </span>
                    <span>{timelineInfo?.likes || 0}</span>
                  </button>
                </div>
              </div>
              <div className="content-timeline">
                <div className="content-timeline-text">
                  <p className="marginCust2">
                    {timelineInfo?.likes ? (
                      <>
                        {t('Liked by')}&nbsp;
                        <strong>
                          {
                            Object.entries(
                              timelineInfo?.likesObj,
                            )?.[0]?.[1] as string
                          }
                        </strong>
                        <strong>
                          &nbsp;{t('Others')} {timelineInfo?.likes || 0}{' '}
                          {t('People')}
                        </strong>
                        <br />
                      </>
                    ) : (
                      ''
                    )}
                  </p>
                  <p
                    className="fontweight marginCust2"
                    style={{ fontWeight: 'normal' }}
                  >
                    <strong>{timelineInfo?.comments?.[0]?.user?.name} </strong>
                    {timelineInfo?.comments?.[0]?.comment}
                    {/* <br /> */}
                    {/* <a href="https://www.instagram.com/explore/tags/goadiaries/">
                  #travel
                </a>
                &nbsp;
                <a href="https://www.instagram.com/explore/tags/goadiaries/">
                  #explore
                </a>
                &nbsp; */}
                    {/* <br /> */}
                  </p>
                </div>
              </div>
            </div>
            <div className="timeline-footer">
              <p
                className="date marginCust"
                onClick={() => changeModal(timelineInfo as any)}
              >
                {t('See all')} {timelineInfo?.comments?.length || 0}{' '}
                {t('Comments')}
                <br />
              </p>
            </div>
          </div>
        ))}
      </div>
    </Conteiner>
  );
};
export default Gallery;
