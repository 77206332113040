import styled from 'styled-components';
import { shade } from 'polished';
import { ContainerTabs } from '../ContainerTabs';

export const Container = styled(ContainerTabs)`
  .table{
    margin-top: 30px;
  }
`;

export const Form = styled.form`
background: none;
  border:0;
  margin-bottom:4px;
  width: 100%;
  label{
    color: #6d6d6d;
    width: 100%;
  }
  input[type="text"], input[type="number"]{
    background: #ffffff;
    border-radius: 5px;
    padding: 0 16px;
    width: 100%;
    height: 50px;
    border: 2px solid #EBEBEB;
    flex: 1;
    color: #6d6d6d;
    box-shadow: 0 0 0 30px white inset !important;
    outline: none;
    font-weight: 400;
    &::placeholder{
      color: #B9B9B9;
    }
  }
`;

export const CheckboxLabel = styled.label`
  display: flex;
  max-width: fit-content;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 1;
  padding: 14px 6px;
  input{
    width: fit-content;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap:6px;
  margin: 0 0 8px 0;

  button{
    white-space: nowrap;
    padding: 8px 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:4px;
    border:none;
    font-weight: 400;
    border-radius: 5px;
    transition: all .2s;
    i{
      margin-right: 4px;
      font-size: 14px;
      color:#fff;
    }
    &.moderator-links-button-save{
      background: #02C90F;
    }
    &.moderator-links-button-clear{
      background: #FF0051;
    }
    &:hover{
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      opacity: .8;
    }
  }
  @media(max-width:512px){
    width: 100%;
    flex:1;
    & > *, button{
      width: 100%;
      flex:1;
    }
  }
`;

export const Table = styled.table`
  width:100%;
  max-width:100%;
  overflow:auto;
  border-radius: 3px;
  border-collapse: separate;
  border-spacing: 2px;
  display: flex;
  border:1px solid #EBEBEB;
  th, td {
    border: none;
  }
  tbody{
    color:#4e4e4e;
    width: 100%;
  tr{
    &:nth-child(odd){
        td{
          background: #f3f3f3;
        }
        th{
      background: #f3f3f3;
        }
      }
    th {
      padding: 0 1.25rem;
      height: 35px;
      overflow: hidden;
      font-weight: 500;
      color: #616161;
      vertical-align: middle;
    }

    td {
      width: 100%;
      color: inherit;
      font-weight: inherit;
      padding: 1rem;
      line-height: 1.4;
      flex:1;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: .88rem;
      vertical-align: middle;
    }
  }
  @media(max-width:768px){
    tr td{
      /* white-space: nowrap; */
    }
  }
  .dropdown{
    position: initial;
    padding: 0 4px;
  }
  .table > tbody > tr > td {
    vertical-align: middle;
  }
  .table > tbody > tr > th {
    vertical-align: middle;
  }
`;
