import React, { useEffect } from 'react';
import './styles.css';
import { useWindowDimensions } from '../../../hooks/useDimension';
import {
  useIsRetrato,
  useIndexTabPlayerView,
} from '../../../context/contextplayer';
import { useEvent } from '../../../hooks/EventContext';
import firebase from 'firebase';
import { getWidth } from '../../../function/DimensionsFunction';
import { useAuth } from '../../../hooks/Auth2';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useTheme } from '../../../hooks/ThemeContext';
import {DivTabsVideo} from './style';
interface Props {
  width?: number;
}
const TabsMenu: React.FC<Props> = (props) => {
  const { isRetrato } = useIsRetrato();
  //// index é o ID => não é posição de um array
  const { indexTabPlayerView, setIndexTabPlayerView } = useIndexTabPlayerView();
  const { getUser } = useAuth();
  const {theme} = useTheme();
  const user = getUser();
  const eventContext = useEvent();

  const onActiveStyleSlector = (index: number) => {
    if (index === indexTabPlayerView) {
      return 'itemtabbarvideo itemactivetabvideoview';
    }
    return 'itemtabbarvideo';
  };
  const onChangeTabSelect = (index: number) => {
    setIndexTabPlayerView(index);
    ///muda a tab selecionada
  };
  const tabEvent = (key: string) => {
    if (eventContext?.channel?.resources) {
      return (
        eventContext.channel.resources.findIndex(
          (e: { key: string }) => e.key === key,
        ) >= 0
      );
    }
    return false;
  };

  return (
    <DivTabsVideo theme={theme}>
      {/* <OverlayTrigger
        // key={`${''}-top`}
        placement={'top'}
        overlay={<Tooltip id={`tooltip-top`}>Player</Tooltip>}
      > */}
      <span
        onClick={() => onChangeTabSelect(0)}
        className={`${onActiveStyleSlector(0)} fas fa-play-circle`}
      />
      {/* </OverlayTrigger> */}

      {/* <OverlayTrigger
        // key={`${''}-top`}
        placement={'top'}
        overlay={<Tooltip id={`tooltip-top`}>Usuários</Tooltip>}
      > */}
      <span
        onClick={() => onChangeTabSelect(4)}
        className={`${onActiveStyleSlector(4)} fas fa-users`}
      />
      {/* </OverlayTrigger> */}

      {tabEvent('survey') && (
        // <OverlayTrigger
        //   // key={`${''}-top`}
        //   placement={'top'}
        //   overlay={<Tooltip id={`tooltip-top`}>Enquete</Tooltip>}
        // >
        <span
          onClick={() => onChangeTabSelect(1)}
          className={`${onActiveStyleSlector(1)} fas fa-edit`}
        />
        // </OverlayTrigger>
      )}

      {tabEvent('ask') && (
        // <OverlayTrigger
        //   // key={`${''}-top`}
        //   placement={'top'}
        //   overlay={
        //     <Tooltip id={`tooltip-top`}>Pertunta ao palestrante</Tooltip>
        //   }
        // >
        <span
          onClick={() => onChangeTabSelect(2)}
          className={`${onActiveStyleSlector(2)} fas fa-question-circle`}
        />
        // </OverlayTrigger>
      )}

      {tabEvent('links') && (
        // <OverlayTrigger
        //   // key={`${''}-top`}
        //   placement={'top'}
        //   overlay={<Tooltip id={`tooltip-top`}>Links</Tooltip>}
        // >
        <span
          onClick={() => onChangeTabSelect(3)}
          className={`${onActiveStyleSlector(3)} fas fa-link`}
        />
        // </OverlayTrigger>
      )}

      {user?.isAttendance && (
        // <OverlayTrigger
        //   // key={`${''}-top`}
        //   placement={'top'}
        //   overlay={<Tooltip id={`tooltip-top`}>Moderação</Tooltip>}
        // >
        <span
          onClick={() => onChangeTabSelect(5)}
          className={`${onActiveStyleSlector(5)} fas fa-user-shield`}
        />
        // </OverlayTrigger>
      )}
      {user?.isModerator && Boolean(tabEvent('chat') || tabEvent('chat2')) && (
        // <OverlayTrigger
        //   // key={`${''}-top`}
        //   placement={'top'}
        //   overlay={<Tooltip id={`tooltip-top`}>Chat</Tooltip>}
        // >
        <span
          onClick={() => onChangeTabSelect(6)}
          className={`${onActiveStyleSlector(6)} fas fa-comments`}
        />
        // </OverlayTrigger>
      )}
    </DivTabsVideo>
  );
};
export default TabsMenu;
