import React from 'react';
import { useParams } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useDimension';
import DashboardMasterPage from '../../masterpages/DashboardMasterPage2';
import DefaultMasterPage from '../../masterpages/DefaultMasterPage';
import { Container, Content } from './styles';
import { useAuth } from '../../hooks/Auth2';
import FooterTerms from '../../components/FooterTerms';

const PrivacyPolicy: React.FC = (props: any) => {
  const { signOut, getUser } = useAuth();
  const user = getUser();

  React.useEffect(() => {
    try {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } catch (error) {}
  }, []);

  const { event } = useParams<{ event?: string }>();
  return !user || (user && user.eventKey !== event) ? (
    <>
      <ContentPage />
      <FooterTerms />
    </>
  ) : (
    <DashboardMasterPage {...props}>
      <ContentPage />
    </DashboardMasterPage>
  );
};

const ContentPage = () => {
  const { event } = useParams<{ event?: string }>();

  const termos_de_uso = `${window.location.origin}/${event}/terms-of-use`;
  const url_politica = `${window.location.origin}/${event}/privacy-and-policy`;
  const url_evento = `${window.location.origin}/${event}`;

  return (
    <Container>
      <Content>
        <h1 className="privacyPolicy-title">
          AVISO DE PRIVACIDADE
        </h1>
        <h1 className="privacyPolicy-title">
        Seminário Internacional “Educação Cidadã e Convivência Democrática” 
        </h1>
        <p>
          Para que você possa se inscrever no evento <b>Seminário Internacional “Educação Cidadã e Convivência Democrática</b> (“Evento”) realizado pelo Instituto Unibanco, é necessário fornecer seu nome completo e e-mail elencados no Formulário de Inscrição (“Formulário”). Esses dados pessoais são coletados para que o Instituto Unibanco possa transmitir o Evento de forma adequada. 
        </p>
        <p>
          O Instituto Unibanco observa as leis vigentes sobre segurança e proteção de Dados Pessoais (“Leis de Proteção de Dados Aplicáveis”), em especial a Lei Federal nº 13.709/2018, Lei Geral de Proteção de Dados (“LGPD”).  
        </p>
        <p className="privacyPolicy-title">
          Os dados pessoais coletados serão utilizados apenas para as finalidades relacionadas à realização/transmissão do Evento, incluindo o compartilhamento com os prestadores de serviços contratados pelo Instituto Unibanco para fins de transmissão e apoio ao Evento. Estabelecemos obrigações contratuais com os nossos parceiros para que eles também cumpram com as leis vigentes, em especial as Leis de Proteção de Dados aplicáveis e tratem os dados pessoais compartilhados apenas nos limites da prestação dos seus respectivos serviços. 
        </p>
        <p className="privacyPolicy-title">
          Além disso, seus dados pessoais também poderão ser tratados com a finalidade de envio de comunicação sobre nossas atividades, de acordo com seu perfil e histórico conosco, tais como convites para participação em atividades, eventos, pesquisas e outras ações do Instituto Unibanco. 
        </p>
        <p className="privacyPolicy-title">
          Manteremos os dados pessoais somente pelo tempo necessário para que as finalidades constantes sejam atingidas. 
        </p>
        <div className="">
        <p className="privacyPolicy-title">Ao preencher o Formulário, você declara estar ciente que:  </p>
          <ul className="privacyPolicy-list-type-letter">
            <li style={{listStyleType: 'circle'}}>
              Os dados pessoais fornecidos ao Instituto Unibanco serão tratados com segurança, de acordo com a LGPD e demais legislações; 
            </li>
            <li style={{listStyleType: 'circle'}}>
              O Instituto Unibanco não compartilhará os dados pessoais fornecidos com terceiros que não precisem ter o acesso, a menos que a lei assim o permita, ou para fins de cumprimento de obrigação legal, contratual ou regulatória, ou para atender os interesses legítimos do Instituto Unibanco; 
            </li>
          </ul>
        </div>
        <p className="privacyPolicy-title">
          Você tem direito de solicitar acesso, retificação, oposição ou eliminação dos seus dados pessoais, o que poderá interferir diretamente no seu cadastro no Evento e no seu acesso aos recursos e transmissão do Evento.   
        </p>
        <p className="privacyPolicy-title">
          Caso tenha qualquer dúvida ou queira obter mais detalhes sobre como tratamos os seus dados pessoais no Instituto Unibanco entre em contato conosco via <a href="mailto:privacidade-iu@institutounibanco.org.br" target="_blank">privacidadeiu@institutounibanco.org.br</a>.
        </p>
      </Content>
    </Container>
  );
};
export default PrivacyPolicy;
