import React, { useEffect, useState } from 'react';
import { FiHome, FiLogOut } from 'react-icons/fi';
import { Link, useParams, useHistory } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import moment from 'moment';

import NavDropdown from 'react-bootstrap/NavDropdown';
import { useAuth } from '../../hooks/Auth2';
import { useEvent } from '../../hooks/EventContext';
import { Container, AnimationContainer } from './styles';
import Languages from '../../components/Languages';

const DashboardMasterPage: React.FC = (props: any) => {
  const { event, channel } = useParams<{ channel: string; event: string }>();
  const { signOut, getUser } = useAuth();
  const user = getUser();
  const eventContext = useEvent();
  const history = useHistory()
  const [timer, setTimer] = useState<any>();

  useEffect(() => {
    eventContext.loadInfo(props.params.event, props.params.channel);
  }, [props.params.event, props.params.channel]);

  useEffect(() => {
    if (!user?.id) return () => null;

    (window.globalThis as any)?.gtag('set', { user_id: user.id });
    (window.globalThis as any)?.gtag('event', 'set_user_id', {
      user_id: user.id,
    });

    if (timer) clearInterval(timer);

    function keepAlive(): void {
      console.log('GA keep_alive', { event, channel, user });

      (window.globalThis as any)?.gtag('event', 'keep_alive', {
        time: moment().utc().valueOf(),
        event_key: event,
        channel_key: channel,
      });
    }

    keepAlive();

    const interval = setInterval(() => {
      if (!event) return;

      keepAlive();
    }, 1000 * 60 * 1);

    setTimer(interval);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    eventContext.event && (
      <AnimationContainer
        customization={eventContext.event?.customization || {}}
      >
        <Container customization={eventContext.event?.customization || {}}>
          <header id="top-header" className="headerEvent">
            <div className="container full-height">
              <div className="row align-items-center full-height">
                <div className="col-8">
                  {!!eventContext?.channels &&
                    eventContext?.event?.pagestart == 'lobby' && (
                      <>
                        <Nav variant="pills" activeKey="1">
                          <Nav.Item as="li">
                            <Link
                              style={{ color: 'white' }}
                              to={`/${props.params.event}/lobby`}
                            >
                              <FiHome
                                color={
                                  eventContext.event?.customization?.fontColor
                                }
                                size={20}
                              />{' '}
                              Lobby
                            </Link>
                          </Nav.Item>
                        </Nav>
                      </>
                    )}

                  {!!eventContext?.channels &&
                    eventContext?.event?.pagestart == 'dashboard/plenaria' && (
                      <>
                        <Nav variant="pills" activeKey="1">
                          <Nav.Item as="li">
                            {eventContext?.channels.length > 1 && (
                              <Link
                                style={{ color: 'white' }}
                                to={`/${props.params.event}/dashboard/plenaria`}
                              >
                                <FiHome
                                  color={
                                    eventContext.event?.customization?.fontColor
                                  }
                                  size={20}
                                />{' '}
                                Lobby
                              </Link>
                            )}
                          </Nav.Item>
                        </Nav>
                      </>
                    )}

                  <Nav variant="pills" activeKey="1">
                    <Nav.Item as="li">
                      <Link
                        style={{ color: 'white' }}
                        to={`/${props.params.event}/${eventContext.event?.pagestart}`}
                      >
                        <FiHome
                          color={eventContext.event?.customization?.fontColor}
                          size={20}
                        />{' '}
                        Lobby
                      </Link>
                    </Nav.Item>
                    {/* <Nav.Item as="li">
                    <Nav.Link eventKey="2" title="Lobby" href="/">
                      <FiCamera color={eventContext.event?.customization?.fontColor} size={20} /> Lobby
                    </Nav.Link>
                  </Nav.Item> */}
                    {/* <Nav.Item as="li">
                    <Nav.Link eventKey="3">
                      Timeline
                    </Nav.Link>
                  </Nav.Item>
                  <NavDropdown title="Salas" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.1">Sala 1</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.2">Sala 2</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.3">Sala 3</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="5">
                      Downloads
                    </Nav.Link>
                  </Nav.Item> */}
                  </Nav>
                </div>
                <div className="col-4">
                  <div className="d-flex justify-content-end align-items-center log-info pr-1">
                    <Languages data={eventContext.event?.settings?.languages} />

                    {!eventContext.event?.settings?.languages && (
                      <span>
                        <p style={{ cursor: 'pointer' }} onClick={() => history.push(`/${event}/profile`)}>{user?.name || user?.email}</p>
                      </span>
                    )}
                    <a className="navbar-brand" href={`/${props.params.event}`}>
                      <img
                        id="log-img"
                        className="d-none d-md-block"
                        src={eventContext.event?.customization?.logo}
                        alt=""
                      />
                    </a>

                    <a
                      className="navbar-brand"
                      href={`/${props.params.event}/logout`}
                      style={{
                        color: eventContext.event?.customization?.fontColor,
                      }}
                    >
                      <FiLogOut
                        color={eventContext.event?.customization?.fontColor}
                        size={20}
                      />
                      SAIR
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </header>

          {props.children}
        </Container>
        <br />
        <br />

        <br />
        <br />
        <footer className="d-none d-md-block foo bg-white">
          <div className="container full-height">
            <div className="row full-height">
              <div className="col-6 d-flex align-items-center">
                <img
                  src={eventContext.event?.customization?.logo}
                  className="logo"
                />
                <span className="pl-2 texts">{eventContext.event?.name}</span>
              </div>

              {/* <div className="col-6 d-flex align-items-center justify-content-end">
              <button type="submit" className="btn btn-outline-primary btn-feed">Enviar feedback</button>
            </div> */}
            </div>
          </div>
        </footer>
        <br />
        <br />
      </AnimationContainer>
    )
  );
};

export default DashboardMasterPage;
