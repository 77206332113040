import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { Container } from './styles';
import { useWindowDimensions } from '../../hooks/useDimension';
import { useIsRetrato } from '../../context/contextplayer';
import Moderator from '../../components/Moderator';
import {
  getWidth,
  getDimensionWidth,
  getWidthRooms,
} from '../../function/DimensionsFunction';
import NavBar from '../../components/navbar';
import PlayerView from '../../components/PlayerView';

import DashboardMasterPage2 from '../../masterpages/DashboardMasterPage2';
import DashboardMasterPage from '../../masterpages/DashboardMasterPage';

import Banner from '../../components/Banner';
import TabMenuRight from '../../components/TabMenuRight';
import MenuTabRoom from '../../components/MenuTabRoom';

import { useAuth } from '../../hooks/Auth2';
import { useEvent } from '../../hooks/EventContext';
import { useTranslation } from 'react-i18next';
// import { usePresence, PresenceContext } from '../../hooks/PresenceContext';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import $ from 'jquery';
import Facelive from '../../components/FaceLive';
import PresenceUtils from '../../components/PresenceUtils';
import './styles.css';

interface Resources {
  tab: boolean;
  name: string;
  key: string;
}

interface Data {
  description: string;
  eventdate: string;
  eventhour: string;
  name: string;
  resources: Resources[];
}
const Schedule: React.FC<any> = (props) => {
  const history = useHistory();
  const { event, channel } = useParams<{ channel: string; event: string }>();
  const { getUser, setUser } = useAuth();
  const user = getUser();
  const eventContext = useEvent();
  // const { registerPresence, getTotal, updatePresence, gap } = useContext(
  //   PresenceContext,
  // );
  // const { registerPresence, getTotal, updatePresence, gap } = usePresence();

  const { width, height } = useWindowDimensions();
  const [activeObj, setActiveObj] = useState<any>({}); //{ chat: true }
  const [audio, setAudio] = useState('');
  const [audioSettings, setAudioSettings] = useState<any[]>([]);
  const { t, i18n } = useTranslation();
  const { isRetrato } = useIsRetrato();
  const [channelModel, setChannelModel] = useState<any>('');

  const DATA = eventContext.event;

  // useEffect(() => {
  //   function prevent(event: any) {
  //     console.log('body scroll', { event });
  //     // event.preventDefault();
  //     // window.scrollTo(0, 0);
  //     document.body.scrollTop = 0;
  //   }

  //   document.querySelector('body')?.addEventListener('scroll', prevent, false);
  //   setTimeout(() => {
  //     document
  //       .querySelector('body')
  //       ?.removeEventListener('scroll', prevent, false);
  //   }, 5000);
  // }, []);

  useEffect(() => {
    try {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    } catch (error) {}
  }, []);

  useEffect(() => {
    // console.log('user: ', user);
    // console.log('event: ',event );
    // console.log('chanel: ', channel);
    // console.log('chanel', eventContext?.channel);
    // setChannelModel(eventContext?.channel?.customization?.channel_model || '');
  }, []);

  function enableComponent(componentName: string) {
    const obj = { ...activeObj };
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = false;
      }
    }
    obj[componentName] = true;
    setActiveObj(obj);

    // $('.' + componentName + '-tab').removeClass('active');
  }

  useEffect(() => {
    if (eventContext?.channel?.resources?.length > 0) {
      enableComponent(eventContext?.channel?.resources[0].key);
    }

    if (
      !!user?.restrictChannels &&
      Object.keys(user?.restrictChannels).length > 0 &&
      eventContext?.channel?.key != undefined
    ) {
      Object.entries<string>(user?.restrictChannels).forEach((entry) => {
        const [keyy, value] = entry;

        if (eventContext?.channel?.key == keyy) {
          user.role = value;
          setUser(user);
        }
      });
    }

    setAudioSettings(eventContext?.channel?.audio_settings);
    setChannelModel(eventContext?.channel?.customization?.channel_model || '');

    $('#iframeBox')
      .contents()
      .find('img')
      .css({ width: '100% !important', height: '90% !important' });

    return () => {};
  }, [eventContext?.event, eventContext?.channel]);

  function toogleAudio() {
    audioSettings.map(function (data) {
      // console.log(data.default);
      data.default = !data.default;

      if (data?.default == false) {
        setAudio(data.url);
        getAudio();
      }
    });
  }

  function getAudio() {
    if (!audio) return eventContext?.channel?.streaming[i18n.language] || '';
    else return audio;
  }

  useEffect(() => {
    // let intervalTotal: any = null;
    // let intervalUpdate: any = null;
    // if (eventContext?.event?.id && eventContext.channel?.id) {
    //   registerPresence(eventContext.event.id, eventContext.channel.id);

    //   if (user?.isModerator) {
    //     getTotal(eventContext?.event.id, eventContext.channel.id); // force first load
    //     intervalTotal = setInterval(() => {
    //       // console.log('getTotal interval');
    //       getTotal(eventContext?.event?.id, eventContext?.channel.id);
    //     }, 1000 * 30);
    //   }

    //   intervalUpdate = setInterval(() => {
    //     // console.log('updatePresence interval');
    //     updatePresence(eventContext?.event?.id, eventContext?.channel?.id);
    //   }, gap * 0.9);
    // }

    setChannelModel(eventContext?.channel?.customization?.channel_model || '');

    $('#iframeBox')
      .contents()
      .find('img')
      .css({ width: '100% !important', height: '90% !important' });

    // return () => {
    //   if (intervalTotal) clearInterval(intervalTotal);
    //   if (intervalUpdate) clearInterval(intervalUpdate);
    //   // console.log('Page Dashboard render unmount');
    // };
  }, [eventContext.event?.id, eventContext?.channel?.id]);

  let toggle = false;
  // $("#ocultarToggle").click(function (event) {
  //   toggle = !toggle;

  //   if (toggle) {
  //     $("#player-container").hide('slow');
  //   }
  //   else {
  //     $("#player-container").show('slow');
  //   }

  //   return false;
  // });

  const maxWid =
    width <= 500
      ? width - 50
      : Number(width >= 1500 ? Number(1500 - 500) : width - 330);
  const largItem =
    width <= 500
      ? width - 50
      : Number(
          width <= 920
            ? Number(width - 150)
            : Number(width >= 1500 ? 1500 - 500 : width - 500),
        );
  const maxWid2 =
    width <= 500
      ? width - 50
      : Number(
          width <= 920
            ? width - 150
            : Number(width >= 1500 ? Number(1500 - 500) : width - 500),
        );
  const modoRetratoCSSmenuRoom: CSSProperties = {
    width: largItem,
    // background: '#707070',
  };
  const widthVideoSS =
    width <= 500
      ? width - 50
      : Number(
          width >= 1500
            ? width >= 1500 && width <= 1920
              ? Number(1500 - 150)
              : Number(1500 - 150)
            : width - 150,
        );

  if (user?.restrictChannels) {
    const userRestrictChannels = Object.keys(user?.restrictChannels);

    if (
      userRestrictChannels.findIndex((channelKey) => channelKey === channel) < 0
    ) {
      history.push(`/${event}`);
      return null;
    }
  }

  function getHTMLGroup12() {
    return (
      <div className="table-container">
        <h3>DIA 01 - Seg. 29/11 - PRESENCIAL</h3>
        <table>
          <tr>
            <td>
              <b>Horário</b>
            </td>
            <td>
              <b>Mentor</b>
            </td>
            <td>
              <b>Aula Dinâmica</b>
            </td>
            {/* <td>
                        <b>Grupo A</b>
                    </td> */}
          </tr>
          <tr>
            <td>
              <b>13H00</b>
            </td>
            <td>Victor Manachini</td>
            <td>Aula Criatividade Aberta</td>
            {/* <td>A01 e A02 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>13H15</b>
            </td>
            <td>Victor Manachini</td>
            <td>Dinâmica Desbloqueio</td>
            {/* <td>A01 e A02 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>13H30</b>
            </td>
            <td>Kisarum Alexandre</td>
            <td>Experiência MECA</td>
            {/* <td>A01 e A02 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>14H30</b>
            </td>
            <td>Victor Manachini</td>
            <td>Aula Futuros Possíveis</td>
            {/* <td>A01 e A02 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>14H50</b>
            </td>
            <td>Victor Manachini</td>
            <td>Experiência Lentes do Futuro</td>
            {/* <td>A01 e A02 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>15H20</b>
            </td>
            <td>Todos</td>
            <td>Encerramento</td>
            {/* <td>A01 e A02 (20 pessoas)</td> */}
          </tr>
        </table>
      </div>
    );
  }
  function getHTMLGroup34() {
    return (
      <div className="table-container">
        <h3>DIA 01 - Seg. 29/11 - PRESENCIAL</h3>
        <table>
          <tr>
            <td>
              <b>16H00</b>
            </td>
            <td>Victor Manachini</td>
            <td>Aula Criatividade Aberta</td>
            {/* <td>A03 e A04 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>16H15</b>
            </td>
            <td>Victor Manachini</td>
            <td>Dinâmica Desbloqueio</td>
            {/* <td>A03 e A04 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>16H30</b>
            </td>
            <td>Kisarum Alexandre</td>
            <td>Experiência MECA</td>
            {/* <td>A03 e A04 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>17H30</b>
            </td>
            <td>Victor Manachini</td>
            <td>Aula Futuros Possíveis</td>
            {/* <td>A03 e A04 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>17h50</b>
            </td>
            <td>Victor Manachini</td>
            <td>Experiência Lentes do Futuro</td>
            {/* <td>A03 e A04 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>18h20</b>
            </td>
            <td>Todos</td>
            <td>Encerramento</td>
            {/* <td>A03 e A04 (20 pessoas)</td> */}
          </tr>
        </table>
      </div>
    );
  }

  function getHTMLGroup5678S() {
    return (
      <div className="table-container">
        <h3>DIA 01 - Seg. 29/11 - ONLINE 01</h3>
        <table>
          <tr>
            <td>
              <b>Horário</b>
            </td>
            <td>
              <b>Mentor</b>
            </td>
            <td>
              <b>Aula Dinâmica</b>
            </td>
            <td>
              <b>Local</b>
            </td>
            {/* <td>
                      <b>Grupo B</b>
                  </td> */}
          </tr>
          <tr>
            <td>
              <b>13H00</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Boas Vindas</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTFmZDUyYjQtNGFkNy00MzQ2LWFjNTItNzk1Y2JhZGNkMzY5%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>B01, 02, 03 e 04 (40 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>13H05</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Introdução / Cronograma Geral/Cronograma do Dia</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTFmZDUyYjQtNGFkNy00MzQ2LWFjNTItNzk1Y2JhZGNkMzY5%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>B01, 02, 03 e 04 (40 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>13H10</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Definição do Desafio (apresentação do desafio e origem)</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTFmZDUyYjQtNGFkNy00MzQ2LWFjNTItNzk1Y2JhZGNkMzY5%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>B01, 02, 03 e 04 (40 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>13H20</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Conteúdo Teórico - Repertório / Brainstorming / Ideação</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTFmZDUyYjQtNGFkNy00MzQ2LWFjNTItNzk1Y2JhZGNkMzY5%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>B01, 02, 03 e 04 (40 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>13H35</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Prática de Ideação (Brainwriting + Brainstorming)</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTFmZDUyYjQtNGFkNy00MzQ2LWFjNTItNzk1Y2JhZGNkMzY5%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>
                    B01, 02, 03 e 04 (40 pessoas)
                  </td> */}
          </tr>
          <tr>
            <td>
              <b>14H00</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Follow Up</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTFmZDUyYjQtNGFkNy00MzQ2LWFjNTItNzk1Y2JhZGNkMzY5%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>B01, 02, 03 e 04 (40 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>14H05</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Intervalo para Descompressão</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTFmZDUyYjQtNGFkNy00MzQ2LWFjNTItNzk1Y2JhZGNkMzY5%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>B01, 02, 03 e 04 (40 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>14H15</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Conteúdo Teórico - Mapa de Contexto / Definição da Ideia</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTFmZDUyYjQtNGFkNy00MzQ2LWFjNTItNzk1Y2JhZGNkMzY5%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>B01, 02, 03 e 04 (40 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>14H25</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Pitch das Startups</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTFmZDUyYjQtNGFkNy00MzQ2LWFjNTItNzk1Y2JhZGNkMzY5%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>B01, 02, 03 e 04 (40 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>14H30</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Prática Mapa de Contexto</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTFmZDUyYjQtNGFkNy00MzQ2LWFjNTItNzk1Y2JhZGNkMzY5%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>B01, 02, 03 e 04 (40 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>14H40</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>
              Prática de definição de ideia (matriz de impacto x abrangência)
            </td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTFmZDUyYjQtNGFkNy00MzQ2LWFjNTItNzk1Y2JhZGNkMzY5%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>B01, 02, 03 e 04 (40 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>14H55</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Conclusões. To Do e Encerramento</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTFmZDUyYjQtNGFkNy00MzQ2LWFjNTItNzk1Y2JhZGNkMzY5%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>B01, 02, 03 e 04 (40 pessoas)</td> */}
          </tr>
        </table>
      </div>
    );
  }

  function getHTMLGroup9101112S() {
    return (
      <div className="table-container">
        <h3>DIA 01 - Seg. 29/11 - ONLINE</h3>
        <table>
          <tr>
            <td>
              <b>Horário</b>
            </td>
            <td>
              <b>Mentor</b>
            </td>
            <td>
              <b>Aula Dinâmica</b>
            </td>
            <td>
              <b>Local</b>
            </td>
            {/* <td>
                        <b>Grupo C</b>
                    </td> */}
          </tr>
          <tr>
            <td>
              <b>16H00</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Boas Vindas</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmNjYWVmNDYtOTU4Zi00ZDQzLTkwZjAtNzBlMzVhMjhmNDJh%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>A01 e A02 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>16H05</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Introdução / Cronograma Geral/Cronograma do Dia</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmNjYWVmNDYtOTU4Zi00ZDQzLTkwZjAtNzBlMzVhMjhmNDJh%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>A01 e A02 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>16H10</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Definição do Desagio (apresentação do desafio e origem)</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmNjYWVmNDYtOTU4Zi00ZDQzLTkwZjAtNzBlMzVhMjhmNDJh%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>A01 e A02 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>16H20</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Conteúdo Teórico - Repertório / Brainstorming / Ideação</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmNjYWVmNDYtOTU4Zi00ZDQzLTkwZjAtNzBlMzVhMjhmNDJh%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>A01 e A02 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>16H35</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Prática de Ideação (Brainwriting + Brainstorming)</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmNjYWVmNDYtOTU4Zi00ZDQzLTkwZjAtNzBlMzVhMjhmNDJh%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>
                      A01 e A02 (20 pessoas)
                    </td> */}
          </tr>
          <tr>
            <td>
              <b>17H00</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Follow Up</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmNjYWVmNDYtOTU4Zi00ZDQzLTkwZjAtNzBlMzVhMjhmNDJh%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>A01 e A02 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>17H05</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Intervalo para Descompressão</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmNjYWVmNDYtOTU4Zi00ZDQzLTkwZjAtNzBlMzVhMjhmNDJh%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>A03 e A04 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>17H15</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Conteúdo Teórico - Mapa de Contexto / Definição da Ideia</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmNjYWVmNDYtOTU4Zi00ZDQzLTkwZjAtNzBlMzVhMjhmNDJh%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>A03 e A04 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>17H25</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Pitch das Startups</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmNjYWVmNDYtOTU4Zi00ZDQzLTkwZjAtNzBlMzVhMjhmNDJh%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>A03 e A04 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>17H30</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Prática Mapa de Contexto</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmNjYWVmNDYtOTU4Zi00ZDQzLTkwZjAtNzBlMzVhMjhmNDJh%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>A03 e A04 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>17H40</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>
              Prática de definição de ideia (matriz de impacto x abrangência)
            </td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmNjYWVmNDYtOTU4Zi00ZDQzLTkwZjAtNzBlMzVhMjhmNDJh%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>A03 e A04 (20 pessoas)</td> */}
          </tr>
          <tr>
            <td>
              <b>17H55</b>
            </td>
            <td>Eduardo e Guilherme</td>
            <td>Conclusões. To Do e Encerramento</td>
            <td>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmNjYWVmNDYtOTU4Zi00ZDQzLTkwZjAtNzBlMzVhMjhmNDJh%40thread.v2/0?context=%7b%22Tid%22%3a%2271af50a5-3660-4e5c-b08c-8b3478ee7809%22%2c%22Oid%22%3a%22e6039fb2-1009-4146-9f0e-852e36445af7%22%7d"
              >
                Acessar sala
              </a>
            </td>
            {/* <td>A03 e A04 (20 pessoas)</td> */}
          </tr>
        </table>
      </div>
    );
  }

  if (event !== 'pfizerstart-se') {
    return <Redirect to={`/${event}/lobby`} />;
  }

  return (
    <>
      {!!channelModel && channelModel?.key == 'externallink' && (
        <DashboardMasterPage2 {...props} style={{ width: '100% !important' }}>
          <Container
            customization={eventContext?.channel?.customization || {}}
            style={{ width: '100% !important' }}
          >
            {/* {!!channelModel && channelModel?.key == 'externallink' && ( */}
            <iframe
              src={
                channelModel?.url +
                user?.id +
                '&skip=' +
                (user?.isModerator ? true : false)
              }
              style={{
                width: '100%',
                height:
                  eventContext?.channel?.type_room == 'eventShowRoom'
                    ? 'calc(100vh - 65px)'
                    : '100vh',
              }} /*Se tiver footer tem que ser - 176*/
            />
            {/* )} */}
          </Container>
        </DashboardMasterPage2>
      )}

      {(!channelModel || channelModel.key == '') && (
        <DashboardMasterPage2 {...props}>
          {/* MODO NORMAL  */}
          {width > 920 && (
            <Container
              customization={eventContext?.channel?.customization || {}}
              style={{
                alignItems: 'center',
                justifyItems: 'flex-start',
                display: width > 920 ? 'flex' : 'none',
                background: eventContext?.channel?.customization?.background
                  ? `url(${eventContext?.channel?.customization.background})`
                  : eventContext?.event?.customization.backgroundColor ||
                    '#f5f8fd',
                backgroundSize: '100% 100%',
              }}
            >
              <br style={{ padding: 5 }} />
              <div
                style={{
                  height:
                    width <= 1600
                      ? Number(Number(widthVideoSS) / 1.67)
                      : Number(
                          width <= 1200
                            ? Number(Number(widthVideoSS) / 2)
                            : Number(Number(widthVideoSS) / 1.68),
                        ),
                  width: isRetrato ? width : widthVideoSS,
                  // background:
                  //   eventContext.event?.customization.backgroundColor ||
                  //   '#f5f8fd',
                }}
              >
                <div className="tables-container">
                  {(user?.fields?.group === 'Grupo 1' ||
                    user?.fields?.group === 'Grupo 2') &&
                    getHTMLGroup12()}
                  {(user?.fields?.group === 'Grupo 3' ||
                    user?.fields?.group === 'Grupo 4') &&
                    getHTMLGroup34()}

                  {(user?.fields?.group === 'Grupo 5' ||
                    user?.fields?.group === 'Grupo 6' ||
                    user?.fields?.group === 'Grupo 7' ||
                    user?.fields?.group === 'Grupo 8') &&
                    getHTMLGroup5678S()}

                  {(user?.fields?.group === 'Grupo 9' ||
                    user?.fields?.group === 'Grupo 10' ||
                    user?.fields?.group === 'Grupo 11' ||
                    user?.fields?.group === 'Grupo 12') &&
                    getHTMLGroup9101112S()}
                </div>
                {/* t(eventContext.channel?.type_room) || t('Loading') */}
                {/* FACELIVE
                 {
                  !!eventContext.channel?.type_room && eventContext.channel?.type_room == 'eventFacelive' &&
                  <Facelive></Facelive>
                } */}
                {!isRetrato && width > 920 && (
                  <TabMenuRight
                    style={{
                      display: !isRetrato && width > 920 ? 'flex' : 'none',
                    }}
                    height={Number(Number(largItem / 1.6) + 40)}
                    width={330}
                  />
                )}
              </div>
              <div
                style={{
                  width: widthVideoSS,
                  // minHeight: maxWid / 1.75,
                  marginBottom: '10px',
                }}
                className="containerretratomenuandbanner"
              >
                {/* , minHeight: maxWid / 1.75  */}
                <MenuTabRoom
                  style={{
                    ...modoRetratoCSSmenuRoom,
                    display: isRetrato || width < 920 ? 'flex' : 'none',
                    alignSelf: 'stretch',
                  }}
                  restProps={props}
                />
                {(isRetrato || width < 920) && (
                  <TabMenuRight
                    style={{
                      display: isRetrato || width < 920 ? 'flex' : 'none',
                    }}
                    height={maxWid / 1.75}
                    width={330}
                  />
                )}
                {isRetrato && width > 920 && <div style={{ width: width }} />}
                <MenuTabRoom
                  style={{
                    ...modoRetratoCSSmenuRoom,
                    display: !isRetrato ? 'flex' : 'none',
                  }}
                  restProps={props}
                />
                <div
                  style={{
                    display: !isRetrato ? 'flex' : 'none',
                    width: 24,
                    height: 20,
                  }}
                />
              </div>
            </Container>
          )}
          {/* MODO MOBILE */}
          {width <= 920 && (
            <Container
              customization={eventContext?.channel?.customization || {}}
              style={{
                display: width <= 920 ? 'flex' : 'none',
                minHeight: height,
                alignItems: 'center',
                justifyItems: 'flex-start',
                background: eventContext?.channel?.customization?.background
                  ? `url(${eventContext?.channel?.customization.background})`
                  : eventContext?.event?.customization.backgroundColor ||
                    '#f5f8fd',
                backgroundSize: '100% 100%',
              }}
            >
              <div
                style={{
                  height:
                    width <= 500
                      ? Number(width) * 1.01
                      : Number(width / 2) * 1.75,
                  width: isRetrato ? width : widthVideoSS,
                  marginBottom: user?.isModerator ? '15px' : '',
                }}
                className="divrowplayerchat"
              >
                <div className="tables-container">
                  {(user?.fields?.group === 'Grupo 1' ||
                    user?.fields?.group === 'Grupo 2') &&
                    getHTMLGroup12()}
                  {(user?.fields?.group === 'Grupo 3' ||
                    user?.fields?.group === 'Grupo 4') &&
                    getHTMLGroup34()}

                  {(user?.fields?.group === 'Grupo 5' ||
                    user?.fields?.group === 'Grupo 6' ||
                    user?.fields?.group === 'Grupo 7' ||
                    user?.fields?.group === 'Grupo 8') &&
                    getHTMLGroup5678S()}

                  {(user?.fields?.group === 'Grupo 9' ||
                    user?.fields?.group === 'Grupo 10' ||
                    user?.fields?.group === 'Grupo 11' ||
                    user?.fields?.group === 'Grupo 12') &&
                    getHTMLGroup9101112S()}
                </div>
              </div>
              <TabMenuRight
                style={{ display: 'flex' }}
                height={Number(width / 2) * 3}
                width={widthVideoSS}
              />
              <div style={{ padding: 10 }} />
              <MenuTabRoom
                style={{
                  ...modoRetratoCSSmenuRoom,
                  display: 'flex',
                  width: widthVideoSS,
                  height: width + 70,
                }}
                restProps={props}
              />
            </Container>
          )}
        </DashboardMasterPage2>
      )}
      {eventContext?.event && user && (
        <PresenceUtils
          user={user}
          eventId={eventContext?.event?.id}
          channelId={eventContext?.channel?.id}
        />
      )}
    </>
  );
};
export default Schedule;
