import styled, { css } from 'styled-components';

interface Ibutton{
  theaterMode ?: boolean;
  customization ?: any;
}

export const TabButtons = styled.div<Ibutton>`
  display: flex;
  position: relative;
  z-index: 1;
  ${(props) => css`
    background: linear-gradient(
        -45deg,
        ${props.customization?.headerBgColor1 || 'rgba(0,0,0, 0.2)'} 20%,
        ${props.customization?.headerBgColor2 || 'rgba(0,0,0, 0.2)'} 80%
      );
`}
  background: ${props => props.theme.title === 'dark' && props.theme.colors.background};
  border-radius: 10px 10px 0 0;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: both mandatory;
  ${props => props.theaterMode ? css`
    flex-wrap: wrap;
    overflow: hidden;
    width: 1072px;
  ` : css`
    overflow: auto;
  `}
  &::-webkit-scrollbar {
  height: 4px;
}

&::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: transparent;
}
  @media (max-width:768px){
    *{
    cursor: grab !important;
  }
}
`
