import React, { CSSProperties, useEffect, useState } from 'react';
import { MainContainer2Column } from '../../components/Ui/MainContainer2Column';
import { useAuth } from '../../hooks/Auth2';
import { useEvent } from '../../hooks/EventContext';
import { useHistory, useParams } from 'react-router-dom';
import $ from 'jquery';
import Facelive from '../../components/FaceLive';
import SideTabMenu from '../../components/SideTabMenu';
import SpeakersContainer from '../../components/SpeakersContainer/index';
import Header from '../../components/Header';
import useWindowDimensions from '../../hooks/useDimension';
import Footer from '../../components/Footer';
import FooterTerms from '../../components/FooterTerms';
import DashboardMasterPage2 from '../../masterpages/DashboardMasterPage2';
interface Resources {
  tab: boolean;
  name: string;
  key: string;
}

interface Data {
  description: string;
  eventdate: string;
  eventhour: string;
  name: string;
  resources: Resources[];
}
const Speakers: React.FC<any> = (props) => {
  const history = useHistory();
  const { event, channel } = useParams<{ channel: string; event: string }>();
  const { getUser, setUser } = useAuth();
  const user = getUser();
  const eventContext = useEvent();
  const { width, height } = useWindowDimensions();
  const inputStyle = width > 920 ? 50 : 0;
  const [activeObj, setActiveObj] = useState<any>({}); //{ chat: true }
  const [channelModel, setChannelModel] = useState<any>('');

  const DATA = eventContext.event;

  useEffect(() => {
    try {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    } catch (error) {}
  }, []);

  useEffect(() => {}, [eventContext]);

  function enableComponent(componentName: string) {
    const obj = { ...activeObj };
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = false;
      }
    }
    obj[componentName] = true;
    setActiveObj(obj);

    // $('.' + componentName + '-tab').removeClass('active');
  }

  useEffect(() => {
    if (eventContext?.channel?.resources?.length > 0) {
      enableComponent(eventContext?.channel?.resources[0].key);
    }

    if (
      !!user?.restrictChannels &&
      Object.keys(user?.restrictChannels).length > 0 &&
      eventContext?.channel?.key != undefined
    ) {
      Object.entries<string>(user?.restrictChannels).forEach((entry) => {
        const [keyy, value] = entry;

        if (eventContext?.channel?.key == keyy) {
          user.role = value;
          setUser(user);
        }
      });
    }

    setChannelModel(eventContext?.channel?.customization?.channel_model || '');

    $('#iframeBox')
      .contents()
      .find('img')
      .css({ width: '100% !important', height: '90% !important' });

    return () => {};
  }, [eventContext?.event, eventContext?.channel]);

  useEffect(() => {
    setChannelModel(eventContext?.channel?.customization?.channel_model || '');

    $('#iframeBox')
      .contents()
      .find('img')
      .css({ width: '100% !important', height: '90% !important' });

    // return () => {
    //   if (intervalTotal) clearInterval(intervalTotal);
    //   if (intervalUpdate) clearInterval(intervalUpdate);
    //   // console.log('Page Dashboard render unmount');
    // };
  }, [eventContext.event?.id, eventContext?.channel?.id]);

  if (user?.restrictChannels) {
    const userRestrictChannels = Object.keys(user?.restrictChannels);

    if (
      userRestrictChannels.findIndex((channelKey) => channelKey === channel) < 0
    ) {
      history.push(`/${event}`);
      return null;
    }
  }

  return (
    <DashboardMasterPage2 {...props}>
      {(!channelModel || channelModel.key == '') && (
        <MainContainer2Column>
          <SpeakersContainer />
          <div className="features-position__sticky">
            {/* <SideTabMenu /> */}
          </div>
        </MainContainer2Column>
      )}
    </DashboardMasterPage2>
  );
};
export default Speakers;
