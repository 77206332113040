import React from 'react';
import FooterTerms from '../../components/FooterTerms';
import { Container } from './styles';
//import logoImage from '../../assets/images/ve4U7ef.png'

export interface MasterProps {
  noContent: boolean;
}

const OpenMasterPage: React.FC<MasterProps> = (props) => {

  return (
    <Container>
      <main>
        <article className={props.noContent ? "screen col-md-12" : "screen col-md-8"}>
          <div className="content">

          </div>
        </article>
        {!props.noContent ? (<aside className="col-md-4">{props.children}</aside>) : ''}
      </main>
      <FooterTerms />
    </Container>
  );
};

export default OpenMasterPage;
