import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { asEnumerable } from 'linq-es2015';
import { SidebarItem } from '../../types/SidebarItem';
import { useEvent } from '../../hooks/EventContext';
import { RiTempHotLine } from 'react-icons/ri';
type SidebarLinkProps = {
  item: SidebarItem;
};

const SidebarLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  font-size: 1.125rem;
  padding: 1.5rem;
  text-decoration: none;
  color: #ffffff !important;
  border-bottom: 1px solid rgb(210, 210, 210);
  &:hover {
    color: #1f1f1b
    /* background-color: #1f1f1b;
    border-left: 4px solid #6d44dc; */
  }
`;

const SidebarLabel = styled.span`
  margin-left: 1rem;
`;

const DropdownLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3.75rem;
  font-size: 1.125rem;
  padding-left: 3rem;
  text-decoration: none;
  color: #ffffff;

  &:hover {
    background-color: #6d44dc;
  }
`;

const Submenu: FC<SidebarLinkProps> = ({ item }) => {
  const { event, channel } = useParams<{ event: string; channel: string }>();
  const { path, ...rest } = useRouteMatch();
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);
  const { t, i18n } = useTranslation();
  const eventContext = useEvent();
  console.log('Submenu', { path, rest });
  if (item.path === "/terms-of-use" && i18n.language === 'en') {
    return (
      <SidebarLink
        to={`/${event}${item.path.replace('{{channel}}', eventContext?.event?.pagestart === 'lobby' ? `dashboard/${eventContext.channels[0]?.key}`
          : `${eventContext?.event?.pagestart}`)}`}
        onClick={(e) => {
          e.preventDefault();
          window.open("https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fvolpecapital-convidado%2FTerms%20and%20Conditions%20of%20Use_Volpe%20Capital.pdf?alt=media&token=1371b567-24d4-4943-9cf8-88ce24e77440", '_blank')
        }}
      // className={item?.MenuNavbar ? 'blockMobile' : ''}
      >
        <div className='siderbar-div'>
          {item.icon}
          <SidebarLabel>{
            `${t(`${item.title}`)}`
          }</SidebarLabel>
        </div>
        <div>
          {item?.subnav && subnav ? item?.iconOpened : item?.iconClosed}
        </div>
      </SidebarLink>
    )
  }
  if (item.path === "/privacy-and-policy" && i18n.language === 'en') {
    return (
      <SidebarLink
        to={`/${event}${item.path.replace('{{channel}}', eventContext?.event?.pagestart === 'lobby' ? `dashboard/${eventContext.channels[0]?.key}`
          : `${eventContext?.event?.pagestart}`)}`}
        onClick={(e) => {
          e.preventDefault();
          window.open("https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fvolpecapital-convidado%2FPrivacy%20Policy_Volpe%20Capital.pdf?alt=media&token=909f68e8-c6c6-4a29-bbd6-ad0083c0d8b4", '_blank')
        }}
      // className={item?.MenuNavbar ? 'blockMobile' : ''}
      >
        <div className='siderbar-div'>
          {item.icon}
          <SidebarLabel>{
            `${t(`${item.title}`)}`
          }</SidebarLabel>
        </div>
        <div>
          {item?.subnav && subnav ? item?.iconOpened : item?.iconClosed}
        </div>
      </SidebarLink>
    )
  }
  return (
    <>
      {asEnumerable(eventContext.event?.resources || []).Any(
        (r: any) => r.key == `${item.key}` || item.key === 'default',
      ) && (
          <SidebarLink
            to={`/${event}${item.path.replace('{{channel}}', eventContext?.event?.pagestart === 'lobby' ? `dashboard/${eventContext.channels[0]?.key}`
              : `${eventContext?.event?.pagestart}`)}`}
            onClick={showSubnav}
          // className={item?.MenuNavbar ? 'blockMobile' : ''}
          >
            <div className='siderbar-div'>
              {item.icon}
              <SidebarLabel>{
                `${t(`${item.title}`)}`
              }</SidebarLabel>
            </div>
            <div>
              {item?.subnav && subnav ? item?.iconOpened : item?.iconClosed}
            </div>
          </SidebarLink>
        )}
      {subnav &&
        item?.subnav?.map((subnavItem, index) => {
            return (
              <DropdownLink to={subnavItem.path} key={index}>
                {subnavItem.icon}
                <SidebarLabel>{subnavItem.title}</SidebarLabel>
              </DropdownLink>
            );
        })}
    </>
  );
};

export default Submenu;
